import store from '@/store/store'

// import feConstants from '@/store/constants/config/fe_constants'
import { loadView, loadComponent } from '@/helpers/asyncLoaders'
import { beforeEnterMiddleware } from '@/router/navGuards'
import { ordersPromoMeta } from '@/router/routeMeta'
import feConstants from '@/store/constants/config/fe_constants'

import {
  VUEX_OPTIONS_FETCH
} from '@/store/constants/options'

export default {
  path: '/orders/promos',
  component: loadView('app/App.vue'),
  beforeEnter: (to, from, next) => {
    beforeEnterMiddleware(to, from, next, {
      authenticate: true,
      permissions: {
        accessRequires: [{ permission: ITS__PERMISSION__ORDERS__PROMO_ORDERS }]
      },
      callback: async (next) => {
        await store.dispatch(VUEX_OPTIONS_FETCH, 'orders')
        next()
      }
    })
  },
  children: [
    {
      path: '',
      name: 'orders--default',
      redirect: { name: 'orders--promo-orders--needs-review' }
    },
    {
      path: 'needs-review',
      name: 'orders--promo-orders--needs-review',
      component: loadComponent('Orders/Orders_PromoOrders.vue'),
      meta: {
        orderType: feConstants.ITS__ORDERS__ORDER_REQUEST_TYPE__SAMPLE,
        orderSubtype: feConstants.ITS__ORDERS__ORDER_TYPE__PROMO,
        manageType: feConstants.ITS__ORDERS__PROMO__NEEDS_REVIEW,
        allowCoordinatorChange: false
      }
    },
    {
      path: 'reviewed',
      name: 'orders--promo-orders--reviewed',
      component: loadComponent('Orders/Orders_PromoOrders.vue'),
      meta: {
        orderType: feConstants.ITS__ORDERS__ORDER_REQUEST_TYPE__SAMPLE,
        orderSubtype: feConstants.ITS__ORDERS__ORDER_TYPE__PROMO,
        manageType: feConstants.ITS__ORDERS__PROMO__REVIEWED,
        allowCoordinatorChange: true
      }
    },
    {
      path: 'archived',
      name: 'orders--promo-orders--archived',
      component: loadComponent('Orders/Orders_PromoOrders.vue'),
      meta: {
        orderType: feConstants.ITS__ORDERS__ORDER_REQUEST_TYPE__SAMPLE,
        orderSubtype: feConstants.ITS__ORDERS__ORDER_TYPE__PROMO,
        manageType: feConstants.ITS__ORDERS__PROMO__ARCHIVED,
        allowCoordinatorChange: true
      }
    },
    {
      path: 'order/:orderId',
      name: 'orders--promo-orders--details',
      component: loadComponent('Orders/Orders_Details.vue'),
      beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_OPTIONS_FETCH, 'assortments')
        ]).then(() => next())
      },
      meta: {
        orderType: feConstants.ITS__ORDERS__ORDER_REQUEST_TYPE__SAMPLE,
        orderSubtype: feConstants.ITS__ORDERS__ORDER_TYPE__PROMO,
        manageType: feConstants.ITS__ORDERS__PROMO__DETAIL
      }
    }/* ,
    {
      path: 'coordinator-assignments',
      name: 'orders--manage-assignments',
      component: loadComponent('Orders/CoordinatorAssignments/Orders_CoordinatorAssignments.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch(VUEX_ROUTING_ENTER_ORDERS_COORDINATOR_ASSIGNMENTS, { to: to, from: from }).then(next)
      },
      meta: {}
    } */
  ],
  meta: ordersPromoMeta
}
