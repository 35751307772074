<template>
  <div class="cell-dropdown-picker">
    <div v-if="this.doRender" justify="center">
      <div class="field-holder fh1" v-if="dropdownType===1">
        <v-autocomplete
          filled density="compact"
          autofocus
          v-model:search="autocompleteSearch"
          :menu-props=this.menuProps
          :multiple="this.multipleSelect"
          :items="this.lookup"
          item-title="label"
          item-value="value"
          v-model="picker"
          @input="setIsTypingWithClear"
          @keydown.enter="closePopup"
          @keydown.tab="closePopup"
          ref="vuetifyComponent"
          id="cell-dropdown-picker-1"
          :style="styleMinWidth"
          :no-data-text="customNoDataText"
          :loading="isLoading"
          :editable="canAdd"
          autocomplete="off"
        ></v-autocomplete>
      </div>
      <div class="field-holder fh2" v-else-if="dropdownType===2">
        <v-autocomplete
          filled density="compact"
          autofocus
          v-model:search="autocompleteSearch"
          :menu-props="this.menuProps"
          :multiple="this.multipleSelect"
          :items="this.lookup"
          item-title="label"
          item-value="value"
          v-model="picker"
          ref="vuetifyComponent"
          id="cell-dropdown-picker-2"
          :style="styleMinWidth"
          @input="itemClicked"
          @keydown.enter="closePopup"
          @keydown.tab="closePopup"
          autocomplete="off"
          :editable="canAdd"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :disabled="item.raw.disabled"
              @keydown.enter="closePopup"
              @click="itemClicked"
            ></v-list-item>
          </template>
        </v-autocomplete>
      </div>
      <div class="field-holder fh3" v-else-if="dropdownType===3">
        <v-combobox
          filled density="compact"
          autofocus
          full-width
          :editable="canAdd"
          :menu-props=this.menuProps
          :multiple="this.multipleSelect"
          :chips="this.multipleSelect"
          :placeholder="'Enter ' + String(this.params.colDef.headerName).toLowerCase()"
          :items="this.lookup"
          item-title="label"
          item-value="value"
          v-model="picker"
          @input="setIsTypingWithClear"
          @keydown.enter="closePopup"
          @keydown.tab="closePopup"
          ref="vuetifyComponent"
          id="cell-dropdown-picker-3"
          :style="styleMinWidth"
          autocomplete="off"
        ></v-combobox>
      </div>

    </div>
  </div>
</template>

<script>
import PropertiesLookupLists from '@/components/_core/GridsCore/helpers/PropertiesLookupLists'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import { VUEX_API_SAMPLE_INVENTORY_AUTOCOMPLETE } from '@/store/constants/api'
import _debounce from 'lodash/debounce'
import { onUnmounted } from 'vue'
export default {
  name: 'DropdownEditor',
  data () {
    return {
      myComponent: null,
      picker: [],
      lookup: [],
      autocompleteSearch: '',
      openedOnce: false,
      isTyping: false,

      // https://vuetifyjs.com/en/components/menus/
      menuProps: {
        // value:true,
        // absolute:true
        transition: 'none'
      },
      doRender: false, // pause the rendering for a beat - fixes some vuetify/ag grid timing issues

      canAdd: false,
      multipleSelect: false,
      doAutocomplete: false,
      doAutocompleteSpecial: '',
      customNoDataTextMode: null, // label for items lookup
      isLoading: false,
      focusedIndex: -1,//used for arrows
    }
  },
  watch: {
    picker: {
      deep: true,
      handler () {
        // need to do a timeout, because this.isTyping needs to update before picker handler, but it doesn't per vue3 sequencing.
        setTimeout(function () {
          if (!this.isTyping && this.openedOnce) {
            this.itemClicked()
          }
          this.openedOnce = true
        }.bind(this), 5)
        // this was triggered via @input="itemClicked" - but vuetify3 killed that idea
      }
    }
  },
  computed: {
    dropdownType () {
      let ret = null
      if (this.doAutocomplete) {
        ret = 1
      } else if (!this.canAdd) {
        ret = 2
      } else {
        ret = 3
      }
      return ret
    },
    styleMinWidth () {
      if (this.params?.colDef?.minWidth) {
        return 'min-width:' + this.params?.colDef?.minWidth + 'px'
      } else {
        return ''
      }
    },
    customNoDataText () {
      if (this.customNoDataTextMode) {
        return this.customNoDataTextMode
      } else {
        return 'No Results Found'
      }
    }
  },
  mounted () {

    this.openedOnce = false

    this.menuProps['position-x'] = 0
    this.menuProps['position-y'] = 0

    // defaults
    let eventKey = this.params.eventKey
    if (eventKey === 'Enter') {
      eventKey = null
    }
    if (eventKey) {
      this.picker = eventKey
    } else {
      this.picker = this.params.value
    }
    this.canAdd = (this.params.canAdd)
    this.doAutocomplete = (this.params.doAutocomplete)

    // pass a type to be used in doAutocompleteLookup function
    if (this.params.doAutocompleteSpecial) {
      this.doAutocompleteSpecial = this.params.doAutocompleteSpecial
      this.doAutocomplete = true
      this.customNoDataTextMode = 'Enter Search Terms'
    }

    this.multipleSelect = (this.params.multipleSelect)
    if (this.multipleSelect) {
      let val = this.params.value?.toString()
      if (val) {
        this.picker = this.params?.value.toString().split(',')
      } else {
        this.picker = []
      }
    }

    // vue3 / aggrid delay madness
    setTimeout(this.postMounted, 50)
  },
  methods: {
    getValue () {
      let val = (this.picker?.value) ? (this.picker.value) : this.picker
      if (Array.isArray(val)) {
        val = [...new Set(val)]
      }
      return val
    },
    isPopup () {
      return true
    },
    setIsTypingWithClear () {
      this.isTyping = true
      this.inputCallbackWithTypingClear2()
    },
    inputCallbackWithTypingClear2: _debounce(function () {
      this.isTyping = false
      if (this.doAutocompleteSpecial) {
        this.doAutocompleteLookup()
      }
    }, 500),

    itemClicked (e) {
      if (this.multipleSelect) {
        if (this.doAutocomplete) {
          // clear input box
          this.autocompleteSearch = ''
        }
      } else {
        // in the past, we'd close if canAdd.  But thing we should just do it for either that is not multi-select
        if (this.canAdd) {
          this.closePopup()
        } else {
          this.closePopup()
        }
      }
    },
    closePopup () {
      if (this.dropdownType === 1 || this.dropdownType === 2) {
        if (!this.multipleSelect) {
          this.picker = this.getValueByLabel(this.autocompleteSearch)
          // if (this.canAdd) {
          //   this.picker = this.autocompleteSearch
          // } else {
          //   this.picker = this.autocompleteSearch
          // }
        }
      }
      this.params.stopEditing()
    },
    postMounted () {
      this.doRender = true
      // vue3 / aggrid delay madness
      setTimeout(this.postMounted2, 10)
    },
    postMounted2 () {
      // SET PICKER DROPDOWNS BASED ON FIELD NAMES
      let field = this.params.colDef.field
      this.lookup = PropertiesLookupLists.getPropertiesLookupList(field, false, false, this)
      setTimeout(this.postMounted3, 10)
    },
    postMounted3 () {
      // loop through components and trigger a click
      // this lets them open by default - otherwise it's a weird double click
      // did it this way because using vuetify native open stuff was buggy within ag grid
      this.myComponent = this.$refs?.vuetifyComponent
      if (this.myComponent) {
        this.myComponent.focus()
        this.myComponent.menu = true
      }
    },
    doAutocompleteLookup () {
      let val = String(this.autocompleteSearch)
      if (val.length >= 2) {
        let endpoint = null
        switch (this.doAutocompleteSpecial) {
          case 'sampleInventoryAutoComplete':
            endpoint = VUEX_API_SAMPLE_INVENTORY_AUTOCOMPLETE
            break
        }
        if (endpoint) {
          this.lookup = []
          this.isLoading = true
          const payload = {
            property: this.params.colDef.field,
            value: val
          }

          this.customNoDataTextMode = 'Searching...'
          this.lookup = []
          GridHelpers.mgThisArray[0].$store.dispatch(endpoint, payload).then(response => {
            this.lookup = response
            this.isLoading = false
            this.customNoDataTextMode = ''
          })
        }
      } else {
        this.lookup = []
        this.customNoDataTextMode = 'Enter Search Terms'
      }
    },
    getValueByLabel (label) {
      const item = this.lookup.find(item => item.label === label)
      if (item) {
        if (item.value) {
          return item.value
        } else {
          return label
        }
      } else {
        return label
      }
    }
  } //methods
}
</script>
