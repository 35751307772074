<template>
  <div class="libraries-grid-date-typer">
    <div v-if="backgroundFormatter" class="background-formatter">
      <span>MM/DD/YY</span>
    </div>
    <div class="input-holder">
      <input
        class="ag-input-field-input"
        ref="inputRef"
        v-model="picker"
        @input="formatInput"
        @keydown="handleKey"
        maxlength="8"
      />
    </div>
  </div>
</template>

<script>
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import ColumnHelpers from '@/components/_core/GridsCore/helpers/ColumnHelpers'
import shared from 'skch_its_be_fe_shared'
import dayjs from 'dayjs'
const dateOnlyFromJsDate = shared.dates.dateOnlyFromJsDate
export default {
  name: 'DateTyperEditor',
  data () {
    return {
      valueOnMount: null,
      picker: null,
      backgroundFormatter: null,
      isDeleteAction: false
    }
  },
  mounted () {
    let val = this.params.value
    let doEventKey = false

    this.valueOnMount = val // make sure valueOnMount DOES NOT = this.params.eventKey.  so we set it here instead of below the logic below

    let eventKey = this.params.eventKey
    if (eventKey === 'Enter') {
      eventKey = null
    }
    if (eventKey) {
      val = eventKey
      doEventKey = true
      this.picker = val
    }

    if (val && !doEventKey) {
      // if saveAsJsDate, value will be a number, so make it into a string - with january offset - to show default value
      if (this.params.saveAsJsDate) {
        val = GridHelpers.convertDateNumberToDateString(val)
        this.picker = ColumnHelpers.formatterDate(val, 'MM/DD/YY')
      } else {
        const yy = Number(val.substring(0, 2))
        const mm = Number(val.substring(3, 5)) - 1
        const dd = Number(val.substring(6, 8))
        let val2 = new Date(yy, mm, dd) // do this or face timezone wrath
        this.picker = val2
      }
    } else {
      this.backgroundFormatter = 'MM/DD/YYYY'
    }
    this.$refs.inputRef.focus()
    this.$nextTick(() => {
      //this.$refs.inputRef.select()
      this.$refs.inputRef.focus()
    })
  },
  methods: {
    handleKey (event) {
      // Detect if a delete key or backspace key is pressed
      this.isDeleteAction = event.key === 'Backspace' || event.key === 'Delete'
    },
    getValue () {
      let val = null
      let picker = this.picker
      if (picker) {
        if (picker.length < 8) {
          val = this.valueOnMount
        } else {
          if (this.params.saveAsJsDate) {
            val = new Date(picker)
            val = dateOnlyFromJsDate(val)
          } else {
            val = dayjs(picker).format('MM/DD/YY')
          }
        }
      }

      return val
    },
    formatInput (event) {
      let value = event.target.value
      value = value.replace(/[^0-9]/g, '') // Remove all non-digit characters

      // Break the string into parts MM/DD/YYYY
      let formattedInput = ''
      for (let i = 0; i < value.length && i < 8; i++) {
        formattedInput += value[i]
        if (i === 1) {
          if (!this.isDeleteAction || value.length >= 3) {
            formattedInput += '/'
          }
        }
        if (i === 3) {
          if (!this.isDeleteAction || value.length >= 5) {
            formattedInput += '/'
          }
        }
      }

      if (value) {
        this.backgroundFormatter = null
      } else {
        this.backgroundFormatter = 'MM/DD/YY'
      }
      this.picker = formattedInput
    },
    isPopup () {
      return false
    },
    modelUpdated (val) {
      this.picker = val
      this.params.stopEditing()
    },
    closePopup () {
      this.params.stopEditing()
    }
  }
}
</script>
<style lang="scss">
.libraries-grid-date-typer {
  width:100%;
  height: 100%;
  background-color: #fff;
  .background-formatter {
    position: absolute;
    top:8px;
    left:5px;
    opacity: 0.4;
    span {
      color:#000;
      font-size:13px;
    }
  }
  .input-holder {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    top:5px;
    left:2px;
    position: relative;
  }
  .ag-input-field-input {

  }
  input {
    background-color: transparent;
    border:none!important;
    box-shadow: none!important;
    outline: none!important;
    width: 100%;
    height: 100%;
    font-size:13px;
    &:focus, &:focus-visible {
      border:none!important;
      box-shadow: none!important;
      outline: none!important;
    }
  }
}
</style>
