import _debounce from 'lodash.debounce'
import cloneDeep from 'lodash/cloneDeep'

import Debug from 'logdown'

import longWaitBlocker from '@/helpers/longWaitBlocker'
import { getUserPOPColors } from '@/helpers/pop_colors'

import {
  // ASSORTMENTS
  VUEX_ASSORTMENTS_FETCH,
  VUEX_ASSORTMENTS_REFETCH,
  VUEX_ASSORTMENTS_FETCH_SUCCESS,
  VUEX_ASSORTMENTS_FETCH_FAILURE,

  VUEX_ASSORTMENTS_ALL_FETCH,
  VUEX_ASSORTMENTS_ALL_FETCH_SUCCESS,
  VUEX_ASSORTMENTS_ALL_FETCH_FAILURE,

  VUEX_ASSORTMENTS_ALL_INTERNAL_FETCH,
  VUEX_ASSORTMENTS_ALL_INTERNAL_FETCH_SUCCESS,
  VUEX_ASSORTMENTS_ALL_INTERNAL_FETCH_FAILURE,

  // ASSORTMENT
  VUEX_ASSORTMENT_FETCH,
  VUEX_ASSORTMENT_REFETCH,
  VUEX_ASSORTMENT_FETCH_SUCCESS,
  VUEX_ASSORTMENT_FETCH_FAILURE,

  VUEX_ASSORTMENT_CREATE,
  VUEX_ASSORTMENT_CREATE_SUCCESS,

  VUEX_ASSORTMENT_UPDATE,
  VUEX_ASSORTMENT_UPDATE_SUCCESS,
  VUEX_ASSORTMENT_UPDATE_FAILURE,

  VUEX_ASSORTMENT_UPDATE_DYNAMIC_PRODUCT_TOTAL_COUNT,

  VUEX_ASSORTMENT_UPDATE_QUERY,

  VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT,
  VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT_REQUEST,
  VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT_CLEAR_BATCHED_REQUEST,

  VUEX_ASSORTMENT_DUPLICATE,
  VUEX_ASSORTMENT_DUPLICATE_SUCCESS,
  VUEX_ASSORTMENT_DUPLICATE_FAILURE,

  // VUEX_ASSORTMENT_PRODUCTS_ADD,
  VUEX_ASSORTMENT_PRODUCTS_UPDATE,
  VUEX_ASSORTMENT_PRODUCTS_STATUS_UPDATE,
  VUEX_ASSORTMENT_PRODUCTS_LOOKUP_UPDATE,
  VUEX_ASSORTMENT_PRODUCTS_RENAME,

  VUEX_ASSORTMENT_TREEDATA_UPDATE,

  VUEX_ASSORTMENT_GRID_ROWS_ADD,
  VUEX_ASSORTMENT_GRID_ROWS_DELETE,
  VUEX_ASSORTMENT_GRID_ROWS_UPDATE,
  VUEX_ASSORTMENT_GRID_ROWS_UPDATE_SORT,
  VUEX_ASSORTMENT_GRID_COLUMNS_UPDATE,
  VUEX_ASSORTMENT_GRID_SETTINGS_UPDATE,
  VUEX_ASSORTMENT_GRID_SETTINGS_CUSTOMSORT_STATE_UPDATE,

  VUEX_ASSORTMENT_GRID_TREEROWS_ADD,
  VUEX_ASSORTMENT_GRID_TREEROWS_DELETE,
  VUEX_ASSORTMENT_GRID_TREEROWS_UPDATE,

  VUEX_ASSORTMENT_GRID_SET_SNAPSHOT,
  VUEX_ASSORTMENT_GRID_REQUEST_CLEAR,

  VUEX_ASSORTMENT_SET_POP_COLORS,

  VUEX_ASSORTMENT_INTERNAL_INTERNATONAL_CATEGORIES_FETCH,
  VUEX_ASSORTMENT_INTERNAL_INTERNATONAL_CATEGORIES_ADD,
  VUEX_ASSORTMENT_CATEGORIES_FETCH,
  VUEX_ASSORTMENT_CATEGORIES_ADD,

  VUEX_ASSORTMENT_ALERTS_UPDATE,

  VUEX_GRID_VALIDATE_PRODUCTS_FETCH,
  VUEX_GRID_VALIDATE_PRODUCTS_SUCCESS,
  VUEX_GRID_VALIDATE_PRODUCTS_FAILURE,

  VUEX_GRID_REKEY,
  VUEX_GRID_REKEY_EXCEL_UPLOADER,

  VUEX_GRID_CONFIRMATION_RESET_PRICES,
  VUEX_GRID_CONFIRMATION_CHANGE_INTERNAL_STATUS,
  VUEX_GRID_CONFIRMATION_LABEL_DELETE,
  VUEX_GRID_CONFIRMATION_SCHEDULE_DELETE,
  VUEX_GRID_CONFIRMATION_ARCHIVE_SELECTED,
  VUEX_GRID_CONFIRMATION_ADD_TO_ASSORTMENT,

  VUEX_GRID_ROWDATA_LOADING_START,
  VUEX_GRID_ROWDATA_LOADING_FINISH

} from '@/store/constants/models/assortments'

import {
  VUEX_API_ASSORTMENTS_REQUEST_FETCH,
  VUEX_API_ASSORTMENT_REQUEST_FETCH,

  VUEX_API_ASSORTMENT_CREATE,
  VUEX_API_ASSORTMENT_UPDATE,
  VUEX_API_ASSORTMENT_UPDATE_BATCH,
  VUEX_API_ASSORTMENT_DUPLICATE,
  VUEX_API_ASSORTMENT_DELETE,

  VUEX_API_ASSORTMENTS_INTERNAL_REQUEST_FETCH,
  VUEX_API_ASSORTMENT_INTERNAL_REQUEST_FETCH,

  VUEX_API_ASSORTMENT_PRODUCTS_UPDATE,

  VUEX_API_PRODUCTS_VALIDATE,
  VUEX_API_OPTIONS_FETCH,
  VUEX_API_ASSORTMENT_PRODUCTS_RENAME
} from '@/store/constants/api'

import {
  VUEX_GRID_UPDATE_START,
  VUEX_GRID_UPDATE_SUCCESS,
  VUEX_GRID_UPDATE_FAILURE,
  VUEX_GRID_REQUEST_SNAPSHOT
} from '@/store/constants/ui/grid'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

import DataMiddleware from '@/components/_core/GridsCore/helpers/DataMiddleware'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import router from '@/router'

// import collectionsState from '@/store/modules/models/collections'
const d = new Debug('its:store:modules:assortments')

const state = {
  // assortments
  assortments: [], // array of all assortments
  assortments2: [], // for when there are two assortments loaded on a page
  assortmentsRefetchParams: {}, // a store of the parameters used to re-draw the assortment - keeping a copy to be used to redraw component
  assortmentsAllActive: [], // a list of assortments - for example, used for reference lookups in the New Assortment popup
  assortmentsAllInternalActive: [], // a list of all internal assortments - used for reference lookups in the New/Edit Assortment popup

  // assortment
  assortment: {}, // single assortment object
  assortmentDynamicProductTotalCount: 0, // product count for dynamic assortments, updated via in the AG Grid ServerSideDatasourceAssortmentManager fetch request
  assortmentQueryParams: [], // a store of the parameters used to draw the assortment - keeping a copy to be used to redraw component
  assortmentProductsLookup: {}, // a snippet of products, used in excel import and product search
  assortmentProductsLookupToggler: '', // used to continue code related to assortmentProductsLookup
  confirmationTogglerLabelDelete: '', // used to continue code related to deleting a label
  confirmationTogglerScheduleDelete: '', // used to continue code related to deleting a schedule
  confirmationTogglerAddToAssortment: '', // used to continue code related to adding to assortment

  customContentBatchedUpdates: [],

  internalInternationalCategories: [], // specific to season/year/region
  assortmentPillars: [], // specific to static assortments

  internalAssortmentsLookupParams: {},

  // processing
  newAssortmentData: {}, // data when adding a new assortment

  // API related
  agGridRequestData: {
    addProducts: [],
    deleteProducts: [],
    updateProducts: [],
    sortProducts: [],
    // patch used for column sorting
    patch: {
      uiSettings: {
        gridSettings: {
          sortOrder: [],
          groupIsEnabled: false
        },
        groupSettings: {}
      }
    }
  },

  // AG Grid specific
  rekey: '', // if this value changes, the loaded aggrid component will do a hard-rekey
  rekeyExcelUploader: '', // if this value changes, the excel uploader aggrid component will do a hard-rekey
  agGridRowData: [], // for main assortment
  agGridRowData2: [], // for second assortment
  agGridRowDataAssortmentsAllActive: [], // to generate assortments list (used in new assortment dialogue)
  agGridSnapshot: null, // a snapshot of the current state of the grid, gets updated over time
  confirmationTogglerRowDelete: '', // used to continue row delete code after the confirmation dialogue is accepted
  confirmationTogglerResetPrices: '', // used to continue reset prices code after the confirmation dialogue is accepted
  confirmationTogglerArchiveSelected: '', // used to continue to archive selected rows after the confirmation dialogue is accepted
  confirmationTogglerChangeInternalStatus: {}, // used to prompt to change status dropdown in internal list grid
  validatedProductsResponse: [], // a response for the validated products - used in excel import
  agGridRowDataLoading: false, // if a new assortment or assortments request is happening

  // for pop assortments. Calculated from userTBEs based on pricing location
  popColors: ['AST']
}

const actions = {
  /* ASSORTMENTS FETCH */
  [VUEX_ASSORTMENTS_FETCH]: async ({ rootState, dispatch, commit }, payload) => {
    // check for tempRouterOptions parameter
    // basically only VUEX_ROUTING_ENTER_ASSORTMENT_MANAGER_QUICKACCESS uses this, since it has TWO AG Grids
    let payload1 = payload
    let payload2 = {}
    if (payload.tempRouterOptions && payload.tempRouterOptions.numAssortments) {
      payload1 = {}
      payload1.params = payload.tempRouterOptions.paramsAssortment1
      payload2.params = payload.tempRouterOptions.paramsAssortment2
    }
    // delete payload.tempRouterOptions
    delete payload.tempRouterOptions

    // store the parameters for later usage
    let commitPayload = {
      payload1: payload1,
      payload2: payload2,
      orgType: payload.orgType
    }

    // tag on extra key initiatives filters
    if (payload.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL) {
      commitPayload.payload1.extraParams = {
        region: payload.region,
        season: payload.season,
        year: payload.year,
        productType: payload.productType,
        method: payload.method,
        orgType: payload.orgType,
        channel: payload.channel
      }
    }

    await commit(VUEX_ASSORTMENTS_FETCH, commitPayload)

    // now draw the page
    await dispatch(VUEX_ASSORTMENTS_REFETCH)
  },

  // refetch uses stored parameters to draw the page
  // this is because sometimes when rekeying a page, we just wat to ping VUEX_ASSORTMENTS_REFETCH to do a hard re-draw
  [VUEX_ASSORTMENTS_REFETCH]: async ({ dispatch, commit }) => {
    await commit(VUEX_GRID_ROWDATA_LOADING_START)
    const orgType = state.assortmentsRefetchParams.orgType

    if (orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL) {
      let payload1 = { ...state.internalAssortmentsLookupParams, ...state.assortmentsRefetchParams.payload1.extraParams }

      // DomesticWholesaleAssortment, InternationalWholesaleAssortment
      // DomesticRetailAssortment, InternationalRetailAssortment
      // PASS season,

      await dispatch(VUEX_API_ASSORTMENTS_INTERNAL_REQUEST_FETCH, payload1).then(response => {
        commit(VUEX_ASSORTMENTS_FETCH_SUCCESS, response)
      }).catch(error => {
        commit(VUEX_ASSORTMENTS_FETCH_FAILURE, error)
        commit(VUEX_GRID_ROWDATA_LOADING_FINISH)
      })
    } else {
      const payload1 = state.assortmentsRefetchParams.payload1
      const payload2 = state.assortmentsRefetchParams.payload2
      const willDoMore = (payload2) ? payload2.params : false
      await dispatch(VUEX_API_ASSORTMENTS_REQUEST_FETCH, payload1).then(response => {
        if (!response) {
          response = {}
        }
        response.willDoMore = willDoMore
        commit(VUEX_ASSORTMENTS_FETCH_SUCCESS, response)

        // load next
        if (willDoMore) {
          dispatch(VUEX_API_ASSORTMENTS_REQUEST_FETCH, payload2).then(response2 => {
            if (!response2) {
              response2 = {}
            }
            response2.payload2 = true
            commit(VUEX_ASSORTMENTS_FETCH_SUCCESS, response2)
            commit(VUEX_GRID_ROWDATA_LOADING_FINISH)
          }).catch(error => {
            commit(VUEX_ASSORTMENTS_FETCH_FAILURE, error)
            commit(VUEX_GRID_ROWDATA_LOADING_FINISH)
          })
        } else {
          commit(VUEX_GRID_ROWDATA_LOADING_FINISH)
        }
      }).catch(error => {
        commit(VUEX_ASSORTMENTS_FETCH_FAILURE, error)
        commit(VUEX_GRID_ROWDATA_LOADING_FINISH)
      })
    }
  },

  /* ASSORTMENTS ALL FETCH */
  [VUEX_ASSORTMENTS_ALL_FETCH]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_ASSORTMENTS_ALL_FETCH)

    let payloadFetch = {
      params: {
        _select: 'title subTitle starred productType locationId archived alerts modifiedDate hierarchy region gender decimalsPlaces',
        state: ITS__ASSORTMENTS__STATE__PUBLISHED
      }
    }

    await dispatch(VUEX_API_ASSORTMENTS_REQUEST_FETCH, payloadFetch).then(response => {
      // the tree data conversion can take some time, and we don't want to always do it
      response.skipTreeDataConvert = payload.skipTreeDataConvert
      commit(VUEX_ASSORTMENTS_ALL_FETCH_SUCCESS, response)
    }).catch(error => {
      commit(VUEX_ASSORTMENTS_ALL_FETCH_FAILURE, error)
    })
  },

  /* ASSORTMENTS ALL FETCH */
  [VUEX_ASSORTMENTS_ALL_INTERNAL_FETCH]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_ASSORTMENTS_ALL_INTERNAL_FETCH)

    await dispatch(VUEX_API_ASSORTMENTS_INTERNAL_REQUEST_FETCH, state.internalAssortmentsLookupParams).then(response => {
      commit(VUEX_ASSORTMENTS_ALL_INTERNAL_FETCH_SUCCESS, response)
    }).catch(error => {
      commit(VUEX_ASSORTMENTS_ALL_INTERNAL_FETCH_FAILURE, error)
    })
  },

  /* ASSORTMENT FETCH */
  [VUEX_ASSORTMENT_FETCH]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_ASSORTMENT_FETCH, payload)
    return dispatch(VUEX_ASSORTMENT_REFETCH, payload).then(res => res)
  },

  [VUEX_ASSORTMENT_REFETCH]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_GRID_ROWDATA_LOADING_START)

    // `clearAssortment` will not clear state.assortment object
    // before it refetches data | used for Group Sort
    if (payload?.clearAssortment) await commit(VUEX_ASSORTMENT_REFETCH)

    if (state.assortmentQueryParams?.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL) {
      return dispatch(VUEX_API_ASSORTMENT_INTERNAL_REQUEST_FETCH, state.assortmentQueryParams).then(async response => {
        commit(VUEX_ASSORTMENT_FETCH_SUCCESS, response)
        commit(VUEX_GRID_ROWDATA_LOADING_FINISH)
        dispatch(VUEX_ASSORTMENT_SET_POP_COLORS).then(() => {
          if (response?.data?.products) {
            dispatch(VUEX_ASSORTMENT_PRODUCTS_LOOKUP_UPDATE, response.data)
          }
        })
        return response.data
      })
    } else {
      return dispatch(VUEX_API_ASSORTMENT_REQUEST_FETCH, state.assortmentQueryParams).then(async response => {
        commit(VUEX_ASSORTMENT_FETCH_SUCCESS, response)
        commit(VUEX_GRID_ROWDATA_LOADING_FINISH)
        dispatch(VUEX_ASSORTMENT_SET_POP_COLORS).then(() => {
          if (response?.data?.products) {
            dispatch(VUEX_ASSORTMENT_PRODUCTS_LOOKUP_UPDATE, response.data)
          }
        })
        return response.data
      })
    }
  },

  /* ASSORTMENT CREATE */
  [VUEX_ASSORTMENT_CREATE]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_ASSORTMENT_CREATE)
    dispatch(VUEX_GRID_UPDATE_START)
    await dispatch(VUEX_API_ASSORTMENT_CREATE, payload).then(async (response) => {
      commit(VUEX_ASSORTMENT_CREATE_SUCCESS, response)
      dispatch(VUEX_GRID_UPDATE_SUCCESS)
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'success',
          title: 'Assortment Created',
          message: `${payload.data.title}`
        }
      })
    })
  },

  /* ASSORTMENT UPDATE */
  [VUEX_ASSORTMENT_UPDATE]: async ({ dispatch, commit }, payload) => {
    if (!payload.id) payload = { id: state.assortment._id, ...payload }
    Object.assign(payload.properties, { method: state.assortment.method })

    dispatch(VUEX_GRID_UPDATE_START)
    await dispatch(VUEX_API_ASSORTMENT_UPDATE, payload).then(response => {
      commit(VUEX_ASSORTMENT_UPDATE_SUCCESS, payload)
      dispatch(VUEX_GRID_UPDATE_SUCCESS)
    }).catch(() => {
      commit(VUEX_ASSORTMENT_UPDATE_FAILURE, payload)
      dispatch(VUEX_GRID_UPDATE_FAILURE)
    })
  },

  /* UPDATE DYNAMIC PRODUCT TOTAL COUNT */
  [VUEX_ASSORTMENT_UPDATE_DYNAMIC_PRODUCT_TOTAL_COUNT]: async ({ commit }, payload) => {
    commit(VUEX_ASSORTMENT_UPDATE_DYNAMIC_PRODUCT_TOTAL_COUNT, payload)
  },

  /* UPDATE DYNAMIC ASSORTMENT QUERY and UI SETTINGS */
  [VUEX_ASSORTMENT_UPDATE_QUERY]: async ({ state, dispatch, commit }, payload) => {
    dispatch(VUEX_GRID_UPDATE_START)

    let query = {}
    payload.forEach(param => {
      switch (param.payloadType) {
        case 'object' :
          query[param.key] = {}
          query[param.key] = { ...param.value }
          break

        case 'string' :
          query[param.key] = {}
          query[param.key] = param.value
          break

        default :
          if (!query[param.key]) query[param.key] = []
          query[param.key].push(param.value)
          break
      }
    })

    let sortObj = {}

    if (state.assortment?.uiSettings?.gridSettings?.sortOrder?.length > 0) {
      state.assortment.uiSettings.gridSettings.sortOrder.map(sortItem => {
        sortObj[sortItem.colId] = (sortItem.sort === 'asc') ? 1 : -1
      })

      Object.assign(query, { _uiOptions: { sort: sortObj } })
    }

    await dispatch(VUEX_ASSORTMENT_UPDATE, {
      id: state.assortment._id,
      properties: {
        query: {
          ...query,
          'locations.code': state.assortment.locationId,
          'locations.lineStatus': state.assortment.status,
          'productType': state.assortment.productType
        },
        uiSettings: {
          gridSettings: state.assortment.uiSettings.gridSettings,
          filterBar: { parameters: payload },
          groupSettings: state.assortment.uiSettings.groupSettings
        }
      }
    }).then(() => {
      dispatch(VUEX_GRID_UPDATE_SUCCESS)
      commit(VUEX_GRID_REKEY)
    })
  },

  /* ASSORTMENT DUPLICATE */
  [VUEX_ASSORTMENT_DUPLICATE]: async ({ dispatch, commit }, payload) => {
    dispatch(VUEX_GRID_UPDATE_START)
    return dispatch(VUEX_API_ASSORTMENT_DUPLICATE, payload).then(response => {
      if (response.status === 'success' && !response.data.failues) {
        commit(VUEX_ASSORTMENT_DUPLICATE_SUCCESS)
        dispatch(VUEX_GRID_UPDATE_SUCCESS)
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'success',
            message: `Assortment Duplicated`
          }
        })
      } else {
        dispatch(VUEX_GRID_UPDATE_FAILURE)
        commit(VUEX_ASSORTMENT_DUPLICATE_FAILURE)
      }
      return response.data
    })
  },

  /* [VUEX_ASSORTMENT_PRODUCTS_ADD]: ({ commit }, payload) => {
    commit(VUEX_ASSORTMENT_PRODUCTS_ADD, payload)
  }, */

  /* ASSORTMENT PRODUCTS UPDATE */
  [VUEX_ASSORTMENT_PRODUCTS_UPDATE]: _debounce(async ({ state, dispatch, commit }) => {
    dispatch(VUEX_GRID_UPDATE_START)
    // grab assortmentId from the state assortment or from query params
    const assortmentId = (state.assortment?._id) ? state.assortment?._id : state.assortmentQueryParams?.id

    // Copy state
    let data = cloneDeep(state.agGridRequestData)
    await commit(VUEX_ASSORTMENT_GRID_REQUEST_CLEAR)

    // Construct data struct. needed by Mongo B.E
    data = Object.keys(data).map(key => {
      // if no items in array  || no items in patch.uiSettings.gridSettings.sortOrder array -> DELETE Array|Object
      if (data[key].length < 1) return delete data[key] // returns Boolean because of deletion

      if (data[key].hasOwnProperty('uiSettings')) {
        Object.assign(data[key], {
          uiSettings: {
            gridSettings: data[key].uiSettings.gridSettings,
            filterBar: (state.assortment.uiSettings && state.assortment.uiSettings.filterBar) ? state.assortment.uiSettings.filterBar : {},
            groupSettings: (state.assortment.uiSettings && state.assortment.uiSettings.groupSettings) ? state.assortment.uiSettings.groupSettings : {}
          }
        })
      }
      return { [key]: data[key] } // returns Object
    }).filter(item => typeof item !== 'boolean') // removes Boolean entries

    // hide the longwait blocker
    longWaitBlocker.hide()

    if ((data[0].addProducts && data[0].addProducts.length >= ITS__LONG_WAIT_BLOCKER__NUM_OF_ITEMS) || (data[0].deleteProducts && data[0].deleteProducts.length >= ITS__LONG_WAIT_BLOCKER__NUM_OF_ITEMS)) {
      let toastMessage = ''

      Object.keys(data[0]).map(key => {
        if (key !== 'deleteProducts' && key !== 'addProducts') return

        let action = `${(key === 'addProducts' ? 'Added' : 'Removed')}`
        toastMessage += `${data[0][key].length} Items ${action} <br/>`
      })

      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        timeout: 2000,
        data: {
          type: 'success',
          message: toastMessage
        }
      })
    }

    dispatch(VUEX_API_ASSORTMENT_PRODUCTS_UPDATE, {
      id: assortmentId,
      data: data
    }).then(async () => {
      dispatch(VUEX_GRID_UPDATE_SUCCESS)
      await dispatch(VUEX_GRID_REQUEST_SNAPSHOT)
      commit(VUEX_ASSORTMENT_PRODUCTS_LOOKUP_UPDATE, state.assortment)
    }).catch(err => {
      dispatch(VUEX_GRID_UPDATE_FAILURE)

      console.error('VUEX_API_ASSORTMENT_PRODUCTS_UPDATE | err', err)
      return err
    })
  }, 1000),

  /* ASSORTMENT PRODUCTS UPDATE */
  [VUEX_ASSORTMENT_PRODUCTS_RENAME]: _debounce(async ({ state, dispatch, commit }, payload) => {
    dispatch(VUEX_GRID_UPDATE_START)

    GridHelpers.mgThisArray[0].productIntegrationStep = 'rename'

    dispatch(VUEX_API_ASSORTMENT_PRODUCTS_RENAME, {
      id: state.assortment._id,
      data: [{ renameProducts: payload }]
    }).then(async response => {
      dispatch(VUEX_GRID_UPDATE_SUCCESS)
      if (response.data?.message !== 'Success') {
        // revert
        DataMiddleware.revertProductsFetch(payload)
      } else {
        // lookup products
        let styleColors = []
        for (let i = 0; i < payload.length; i++) {
          styleColors.push({
            style: payload[i].newStyle,
            color: payload[i].newColor
          })
        }
        DataMiddleware.productsFetch(styleColors, true)
      }
    }).catch(() => {
      dispatch(VUEX_GRID_UPDATE_FAILURE)
      GridHelpers.mgThisArray[0].productIntegrationStep = ''

      DataMiddleware.revertProductsFetch(payload)
    })
  }, 250),

  /* ASSORTMENT PRODUCTS UPDATE CLEAN STATUS */
  [VUEX_ASSORTMENT_PRODUCTS_STATUS_UPDATE]: async ({ dispatch, commit }, payload) => {
    dispatch(VUEX_GRID_UPDATE_START)
    await dispatch(VUEX_API_ASSORTMENT_PRODUCTS_UPDATE, payload).then(() => {
      dispatch(VUEX_GRID_UPDATE_SUCCESS)
      commit(VUEX_ASSORTMENT_GRID_REQUEST_CLEAR)
      dispatch(VUEX_GRID_REQUEST_SNAPSHOT)

      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'success',
          message: `${payload.count} Alerts Cleared`
        }
      })
    }).catch(err => {
      dispatch(VUEX_GRID_UPDATE_FAILURE)

      console.error('VUEX_API_ASSORTMENT_PRODUCTS_UPDATE | err', err)
      return err
    })
  },

  [VUEX_ASSORTMENT_PRODUCTS_LOOKUP_UPDATE]: ({ commit }, payload) => {
    commit(VUEX_ASSORTMENT_PRODUCTS_LOOKUP_UPDATE, payload)
  },

  // ********************************************
  // CUSTOM CONTEST UPDATES
  // ********************************************
  [VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT]: async ({ commit, dispatch }, payload) => {
    await commit(VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT, payload)
    dispatch(VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT_REQUEST)
  },

  [VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT_REQUEST]: _debounce(async ({ state, dispatch, commit }) => {
    const batchedUpdates = cloneDeep(state.customContentBatchedUpdates)
    await commit(VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT_CLEAR_BATCHED_REQUEST)

    dispatch(VUEX_API_ASSORTMENT_UPDATE_BATCH, {
      id: state.assortment._id,
      properties: batchedUpdates
    })
  }, 500),
  // ********************************************

  // My Assortments Tree Data Update
  [VUEX_ASSORTMENT_TREEDATA_UPDATE]: _debounce(async ({ state, dispatch, commit }, payload) => {
    d.log('VUEX_ASSORTMENT_TREEDATA_UPDATE : ' + payload)
  }, 500),

  /* ASSORTMENT ROWS, COLUMNS, SETTINGS MANIPULATORS */
  [VUEX_ASSORTMENT_GRID_ROWS_ADD]: async ({ dispatch, commit }, payload) => {
    /// ////////////////////////
    // longWaitBlocker
    /// ////////////////////////
    if (payload.length >= ITS__LONG_WAIT_BLOCKER__NUM_OF_ITEMS) longWaitBlocker.show()

    // preformat data
    let paramsFormattedForMutation = []
    let params = cloneDeep(payload)
    for (let i = 0; i < params.length; i++) {
      let obj = params[i]

      paramsFormattedForMutation.push(
        {
          data: obj
        }
      )
    }

    setTimeout(async function () {
      await commit(VUEX_ASSORTMENT_GRID_ROWS_ADD, paramsFormattedForMutation)
      dispatch(VUEX_ASSORTMENT_PRODUCTS_UPDATE)
    }, 100)
  },
  [VUEX_ASSORTMENT_GRID_ROWS_UPDATE]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_ASSORTMENT_GRID_ROWS_UPDATE, payload)
    dispatch(VUEX_ASSORTMENT_PRODUCTS_UPDATE)
  },
  [VUEX_ASSORTMENT_GRID_ROWS_UPDATE_SORT]: async ({ dispatch, commit }, payload) => {
    await commit(VUEX_ASSORTMENT_GRID_ROWS_UPDATE_SORT, payload)
    dispatch(VUEX_ASSORTMENT_PRODUCTS_UPDATE)
  },
  [VUEX_ASSORTMENT_GRID_ROWS_DELETE]: async ({ dispatch, commit }, payload) => {
    /// ////////////////////////
    // longWaitBlocker
    /// ////////////////////////
    if (payload.length >= ITS__LONG_WAIT_BLOCKER__NUM_OF_ITEMS) longWaitBlocker.show()

    setTimeout(async function () {
      await commit(VUEX_ASSORTMENT_GRID_ROWS_DELETE, payload)
      dispatch(VUEX_ASSORTMENT_PRODUCTS_UPDATE)
    }, 100)
  },

  [VUEX_ASSORTMENT_GRID_TREEROWS_ADD]: async ({ dispatch, commit }, payload) => {
    // Basic add - will need to compile only needed data
    dispatch(VUEX_GRID_UPDATE_START)
    await commit(VUEX_ASSORTMENT_GRID_TREEROWS_ADD, payload)
    dispatch(VUEX_ASSORTMENT_TREEDATA_UPDATE).then(response => {
      dispatch(VUEX_GRID_UPDATE_SUCCESS)
    })
  },
  [VUEX_ASSORTMENT_GRID_TREEROWS_UPDATE]: async ({ dispatch, commit }, payload) => {
    dispatch(VUEX_GRID_UPDATE_START)
    payload.map(assortmentData => {
      dispatch(VUEX_API_ASSORTMENT_UPDATE, assortmentData).then(response => {
        dispatch(VUEX_GRID_UPDATE_SUCCESS)
      })
    })
  },
  [VUEX_ASSORTMENT_GRID_TREEROWS_DELETE]: async ({ dispatch, commit }, payload) => {
    dispatch(VUEX_GRID_UPDATE_START)
    payload.map(async assortmentData => {
      await dispatch(VUEX_API_ASSORTMENT_DELETE, assortmentData).then(response => {
        dispatch(VUEX_GRID_UPDATE_SUCCESS)

        // refetch KI
        if (router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) {
          dispatch(VUEX_ASSORTMENTS_REFETCH)
        }
      })
    })
  },

  [VUEX_ASSORTMENT_GRID_COLUMNS_UPDATE]: async ({ dispatch, commit }, payload) => {
    dispatch(VUEX_GRID_UPDATE_START)
    await dispatch(VUEX_GRID_REQUEST_SNAPSHOT)

    await commit(VUEX_ASSORTMENT_GRID_COLUMNS_UPDATE, payload)
    dispatch(VUEX_ASSORTMENT_PRODUCTS_UPDATE)
  },

  [VUEX_ASSORTMENT_GRID_SETTINGS_UPDATE]: async ({ state, dispatch, commit }, payload) => {
    dispatch(VUEX_GRID_UPDATE_START)
    await commit(VUEX_ASSORTMENT_GRID_SETTINGS_UPDATE, payload)

    let properties = {}
    payload.map(prop => {
      Object.assign(properties, prop)
    })

    if (state.assortment?._id) {
      let data = {
        id: state.assortment._id,
        properties: {
          uiSettings: {
            gridSettings: {
              ...properties,
              sortOrder: state.assortment.uiSettings?.gridSettings?.sortOrder,
              groupIsEnabled: state.assortment.uiSettings?.gridSettings?.groupIsEnabled,
              customSort: state.assortment.uiSettings?.gridSettings?.customSort
            },
            groupSettings: state.assortment.uiSettings?.groupSettings
          }
        }
      }

      setTimeout(() => {
        dispatch(VUEX_ASSORTMENT_UPDATE, data)
      }, 750)
    }
  },

  // just update the sort state temporarily
  [VUEX_ASSORTMENT_GRID_SETTINGS_CUSTOMSORT_STATE_UPDATE]: ({ commit }, payload) => {
    commit(VUEX_ASSORTMENT_GRID_SETTINGS_CUSTOMSORT_STATE_UPDATE, payload)
  },

  [VUEX_ASSORTMENT_GRID_SET_SNAPSHOT]: ({ commit }, payload) => {
    commit(VUEX_ASSORTMENT_GRID_SET_SNAPSHOT, payload)
  },

  [VUEX_GRID_REKEY]: ({ commit }, payload) => {
    commit(VUEX_GRID_REKEY, payload)
  },

  [VUEX_GRID_REKEY_EXCEL_UPLOADER]: ({ commit }, payload) => {
    commit(VUEX_GRID_REKEY_EXCEL_UPLOADER, payload)
  },

  [VUEX_GRID_VALIDATE_PRODUCTS_FETCH]: ({ commit, dispatch }, payload) => {
    let data = {
      params: payload
    }
    // server error occurs if no products are passed, so first check
    if (payload.products && payload.products.length > 0) {
      dispatch(VUEX_API_PRODUCTS_VALIDATE, data).then((response) => {
        commit(VUEX_GRID_VALIDATE_PRODUCTS_SUCCESS, response.data)
        return response.data
      }).catch(() => {
        commit(VUEX_GRID_VALIDATE_PRODUCTS_FAILURE)
      })
    } else {
      let data = {
        message: 'Success',
        errors: []
      }
      commit(VUEX_GRID_VALIDATE_PRODUCTS_SUCCESS, data)
      return data
    }
  },

  [VUEX_GRID_CONFIRMATION_CHANGE_INTERNAL_STATUS]: ({ commit }, payload) => {
    commit(VUEX_GRID_CONFIRMATION_CHANGE_INTERNAL_STATUS, payload)
  },
  [VUEX_GRID_CONFIRMATION_RESET_PRICES]: ({ commit }) => {
    commit(VUEX_GRID_CONFIRMATION_RESET_PRICES)
  },
  [VUEX_GRID_CONFIRMATION_LABEL_DELETE]: ({ commit }, payload) => {
    commit(VUEX_GRID_CONFIRMATION_LABEL_DELETE, payload)
  },
  [VUEX_GRID_CONFIRMATION_SCHEDULE_DELETE]: ({ commit }) => {
    commit(VUEX_GRID_CONFIRMATION_SCHEDULE_DELETE)
  },
  [VUEX_GRID_CONFIRMATION_ARCHIVE_SELECTED]: ({ commit }, payload) => {
    commit(VUEX_GRID_CONFIRMATION_ARCHIVE_SELECTED, payload)
  },
  [VUEX_GRID_CONFIRMATION_ADD_TO_ASSORTMENT]: ({ commit }) => {
    commit(VUEX_GRID_CONFIRMATION_ADD_TO_ASSORTMENT)
  },

  [VUEX_GRID_ROWDATA_LOADING_START]: ({ commit }) => {
    commit(VUEX_GRID_ROWDATA_LOADING_START)
  },
  [VUEX_GRID_ROWDATA_LOADING_FINISH]: ({ commit }) => {
    commit(VUEX_GRID_ROWDATA_LOADING_FINISH)
  },

  // need to set popColors by cross-referencing with user.userTBEs
  [VUEX_ASSORTMENT_SET_POP_COLORS]: ({ rootState, state, commit }) => {
    const assortment = state.assortment
    const location = assortment.locationId
    const status = assortment.status

    let popColors = getUserPOPColors(location, status)
    commit(VUEX_ASSORTMENT_SET_POP_COLORS, popColors)
  },

  // get category list specific to the season/year/region
  // pass payload.assortment, optionally pass payload.forceLookup
  [VUEX_ASSORTMENT_INTERNAL_INTERNATONAL_CATEGORIES_FETCH]: async ({ state, dispatch, commit }, payload) => {
    // get season/year/region
    const id = payload.assortment._id

    // if already fetched, return it, otherwise fetch it and return
    payload.forceLookup = true // override with force lookup for all
    if (!payload.forceLookup && state.internalInternationalCategories[id] && state.internalInternationalCategories[id].length > 0) {
      return state.internalInternationalCategories[id]
    } else {
      const { season, year, productType, orgType, method, channel, region } = payload.assortment
      let params = {
        libraryType: 'Assortment',
        data: {
          query: {
            state: payload.assortment.state || ITS__ASSORTMENTS__STATE__PUBLISHED,
            season,
            year,
            productType,
            orgType,
            method,
            channel,
            region
          },
          requestedOptions: [
            'products.pillar'
          ]
        }
      }

      await dispatch(VUEX_API_OPTIONS_FETCH, params).then(async response => {
        let payload = {
          id: id,
          list: (response['products.pillar']) ? response['products.pillar'] : []
        }
        await commit(VUEX_ASSORTMENT_INTERNAL_INTERNATONAL_CATEGORIES_FETCH, payload)
      }).catch(error => {
        d.log('VUEX_ASSORTMENT_INTERNAL_INTERNATONAL_CATEGORIES_FETCH', error)
      })
      return state.internalInternationalCategories[id]
    }
  },

  // adds an element temporarily to the internal state
  // payload.assortment & payload.item
  // debounce this at least 750ms to get the backend time to update the list from the backend value change
  [VUEX_ASSORTMENT_INTERNAL_INTERNATONAL_CATEGORIES_ADD]: _debounce(async ({ state, dispatch, commit }, payload) => {
    // refresh the list
    const tpayload = {
      assortment: payload.assortment,
      forceLookup: true
    }
    await dispatch(VUEX_ASSORTMENT_INTERNAL_INTERNATONAL_CATEGORIES_FETCH, tpayload).then(async response => {
      // add the temp value
      await commit(VUEX_ASSORTMENT_INTERNAL_INTERNATONAL_CATEGORIES_ADD, payload)
    })
  }, 1250),

  // get category list specific to assortments
  // pass payload.assortment, optionally pass payload.forceLookup
  [VUEX_ASSORTMENT_CATEGORIES_FETCH]: async ({ state, dispatch, commit }, payload) => {
    // if already fetched, return it, otherwise fetch it and return
    payload.forceLookup = true // override with force lookup for all
    if (!payload.forceLookup && state.assortmentPillars.length > 0) {
      return state.assortmentPillars
    } else {
      let params = {
        libraryType: 'Assortment',
        data: {
          query: {
            orgType: state.assortment.orgType,
            productType: state.assortment.productType,
            method: state.assortment.method,
            state: state.assortment.state || ITS__ASSORTMENTS__STATE__PUBLISHED
          },
          requestedOptions: [
            'products.pillar'
          ]
        }
      }// InternationalWholesaleAssortment

      await dispatch(VUEX_API_OPTIONS_FETCH, params).then(async response => {
        if (response['products.pillar'] && response['products.pillar'].length > 0) {
          let payload = {
            list: response['products.pillar']
          }
          await commit(VUEX_ASSORTMENT_CATEGORIES_FETCH, payload)
        }
      }).catch(error => {
        d.log('VUEX_ASSORTMENT_CATEGORIES_FETCH', error)
      })
      return state.assortmentPillars
    }
  }

}

const mutations = {
  /* ASSORTMENTS FETCH */
  [VUEX_ASSORTMENTS_FETCH]: (state, data) => {
    state.assortments = []
    state.assortments2 = []
    state.assortmentsRefetchParams = {
      payload1: data.payload1,
      payload2: data.payload2,
      orgType: data.orgType
    }
    state.assortment = {} // clear out assortment too. the shared grid component might incorrectly think we are in a different view if not
  },

  [VUEX_ASSORTMENTS_FETCH_SUCCESS]: (state, data) => {
    if (data && data.data) {
      if (data.payload2) {
        state.assortments2 = data.data
        state.agGridRowData2 = DataMiddleware.convertAssortmentsToTreeData(data.data)
      } else {
        let finalData = data.data
        state.assortments = finalData
        if (state.assortmentsRefetchParams.payload1.params && state.assortmentsRefetchParams.payload1.params.isInternalAssortments) {
          state.agGridRowData = DataMiddleware.convertInternalAssortmentsListToRowData(finalData)
        } else {
          state.agGridRowData = DataMiddleware.convertAssortmentsToTreeData(finalData)
        }
      }

      // rekey if not doing another lookup
      if (!data.willDoMore) {
        state.rekey = Math.random()
      }
    } else {
      state.assortments = []
      state.assortments2 = []
      state.agGridRowData = []
      state.agGridRowData2 = []
    }
  },
  [VUEX_ASSORTMENTS_FETCH_FAILURE]: (state, data) => {
    state.assortments = []
    state.assortments2 = []
    state.assortment = {}
  },

  [VUEX_ASSORTMENTS_ALL_FETCH]: state => {
    state.assortmentsAllActive = []
    state.agGridRowDataAssortmentsAllActive = []
  },
  [VUEX_ASSORTMENTS_ALL_FETCH_SUCCESS]: (state, data) => {
    if (data && data.data) {
      state.assortmentsAllActive = data.data
      state.agGridRowDataAssortmentsAllActive = DataMiddleware.convertAssortmentsToTreeData(data.data, data.skipTreeDataConvert)
    } else {
      state.assortmentsAllActive = []
      state.agGridRowDataAssortmentsAllActive = []
    }
  },
  [VUEX_ASSORTMENTS_ALL_FETCH_FAILURE]: (state, data) => {
    console.error('VUEX_ASSORTMENTS_ALL_FETCH_FAILURE', data)
  },

  [VUEX_ASSORTMENTS_ALL_INTERNAL_FETCH]: state => {
    state.assortmentsAllInternalActive = []
  },
  [VUEX_ASSORTMENTS_ALL_INTERNAL_FETCH_SUCCESS]: (state, data) => {
    if (data && data.data) {
      state.assortmentsAllInternalActive = data.data
    } else {
      state.assortmentsAllInternalActive = []
    }
  },
  [VUEX_ASSORTMENTS_ALL_INTERNAL_FETCH_FAILURE]: (state, data) => {
    console.error('VUEX_ASSORTMENTS_ALL_INTERNAL_FETCH_FAILURE', data)
  },

  /* ASSORTMENT FETCH */
  [VUEX_ASSORTMENT_FETCH]: (state, data) => {
    if (data.tempRouterOptions && data.tempRouterOptions.fromRouting) {
      state.assortmentQueryParams = data

      // add assortmentsQueryPreserveNull generally
      if (!state.assortmentQueryParams._options) {
        state.assortmentQueryParams._options = {}
      }

      state.assortmentQueryParams._options.assortmentsQueryPreserveNull = true
      // state.assortmentQueryParams._options.includeCollectionsDataQuery = collectionsState?.state?.collectionLookupQuery
      state.assortmentQueryParams._options.includeCollectionsDataQuery = {
        name: 'Default'
      }

      state.assortmentQueryParams._options.includeOrdersDataQuery = {
        assortment: data.id
      }
    }
  },
  [VUEX_ASSORTMENT_REFETCH]: (state) => {
    state.assortment = {}
  },
  [VUEX_ASSORTMENT_FETCH_SUCCESS]: (state, data) => {
    if (data && data.data) {
      state.assortment = data.data // update the assortment
    } else {
      state.assortment = []
    }

    if (data?.data?.products) {
      state.agGridRowData = DataMiddleware.convertAssortmentDetailsToRowData(data.data.products, data.data)
    } else {
      state.agGridRowData = []
    }

    // set default patch data - BEGIN
    if (!state.assortment.patch) state.assortment.patch = {}
    if (!state.assortment.patch.uiSettings) state.assortment.patch.uiSettings = {}
    if (!state.assortment.patch.uiSettings.gridSettings) state.assortment.patch.uiSettings.gridSettings = {}
    if (!state.assortment.patch.uiSettings.gridSettings.sortOrder) state.assortment.patch.uiSettings.sortOrder = []
    if (!state.assortment.patch.uiSettings.gridSettings.groupIsEnabled) state.assortment.patch.uiSettings.groupIsEnabled = false
    if (data?.data?.uiSettings?.gridSettings) {
      if (data.data.uiSettings.gridSettings.sortOrder) {
        state.assortment.patch.uiSettings.gridSettings.sortOrder = data.data.uiSettings.gridSettings.sortOrder
      } else {
        state.assortment.patch.uiSettings.gridSettings.sortOrder = []
      }
      state.assortment.patch.uiSettings.groupSettings = (data.data.uiSettings?.groupSettings) ? data.data.uiSettings?.groupSettings : { }
      state.assortment.patch.uiSettings.gridSettings.groupIsEnabled = (data.data.uiSettings.gridSettings.groupIsEnabled)
    }
    state.agGridRequestData.patch = cloneDeep(state.assortment.patch)
    // set default patch data - END

    // set KEY INITIATIVES missing locationId fix
    if (!state.assortment.locationId) {
      if (state.assortment.region) {
        let locationId = 'US'
        switch (state.assortment.region) {
          case 'Europe':
            locationId = 'EU'
        }
        state.assortment.locationId = locationId
      }
    }

    // only rekey if drawing a new assortment, not clearing
    if (state.assortment) {
      state.rekey = Math.random()
    }
  },
  [VUEX_ASSORTMENT_FETCH_FAILURE]: (state) => {
    console.error('VUEX_ASSORTMENT_FETCH_FAILURE')
  },

  [VUEX_ASSORTMENT_CREATE]: (state) => {
    state.assortment = {}
    state.assortmentProductsLookup = {}
  },

  [VUEX_ASSORTMENT_CREATE_SUCCESS]: (state, data) => {
    state.newAssortmentData = data
  },

  [VUEX_ASSORTMENT_UPDATE_SUCCESS]: (state, data) => {
    state.assortment = {
      ...state.assortment,
      ...data.properties
    }
  },

  [VUEX_ASSORTMENT_UPDATE_FAILURE]: (state, data) => {
    console.error('VUEX_ASSORTMENT_UPDATE_FAILURE', data)
  },

  [VUEX_ASSORTMENT_UPDATE_DYNAMIC_PRODUCT_TOTAL_COUNT]: (state, data) => {
    state.assortmentDynamicProductTotalCount = data
  },

  /* [VUEX_ASSORTMENT_PRODUCTS_ADD]: (state, data) => {
    state.assortment.products.push(data)
  }, */

  [VUEX_ASSORTMENT_PRODUCTS_LOOKUP_UPDATE]: (state, data) => {
    state.assortmentProductsLookup = {}

    // Create assortment products lookup
    if (data.products?.length > 0) {
      data.products.forEach(p => {
        state.assortmentProductsLookup[`${p.style}-${p.color}`] = true
      })
    }

    state.assortmentProductsLookupToggler = Math.random()
  },

  [VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT]: (state, data) => {
    const displayGroup = data.displayGroup

    if (!state.assortment.customContent) {
      state.assortment.customContent = []
    }

    const customContentIdx = state.assortment.customContent.findIndex(content => content.displayGroup === displayGroup)

    const requestObj = {}
    requestObj.displayGroup = data.displayGroup
    if (data.data.hasOwnProperty('html')) requestObj.html = data.data.html
    if (data.data.hasOwnProperty('previewJpg')) requestObj.previewJpg = data.data.previewJpg
    if (data.data.hasOwnProperty('sourceFile')) requestObj.sourceFile = data.data.sourceFile

    if (customContentIdx > -1) {
      Object.assign(state.assortment.customContent[customContentIdx], requestObj)
    } else {
      state.assortment.customContent.push({
        displayGroup,
        ...requestObj
      })
    }

    state.customContentBatchedUpdates = [{ patch: { customContent: state.assortment.customContent } }]
  },

  [VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT_CLEAR_BATCHED_REQUEST]: state => {
    state.customContentBatchedUpdates = []
  },

  [VUEX_ASSORTMENT_DUPLICATE_SUCCESS]: (state, data) => {
    d.log('VUEX_ASSORTMENT_DUPLICATE_SUCCESS')
  },
  [VUEX_ASSORTMENT_DUPLICATE_FAILURE]: (state, data) => {
    console.error('VUEX_ASSORTMENT_DUPLICATE_FAILURE')
  },

  /* ASSORTMENT PRODUCT AG GRID
  * NOTE!  payload.map is VERY inefficient when applying directly to a state
  * Prep the data beforehand and don't do these mini maps (code commented out so we won't forget)
  * */
  [VUEX_ASSORTMENT_GRID_ROWS_ADD]: (state, data) => {
    state.agGridRequestData.addProducts = state.agGridRequestData.addProducts.concat(data)
    /*
      data.map(item => {
        state.agGridRequestData.addProducts.push({
          data: item
        })
      })
     */
  },
  [VUEX_ASSORTMENT_GRID_ROWS_UPDATE]: (state, data) => {
    state.agGridRequestData.updateProducts = state.agGridRequestData.updateProducts.concat(data)
    /*
    data.map(item => {
      state.agGridRequestData.updateProducts.push(item)
    })
    */
  },
  [VUEX_ASSORTMENT_GRID_ROWS_UPDATE_SORT]: (state, data) => {
    state.agGridRequestData.sortProducts = data.params
  },
  [VUEX_ASSORTMENT_GRID_ROWS_DELETE]: (state, data) => {
    state.agGridRequestData.deleteProducts = data
  },

  [VUEX_ASSORTMENT_GRID_TREEROWS_ADD]: (state, data) => {
    state.agGridRequestData.addProducts = state.agGridRequestData.addProducts.concat(data)
    /*
    data.map(item => {
      state.agGridRequestData.addProducts.push(item)
    })
    */
  },
  [VUEX_ASSORTMENT_GRID_TREEROWS_UPDATE]: (state, data) => {

  },
  [VUEX_ASSORTMENT_GRID_TREEROWS_DELETE]: (state, data) => {
    // Note: treerows uses ID isntead of style-color
    data.map(item => {
      state.agGridRequestData.deleteProducts.push({
        id: item.id
      })
    })
  },

  [VUEX_ASSORTMENT_GRID_COLUMNS_UPDATE]: (state, data) => {
    // prep many defaults
    if (!state.assortment.uiSettings) state.assortment.uiSettings = {}
    if (!state.assortment.uiSettings.gridSettings) state.assortment.uiSettings.gridSettings = {}
    if (!state.agGridRequestData.patch.uiSettings) {
      state.agGridRequestData.patch.uiSettings = {
        gridSettings: {},
        groupSettings: {}
      }
    }
    Object.assign(state.agGridRequestData.patch.uiSettings.gridSettings, state.assortment.uiSettings.gridSettings || state.assortment.gridSettings)

    Object.assign(state.agGridRequestData.patch.uiSettings.groupSettings, state.assortment.uiSettings.groupSettings)

    // now set sort order
    state.agGridRequestData.patch.uiSettings.gridSettings.sortOrder = data
    state.assortment.uiSettings.gridSettings.sortOrder = data

    if (state.assortment.method === ITS__ASSORTMENTS__METHOD_TYPE__STATIC) {
      // If StaticAssortment update products or KI

      // NOTE - if state.agGridRequestData.sortProducts is in play, use that - otherwise, use state.assortment.products
      // this addresses a drag sorting issue - where products was updated, then column sort was being updated right afterwards
      // the column sort was overriding the first one. the first one had updated state.agGridRequestData.sortProducts - but columns was reading from state.assortment.products
      // so this is why we now check if sortProducts has anything first, because we dont want to lose that data
      let productsList = (state.agGridRequestData.sortProducts) ? state.agGridRequestData.sortProducts : state.assortment.products
      let productsData = productsList.map((item, index) => {
        return { sort: index, style: item.style, color: item.color }
      })
      state.agGridRequestData.sortProducts = productsData
    } else if (state.assortment.method === ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC) {
      // If DynamicAssortment update query
      if (!state.agGridRequestData.patch.query) state.agGridRequestData.patch.query = {}

      let sortObj = {}

      if (data.length > 0) {
        data.map(sortItem => {
          // aggrid users & needs style, but _uiOptions needs styleNumeric
          // This logic is in TWO PLACES - search dynamicColumnRemappingConfig
          let colId = sortItem.colId
          if (colId === 'style') {
            colId = 'styleNumeric'
          } else if (colId === 'cost') {
            colId = 'locations.wholesalePrice'
          } else if (colId === 'suggestedRetail') {
            colId = 'locations.suggestedRetail'
          }
          sortObj[colId] = (sortItem.sort === 'asc') ? 1 : -1
        })
      }

      Object.assign(state.agGridRequestData.patch.query, {
        ...state.assortment.query,
        _uiOptions: { sort: sortObj }
      })
    }
  },

  [VUEX_ASSORTMENT_GRID_SETTINGS_CUSTOMSORT_STATE_UPDATE]: (state, data) => {
    // prep many defaults
    if (!state.assortment.uiSettings) state.assortment.uiSettings = {}
    if (!state.assortment.uiSettings.gridSettings) state.assortment.uiSettings.gridSettings = {}
    if (!state.assortment.uiSettings.gridSettings.customSort) state.assortment.uiSettings.gridSettings.customSort = {}

    // set via payload.field and payload.items
    state.assortment.uiSettings.gridSettings.customSort[data.field] = data.items
  },

  // NOTE!! There might be a hole here.  It seems we have to re-construct the entire packet when updating settings
  // right now, only groupIsEnabled calls VUEX_ASSORTMENT_GRID_SETTINGS_UPDATE
  // column sort loads ia via VUEX_ASSORTMENT_GRID_COLUMNS_UPDATE
  // so if we ever add additional settings, check for that
  [VUEX_ASSORTMENT_GRID_SETTINGS_UPDATE]: (state, data) => {
    let sort = cloneDeep(state.assortment?.uiSettings?.gridSettings?.sortOrder)
    if (state.assortment?.uiSettings?.gridSettings) {
      data.forEach(item => {
        Object.assign(state.assortment?.uiSettings?.gridSettings, {
          ...item,
          sortOrder: sort
        })
      })
    }
    state.agGridRequestData.patch.uiSettings = state.assortment?.uiSettings
  },

  // Update store with latest products
  [VUEX_ASSORTMENT_GRID_SET_SNAPSHOT]: (state, data) => {
    state.agGridSnapshot = cloneDeep(data)
    state.assortment.products = []
    state.assortment.products = cloneDeep(data)
  },

  [VUEX_ASSORTMENT_GRID_REQUEST_CLEAR]: (state) => {
    Object.keys(state.agGridRequestData).forEach(item => {
      if (item !== 'patch') state.agGridRequestData[item] = []
    })
  },

  [VUEX_GRID_REKEY]: (state) => {
    state.rekey = Math.random()
  },

  [VUEX_GRID_REKEY_EXCEL_UPLOADER]: (state) => {
    state.rekeyExcelUploader = Math.random()
  },

  [VUEX_GRID_VALIDATE_PRODUCTS_SUCCESS]: (state, data) => {
    state.validatedProductsResponse = data
  },

  [VUEX_GRID_VALIDATE_PRODUCTS_FAILURE]: (state, data) => {
    d.log('VUEX_GRID_VALIDATE_PRODUCTS_FAILURE : ' + data)
  },

  [VUEX_GRID_CONFIRMATION_RESET_PRICES]: (state) => {
    state.confirmationTogglerResetPrices = Math.random()
  },
  [VUEX_GRID_CONFIRMATION_CHANGE_INTERNAL_STATUS]: (state, data) => {
    state.confirmationTogglerChangeInternalStatus = {
      id: data.id,
      newValue: data.newValue,
      toggle: Math.random()
    }
  },

  [VUEX_GRID_CONFIRMATION_LABEL_DELETE]: (state, data) => {
    state.confirmationTogglerLabelDelete = data.label
  },
  [VUEX_GRID_CONFIRMATION_SCHEDULE_DELETE]: (state) => {
    state.confirmationTogglerScheduleDelete = Math.random()
  },
  [VUEX_GRID_CONFIRMATION_ARCHIVE_SELECTED]: (state, data) => {
    state.confirmationTogglerArchiveSelected = {
      rand: Math.random(),
      archiveIt: data.archiveIt
    }
  },
  [VUEX_GRID_CONFIRMATION_ADD_TO_ASSORTMENT]: (state) => {
    state.confirmationTogglerAddToAssortment = Math.random()
  },

  [VUEX_GRID_ROWDATA_LOADING_START]: (state) => {
    state.agGridRowDataLoading = true
  },
  [VUEX_GRID_ROWDATA_LOADING_FINISH]: (state) => {
    state.agGridRowDataLoading = false
  },

  [VUEX_ASSORTMENT_SET_POP_COLORS]: (state, popColors) => {
    state.popColors = popColors
  },

  [VUEX_ASSORTMENT_INTERNAL_INTERNATONAL_CATEGORIES_FETCH]: (state, data) => {
    state.internalInternationalCategories[data.id] = data.list
  },
  [VUEX_ASSORTMENT_INTERNAL_INTERNATONAL_CATEGORIES_ADD]: (state, data) => {
    let id = data.assortment.id
    if (state.internalInternationalCategories[id] && state.internalInternationalCategories[id].indexOf(data.item) === -1) {
      state.internalInternationalCategories[id].push(data.item)
      state.internalInternationalCategories[id].sort()
    }
  },

  [VUEX_ASSORTMENT_CATEGORIES_FETCH]: (state, data) => {
    state.assortmentPillars = data.list
  },
  [VUEX_ASSORTMENT_CATEGORIES_ADD]: (state, data) => {
    if (state.assortmentPillars && state.assortmentPillars.indexOf(data.item) === -1) {
      state.assortmentPillars.push(data.item)
      state.assortmentPillars.sort()
    }
  },

  [VUEX_ASSORTMENT_ALERTS_UPDATE]: (state, data) => {
    // If an item that has been deleted has an alert associated with it, remove it from the alerts array
    if (data.rowData && state.assortment?.alerts) {
      let updatedAlerts = state.assortment.alerts.filter((alert) => {
        let alertIsValid = true
        data.rowData.forEach(deletedItem => {
          if (alert.style === deletedItem.style && alert.color === deletedItem.color) {
            alertIsValid = false
          }
        })
        return alertIsValid
      })

      state.assortment.alerts = updatedAlerts
    }
  }

}

export default {
  state,
  actions,
  mutations
}
