import DataMiddleware from '@/components/_core/GridsCore/helpers/DataMiddleware'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import shared from 'skch_its_be_fe_shared'
import { s3Href } from '@/utils/file'
const imgSrc = shared.productImage.imgSrc
function ImageEditor () {}
ImageEditor.prototype.init = function (params) {
  this.params = params
  let t = this
  let introText = document.createElement('div')
  introText.className = 'intro-text'
  // introText.innerHTML = '<p>Upload a custom image if the product either does not yet have an image available or you need to replace the system image. Your custom image will be included in any document you export from this assortment. You can remove it at any time and the assortment will revert back to whatever is available for this product.</p>'

  // grab thumb or version of thumb
  let thumbnailKey = this.params.colDef.field
  let img = this.params.data[thumbnailKey]
  if (this.params.isSeasonsManager) {
    // add seasons manager image bucket
    img = s3Href(img)
  } else {
    if (!img) {
      if (this.params.isProducts) {
        img = imgSrc('medium', params?.data.products.style, this.params.data.products.color, 'A', this.params.data.products.productType)
      } else {
        img = imgSrc('medium', params.data.style, params.data.color, 'A', params.data.productType)
      }
    }
  }
  let htmlImage = `<div class="img-responsive"><img class="imageBigger" id="imageBigger" ></div>`

  // make container
  this.container = document.createElement('div')
  this.container.className = 'image-selector'
  this.container.innerHTML = htmlImage

  // new on error style
  let imgHook = this.container.querySelector('#imageBigger')
  imgHook.onerror = function () {
    imgHook.src = '/images/thumb-image-coming-soon.jpg'
  }
  imgHook.src = img

  // add buttons to container
  if (this.params.enableUploading) {
    // buttons
    let buttonHolder = document.createElement('div')
    buttonHolder.className = 'buttonHolder'
    buttonHolder.setAttribute('id', 'buttonHolder')

    let buttonRemoveHide = ''
    let buttonUploadHide = ''
    let thumbnailKey = this.params.colDef.field
    if (params.data[thumbnailKey] === '' || !params.data[thumbnailKey]) {
      buttonRemoveHide = ' hide'
    } else {
      buttonUploadHide = ' hide'
    }

    // remove button
    let buttonRemove = document.createElement('button')
    buttonRemove.className = 'v-btn v-btn--contained theme--light v-size--small primary' + buttonRemoveHide
    buttonRemove.setAttribute('type', 'button')
    buttonRemove.setAttribute('id', 'buttonRemove')
    buttonRemove.innerHTML = "<span class='v-btn__content'>Remove</span>"
    buttonRemove.addEventListener('click', function (event) {
      t.removeThumbnail()
      // params.stopEditing()
    }.bind(t, params))
    buttonHolder.appendChild(buttonRemove)

    // upload button
    let buttonUpload = document.createElement('button')
    buttonUpload.className = 'v-btn v-btn--contained theme--light v-size--small primary' + buttonUploadHide
    buttonUpload.setAttribute('type', 'button')
    buttonUpload.setAttribute('id', 'buttonUpload')
    buttonUpload.innerHTML = "<span class='v-btn__content'>Upload</span>"
    buttonUpload.addEventListener('click', function (event) {
      t.uploadThumbnail()
      params.stopEditing()
    }.bind(t, params))
    buttonHolder.appendChild(buttonUpload)

    this.container.appendChild(buttonHolder)
  }

  // append introText
  this.container.appendChild(introText)

  // mouse leave cancel
  this.container.addEventListener('mouseleave', params.stopEditing)
}
ImageEditor.prototype.getGui = function () {
  return this.container
}
ImageEditor.prototype.afterGuiAttached = function () {
  this.container.focus()
}
ImageEditor.prototype.getValue = function () {
  return this.value
}
ImageEditor.prototype.isCancelAfterEnd = function () {
  return true
}
ImageEditor.prototype.destroy = function () {

}
ImageEditor.prototype.isPopup = function () {
  return true
}

ImageEditor.prototype.removeThumbnail = function () {
  let thumbnailKey = this.params.colDef.field
  let obj = {
    id: this.params.data.id,
    properties: {}
  }
  obj.properties[thumbnailKey] = ''
  let data = {
    type: 'rowsUpdate',
    payload: [obj]
  }
  DataMiddleware.emitSwitchboard(data)
  // this.value = ''

  // switch out the buttons
  let element = document.getElementById('buttonRemove')
  element.classList.add('hide')
  element = document.getElementById('buttonUpload')
  element.classList.remove('hide')

  // flip image
  let img = imgSrc('medium', this.params.data.style, this.params.data.color, 'A', this.params.data.productType)
  element = document.getElementById('imageBigger')
  element.setAttribute('src', img)
}
ImageEditor.prototype.uploadThumbnail = function () {
  if (this.params.isSeasonsManager) {
    GridHelpers.mgThisArray[0].openDialog({
      content: 'AssortmentManager/Dialogs/SeasonsManager/Dialog_NewSeason.vue',
      title: `Upload Image`,
      subtitle: null,
      data: {
        editImageMode: true,
        seasonObjectData: this.params.data,
        aggridRowId: this.params.data.id
      }
    })
  } else {
    GridHelpers.mgThisArray[0].openDialog({
      content: 'AssortmentManager/Dialogs/Grids/Dialog_ProductImage.vue',
      title: `Upload Image`,
      subtitle: null,
      data: {
        orgType: this.params.data.orgType,
        assortmentId: GridHelpers.mgThisArray[0].assortment._id,
        color: this.params.data.color,
        style: this.params.data.style,
        aggridRowId: this.params.data.id
      }
    })
  }
}

export default ImageEditor
