<template>
  <div class="type-ahead-filter" >
    <div class="field-holder fh1" >
      <v-autocomplete
        filled density="compact"
        v-model="picker"
        v-model:search="autocompleteSearch"
        :multiple="false"
        :items="this.items"
        :modelValue="pickerValue"
        @update:modelValue="modelValueChange"
        @input="inputCallback"
        ref="vuetifyComponent"
        :focused="true"
        :menu-props="menuProps"
        :loading="isLoading"
        :no-data-text="customNoDataText"
        hide-details
      >
      </v-autocomplete>
      <div id="acid"></div>
    </div>
  </div>
</template>
<script>
/*
<template v-slot:item="data">
          <div v-on:click="itemClick(data.item.value)" class="autocomplete-item">
            {{ data.item.value }}
          </div>
        </template>

 */
import _debounce from 'lodash/debounce'
import {
  VUEX_API_SAMPLE_INVENTORY_AUTOCOMPLETE
} from '@/store/constants/api'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'

export default {
  name: 'TypeAheadFilter',
  data () {
    return {
      items: [],
      // https://vuetifyjs.com/en/components/menus/
      menuProps: {
        attach: '#acid',
        maxHeight: '190px',
        openDirection: 'bottom'
      },
      isLoading: false,
      colId: '',
      filterText: '',
      picker: null,
      pickerValue: null,
      autocompleteSearch: '',
      customNoDataTextMode: 'Enter Search Terms' // have it be this on load
    }
  },
  mounted () {
    this.colId = this.params.colDef.field
  },
  computed: {
    customNoDataText () {
      if (this.customNoDataTextMode) {
        return this.customNoDataTextMode
      } else {
        return 'No Results Found'
      }
    }
  },
  methods: {
    modelValueChange (event) {
      this.updateFilter()
    },
    // AG GRID
    updateFilter () {
      this.params.filterChangedCallback()
      GridHelpers.mgThisArray[0].togglerfilterBar = Math.random()
    },
    doesFilterPass (params) {
      return (params.data[this.colId] === this.filterText)
    },
    isFilterActive () {
      return (this.filterText)
    },
    // this example isn't using getModel() and setModel(),
    getModel () {
      if (this.picker) {
        return {
          filterType: 'typeAhead',
          colId: this.colId,
          value: this.picker
        }
      } else {
        return null
      }
    },
    setModel (model) {
      // clear out
      if (!model?.values) {
        this.picker = null
        this.pickerValue = null
        this.filterText = ''
        this.autocompleteSearch = ''
        this.items = []
        this.updateFilter()
      } else {
        this.filterType = model.values
      }
    },

    // SEARCH
    inputCallback () {
      this.inputCallback2() // don't auto close it anymore
    },

    inputCallback2: _debounce(function () {
      this.doLookup()
    }, 750),

    doLookup () {
      let val = String(this.autocompleteSearch)
      if (val.length >= 2) {
        this.items = []
        this.isLoading = true
        const payload = {
          property: this.colId,
          value: val
        }

        this.customNoDataTextMode = 'Searching...'
        this.items = []

        GridHelpers.mgThisArray[0].$store.dispatch(VUEX_API_SAMPLE_INVENTORY_AUTOCOMPLETE, payload).then(response => {
          this.items = response
          this.isLoading = false
          this.customNoDataTextMode = ''
        })
      } else {
        this.items = []
        this.customNoDataTextMode = 'Enter Search Terms'
      }
    }
  }
}
</script>
<style lang="scss">
.type-ahead-filter {
  padding: 10px;
  min-height: 250px;
  height: 250px;
  .field-holder {
    height: 100%;
    position: relative;
  }
  #acid {
    position: absolute;
    top:30px;
    left:0;
  }
}
</style>
