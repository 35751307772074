<template>
  <div
    :class="['aggrid-component','master-grid',gridTypeClass]"
    @focusin="gh.setFocusIn"
    @focusout="gh.setFocusOut">
    <!-- HEADER -->
    <div
      :class="['grid-header-bar','header-bar-grid-type-'+this.type]"
      v-show="showMasterGrid && showMasterGridHeader"
    >
      <!-- HEADER: Assortment Details  -->
      <div v-if="type === 'assortment-details'" class="bar-row">
        <div class="bar-col left">
          <div class="bar-top-buttons" style="min-width:450px">
            <div
              class="bar-top-buttons-inner"
              v-if="doDraggedSort"
            >
              <v-switch
                color="primary"
                class="drag-sort"
                :label="dragLabel"
                v-model="dragIsEnabledModel"
              />
            </div>
            <div class="bar-btns" style="margin-left: 0; margin-right:10px;" v-if="doInternalAssortmentsSortGroupDragTreatment">
              <v-btn @click="internalAssortmentsSortControl" color="primary" size="small">Sort by Pillar
              </v-btn>
            </div>
            <div v-if="staticCountDescription !== ''" class="simple-text" style="padding-right:7px;">
              {{ staticCountDescription }}
            </div>
            <div v-if="getSortedByDescription() !== ''" class="simple-text"
                 style="padding-right:7px;"><b>Sorted By</b>:
              <div v-for="(itemChip, indexChip) in getSortedByItems" class="chip-outer" v-bind:key="'sort-chip-'+ indexChip + Math.random()">
                <v-chip
                  class="ma-2"
                  color="gray"
                  closable
                  size="x-small"
                  @click:close="gh.clearSortKey(itemChip.key)"
                >
                  {{ itemChip.name }}
                </v-chip>
              </div>
            </div>
            <div v-if="showMasterGrid && showDownloadImages" class="simple-text">
              <div class="aggrid-items-button-list--wrapper">
                <v-menu left offset-y open-on-hover content-class="aggrid-items-button-list--wrapper--menu">
                  <template v-slot:activator="{ props }">
                    <v-btn class="ma-2" variant="outlined" color="primary"
                           v-bind="props"
                           size="x-small">Download Images
                    </v-btn>
                  </template>
                  <div class="single-select">
                    <a :href="zipImagesHrefMethod('heroSmall')" download>
                      <div class="text-body-2 single-select__item">
                        <p>Hero Small</p>
                      </div>
                    </a>
                    <a :href="zipImagesHrefMethod('heroLarge')" download>
                      <div class="text-body-2 single-select__item">
                        <p>Hero Large</p>
                      </div>
                    </a>
                    <a :href="zipImagesHrefMethod('allSmall')" v-if="isFootwear" download>
                      <div class="text-body-2 single-select__item">
                        <p>All Small</p>
                      </div>
                    </a>
                    <a :href="zipImagesHrefMethod('allLarge')" v-if="isFootwear" download>
                      <div class="text-body-2 single-select__item">
                        <p>All Large</p>
                      </div>
                    </a>
                  </div>
                </v-menu>
              </div>
            </div>
            <div v-if="showStyleFinder" class="simple-text">
              <StyleFinder></StyleFinder>
            </div>
          </div>
        </div>
        <div class="bar-col col-right-txt right colzone-flex" style="min-width: 570px" v-if="doResetPrices">
          <div class="colzone updating">
            <div
              :class="['txt action-link no-link',(showUpdatingBar) ? '' : 'hide']"
            >{{ updatingInformation.message }}
            </div>
          </div>
          <div class="colzone undoredo">
            <div
              v-if="hasUndoElements || hasRedoElements" title="CMD-Z or CTRL-Z"
              :class="['action-link',(!hasUndoElements)?'disabled':'']" small
              @click="gh.actionUndo"
            >Undo
            </div>
            <div
              v-if="hasUndoElements || hasRedoElements" title="CMD-Y or CMD-Y"
              :class="['action-link',(!hasRedoElements)?'disabled':'']" small
              @click="gh.actionRedo"
            >Redo
            </div>
          </div>
          <div class="colzone bulkedit" v-if="showResetPrices">
            <div class="action-link disabled" style="padding-right:1px;">Bulk Edit:</div>
            <div
              class="action-link" small
              @click="bulkEditColumn('discountPercent')"
            >Discount %
            </div>
            <div
              class="action-link" small
              @click="bulkEditColumn('suggestedRetail')"
            >Sugg. Retail
            </div>
            <div
              class="action-link" small
              @click="resetPrices"
            >Reset Prices
            </div>
          </div>
        </div>
      </div>
      <!-- HEADER: My Assortments (folders)  -->
      <div v-else-if="type === 'assortments-list'" class="bar-row">
        <div class="bar-col left" v-if="showMasterGrid">
          <v-text-field
            class="col-field-filter"
            variant="underlined"
            density="compact"
            hide-details="auto"
            color="primary"
            clearable
            label="Search by Title"
            ref="field_filter"
            v-model="field_filter"
            @keyup="ch.gridFilter"
            @click:clear="ch.gridFilterClearClick"
            @blur="ch.gridFilter"
          ></v-text-field>
        </div>
        <div class="bar-col right">
          <div :class="['txt action-link no-link',(showUpdatingBar) ? '' : 'hide']">{{
              updatingInformation.message
            }}
          </div>
          <div v-if="hasUndoElements" title="CMD-Z or CTRL-Z" @click="gh.actionUndo" class="action-link" size="small">Undo
          </div>
          <div v-if="hasRedoElements" title="CMD-ALT-Z or CMD-ALT-Z" @click="gh.actionRedo" class="action-link" size="small">
            Redo
          </div>
          <div @click="addNewSingleFolder(null)" class="new-folder">
            <i class='material-icons folder'>folder_open</i><span>New Folder</span>
          </div>
          <div class="bar-btns">
            <v-btn color='primary' size="small" @click="gh.popupNewAssortment">New Assortment</v-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- GRID -->
    <div
      :class="[
        'grid-holder',
        (this.assortment) ? this.assortment.channel : '',
        'subheader-is-open-'+subheaderOpenState,
        (this.getSortedByDescription!== '') ? 'has-sorted-description': '',
        (somethingSelected) ? 'selected': '',
        (type === 'assortments-list' || this.assortment.method === $store.state.constants.ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC) ? 'no-footer-when-unselected' : ''
        ]"
      v-show="showMasterGrid"
    >
      <AgGridVue :class="['ag-theme-balham','master-grid-type-'+type]" id="myGrid" style="width: 100%; height: 100%;"
                   :gridOptions="gridOptions"
                   :rowData="getRowDataBound"
                   v-bind:key="gridKey"
                   @grid-ready="onGridReady"
                   @row-drag-move="onRowDragMove"
                   @row-drag-leave="onRowDragLeave"
                   @row-drag-end="onRowDragEnd"
                   @paste-start="gh.pasteStart"
                   @paste-end="gh.pasteEnd"
      ></AgGridVue>
    </div>

    <!-- SPINNER -->
    <div v-if="!showMasterGrid" class="master-grid-not-shown">
      <!--
      HIDE HOW TO - Leave in place because we may want to bring back
      <div
        v-else-if="showAssortmentHowTo"
        class="assortment-how-to--wrapper">
        <assortment-howto
          :loadCallback="initCloseHowTo"
          :clickCallback="clickCloseHowTo"/>
      </div>
      <div v-else class="no-rows" style="opacity:1">
        <span>{{gh.getNoRowsToShowMessage()}}</span>
      </div>
      -->
      <div v-if="showSpinningLoader" class="loading">
        <div class="view-global-loading">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
      </div>
      <div v-else v-show="noRowsMessageBounceShow" class="no-rows" style="opacity:1; text-align: center">
        <span>{{ noRowsMessage.text || noRowsMessage }}</span>
        <div class="mt-2" v-if="noRowsMessage.type === 'assortments'">
          <v-btn color='primary' size="small" @click="gh.popupNewAssortment">New Assortment</v-btn>
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <div
      v-if="showMasterGrid && this.assortment.method !== $store.state.constants.ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC" class="grid-footer-bar">
      <!-- FOOTER: IF SOMETHING SELECTED -->
      <div v-if="somethingSelected && this.assortment.method !== $store.state.constants.ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC" class="bar-row">

        <div class="bar-col col-left-txt">
          <div class="bar-info" v-if="type !== 'assortments-list'">{{ barInfoRows }}</div>
          <div :class="['bar-btns',(type === 'assortments-list') ? 'no-margin' : '']">
            <v-btn v-if="rowIsSelected" color="red" @click="gh.removeSelectedRowsSelected" size="small">Delete</v-btn>
            <v-btn
              :disabled="showCopyButtonAsDisabled"
              v-if="type === 'assortments-list'"
              color="primary"
              size="small"
              @click="gh.popupCopyAssortment"
            >Copy
            </v-btn>
            <v-btn v-if="showArchiveButton" @click="gh.archiveSelectedRows(true)" color="primary" size="small">Archive</v-btn>
            <v-btn v-if="showUnarchiveButton" @click="gh.archiveSelectedRows(false)" color="primary" size="small">Unarchive
            </v-btn>
          </div>

        </div>

        <div class="bar-col col-center center">
          <div class="action-link" @click="gh.deselectAll()" size="small">Deselect All</div>
        </div>

        <div v-if="type !== 'assortments-list'" class="bar-col col-right-txt right">
          <div class="bar-info">{{ barInfoCells }}</div>
          <div class="bar-btns">
            <v-btn color="primary" :disabled="gh.getRangeSelectionNodes().clearableNodes.length===0"
                   @click="gh.clearValuesWithinSelectedRange()" size="small">Clear
            </v-btn>
            <v-btn @click="gh.popupGroupEdit" color="primary"
                   :disabled="gh.getRangeSelectionNodes().editableNodes.length===0" size="small">Group Edit
            </v-btn>
          </div>
        </div>
        <div v-else class="bar-col col-right-txt right"></div>
      </div>

      <!-- FOOTER: IF SOMETHING NOT SELECTED -->
      <div v-else class="bar-row">
        <div v-if="this.assortment.method !== $store.state.constants.ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC" class="bar-col col-left-txt">
          <div
            class="bar-switches"
            v-if="doRowGrouping && type !== 'assortments-list'"
            v-show="!doInternalAssortmentsSortGroupDragTreatment"
          >
            <v-switch
              color="primary"
              class="group-ungroup"
              label="Group"
              v-model="groupIsEnabledModel"
            />
          </div>
          <div v-if="showExpandCollapseAll" class="action-link" @click="gh.collapseAll()" size="small">Collapse All</div>
          <div v-if="showExpandCollapseAll" class="action-link" @click="gh.expandAll()" size="small">Expand All</div>
          <div v-if="masterDetail" class="action-link" @click="gh.selectAll()" size="small">Select All</div>
          <div v-else-if="assortment.channel === $store.state.constants.ITS__ASSORTMENTS__CHANNEL_TYPE__WHOLESALE" bar-col>
            <span>Document Exports and Showroom only include 'A' status</span>
          </div>
        </div>

        <div v-else class="bar-col left dynamic-placeholder">&nbsp;</div>

        <!-- <div class="bar-col col-right-txt right">
          <div class="bar-btns">
            <v-btn @click="gh.popupGroupEdit" color="primary" size="small">Create Order</v-btn>
          </div>
        </div> -->

        <div v-if="this.type==='assortment-details' " class="bar-col col-center center">
          <v-btn
            size="small"
            color="primary"
            @click="setControlBarTab('assortment')"
            v-if="this.controlBarTab === 'document'">
            <i aria-hidden="true" class="v-icon notranslate material-icons theme--dark" style="font-size: 20px;">arrow_left</i>
            Manage Assortment
          </v-btn>
          <v-btn
            size="small"
            color="primary"
            @click="setControlBarTab('document')"
            v-if="showCreateDocument">
            Create Document
            <i aria-hidden="true" class="v-icon notranslate material-icons theme--dark" style="font-size: 20px;">arrow_right</i>
          </v-btn>
        </div>
        <!--right placeholder - currently unused, but needed to be in place for centering-->
        <!-- <div v-if="this.type === 'assortment-details' && this.assortment.method === $store.state.constants.ITS__ASSORTMENTS__METHOD_TYPE__STATIC" class="bar-col col-right-txt right">
          <div class="bar-btns">
            <v-btn @click="gh.popupGroupEdit" color="primary" size="small">Create Order</v-btn>
          </div>
        </div> -->
        <div v-else class="bar-col right dynamic-placeholder">&nbsp;</div>
      </div>
    </div><!--footer -->

  </div>
</template>
<script>
import _cloneDeep from 'lodash.clonedeep'
import _isEmpty from 'lodash.isempty'
import dayjs from 'dayjs'
import router from '@/router'

import checkUserPermissions from '@/mixins/checkUserPermissions'
import store from '@/store/store'

import { mapActions, mapGetters, mapState } from 'vuex'
import { VUEX_DIALOG_SHOW } from '@/store/constants/ui/dialog'
import { VUEX_CONTROLBAR_SET_TAB } from '@/store/constants/ui/controlBar'
import {
  VUEX_ASSORTMENT_REFETCH,
  VUEX_GRID_REKEY,
  VUEX_GRID_CONFIRMATION_RESET_PRICES,
  VUEX_ASSORTMENT_INTERNAL_INTERNATONAL_CATEGORIES_FETCH,
  VUEX_ASSORTMENT_CATEGORIES_FETCH
} from '@/store/constants/models/assortments'

import {
  VUEX_ASSORTMENT_DOWNLOADS_ADD_TO_QUEUE
} from '@/store/constants/models/assortments/assortmentDownloads'

import {
  VUEX_STATISTICS_SEND
} from '@/store/constants/statistics'

import ColumnHelpers from '@/components/_core/GridsCore/helpers/ColumnHelpers'
import LabelEditor from '@/components/_core/GridsCore/CellEditors/LabelEditor'
import LargeTextEditor from '@/components/_core/GridsCore/CellEditors/LargeTextEditor'
import ImageEditor from '@/components/_core/GridsCore/CellEditors/ImageEditor'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import MyAssortmentDragger from '@/components/_core/GridsCore/helpers/MyAssortmentDragger'
import DataMiddleware from '@/components/_core/GridsCore/helpers/DataMiddleware'
import ServerSideDatasourceAssortmentManager from '@/components/_core/GridsCore/ServerSideDatasource/AssortmentManager'
import ExcelMiddleware from '@/components/_core/GridsCore/helpers/ExcelMiddleware'
import * as SharedComponents from '@/components/_core/GridsCore/composables/sharedComponents'

import productDataModifier from '@/mixins/productDataModifier'
import {
  useGridGlobalSetup
} from '@/components/_core/GridsCore/composables/gridComposables'
import _debounce from 'lodash.debounce'
export default {
  name: 'MasterGrid',
  setup () {
    const { showUpdatingBar } = useGridGlobalSetup()
    return {
      showUpdatingBar
    }
  },
  components: {
    ...SharedComponents
  }, // components

  props: {
    type: {
      type: String,
      required: true
    },
    subtype: {
      type: String,
      required: false
    },
    rowData: {
      type: Array,
      required: true
    },
    columnDefArray: {
      type: Array,
      required: true
    },
    autoGroupColumnDefObject: {
      type: Object,
      required: true,
      default: () => ({})
    }
  }, // props

  mixins: [
    productDataModifier
  ],

  data: function () {
    return {
      // AG Grid core
      theme: 'ag-theme-balham',
      gridApi: null,
      getSortedByDescriptionToggler: Math.random(),

      staticCountDescription: '',

      // AG Grid options listed here, those options later set by Master Grid "type" variable
      treeData: null,
      masterDetail: null,
      groupDefaultExpanded: null,
      rowSelection: 'multiple',
      rowModelType: 'clientSide',
      pagination: false,
      suppressServerSideInfiniteScroll: true, // false = partial, true is full
      cacheBlockSize: null,
      enableRangeSelection: true,
      // groupMultiAutoColumn: false, // double grouping DISCONTINUED AND NOT USED

      // MasterGrid specifc (aka, not AG Grid directly)
      gridKey: Date.now(),
      dragIsEnabledModel: false,
      groupIsEnabledModel: false,
      rowDataBound: [],
      preredrawSnapshot: null,
      keysDepressed: [],
      eventSortChangedIsEnabled: false, // is enabled after first page load
      eventDragChangedIsEnabled: false, // is enabled after first page load
      em: ExcelMiddleware,
      gh: GridHelpers,
      ch: ColumnHelpers,
      dm: DataMiddleware,
      showMasterGridTogger: Math.random(),
      field_filter: '',
      isLibrariesGrid: false,
      assortmentHowToClosed: null, // showing assortment how to
      enabledManagedDragging: false,
      rowDragMultiRow: false,
      productIntegrationStep: '',

      // external control panels
      doDraggedSort: true,
      doResetPrices: true,
      doRowGrouping: true,
      doInternalAssortmentsSortGroupDragTreatment: false,

      // togglers
      togglerSomethingSelected: '',
      togglerUndoRedo: '',
      noRowsMessageBounceShow: false,

      // the grid helper selection object
      ghSelectionObject: null
    }
  }, // data

  mounted () {

  }, // mounted

  beforeUnmount () {

  }, // destroyed

  watch: {
    groupIsEnabledModel (newValue) {
      if (!this.doInternalAssortmentsSortGroupDragTreatment) {
        GridHelpers.determineGroupUngroup()
      }
    },
    dragIsEnabledModel (newValue) {
      if (this.eventDragChangedIsEnabled) {
        if (newValue) {
          this.enableDrag(false)
        } else {
          this.disableDrag()
        }
      }
    },
    confirmationTogglerResetPrices () {
      this.resetPricesConfirmed()
    },
    confirmationTogglerArchiveSelected: {
      deep: true,
      handler () {
        GridHelpers.archiveSelectedRowsConfirmed(this.confirmationTogglerArchiveSelected.archiveIt)
      }
    },
    showMasterGrid () {
      // if we show the grid, even once, then don't do assortment HowTo anymore
      this.assortmentHowToClosed = true
    },
    products: {
      deep: true,
      handler () {
        this.productsLookupSuccess()
      }
    },
    noRowsMessage: {
      deep: true,
      handler (val) {
        this.noRowsMessageBounceShow = false
        setTimeout(function () {
          this.noRowsMessageBounceShow = true
        }.bind(this), 1000)
      }
    }
  }, // watch

  // beforeMount essentially builds AG Grid and loads the data
  beforeMount () {
    // stash
    GridHelpers.setMasterGridThis(this)
    GridHelpers.stashMasterGridThis(this) // keep a copy as well

    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // 1) Do MasterGrid Type Specific Settings, component reference
    switch (this.type) {
      case 'assortment-details':
        this.treeData = false
        this.masterDetail = true
        this.groupDefaultExpanded = 0 // none

        // disable dragged sort, reset prices, and row selection
        if (this.assortment.method === ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC) {
          // dynamic assortments
          this.doDraggedSort = false
          this.doResetPrices = false
          this.rowSelection = false
          this.rowModelType = 'serverSide'
          this.pagination = true
          this.cacheBlockSize = 100 // was 50 under infinite scroll, change to 100 to match default page size
          this.doRowGrouping = false
          this.groupDefaultExpanded = null

          this.masterDetail = false // no master detail either
        } else {
          // static assortments
          this.enabledManagedDragging = true
          this.rowDragMultiRow = true
          let isInternal = (this.assortment.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL)
          if (isInternal) {
            // internal international
            // this.doDraggedSort = true
            // this.doInternalAssortmentsSortGroupDragTreatment = true
            this.masterDetail = false // no master detail either
          } else {
            // regular statics
            // this.groupMultiAutoColumn = true //enable double column grouping
          }
        }

        // POP - turn off master detail & row grouping
        if (this.assortment?.productType === ITS__PRODUCT_TYPE__POP) {
          this.masterDetail = false
          this.doRowGrouping = false
        }

        break
      case 'assortments-list':
        this.treeData = true
        this.masterDetail = false
        this.groupDefaultExpanded = -1 // all levels
        this.enableRangeSelection = false
        break
    }

    // MASTER KILL
    this.doRowGrouping = false
    this.doDraggedSort = false

    // if doRowGrouping, check and see what the default state is
    if (this.doRowGrouping) {
      if (this.doInternalAssortmentsSortGroupDragTreatment) {
        this.groupIsEnabledModel = false
      } else if (this.assortment?.uiSettings?.gridSettings?.groupIsEnabled) {
        this.groupIsEnabledModel = this.assortment.uiSettings.gridSettings.groupIsEnabled
      }
    }

    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // 2) Default column settings
    this.autoGroupColumnDef = this.autoGroupColumnDefObject
    this.columnDefs = this.columnDefArray
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
      editable: true,
      enableValue: true,
      sortable: true,
      suppressHeaderMenuButton: true,
      suppressKeyboardEvent: GridHelpers.suppressKeyboardEvent, // custom keyboard surpress event
      cellClassRules: {
        'cell-style-finder-match': params => {
          return (params.colDef?.field === 'style' && params.data?.styleFinderMatch)
        },
        'cell-style-finder-match-current': params => {
          return (params.colDef?.field === 'style' && params.data?.styleFinderMatchCurrent)
        },
        'cell-readonly': params => {
          if (params.colDef) {
            if (typeof params.colDef.editable === 'function') {
              let ret = params.colDef.editable()
              return ret === false
            } else {
              return params.colDef.editable === false
            }
          } else {
            return true // default to read only
          }
        },
        'master-detail-no-results': params => {
          return GridHelpers.hasMasterDetail(params) === false
        },
        'master-detail-results': params => {
          return GridHelpers.hasMasterDetail(params)
        },
        'hover-over-row': params => {
          if (this.type === 'assortments-list') {
            if (MyAssortmentDragger.potentialParent === null) {
              return false
            } else {
              return params.node === MyAssortmentDragger.potentialParent
            }
          }
        }
      }
    }

    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // 3) Master Detail Cell Renderer (aka, the tables within tables)
    this.detailCellRendererParams = params => {
      let res = {}

      // pull column titles from actual sample data
      let detailColumns = []
      if (params.data?.locations?.availability) {
        for (let i = 0; i < params.data.locations.availability.length; i++) {
          let obj = params.data.locations.availability[i]

          // filter out header
          let doHeader = true
          if (params.data.locations.lineStatus === 'R') {
            doHeader = (obj.inventory)
          }
          if (doHeader) {
            // match fieldName with field name below
            let retobj = {
              field: 'field_' + i,
              headerName: obj.size,
              flex: 1
            }
            detailColumns.push(retobj)
          }
        }
      }

      // default columns
      res.detailGridOptions = {
        columnDefs: detailColumns,
        defaultColDef: {
          suppressHeaderMenuButton: true,
          suppressFieldDotNotation: true,
          minWidth: 83,
          maxWidth: 83,
          lockPosition: true,
          valueFormatter: (this.assortment.status === 'R') ? null : ColumnHelpers.formatterDatePassthroughYYYYMMDD_AddOneToMonth,
          cellClassRules: {
            'cell-master-detail-current': params => {
              // make old and current dates bold - except for status R, or invalud dates
              if (GridHelpers.mgThisArray[0].assortment.status === 'R') {
                return false
              } else {
                let cellDate = dayjs(String(params.value), 'YYYYMMDD').add(1, 'month')
                if (!cellDate.isValid()) {
                  return false
                } else {
                  let todaysDate = dayjs()
                  return todaysDate.diff(cellDate) >= 0
                }
              }
            },
            'cell-readonly cell-master-detail': params => {
              return true
            }
          }
        }

      }

      // pull row data
      res.getDetailRowData = function (params) {
        let rowDataDetail = []
        if (params.data?.locations?.availability) {
          let retobj = {}
          for (let i = 0; i < params.data.locations.availability.length; i++) {
            let obj = params.data.locations.availability[i]
            // sept 12, 2024 use custom field name - decimal point field names broke in some recent version of aggrid, even with suppressFieldDotNotation (above
            // match fieldName with field name above (search fieldName for my other comment)
            let fieldName = 'field_' + i
            // value is inventory for R status types
            if (params.data.locations.lineStatus === 'R') {
              // only add if the data for this column is not blank
              if (obj.inventory) {
                retobj[fieldName] = obj.inventory
              }
            } else {
              let tempTemp = String(obj.date)
              retobj[fieldName] = tempTemp
            }
          }
          rowDataDetail.push(retobj)
          params.successCallback(rowDataDetail)
        }
      }
      return res
    }

    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // 4) Final Grid Options settings
    let gridOptions = {
      /// ///////////////////
      // column defs
      excelStyles: ExcelMiddleware.defaultExcelStyles(),
      columnDefs: this.columnDefs,
      defaultColDef: this.defaultColDef,
      getMainMenuItems: ColumnHelpers.getMainMenuItems,
      autoGroupColumnDef: this.autoGroupColumnDef, // group column rendering
      onGridSizeChanged: GridHelpers.eventGridSizeChanged,
      suppressHorizontalScroll: false,
      onColumnGroupOpened: this.eventColumnGroupOpened,
      tooltipShowDelay: 0,

      /// ///////////////////
      // tree data specific
      treeData: this.treeData, // use tree data
      getDataPath: function (data) {
        return data.hierarchy
      },

      /// ///////////////////
      // selection
      rowSelection: this.rowSelection,
      rowMultiSelectWithClick: false,
      enableCellTextSelection: false, // lets you select a cell - it is kind of ugly also: "Note: When this is set to true, the clipboard service is disabled."
      enableRangeSelection: this.enableRangeSelection, // lets you select a range of rows and columns
      onSelectionChanged: this.eventSelectionChanged,
      onRangeSelectionChanged: this.eventSelectionChanged,
      // suppressRowClickSelection:true, //dont make rows selectable

      /// ///////////////////
      // data, & data changing
      onCellValueChanged: this.eventCellValueChanged,
      getRowId: function (e) { // ensure that data row node id is ID
        return e?.data?.id
      },
      rowModelType: this.rowModelType, // usually client side, unless server side for dyanmic
      pagination: this.pagination, // paginate? used by server side, instead of infinite scroll
      cacheBlockSize: this.cacheBlockSize, // for server side data, how many to fetch at once
      paginationPageSize: this.cacheBlockSize,
      valueCache: true,

      /// ///////////////////
      // sort
      onSortChanged: this.eventSortChanged,
      serverSideSortAllLevels: true, // used in server side data
      // maxBlocksInCache: 1, // used in server side data - setting to very low number - unfortunately, caching = BUGGY! but disabling this because pagination resolves it

      /// ///////////////////
      // drag
      rowDragManaged: this.enabledManagedDragging,
      animateRows: true, // this.enabledManagedDragging,
      suppressDragLeaveHidesColumns: true, // stop columns being dragged off screen
      rowDragMultiRow: this.rowDragMultiRow,

      /// ///////////////////
      // group
      groupDefaultExpanded: this.groupDefaultExpanded, // expand everything by default
      // groupMultiAutoColumn: this.groupMultiAutoColumn, // double grouping DISCONTINUED AND NOT USED
      // groupUseEntireRow: true, // set to true and you kill group row drag
      // groupRowRenderer: 'agGroupCellRenderer', //related to groupUseEntireRow
      // groupRowRendererParams: {}, //related to groupUseEntireRow

      /// ///////////////////
      // clipboard
      suppressCopyRowsToClipboard: true, // if row selection is enabled, don't copy rows
      // sendToClipboard:this.sendToClipboardFunction, //what has been copied - overrides normal clipboard behavior - don't use
      // processDataFromClipboard:this.processDataFromClipboardFunction, //tool to manage what has been copied - overrides normal clipboard behavior - don't use

      /// ///////////////////
      // master detail options
      masterDetail: this.masterDetail, // master detail table - SET IN TYPE
      embedFullWidthRows: true, // horizontal scrolling of master detail table
      detailCellRendererParams: this.detailCellRendererParams,
      isRowMaster: function (params) {
        let tparams = {
          data: params
        }
        return GridHelpers.hasMasterDetail(tparams)
      },

      /// ///////////////////
      // RIGHT CLICK
      getContextMenuItems: GridHelpers.getContextMenuItems,

      /// ///////////////////
      // components
      components: {
        imageEditor: ImageEditor,
        labelEditor: LabelEditor,
        largeTextEditor: LargeTextEditor
      },

      // new undo redo
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 20,

      /// ///////////////////
      // custom row class & styles
      getRowStyle: function (params) {
        // currently used in relation to drag indication
        if ((params.node.groupData?.isDragged === 'top') || (params.node.data?.isDragged === 'top')) {
          return { 'border-top': '1px solid #000' }
        } else if ((params.node.groupData?.isDragged === 'bottom') || (params.node.data?.isDragged === 'bottom')) {
          return { 'border-bottom': '1px solid #000' }
        }
      },
      getRowClass: function (params) {
        let ret = ''
        if (params.node.group) {
          ret += ' row-group'
        } else {
          ret += ' row-item'
          if (GridHelpers.mgThisArray[0].type === 'assortment-details' && !params.data?.createdDate) {
            ret += ' row-is-deleted'
          }
        }
        if (params.data?.type === 'folder') {
          ret += ' row-folder'
        }
        if (params.node.detail) {
          ret += ' row-master-detail'
        }
        if (GridHelpers.mgThisArray[0]) {
          ret += ' rowtype-' + GridHelpers.mgThisArray[0].type
        }
        return ret
      },

      /// ///////////////////
      // change default messaging
      localeText: {
        noRowsToShow: ''
      },
      suppressNoRowsOverlay: true, // hide no rows to show completely, since we now do it custom
      loading: false, // hide loading completely, since we now do it custom

      /// ///////////////////
      // filtering
      excludeChildrenWhenTreeDataFiltering: true, //
      onFilterChanged: ColumnHelpers.gridFilterChanged
    }

    gridOptions.getRowHeight = function (params) {
      // currently used mostly in releation to drawing detail rows
      let isDetailRow = params.node.detail
      if (isDetailRow) {
        return 118 // 110
      } else {
        // for all non-detail rows, return 35, the default row height
        return 35
      }
    }

    this.gridOptions = gridOptions

    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // 5) THE END - Load Data
    this.loadData()
  }, // beforeMount

  computed: {
    ...mapState({
      openState: state => state.amSubheader.openState,
      assortment: state => state.assortments.assortment,
      assortments: state => state.assortments.assortments,
      documents: state => state.documents.documents, // scheduled documents
      products: state => state.products.products,
      productsRequest: state => state.apiProducts.productsRequest,
      assortmentsAllInternalActive: state => state.assortments.assortmentsAllInternalActive,
      agGridRowDataAssortmentsAllActive: state => state.assortments.agGridRowDataAssortmentsAllActive,
      dynamicQuery: state => state.assortments.assortment.query,
      updatingInformation: state => state.grid.updatingInformation,
      assortmentPillars: state => state.assortments.assortmentPillars,
      internalInternationalCategories: state => state.assortments.internalInternationalCategories,
      confirmationTogglerResetPrices: state => state.assortments.confirmationTogglerResetPrices,
      confirmationTogglerArchiveSelected: state => state.assortments.confirmationTogglerArchiveSelected,
      agGridRowDataLoading: state => state.assortments.agGridRowDataLoading,
      filters: state => state.filterBar.filters,
      localStorage: state => state.localStorage.localStorage,
      controlBarTab: state => state.controlBar.currentTab,
      dynamicOptionsLoading: state => state.filterBar.dynamicOptionsLoading
    }),

    ...mapGetters({
      appliedFilterBarChips: 'appliedParameters', // aka, from the filter bar, what chips are being used,
      localStorage_Get: 'localStorage_Get'
    }),

    showSpinningLoader () {
      if (!this.gridApi) {
        return true
      } else if (this.assortment?.method === ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC) {
        let chips = this.appliedFilterBarChips()
        if (chips && chips.length > 0) {
          if (this.productsRequest !== ITS__API__REQUEST_TYPE__SUCCESS && this.productsRequest !== undefined) {
            return true
          } else {
            return this.agGridRowDataLoading || ServerSideDatasourceAssortmentManager.loading // || !this.dynamicOptionsLoading
          }
          // return this.agGridRowDataLoading || ServerSideDatasourceAssortmentManager.loading // || !this.dynamicOptionsLoading
        } else {
          return false
        }
      } else {
        return this.agGridRowDataLoading
      }
    },

    gridTypeClass () {
      let ret = 'type-'
      if (this.assortment?.channel) {
        ret += this.assortment.channel
      }
      return ret
    },

    // show the grid
    showMasterGrid () {
      // dont show if gridApi is not ready
      let ret = false
      if (this.gridApi) {
        // assume we will show
        ret = true

        // always show if a filter is applied - don't even check "WAYS TO BE FALSE" below
        let filterModel = this.gridApi.getFilterModel()
        if (!_isEmpty(filterModel)) {
          return true
        }

        // WAYS TO BE FALSE
        if (this.agGridRowDataLoading) {
          // if loading
          ret = false
        } else if (this.showMasterGridTogger) {
          if (this.rowModelType === 'serverSide') {
            // if server side or totalCount is 0
            let chips = this.appliedFilterBarChips()
            if (!chips || chips.length === 0 || ServerSideDatasourceAssortmentManager.totalCount === 0 || ServerSideDatasourceAssortmentManager.loading) {
              ret = false
            }
            if (this.productsRequest !== ITS__API__REQUEST_TYPE__SUCCESS) {
              ret = false
            }
          } // serverSide
        } // showMasterGridTogger
      } // gridApi ready
      return ret
    },

    hasOrdersRights () {
      return checkUserPermissions({
            accessRequires: [{ permission: ITS__PERMISSION__ORDERS__SAMPLE_ORDERS }]
          }, store).hasPerm
      /* if (this.subtype === ITS__ORDERS__ORDER_TYPE__SAMPLE) {
        return (this.permissionsSampleCoordinator.hasPerm)
      }
      if (this.subtype === ITS__ORDERS__ORDER_TYPE__PROMO) {
        return (this.permissionsApprover.hasPerm || this.permissionsSeniorApprover.hasPerm)
      } else {
        return false
      } */
    },

    noRowsMessage: function () {
      return this.gh.getNoRowsToShowMessage()
    },

    showExpandCollapseAll () {
      return (!this.doInternalAssortmentsSortGroupDragTreatment) && (this.doRowGrouping || this.masterDetail)
    },

    showMasterGridHeader () {
      let ret = this.showMasterGrid
      if (!ret && this.type === 'assortments-list') {
        ret = true
      }
      return ret
    },

    // for showing assortment how to - BEGIN
    showAssortmentHowTo () {
      // dont show if ag grid is not ready
      if (!this.gridApi) return false
      // dont show if ag grid is loading
      if (this.agGridRowDataLoading) return false
      // check has products
      if (this.assortmentHasProducts || this.assortmentHasQuery) return false
      // check if how-to not loaded AND localStorage === true
      if (this.assortmentHowToClosed === null && !!this.localStorage_Get('user.settings.showAssortmentHowTo') === true) return true
      // check if close clicked
      if (this.assortmentHowToClosed) return false
      // check localstorage
      return (this.localStorage_Get('user.settings.showAssortmentHowTo') !== undefined) ? this.localStorage_Get('user.settings.showAssortmentHowTo') : true
    },
    assortmentHasProducts () {
      if (this.assortment._id === undefined) return true // fallback for unloading assortment
      return (this.assortment.hasOwnProperty('products') && this.assortment.products.length > 0)
    },
    assortmentHasQuery () {
      if (this.assortment._id === undefined) return true // fallback for unloading assortment
      return (this.assortment.hasOwnProperty('query') && Object.keys(this.assortment.query).filter(key => {
        if (key !== '_options' && key !== 'locations.code' && key !== 'locations.lineStatus' && key !== 'productType') return key
      }).length > 0)
    },
    // for showing assortment how to - END

    // archive related
    showArchiveButton () {
      return (this.type === 'assortments-list' && router?.currentRoute?.value.name === 'assortment-manager--all-assortments')
    },
    showUnarchiveButton () {
      return (this.type === 'assortments-list' && router?.currentRoute?.value.name === 'assortment-manager--archived')
    },

    showCopyButtonAsDisabled () {
      const sd = GridHelpers.getSelectionDetails()
      const rowCount = sd.rangeSelectionNodes.rows.length
      return (this.barInfoRows.includes('folder') || !rowCount)
      // barInfoRows.includes('folder') || barInfoRows.includes('assortments')
    },

    // header related
    subheaderOpenState () {
      if (this.type === 'assortments-list') {
        return 'my'
      } else {
        return this.openState
      }
    },

    // is something at all selected?
    somethingSelected () {
      if (this.togglerSomethingSelected !== '') {
        return this.ghSelectionObject?.somethingSelected
      } else {
        return false
      }
    },
    rowIsSelected () {
      if (this.togglerSomethingSelected !== '') {
        return (this.ghSelectionObject?.selectedRows > 0)
      } else {
        return false
      }
    },
    getSortedByItems
     () {
      let ret = []
      if (this.getSortedByDescriptionToggler && this.gridApi) {
        let sortState = this.gridApi.getColumnState()
        ret = GridHelpers.getSortedByItems(sortState)
      }
      return ret
    },

    // bar info labels: for cells and rows
    barInfoRows () {
      if (this.togglerSomethingSelected && this.somethingSelected) {
        return GridHelpers.barInfoRows()
      } else {
        return ''
      }
    },
    barInfoCells () {
      if (this.somethingSelected && this.ghSelectionObject?.selectedCells > 0) {
        return GridHelpers.barInfoCells(this.ghSelectionObject)
      } else {
        return ''
      }
    },

    isFootwear () {
      return this.assortment?.productType === ITS__PRODUCT_TYPE__FOOTWEAR
    },

    // redo/undo
    hasUndoElements () {
      let ret = DataMiddleware.hasUndoElements()
      if (this.togglerUndoRedo) {
        return ret
      } else {
        return ret
      }
    },
    hasRedoElements () {
      let ret = DataMiddleware.hasRedoElements()
      if (this.togglerUndoRedo) {
        return ret
      } else {
        return ret
      }
    },

    // get row data
    getRowDataBound () {
      return (this.assortment?.method === ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC) ? null : this.rowDataBound
    },

    showResetPrices () {
      let ret = (this.assortment?.productType !== ITS__PRODUCT_TYPE__POP)
      let isInternal = (this.assortment?.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL)
      if (this.doInternalAssortmentsSortGroupDragTreatment) {
        ret = false
      } else if (isInternal) {
        ret = false
      }
      return ret
    },

    showDownloadImages () {
      return (!this.doInternalAssortmentsSortGroupDragTreatment)
    },

    showCreateDocument () {
      let isInternal = (this.assortment.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL)
      return (this.controlBarTab === 'assortment' && !isInternal)
    },

    dragLabel () {
      return 'Drag Sort'
    },

    showStyleFinder () {
      return (this.showMasterGrid && this.assortment.method === ITS__ASSORTMENTS__METHOD_TYPE__STATIC)
    }

  }, // computed

  methods: {
    ...mapActions({
      openDialog: VUEX_DIALOG_SHOW,
      setControlBarTab: VUEX_CONTROLBAR_SET_TAB,
      // downloadImages: VUEX_ASSORTMENT_PACKAGE_DOWNLOAD
      addAssortmentDownloadRequest: VUEX_ASSORTMENT_DOWNLOADS_ADD_TO_QUEUE,
      sendStatistics: VUEX_STATISTICS_SEND
    }),

    // output label for what is being sorted by
    getSortedByDescription () {
      let ret = ''
      if (this.getSortedByDescriptionToggler && this.gridApi) {
        let sortState = this.gridApi.getColumnState()
        ret = GridHelpers.getSortedByDescription(sortState)
      }
      return ret
    },

    // output label for what is being sorted by
    getStaticCountDescription () {
      let ret = ''
      if (this.gridApi) {
        if (this.assortment?.method !== ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC) {
          let rows = GridHelpers.getRowNodes()
          let rows2 = GridHelpers.extractRowDataFromRowNodes(rows, false)
          ret = rows2.length
          if (ret >= 0) {
            ret += (ret === 1) ? ' item' : ' items'
          } else {
            ret = ''
          }
        }
      }
      this.staticCountDescription = ret
    },

    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    /// DATA RELATED
    // AG Grid principal ready callback
    onGridReady (params) {
      // set the ag grid "soft models"
      this.gridApi = params.api

      if (!this.gridApi) return

      // completely disable row grouping
      if (this.doRowGrouping === false) {
        this.groupDefaultExpanded = 0 // none
        this.groupIsEnabledModel = false
      }

      // if dynamic assortment, load server side infinite scroll mode
      if (this.assortment?.method === ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC) {
        let datasource = ServerSideDatasourceAssortmentManager.fetcher()
        this.gridApi.setGridOption('serverSideDatasource', datasource)
      }

      // Remove Season column from footwear product type
      if (this.assortment?.productType) {
        if (this.assortment.productType === ITS__PRODUCT_TYPE__FOOTWEAR) {
          // remove seasonCodes
          ColumnHelpers.setColumnVisible('seasonCodes', false)
        } else if (this.assortment.productType === ITS__PRODUCT_TYPE__APPAREL) {
          // remove boxCategory
          ColumnHelpers.setColumnVisible('boxCategory', false)
        }
      }

      // Remove carryover from: DOMESTIC WHOLESALE, Footwear
      if (this.assortment?.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL &&
          this.assortment?.channel === ITS__ASSORTMENTS__CHANNEL_TYPE__WHOLESALE &&
          this.assortment?.region === ITS__REGION__DOMESTIC &&
          this.assortment?.productType === ITS__PRODUCT_TYPE__FOOTWEAR) {
        ColumnHelpers.setColumnVisible('carryover', false)
      }

      // Only status R has totalInventory
      if (this.assortment?.status !== 'R') {
        ColumnHelpers.setColumnVisible('locations.totalInventory', false)
      }

      if (!this.hasOrdersRights) {
        ColumnHelpers.setColumnVisible('inOrders', false)
      }

      // SET SORT columns
      let savedSortLoaded = false
      if (this.assortment?.uiSettings && this.assortment.uiSettings.gridSettings && this.assortment.uiSettings.gridSettings.sortOrder && this.assortment.uiSettings.gridSettings.sortOrder.length > 0) {
        let sortState = this.assortment.uiSettings.gridSettings.sortOrder
        sortState = DataMiddleware.parseAssortmentSort(sortState, true, false)
        this.gridApi.applyColumnState({
          state: sortState
        })
        this.getSortedByDescriptionToggler = Math.random()
        savedSortLoaded = true
      } else {
        this.eventSortChangedIsEnabled = true
      }

      // FIRST TIME LOADING / ADDITIONAL TIME LOADING CHECKS
      // Sometimes we may need to re-key the component and do a hard re-draw
      // in this case, you can specify different actions and checks that need to occur
      if (this.rowDataBound === null || this.rowDataBound.length === 0) {
        // first time loading
        this.rowDataBound = _cloneDeep(this.rowData)

        // FOR ASSORTMENT DETAILS - NON Key Innitiatives
        if (this.type === 'assortment-details') {
          let isInternal = (this.assortment.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL)
          if (isInternal) {
            if (this.doInternalAssortmentsSortGroupDragTreatment) {
              // if not doing saved sort, enable drag
              if (!savedSortLoaded) {
                this.dragIsEnabledModel = true
              }
            }
            // dispatch dynamic categories particular to this assortment
            this.fetchInternalAssortmentsCategories()
          } else {
            // turn off row drag & grouping by default if groupIsEnabledModel === false
            if (this.groupIsEnabledModel === false) {
              GridHelpers.ungroupColumns(true)
            } else {
              // see if grouping is needed
              GridHelpers.determineGroupUngroup()
            }

            // fetch categories for non dyanmics
            if (this.assortment?.method === ITS__ASSORTMENTS__METHOD_TYPE__STATIC) {
              this.fetchAssortmentPillars()
            }

            // kill drag by default, except here
            this.disableDrag()
          } // if doInternalAssortmentsSortGroupDragTreatment
        } // if assortment-details
      } else {
        // a hard reset has occured, so do a couple of checks
        // check and see if we need to group or ungroup
        GridHelpers.determineGroupUngroup()

        // check and see if we need to expand or un-expand
        this.determineExpandUnexpand()
      }

      // once done, allow draag change detection sending to the back end to occur
      // Enable Drag for doInternalAssortmentsSortGroupDragTreatment
      if (this.doInternalAssortmentsSortGroupDragTreatment) {
        this.doDraggedSort = true
        // this.gridApi.setGridOption('suppressRowDrag', false)
      }

      // micro-delays for aggrid to catch up
      setTimeout(function () {
        this.eventDragChangedIsEnabled = true
        this.getStaticCountDescription()
      }.bind(this), 500)
    },
    // load in the data!
    loadData () {
      if (this.gridApi) {
        // client side RowData - not used by dynamic assortment
        if (this.assortment?.method === ITS__ASSORTMENTS__METHOD_TYPE__STATIC && this.rowData) {
          this.rowDataBound = _cloneDeep(this.rowData)
          this.setRowDataRelay(this.rowDataBound, true)
        }
      }
    },

    // set row data - relay to emit function
    setRowDataRelay (rows, doHardReset) {
      // dont do this for server side data
      if (this.rowModelType !== 'serverSide') {
        this.rowDataBound = rows
        let params = {
          payload: rows,
          doHardReset: doHardReset
        }
        DataMiddleware.emitRowsUpdateSort(params)
      }
    },

    // bulk edit a particular column
    bulkEditColumn (colId) {
      GridHelpers.deselectAll()
      ColumnHelpers.selectColumn(colId, 'Prices')
      GridHelpers.popupGroupEdit()
    },

    // select a range of cells, clears all the values within it
    resetPrices () {
      // GLOBAL ACTION PROMPT: Pass messages & dispatch action
      let actionObj = {
        checkChangedFields: false,
        title: 'Are You Sure?',
        description: 'This will reset Cost and Suggested Retail back to their values that displayed on page load.',
        dispatchAction: VUEX_GRID_CONFIRMATION_RESET_PRICES,
        dispatchActionObject: {}
      }
      GridHelpers.mgThisArray[0].$store.dispatch('VUEX_GLOBAL_ACTION_PROMPT_SHOW', { actionObj: actionObj })
    },
    resetPricesConfirmed () {
      if (this.gridApi) {
        this.gridApi.forEachNodeAfterFilterAndSort(function (rowNode, index) {
          if (rowNode && typeof rowNode !== 'undefined') {
            if (rowNode.group === false) {
              if (rowNode.data.cost !== rowNode.data.costResetValue) {
                rowNode.setDataValue('cost', rowNode.data.costResetValue)
              }
              if (rowNode.data.suggestedRetail !== rowNode.data.suggestedRetailResetValue) {
                rowNode.setDataValue('suggestedRetail', rowNode.data.suggestedRetailResetValue)
              }
            }
          }
        })
      }
    },

    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    /// DRAGGING
    // enable drag - we need to take a hard snapshot of the data and renumber the sort
    // if its coming from sort, we don't need to renumber and clear sort
    enableDrag (fromSortChangeAutoCallback) {
      if (fromSortChangeAutoCallback === false) {
        this.renumberSort()
        this.clearAllSort()
      }
      this.dragIsEnabledModel = true
      this.gridApi.setGridOption('suppressRowDrag', false)

      // kill groups
      if (this.groupIsEnabledModel && !this.doInternalAssortmentsSortGroupDragTreatment) {
        this.groupIsEnabledModel = false
        GridHelpers.ungroupColumns()
      }
      // this.gridKey=Date.now()  //yyyy
    },

    // disable drag
    disableDrag () {
      this.dragIsEnabledModel = false
      this.gridApi.setGridOption('suppressRowDrag', true)
    },

    // ===============================================================
    // DRAG MOVING - main listener = moving, but not dropped yet
    onRowDragMove (event) {
      if (this.type === 'assortment-details') {
        if (this.enabledManagedDragging === false) {
          // this.onRowDragMoveManage(event)
        }
      } else if (this.type === 'assortments-list') {
        this.onRowDragMoveMyAssortments(event)
      }
    },

    // DRAG MOVING - MasterGrid assortments-list type
    // all handled via the MyAssortmentDragger helper
    onRowDragMoveMyAssortments (event) {
      MyAssortmentDragger.setPotentialParentForNode(event.api, event.overNode, event)
    },

    // ===============================================================
    // DRAG DROPPED - main listener = dropped, no longer moving - aka, drag has ended
    onRowDragEnd (event) {
      if (this.type === 'assortment-details') {
        this.onRowDragEndManage(event)
      } else if (this.type === 'assortments-list') {
        this.onRowDragEndMyAssortments(event)
      }
    },

    onRowDragEndManage (event) {
      let rows = GridHelpers.getRowNodes(false)
      let ret = GridHelpers.generateNewRowData(rows, false, this.groupIsEnabledModel)
      this.setRowDataRelay(ret, false)
    },

    // DRAG MOVING - MasterGrid assortments-list type
    onRowDragEndMyAssortments (event, fromLeave) {
      if (!MyAssortmentDragger.potentialParent) {
        return
      }
      var movingData = event.node.data
      var newParentPath = MyAssortmentDragger.potentialParent.data ? MyAssortmentDragger.potentialParent.data.hierarchy : []

      // if we left the screen, set to no root folder
      if (fromLeave) {
        newParentPath = []
      }

      var needToChangeParent = !MyAssortmentDragger.arePathsEqual(newParentPath, movingData.hierarchy)
      var invalidMode = MyAssortmentDragger.isSelectionParentOfTarget(event.node, MyAssortmentDragger.potentialParent)

      if (needToChangeParent && !invalidMode) {
        var updatedRows = []

        MyAssortmentDragger.moveToPath(newParentPath, event.node, updatedRows, this.gridApi)
        this.gridApi.applyTransaction({ update: updatedRows })
        this.gridApi.clearFocusedCell()

        // refresh
        this.gridApi.redrawRows()

        // send to back end
        DataMiddleware.addNewChange(updatedRows, 'rowsUpdateTreeDataSort')
      }

      // clear out to end it
      MyAssortmentDragger.setPotentialParentForNode(this.gridApi, null, null)
    },

    // ===============================================================
    // DRAG LEFT - main listener = left the screen
    // currently only needed for my assortments type
    onRowDragLeave (event) {
      if (this.type === 'assortments-list') {
        this.onRowDragLeaveMyAssortments(event)
      } else {
        // this.clearAllDragIndicators()
      }
    },
    onRowDragLeaveMyAssortments (event) {
      this.onRowDragEndMyAssortments(event, true)
    },

    // ===============================================================
    // some drag helpers

    // is this item being dragged within a group? - pass in drag event object
    isWithinGroupFromEvent: function (event) {
      let itemGroup = ''
      if (event.node.group === false) {
        itemGroup = event.node.data.style // group is the style name
      }
      let isWithinGroup = (this.groupIsEnabledModel === false || (event.overNode && event.overNode.group === false && event.overNode.data.style === itemGroup))
      return isWithinGroup
    },

    // which was are we going? - pass in drag event object
    directionUpFromEvent (event) {
      let directionUp = false
      if (event.overNode) {
        if (event.y < event.node.rowTop) {
          directionUp = true
        }
      }
      return directionUp
    },

    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // SORTING

    // fired whenever the sort is being changed
    eventSortChanged (params) {
      if (this.eventSortChangedIsEnabled && this.type === 'assortment-details') {
        // get sorts
        let sortState = this.gridApi.getColumnState()
        let columnsSorted = sortState.filter(x => x['sort'] !== null)
        let isSorted = (columnsSorted.length > 0)

        // force dynamic to apply a sort - also check clearAllSort
        if (!isSorted && this.assortment?.method === ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC) {
          let sort = [{
            colId: 'style',
            sort: 'asc',
            sortIndex: 0
          }]
          this.gridApi.applyColumnState({
            state: sort
          })
        } else {
          DataMiddleware.addNewChange(columnsSorted, 'columnsUpdate')
        }

        this.getSortedByDescriptionToggler = Math.random()

        // refresh
        // this.gridApi.refreshCells()
        // this.gridApi.redrawRows()
      }

      this.eventSortChangedIsEnabled = true // is enabled after first page load
    },

    /*
    eventSortChanged (params) {
      if (this.eventSortChangedIsEnabled && this.type === 'assortment-details') {
        // get sorts
        let sortState = this.gridApi.getColumnState()
        let columnsSorted = sortState.filter(x => x['sort'] !== null)
        let isSorted = (columnsSorted.length > 0)

        // force dynamic to apply a sort - also check clearAllSort
        if (!isSorted && this.assortment?.method === ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC) {
          let sort = [{
            colId: 'style',
            sort: 'asc'
          }]
          this.gridApi.applyColumnState({
            state: sort
          })
        } else {
          // disable drag if columns are applied
          if (columnsSorted.length > 0) {
            this.disableDrag()
          }

          // if no drag, then update column sort
          if (this.dragIsEnabledModel === false) {
            // send to back end
            DataMiddleware.addNewChange(columnsSorted, 'columnsUpdate')

            // also send sort order to back end
            DataMiddleware.prepAndSendSortPacketForBackend()
          } else {
            DataMiddleware.addNewChange([], 'columnsUpdate')
          }
        }

        // refresh
        // this.gridApi.refreshCells()
        // this.gridApi.redrawRows()
      }

      this.eventSortChangedIsEnabled = true // is enabled after first page load
    },

     */

    // clears sort columns
    // force dynamic to apply a sort - also check eventSortChanged
    // for static, not that this MUST be blank, otherwise dragging won't work
    clearAllSort () {
      if (this.assortment?.method === ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC) {
        let sort = [{
          colId: 'style',
          sort: 'asc',
          sortIndex: 0
        }]
        this.gridApi.applyColumnState({
          state: sort
        })
      } else {
        this.gridApi.applyColumnState({
          defaultState: {
            sort: null
          }
        })
      }

      this.getSortedByDescriptionToggler = Math.random()
    },

    // we want to renumber the sort positions on all the rows
    // this is used when columns are being sorted then we click the drag button
    renumberSort () {
      // redraw
      let rows = null
      if (this.groupIsEnabledModel) {
        rows = GridHelpers.getRowNodes(true)
      } else {
        rows = GridHelpers.getRowNodes(false)
      }

      this.preredrawSnapshot = {
        rows: rows,
        nodeBeingDragged: null,
        rowsData: null
      }

      let ret = GridHelpers.generateNewRowData(rows, false, this.groupIsEnabledModel)
      this.setRowDataRelay(ret, true)
    },

    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // COLUMN HIDERS, EXPANDERS, NAME FETCHERS

    // column group has been opened/closed
    eventColumnGroupOpened (e) {
      // if shrinking, then resize
      if (this.gridApi && e.clientWidth) {
        this.gridApi.sizeColumnsToFit()
      }
    },

    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // STASH STATE CHECKS

    // when we do a re-draw, sometimes we'll need to see if a group had been expanded or contracted before the re-draw
    // this does a lookup against a pre-redraw stash to see the state
    determineExpandUnexpand () {
      // if a value has been stashed here, then loop through all the rows and set the groups to expanded or not
      if (this.preredrawSnapshot !== null) {
        this.gridApi.forEachNode((node) => {
          if (this.groupIsEnabledModel) {
            // check and see if we need to expand anything
            if (GridHelpers.isNodeGroup(node)) {
              // get the previous expansion state before we re-drew
              let nodeFromStash = this.getNodeReferenceFromStash(node)
              let theExpanded = false
              if (nodeFromStash && nodeFromStash.expanded) {
                theExpanded = true
              }
              // DynamicAssortments you can't batch, so manually trigger setExpanded per node (connected to below)
              if (this.assortment?.method === ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC) {
                node.setExpanded(theExpanded)
              } else {
                node.expanded = theExpanded
              }
            }
          }
        })

        // For DynamicAssortments, just trigger the fast batch update  (connected to above)
        if (this.assortment?.method === ITS__ASSORTMENTS__METHOD_TYPE__STATIC) {
          this.gridApi.onGroupExpandedOrCollapsed()
        }

        // set offset also
        this.scrollToPreredrawRowStashOffset()
      }// null
    },

    // get a node object from the stash - if not found, return a minimal representation of a node
    // in this case, a blank object just with one setting: expanded.  because that's all we are using here currently.
    getNodeReferenceFromStash (node) {
      for (let i = 0; i < this.preredrawSnapshot.rows.length; i++) {
        if (node && this.preredrawSnapshot.rows[i] && node.key === this.preredrawSnapshot.rows[i].key) {
          return this.preredrawSnapshot.rows[i]
        }
      }
      return {
        expanded: false
      }
    },

    // scroll to a node, based on it's position in the stash
    scrollToPreredrawRowStashOffset () {
      // the way this updates, we only need to scroll on a hard refresh - aka, if a group node was being dragged
      if (this.preredrawSnapshot && this.preredrawSnapshot.nodeBeingDragged) {
        let nodeFromStash = this.preredrawSnapshot.nodeBeingDragged
        if (nodeFromStash.group) {
          this.gridApi.forEachNode((node) => {
            if (this.groupIsEnabledModel) {
              if (nodeFromStash && node.key === nodeFromStash.key) {
                // this.gridApi.ensureNodeVisible(node, 'middle')
                this.gridApi.ensureIndexVisible(node?.rowIndex, 'middle')
              }
            }
          })
        }
      }
    },

    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // DATA SELECTION, CELL VALUE MANIPULATION
    // deselect all cells and rows

    // fired when cell value changes
    eventCellValueChanged (params) {
      if (!GridHelpers.temporarilyDisableHistory) {
        // do history on cell update, unless we are skipping it
        // generally, "skip api history" means this is coming from an undo/redo emit event and we don't want to send the change BACK to the back end
        if (params.data && params.data.SKIPAPIHISTORY) {
          delete params.data.SKIPAPIHISTORY

          // disable history for a beat to let all the other recalc fields come in
          GridHelpers.temporarilyDisableHistory = true
          clearTimeout(GridHelpers.temporarilyDisableHistoryTimer)
          GridHelpers.temporarilyDisableHistoryTimer = setTimeout(function () {
            GridHelpers.temporarilyDisableHistory = false
          }, 200)

          // recalc
          GridHelpers.postUpdateRecalculations(params)
        } else {
          // recalc
          GridHelpers.postUpdateRecalculations(params)

          // send to back end
          if (GridHelpers.newValueDiffersFromOld(params.oldValue, params.newValue)) {
            // get field
            const field = params.colDef?.field

            // dont sends
            // don't send calculated fields to data middleware
            let dontSend = (field.indexOf('calculated_') > -1)

            if (dontSend === false) {
              // type
              const type = (field === 'style' || field === 'color') ? 'rowsRename' : 'rowsUpdate'
              DataMiddleware.addNewChange(params, type)
            }
          }
        }

        // fix bug in ag-grid
        // if renaming a folder title, run a cleanup
        if (this.type === 'assortments-list') {
          if (params?.data?.type === 'folder' && params.column.colId === 'ag-Grid-AutoColumn') {
            setTimeout(function () {
              GridHelpers.parseBuggyTreeData()
            }, 100)
          }
        }

        // if we are sorting this view by a column, refresh the view and send the new sort order to the backend
        // we do this because sometimes a value can change in the column that causes the sort order to change
        // and we need to immediately reflect that in the view
        setTimeout(function () {
          if (this.getSortedByItems && this.getSortedByItems.length > 0) {
            GridHelpers.refreshSortModel(true)
          }
        }, 250)
      }// GridHelpers.temporarilyDisableHistory
    },

    // fired whenever the selection range is being changed
    eventSelectionChanged () {
      this.togglerSomethingSelected = Math.random()
      this.ghSelectionObject = GridHelpers.getSelectionDetails()
    },

    addNewSingleFolder: function (startingId) {
      let newTitle = 'New Folder'
      let ogTitle = newTitle

      // grab rows - either at root, or starting from nested
      let rows = GridHelpers.getRowNodes()
      let levelToCheck = 0
      let finalHierarchy = []
      if (startingId) {
        let node = this.gridApi.getRowNode(startingId)
        if (node) {
          if (node.data.type === 'item') {
            node = node.parent
          }
          if (node.data && node.data.type === 'folder') {
            levelToCheck = node.level + 1
            finalHierarchy = node.data.hierarchy.slice(0)
            rows = node.allLeafChildren
          }
        }
      }

      // now check the rows we grabbed to get a proper title
      let finalTitle = this.recursiveFolderNameCheck(levelToCheck, rows, ogTitle, 0, newTitle)

      finalHierarchy.push(finalTitle)
      // let finalId = DataMiddleware.getNestedIDFromHierarchy(finalHierarchy)
      let payload = [
        {
          hierarchy: finalHierarchy,
          title: finalTitle,
          // id: finalId,
          id: Math.random(),
          type: 'folder'
        }
      ]
      DataMiddleware.emitSwitchboard({ type: 'rowsAdd', payload: payload })
      this.gridApi.redrawRows() // refreshes the visual front end
    },
    recursiveFolderNameCheck: function (levelToCheck, rows, ogTitle, interation, newTitle) {
      let finalTitle = newTitle
      for (let i = 0; i < rows.length; i++) {
        let node = rows[i]
        if (node.level === levelToCheck && node.data.type === 'folder') {
          if (newTitle === node.data.title) {
            interation++
            newTitle = ogTitle + ' *' + interation + '*'
            finalTitle = this.recursiveFolderNameCheck(levelToCheck, rows, ogTitle, interation, newTitle)
          }
        }
      }
      return finalTitle
    },

    // downloadType - heroSmall, heroLarge
    zipImages: function (isSmall) {
      this.addAssortmentDownloadRequest([{
        id: this.assortment._id,
        type: ITS__FILE__DOWNLOAD_TYPE__ZIP,
        request_type: ITS__FILE__REQUEST_TYPE__ASSORTMENT__PRODUCTS_DOWNLOAD,
        action: ITS__FILE__ACTION_TYPE__DOWNLOAD,
        filename: `${this.assortment.title}.zip`,
        request_data: {
          small: (isSmall),
          heroOnly: true
        }
      }])

      this.sendStatistics({
        action: ITS__STATISTICS__ACTION_TYPE__ASSORTMENT__DOWNLOAD_ZIP_IMAGES
      })
    },
    // in the past, zipImages was linked to @click, which then integrated to addAssortmentDownloadRequest
    // this new way loads the download link as a simple href
    // note - this produces "file failed" in local host - only works on a real server
    zipImagesHrefMethod (type) {
      let heroOnly = null
      let isSmall = null
      switch (type) {
        case 'heroSmall':
          heroOnly = true
          isSmall = true
          break
        case 'heroLarge':
          heroOnly = true
          isSmall = false
          break
        case 'allSmall':
          heroOnly = false
          isSmall = true
          break
        case 'allLarge':
          heroOnly = false
          isSmall = false
          break
      }
      if (heroOnly !== null && isSmall !== null) {
        const assortmentPath = this.assortment.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL ? ITS__ASSORTMENTS__ORG_TYPE__INTERNAL : 'assortments'
        return `/api/${assortmentPath}/zip/images/${isSmall}/${heroOnly}/${this.assortment._id}`
      } else {
        return '#'
      }
    },

    refetchAndRekey () {
      this.$store.dispatch(VUEX_ASSORTMENT_REFETCH).then(res2 => {
        this.$nextTick(() => {
          this.$store.dispatch(VUEX_GRID_REKEY).then(res3 => {
            GridHelpers.restoreMasterGridThis()
          })
        })
      })
    },
    debugRefesh () {
      // let rows = GridHelpers.getRowNodes()
      // refresh
      // this.gridApi.redrawRows() // refreshes the visual front end
      // this.$store.dispatch(VUEX_GRID_REKEY).then(res3 => {GridHelpers.restoreMasterGridThis()})

      GridHelpers.parseBuggyTreeData()
    },

    // for assortment how to
    initCloseHowTo () {
      this.assortmentHowToClosed = false
    },
    clickCloseHowTo () {
      this.assortmentHowToClosed = true
    },

    internalAssortmentsSortControl () {
      const params = {
        defaultItems: [],
        colId: 'pillar',
        colTitle: 'Pillar',
        extras: {
          fromInternalAssortments: true
        },
        comparatorParams: {
          useListInView: true
        }
      }
      ColumnHelpers.customColumnSortingPopup(params)
    },
    fetchInternalAssortmentsCategories: function () {
      const payload = {
        assortment: this.assortment
      }
      this.$store.dispatch(VUEX_ASSORTMENT_INTERNAL_INTERNATONAL_CATEGORIES_FETCH, payload)
    },
    getInternalAssortmentsCategories () {
      let ret = []
      if (this.assortment) {
        ret = this.internalInternationalCategories[this.assortment._id]
      }
      return ret
    },
    fetchAssortmentPillars: function () {
      const payload = {
        assortment: this.assortment
      }
      this.$store.dispatch(VUEX_ASSORTMENT_CATEGORIES_FETCH, payload)
    },
    getassortmentPillars () {
      let ret = []
      if (this.assortment) {
        ret = this.assortmentPillars
      }
      return ret
    },

    // products have been looked up
    productsLookupSuccess () {
      switch (this.productIntegrationStep) {
        case 'rename':
          let rows = GridHelpers.getRowNodes(false, true)
          for (let i = 0; i < rows.length; i++) {
            let node = rows[i]
            let nodeNewId = node.data.style + '-' + node.data.color // id will not match since style color changed, so key off new id

            let filteredData = this.products.filter(x => x?.styleColorId === nodeNewId)
            if (filteredData.length > 0) {
              node.setData(filteredData[0])
            }
          }

          // this.gridApi.refreshCells()
          this.gridApi.redrawRows()
          break
      }

      this.productIntegrationStep = ''
    }
  } // methods
}
</script>
<style lang="scss">
@import "@/styles/components/grids/_grid-global-shared.scss";
</style>
