<template>
  <div class="libraries-grid-date-picker" :class="(params?.inlineCell) ? 'inline' : ''">
    <div class="picker-attacher" :id="`libraries-grid-date-picker-${rndId}`"></div>

    <!-- Inline Mode -->
    <template v-if="params.inlineCell">
      <v-row align="center" no-gutters>
        <its-text-field
          ref="imaskInput"
          v-maska="'##/##/####'"
          class="inline-date-input"
          density="compact" hide-details
          v-model="inlineValue"
          :placeholder="params.dateFormat"
          @focus="onFocus"
          @blur="commitInline"
          @keydown.enter.prevent="commitInline"
        />
        <span class="material-icons calendar-icon" @mousedown.prevent.stop="openCalendar">calendar_today</span>
      </v-row>
      <v-menu
        v-model="calendarMenu"
        offset-y
        transition="scale-transition"
        :close-on-content-click="false"
        :activator="calendarActivator"
        location="bottom"
        origin="top left"
        :attach="`#libraries-grid-date-picker-${rndId}`"
      >
        <v-date-picker
          :model-value="picker"
          hide-actions
          :min="minDate"
          :max="maxDate"
          @update:modelValue="onCalendarSelect"
          @keydown.escape="closeCalendar"
          @click:cancel="closeCalendar"
        ></v-date-picker>
      </v-menu>
    </template>

    <!-- Popup Mode -->
    <template v-else>
      <v-row justify="center">
        <v-date-picker
          @update:modelValue="modelUpdated"
          @keydown.escape="closePopup"
          @click:cancel="closePopup"
          :model-value="picker"
          hide-actions
          :min="minDate"
          :max="maxDate"
        ></v-date-picker>
      </v-row>
    </template>
  </div>
</template>

<script>
import shared from 'skch_its_be_fe_shared'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import ColumnHelpers from '@/components/_core/GridsCore/helpers/ColumnHelpers'
import { mapState } from 'vuex'
import { VUEX_TOAST_ADD_TO_QUEUE } from '@/store/constants/ui/toast'

dayjs.extend(customParseFormat)
const dateOnlyFromJsDate = shared.dates.dateOnlyFromJsDate

export default {
  name: 'DatePickerEditor',
  data() {
    return {
      rndId: Math.floor(Math.random() * 100000000),
      picker: null,
      calendarMenu: false,
      committing: false,
      inlineValue: null,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      order: (state) => state.orders.order,
    }),
    minDate() {
      if (this.params?.limitPast) {
        const [amount, unit] = this.params.limitPast.split(' ')
        return dayjs().subtract(parseInt(amount), unit).format('YYYY-MM-DD')
      }
      return null
    },
    maxDate() {
      if (this.params?.limitFuture) {
        const [amount, unit] = this.params.limitFuture.split(' ')
        return dayjs().add(parseInt(amount), unit).format('YYYY-MM-DD')
      }
      return null
    },
    calendarActivator() {
      return this.$refs.imaskInput
    }
  },
  methods: {
    getValue() {
      if (!this.picker) return this.params.value
      return this.params.saveAsJsDate
        ? dateOnlyFromJsDate(new Date(this.picker))
        : dayjs(this.picker).format(this.params.dateFormat || 'MM/DD/YYYY')
    },

    setPickerValue(val) {
      if (!val) {
        this.picker = null
        return false
      }

      const date = dayjs(val)
      const min = this.minDate ? dayjs(this.minDate) : null
      const max = this.maxDate ? dayjs(this.maxDate) : null

      if (!date.isValid()) {
        this.$store.dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          timeout: 2000,
          data: {
            type: 'error',
            message: 'Invalid Date'
          }
        })
        return false
      }

      if (min && date.isBefore(min)) {
        this.$store.dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          timeout: 3000,
          data: {
            type: 'error',
            message: 'Date cannot be more than 1 week in the past'
          }
        })
        return false
      }

      if (max && date.isAfter(max)) {
        this.$store.dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          timeout: 3000,
          data: {
            type: 'error',
            message: 'Date cannot be more than 1 year in future'
          }
        })
        return false
      }

      let field = this.params?.colDef?.field
      if (field === 'endDate') {
        let sd = ColumnHelpers.parseNumericDateIntoDayjs(this.params?.data?.startDate)
        let ed = dayjs(val)
        if (sd?.isValid() && ed?.isValid() && ed.isBefore(sd, 'day')) {
          this.$store.dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
            component: '_core/Toast/Toast_Message.vue',
            timeout: 3000,
            data: {
              type: 'error',
              message: 'End date cannot be before start date'
            }
          })
          return false
        }
      }

      this.picker = date.toDate()
      return true
    },

    modelUpdated(val) {
      if (!this.setPickerValue(val)) return
      this.setEndDate()
      this.params.stopEditing()
    },

    closePopup() {
      this.params.stopEditing()
    },

    setEndDate() {
      let field = this.params?.colDef?.field
      if (field === 'startDate') {
        let sd = this.getValue()
        let daysOutObject = ColumnHelpers.wholesaleOrdersDefaultDaysOutCalculation(sd, this.user, this.order)
        this.params.node.setDataValue('endDate', daysOutObject.endDate)
      }
    },

    commitInline() {
      if (this.committing || this.calendarMenu) return
      this.committing = true

      const parsedDate = dayjs(this.inlineValue, this.params.dateFormat, true)
      if (!this.setPickerValue(parsedDate)) {
        this.setPickerValue(null)
      }

      this.setEndDate()
      this.params.stopEditing()
      this.resetAgCellOverflow()
      this.$nextTick(() => (this.committing = false))
    },

    openCalendar() {
      this.calendarMenu = true
      this.$nextTick(this.setAgCellOverflowVisible)
    },

    closeCalendar() {
      this.calendarMenu = false
      this.resetAgCellOverflow()
    },

    onCalendarSelect(val) {
      if (!this.setPickerValue(val)) return
      this.calendarMenu = false
      this.resetAgCellOverflow()
      this.setEndDate()
      this.params.stopEditing()
    },

    onFocus () {
    },

    setAgCellOverflowVisible () {
      const cellEl = this.$el.closest('.ag-cell-inline-editing')
      if (cellEl) {
        cellEl.dataset.originalOverflow = cellEl.style.overflow
        cellEl.style.overflow = 'visible'
      }
    },

    resetAgCellOverflow () {
      const cellEl = this.$el.closest('.ag-cell-inline-editing')
      if (cellEl && cellEl.dataset.originalOverflow !== undefined) {
        cellEl.style.overflow = cellEl.dataset.originalOverflow
        delete cellEl.dataset.originalOverflow
      }
    }
  },

  mounted () {
    let val = this.params.value
    if (val) {
      if (this.params.saveAsJsDate) {
        val = GridHelpers.convertDateNumberToDateString(val)
        this.setPickerValue(dayjs(val, 'YYYY-MM-DD'))
      } else {
        const yyyy = +val.substring(0, 4)
        const mm = +val.substring(5, 7) - 1
        const dd = +val.substring(8, 10)
        this.setPickerValue(new Date(yyyy, mm, dd))
      }
    }

    if (this.params.inlineCell) {
      const parsedDayjs = ColumnHelpers.parseNumericDateIntoDayjs(this.params.value)
      if (parsedDayjs?.isValid?.() && this.setPickerValue(parsedDayjs)) {
        this.inlineValue = parsedDayjs.format(this.params.dateFormat)
      }

      this.$nextTick(() => {
        const inputEl = this.$refs.imaskInput?.$el?.querySelector('input')
        if (inputEl) {
          inputEl.focus()
          inputEl.select()
        }
      })
    }
  },

  beforeUnmount () {
    this.resetAgCellOverflow()
  }
}
</script>

<style lang="scss">
.libraries-grid-date-picker {

  &.inline {
    .inline-date-input {
      .v-field--variant-filled .v-field__overlay {
        background-color: transparent !important;
      }

      .v-field {
        --v-field-padding-bottom: 0;
        margin-top: 7px;
      }

      input {
        width: 100px;
        margin: 0;
        padding: 0 2px;
        background-color: transparent;
        font-size: 14px;
        border-bottom: 1px solid var(--ag-header-foreground-color);
      }
    }

    .calendar-icon {
      z-index: 2;
      cursor: pointer;
      color: var(--ag-data-color);
      font-size: 20px;
    }
  }
}
</style>
