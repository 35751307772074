<template>
  <div :cellComponentKey="cellComponentKey" @mouseleave="closePopup" :class="['libraries-grid-primary-file-renderer']">
    <div class="img-responsive test23">
      <img
        class="type1" v-if="hasPrimaryFile"
        :src="loadImage"
        @load="imageLoaded=true"
        @error="imageLoad_OnError"/>
      <img class="type2" v-else-if="paramsData._id" @click="uploadFile" :src="require('@/assets/images/thumb-image-needs-uploaded.jpg')">
    </div>
    <div v-if="isPopup">
      <div v-if="showStateLabel" class="flyout-label">{{ getStateLabel }}</div>
      <div class="buttonHolder">
        <!--
        <v-btn v-if="hasPrimaryFile" light size="small" color="red" @click="deleteFile">Delete</v-btn>
        <v-btn v-else light size="small" color="primary" @click="uploadFile">Upload</v-btn>
        -->
        <v-btn light size="small" color="primary" @click="uploadFile">Replace</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import _cloneDeep from 'lodash.clonedeep'
import axios from 'axios'

// import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import {
  VUEX_DIALOG_SHOW
} from '@/store/constants/ui/dialog'
import {
  VUEX_API_S3_DOWNLOAD_SIGNED_REQUEST
} from '@/store/constants/api'

export default {
  name: 'PrimaryFileRenderer',
  data () {
    return {
      cellComponentKey: '',
      fileChangedObjects: {},
      popupIsOpen: false,
      paramsData: {},
      src: null,
      imageLoaded: false,
      imageLoadFailed: false
    }
  },
  watch: {
    assetFileChangedObjectsToggler: {
      deep: true,
      handler () {
        if (this.assetFileChangedObjects[this.cellComponentKey] && this.assetFileChangedObjects[this.cellComponentKey].deleted) {
          // this.paramsData.primaryFile = {}
        }

        // set source
        if (this.hasPrimaryFile) {
          this.setImage(this.paramsData.primaryFile.thumbnail)
        }
      }
    }
  },
  computed: {
    ...mapState({
      assetFileChangedObjects: state => state.libraries.assetFileChangedObjects,
      assetFileChangedObjectsToggler: state => state.libraries.assetFileChangedObjectsToggler
    }),
    hasPrimaryFile () {
      return (this.assetFileChangedObjectsToggler && this.paramsData.primaryFile?._id)
    },

    getStateLabel () {
      let ret = ''
      if (this.paramsData.primaryFile && this.paramsData.primaryFile.state && this.paramsData.primaryFile.state !== 'Active') {
        ret = this.paramsData.state
      } else if (!this.paramsData || !this.paramsData.primaryFile || !this.paramsData.primaryFile.state) {
        ret = 'Unknown Error'
      }
      return ret
    },
    showStateLabel () {
      return (this.getStateLabel !== '')
    },
    loadImage () {
      if (this.src) {
        return !this.imageLoadFailed
          ? this.src
          : require('@/assets/images/thumb-image-coming-soon.jpg')
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapActions({
      s3Download: VUEX_API_S3_DOWNLOAD_SIGNED_REQUEST,
      openDialog: VUEX_DIALOG_SHOW
    }),
    imageLoad_OnError () {
      this.imageLoaded = true
      this.imageLoadFailed = true
    },
    setImage: async function (src) {
      // try {
      //   await axios.get(src)
      // } catch (err) {
      //   src = require('@/assets/images/thumb-image-coming-soon.jpg')
      // }
      this.src = src
    },
    isCancelAfterEnd () {
      return true
    },
    isPopup () {
      return true
    },
    closePopup () {
      // cell editor
      if (typeof this.params.stopEditing === 'function') {
        this.params.stopEditing()
      }
    },
    uploadFile () {
      this.openDialog({
        content: '_core/Dialogs/Libraries/Grids/Dialog_FileUpload.vue',
        title: 'Add Primary File',
        subtitle: 'Upload your new primary file here',
        data: {
          cellComponentKey: this.cellComponentKey,
          assetID: this.paramsData._id,
          assetData: this.paramsData,
          usageType: 'Primary'
        }
      })
    }
    /*
    deleteFile () {
      if (this.paramsData.primaryFile.filename) {
        //let payload = _cloneDeep(this.paramsData)
        // GLOBAL ACTION PROMPT: Pass messages & dispatch action
        let actionObj = {
          checkChangedFields: false,
          title: 'Are You Sure?',
          description: 'You are about to delete the primary file for the "' + this.paramsData.name + '" asset. This cannot be undone.',
          dispatchAction: VUEX_LIBRARIES_FILE_DELETE,
          dispatchActionObject: {
            cellComponentKey: this.cellComponentKey,
            assetID: this.paramsData._id,
            fileID: this.paramsData.primaryFile._id
          }
        }
        actionObj = _cloneDeep(actionObj)
        GridHelpers.mgThisArray[0].$store.dispatch('VUEX_GLOBAL_ACTION_PROMPT_SHOW', { actionObj: actionObj })
      }
    }
     */
  },
  created () {
    if (this.params.data) {
      if (!this.params.data.cellComponentKeyPrimary) {
        this.params.data.cellComponentKeyPrimary = 'key-' + Date.now()
      }
      this.cellComponentKey = this.params.data.cellComponentKeyPrimary
      this.paramsData = _cloneDeep(this.params.data)
    }
  },
  mounted () {
    if (this.hasPrimaryFile) {
      this.setImage(this.paramsData.primaryFile.thumbnail)
    }
  }
}
</script>
