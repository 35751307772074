// import Vue from 'vue'
import { createStore as CreateStore } from 'vuex'
// import VuexLogger from 'vuex/dist/logger'
import Debug from 'logdown'
import router from '@/router'

import options from '@/store/modules/options'
import user from '@/store/modules/user'

import version from '@/store/modules/config/version'
import constants from '@/store/modules/config/constants'
import properties from '@/store/modules/config/properties'
import requestOptions from '@/store/modules/config/requestOptions'
import app from '@/store/modules/app'

import reporting from '@/store/modules/reporting'

import statistics from '@/store/modules/statistics'

import routing from '@/store/modules/routing'
import routingDashboard from '@/store/modules/routing/routingDashboard'
import routingAdvertising from '@/store/modules/routing/routingAdvertising'
import routingProduct from '@/store/modules/routing/routingProduct'
import routingGlobalRetail from '@/store/modules/routing/routingGlobalRetail'
import routingSales from '@/store/modules/routing/routingSales'
import routingOrders from '@/store/modules/routing/routingOrders'

import localization from '@/store/modules/localization'

// LocalStorage
import localStorage from '@/store/modules/localStorage'

// S3 File Manager
import s3FileUploadManager from '@/store/modules/fileManagement/s3FileUploadManager'
import s3FileUploadResponseManager from '@/store/modules/fileManagement/s3FileUploadResponseManager'

// api stores
import apiAssortments from '@/store/modules/api/assortments'
import apiAnnouncements from '@/store/modules/api/announcements'
import apiCollections from '@/store/modules/api/collections'
import apiCustomers from '@/store/modules/api/customers'
import apiDocuments from '@/store/modules/api/documents'
import apiOptions from '@/store/modules/api/options.js'
import apiProducts from '@/store/modules/api/products'
import apiPhotos from '@/store/modules/api/photos'
import apiAssets from '@/store/modules/api/assets'
import apiAssortmentGroupSort from '@/store/modules/api/assortmentGroupSort'
import apiAssortmentProducts from '@/store/modules/api/assortmentProducts'
import apiStatistics from '@/store/modules/api/statistics'
import apiAws from '@/store/modules/api/aws'
import apiLibraries from '@/store/modules/api/libraries'
import apiPackages from '@/store/modules/api/packages'
import apiMail from '@/store/modules/api/mail'
import apiUsers from '@/store/modules/api/users'
import apiReporting from '@/store/modules/api/reporting'
import apiSeasons from '@/store/modules/api/seasons'
import apiUserFlags from '@/store/modules/api/userFlags'
import apiSamples from '@/store/modules/api/samples'
import apiNotifications from '@/store/modules/api/notifications'
import apiOrders from '@/store/modules/api/orders'
import apiAssignments from '@/store/modules/api/assignments'
import apiWOF from '@/store/modules/api/wof'

// model stores
import assortments from '@/store/modules/models/assortments'
import assortmentsInternal from '@/store/modules/models/assortments/assortmentsInternal'
import assortmentDownloads from '@/store/modules/models/assortments/assortmentDownloads'
import assortmentShowroom from '@/store/modules/models/assortments/assortmentShowroom'
import assortmentProducts from '@/store/modules/models/assortments/assortmentProducts'
import assortmentGroupSort from '@/store/modules/models/assortments/assortmentGroupSort'
import collections from '@/store/modules/models/collections'
import documents from '@/store/modules/models/documents'
import globalConference from '@/store/modules/models/globalConference'
import products from '@/store/modules/models/products'
import photos from '@/store/modules/models/photos'
import assets from '@/store/modules/models/assets'
import announcements from '@/store/modules/models/announcements'
import libraries from '@/store/modules/models/libraries'
import seasons from '@/store/modules/models/seasons'
import samples from '@/store/modules/models/samples'
import sampleTracking from '@/store/modules/models/samples/sampleTracking'
import notifications from '@/store/modules/models/notifications'
import orders from '@/store/modules/models/orders'
import assignments from '@/store/modules/models/assignments'

// ui stores
import amDrawer from '@/store/modules/ui/drawer'
import amOverlay from '@/store/modules/ui/overlay'
import alertDialog from '@/store/modules/ui/alertDialog'
import amSubheader from '@/store/modules/ui/subheader'
import productSearch from '@/store/modules/ui/productSearch'
import productDetail from '@/store/modules/ui/productDetail'
import contentContainer from '@/store/modules/ui/contentContainer'

import filterBar from '@/store/modules/ui/filterBar'
import filterBarItemsDisplay from '@/store/modules/ui/filterBar/filterBar_ItemsDisplay'
import filterBarOptionsLists from '@/store/modules/ui/filterBar/filterBar_OptionsLists'
import filterBarSelectors from '@/store/modules/ui/filterBar/filterBar_Selectors'
import filterBarProductSearch from '@/store/modules/ui/filterBar/filterBar_ProductSearch'
import filterBarQueryUpdate from '@/store/modules/ui/filterBar/filterBar_QueryUpdate'

import categoryBrowser from '@/store/modules/ui/categoryBrowser'
import categoryBrowserQueryUpdate from '@/store/modules/ui/categoryBrowser/categoryBrowser_QueryUpdate'

import appBar from '@/store/modules/ui/appBar'
import grid from '@/store/modules/ui/grid'
import amDialog from '@/store/modules/ui/dialog'
import toast from '@/store/modules/ui/toast'
import controlBar from '@/store/modules/ui/controlBar'
import fileProgress from '@/store/modules/ui/fileProgress'
import globalActionPrompt from '@/store/modules/ui/globalActionPrompt'
import navigationDialog from '@/store/modules/ui/navigationDialog'
import librariesCollection from '@/store/modules/ui/librariesCollection'
import globalUploader from '@/store/modules/ui/globalUploader'
import groupSort from '@/store/modules/ui/groupSort'
import itemDisplay from '@/store/modules/ui/itemDisplay'

import {
  VUEX_ROUTING_INIT
} from '@/store/constants/routing'

import {
  VUEX_OPTIONS_FETCH
} from '@/store/constants/options'

import {
  VUEX_USER_INIT
} from '@/store/constants/user'

const d = new Debug('its:store:store')

const defaultState = {
  initialized: false,
  browser: null,
  showEditorTools: true
  // serverBaseUrl: process.env.VUE_APP_API_BASE_URL.split('/api')[0]
}

const inBrowser = typeof window !== 'undefined'

// if in browser, use pre-fetched state injected by SSR
const state = (inBrowser && window.__INITIAL_STATE__) || defaultState

const actions = {
  async init ({ dispatch, commit }) {
    // watch for router too
    // eslint-disable-next-line
    let routerResolve, routerReject
    // eslint-disable-next-line
    let routerPromise = new Promise(function (resolve, reject) {
      routerResolve = resolve
      routerReject = reject
    })

    router.isReady(function () {
      d.log('Router ready!')
      routerResolve()
      dispatch(VUEX_ROUTING_INIT)
    })

    store.registerModule('options', options)
    await dispatch(VUEX_OPTIONS_FETCH, 'loggedIn')

    store.registerModule('user', user)
    await dispatch(VUEX_USER_INIT)

    d.log('Starting router and view render')

    router.start()
    await routerPromise

    d.log('Primary store init complete. Inspect with Vue Devtools.')
    commit('initialized', true)
  }
}

const getters = {
  browser: state => state.browser
}

const mutations = {
  initialized (state, isInitialized) {
    state.initialized = isInitialized
  },
  setBrowser (state, data) {
    state.browser = data
  },
  setWindowDims (state, data) {
    state.browser.info.width = data.width
    state.browser.info.height = data.height
  },
  setScrollData (state, data) {
    state.browser.scrollData = { ...state.browser.scrollData, ...data }
  },
  editorToolsVisibility (state, isVisible) {
    state.showEditorTools = !state.showEditorTools
  }
}

const store = new CreateStore({
  strict: false, // process.env.NODE_ENV !== 'production',

  state,
  actions,
  mutations,
  getters,

  modules: {
    // App
    constants,
    properties,
    requestOptions,
    version,
    app,

    // Reporting
    reporting,

    // Statistics
    statistics,

    // Routing
    routing,
    routingDashboard,
    routingAdvertising,
    routingProduct,
    routingGlobalRetail,
    routingSales,
    routingOrders,

    // APIs
    apiAssortments,
    apiAnnouncements,
    apiCollections,
    apiCustomers,
    apiOptions,
    apiDocuments,
    apiProducts,
    apiPhotos,
    apiAssets,
    apiAssortmentGroupSort,
    apiAssortmentProducts,
    apiStatistics,
    apiAws,
    apiLibraries,
    apiPackages,
    apiMail,
    apiUsers,
    apiReporting,
    apiSeasons,
    apiUserFlags,
    apiSamples,
    apiNotifications,
    apiOrders,
    apiAssignments,
    apiWOF,

    localization,

    // Local Storage
    localStorage,

    // File Management
    s3FileUploadManager,
    s3FileUploadResponseManager,

    // Models
    assortments,
    assortmentsInternal,
    assortmentDownloads,
    assortmentShowroom,
    assortmentProducts,
    assortmentGroupSort,
    collections,
    documents,
    globalConference,
    products,
    photos,
    assets,
    announcements,
    libraries,
    seasons,
    samples,
    sampleTracking,
    notifications,
    orders,
    assignments,

    // UI
    appBar,
    toast,
    alertDialog,
    amOverlay,
    amSubheader,
    amDrawer,
    contentContainer,
    fileProgress,
    globalUploader,
    groupSort,
    globalActionPrompt,
    librariesCollection,

    productDetail,

    productSearch,

    filterBar,
    filterBarItemsDisplay,
    filterBarOptionsLists,
    filterBarSelectors,
    filterBarProductSearch,
    filterBarQueryUpdate,

    categoryBrowser,
    categoryBrowserQueryUpdate,

    grid,

    amDialog,

    controlBar,

    navigationDialog,

    itemDisplay
  }
})

export default store
