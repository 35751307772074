import axios from 'axios'
import api from '@/api'

import {
  VUEX_API_ORDER_FETCH,
  VUEX_API_ORDER_CREATE,
  VUEX_API_ORDER_DUPLICATE,
  VUEX_API_ORDER_DELETE,
  VUEX_API_ORDER_BATCH_UPDATE,
  VUEX_API_ORDER_ADD_COMMENT,
  VUEX_API_ORDER_ADDRESS_LOOKUP,
  VUEX_API_ORDER_ZIPCODE_LOOKUP,
  VUEX_API_ORDER_ADDRESS_VALIDATION,
  VUEX_API_ORDERS_REQUEST_FETCH,
  VUEX_API_ORDERS_REQUEST_FETCH_SUCCESS,
  VUEX_API_ORDERS_REQUEST_FETCH_FAILED,
  VUEX_API_ORDER_APPROVAL_HISTORY,

  VUEX_API_ORDERS_WOF_STYLES_FETCH,
  VUEX_API_ORDERS_WOF_STYLE_COLORS_FETCH,

  VUEX_API_ORDERS_SET_CANCEL_TOKEN,
  VUEX_API_ORDERS_PRODUCTS_DELETE,
  VUEX_API_ORDERS_DROP_PRODUCTS, VUEX_API_ORDER_EXCEL_EXPORT
} from '@/store/constants/api'

import {
  VUEX_TOAST_ADD_TO_QUEUE
} from '@/store/constants/ui/toast'

import {
  VUEX_ORDERS_DETAIL_REFETCH
} from '@/store/constants/models/orders'

// import ApiValidator from '@/classes/ApiValidator'

const state = {
  ordersRequest: null,
  ordersCancelRequest: null
}

const getters = {}

const actions = {
  [VUEX_API_ORDER_FETCH]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}
    const { orderType, orderSubtype, data } = payload

    await commit(VUEX_API_ORDERS_REQUEST_FETCH)

    if (state.ordersCancelRequest) state.ordersCancelRequest.cancel()
    await commit(VUEX_API_ORDERS_SET_CANCEL_TOKEN, 'ordersCancelRequest')

    /* const newPayload = {
      orderNumber: id,
      _options
    } */

    return api.post(`orders/${orderType}/${orderSubtype}/query`, data, {
      cancelToken: state.ordersCancelRequest.token
    }).then(response => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `VUEX_API_ORDER_FETCH | Cannot load '/api/orders/${orderType}/${orderSubtype}/${id}' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_ORDERS_REQUEST_FETCH]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}
    let { orderType, orderSubtype, data } = payload
    await commit(VUEX_API_ORDERS_REQUEST_FETCH)

    if (state.ordersCancelRequest) state.ordersCancelRequest.cancel()
    await commit(VUEX_API_ORDERS_SET_CANCEL_TOKEN, 'ordersCancelRequest')

    return api.post(`orders/${orderType}/${orderSubtype}/query`, data, {
      cancelToken: state.ordersCancelRequest.token
    }).then(response => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `VUEX_API_ORDERS_REQUEST_FETCH | Cannot load '/api/orders/${orderType}/${orderSubtype}/query' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_ORDER_CREATE]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}
    const { orderType, orderSubtype, data } = payload

    await commit(VUEX_API_ORDERS_REQUEST_FETCH)

    if (state.ordersCancelRequest) state.ordersCancelRequest.cancel()
    await commit(VUEX_API_ORDERS_SET_CANCEL_TOKEN, 'ordersCancelRequest')

    return api.post(`orders/${orderType}/${orderSubtype}`, data, {
      cancelToken: state.ordersCancelRequest.token
    }).then(response => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_FAILED)

      if (!axios.isCancel(err)) {
        throw err.response

        /* dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `VUEX_API_ORDER_CREATE | Cannot load '/api/orders/${orderType}/${orderSubtype}' - ${err.response && err.response.data.message}`
          }
        }) */
      }
    })
  },

  [VUEX_API_ORDER_DUPLICATE]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}
    const { orderType, orderSubtype, originalOrderId, data } = payload

    await commit(VUEX_API_ORDERS_REQUEST_FETCH)

    if (state.ordersCancelRequest) state.ordersCancelRequest.cancel()
    await commit(VUEX_API_ORDERS_SET_CANCEL_TOKEN, 'ordersCancelRequest')

    let url = `orders/${orderType}/${orderSubtype}/duplicate/${originalOrderId}`

    return api.post(url, data, {
      cancelToken: state.ordersCancelRequest.token
    }).then(response => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `VUEX_API_ORDER_DUPLICATE | Cannot load '/api/orders/${orderType}/${orderSubtype}' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_ORDER_DELETE]: async ({ state, dispatch, commit }, payload = {}) => {
    const { orderType, orderSubtype, id } = payload

    commit(VUEX_API_ORDERS_REQUEST_FETCH, false)
    return api.delete(`orders/${orderType}/${orderSubtype}/${id}`,
      {}
    ).then(response => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_SUCCESS, true)
      return response
    }).catch((err, response) => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_FAILED, false)
      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Error: ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_ORDER_BATCH_UPDATE]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}

    const { orderType, orderSubtype, id, data } = payload

    await commit(VUEX_API_ORDERS_REQUEST_FETCH)

    if (state.ordersCancelRequest) state.ordersCancelRequest.cancel()
    await commit(VUEX_API_ORDERS_SET_CANCEL_TOKEN, 'ordersCancelRequest')
    return api.patch(`orders/${orderType}/${orderSubtype}/batch/${id}`, data, {
      cancelToken: state.ordersCancelRequest.token
    }).then(response => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_FAILED)
      if (!axios.isCancel(err)) {
        // dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        //   component: '_core/Toast/Toast_Message.vue',
        //   data: {
        //     type: 'error',
        //     message: `VUEX_API_ORDER_BATCH_UPDATE | Cannot load '/api/orders/${orderType}/${orderSubtype}/batch/${id}' - ${err.response && err.response.data.message}`
        //   }
        // })
        throw err.response
      }

    })
  },

  [VUEX_API_ORDER_ADD_COMMENT]: async ({ commit, dispatch }, payload) => {
    const { orderType, orderSubtype, id, data } = payload
    await commit(VUEX_API_ORDERS_REQUEST_FETCH)

    if (state.ordersCancelRequest) state.ordersCancelRequest.cancel()
    await commit(VUEX_API_ORDERS_SET_CANCEL_TOKEN, 'ordersCancelRequest')
    return api.post(`orders/${orderType}/${orderSubtype}/comment/${id}`, data, {
      cancelToken: state.ordersCancelRequest.token
    }).then(response => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_FAILED)
      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `VUEX_API_ORDER_ADD_COMMENT | Cannot load '/api/orders/${orderType}/${orderSubtype}/comment/${id}' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_ORDER_ADDRESS_LOOKUP]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}
    const { orderType, orderSubtype, data } = payload
    await commit(VUEX_API_ORDERS_REQUEST_FETCH)
    if (state.ordersCancelRequest) state.ordersCancelRequest.cancel()
    await commit(VUEX_API_ORDERS_SET_CANCEL_TOKEN, 'ordersCancelRequest')
    return api.get(`orders/${orderType}/${orderSubtype}/accounts/address/${data}`, {
      cancelToken: state.ordersCancelRequest.token
    }).then(response => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `VUEX_API_ORDER_ADDRESS_LOOKUP | Cannot load '/api/orders/${orderType}/${orderSubtype}/accounts/address/${data}' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_ORDER_ZIPCODE_LOOKUP]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}

    await commit(VUEX_API_ORDERS_REQUEST_FETCH)

    if (state.ordersCancelRequest) state.ordersCancelRequest.cancel()
    await commit(VUEX_API_ORDERS_SET_CANCEL_TOKEN, 'ordersCancelRequest')

    return api.get(`shippers/usps/citystate/${payload}`, {
      cancelToken: state.ordersCancelRequest.token
    }).then(response => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `VUEX_API_ORDER_ZIPCODE_LOOKUP | Cannot load '/api/shippers/usps/citystate/${payload}' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_ORDER_ADDRESS_VALIDATION]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}

    await commit(VUEX_API_ORDERS_REQUEST_FETCH)

    if (state.ordersCancelRequest) state.ordersCancelRequest.cancel()
    await commit(VUEX_API_ORDERS_SET_CANCEL_TOKEN, 'ordersCancelRequest')

    return api.post('shippers/ups/confirm/', payload, {
      cancelToken: state.ordersCancelRequest.token
    }).then(response => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_SUCCESS)
      return response.data
    }).catch((err) => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `VUEX_API_ORDER_ADDRESS_VALIDATION | Cannot load '/api/shippers/ups/confirm' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_ORDERS_PRODUCTS_DELETE]: async ({ state, dispatch, commit }, payload = {}) => {
    const { orderType, orderSubtype, id, data } = payload

    // Jason: TODO?
    // const apiValidator = new ApiValidator('ordersDeleteSchema')
    // const validatedPayload = apiValidator.validate(data)
    const validatedPayload = data
    commit(VUEX_API_ORDERS_REQUEST_FETCH, false)
    return api.patch(`orders/${orderType}/${orderSubtype}/batch/${id}`,
      validatedPayload
    ).then(response => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_SUCCESS, true)
      return response
    }).catch((err, response) => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_FAILED, false)
      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Error: ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  // -------------------------------------------------------
  // WOF ----
  // -------------------------------------------------------
  // Fetch Product(s) Styles for autocomplete
  [VUEX_API_ORDERS_WOF_STYLES_FETCH]: async ({ rootState, state, dispatch, commit }, payload) => {
    if (state.ordersCancelRequest) state.ordersCancelRequest.cancel()
    await commit(VUEX_API_ORDERS_SET_CANCEL_TOKEN, 'ordersCancelRequest')

    const { region, origin, style, customer } = payload.data

    if (!region || !style) return new Error()
    if (!payload.params) payload.params = {}

    return api.get(`/orders/wof/style/autocomplete/${region}/${origin}/${customer}/${style}`,
      {
        params: payload.params,
        cancelToken: state.ordersCancelRequest.token
      }).then(response => {
      return response
    }).catch((err) => {
      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Cannot load '/orders/wof/style/autocomplete/${region}/${style}' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },
  // Fetch Product(s) Styles Colors for autocomplete
  [VUEX_API_ORDERS_WOF_STYLE_COLORS_FETCH]: async ({ state, dispatch, commit }, payload) => {
    if (!payload.params) payload.params = {}

    return api.post(`/orders/wof/style`, payload.data).then(response => {
      // Throw error is style/colors data does not exist
      if (!response.data.colors.length) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: payload?.options?.emptyResponseMsg || `No style/color data found`
          }
        })
      }

      return response
    }).catch((err) => {
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          type: 'error',
          message: `Cannot load '/orders/wof/style' - ${err.response && err.response.data.message}`
        }
      })
    })
  },
  // -------------------------------------------------------
  // -------------------------------------------------------

  [VUEX_API_ORDERS_DROP_PRODUCTS]: async ({ state, dispatch, commit }, payload = {}) => {
    const { orderType, orderSubtype, id, data } = payload

    const validatedPayload = data
    commit(VUEX_API_ORDERS_REQUEST_FETCH, false)
    return api.patch(`orders/${orderType}/${orderSubtype}/batch/${id}`,
      validatedPayload
    ).then(response => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_SUCCESS, true)

      dispatch(VUEX_ORDERS_DETAIL_REFETCH)
      // dispatch(VUEX_GRID_REKEY)
      dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
        component: '_core/Toast/Toast_Message.vue',
        timeout: 2000,
        data: {
          type: 'success',
          message: 'Success'
        }
      })
      return response
    }).catch((err, response) => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_FAILED, false)
      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Error: ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },

  [VUEX_API_ORDER_APPROVAL_HISTORY]: async ({ commit, dispatch }, payload) => {
    if (!payload) payload = {}
    const { orderType, orderSubtype, requesterCode, productType } = payload
    await commit(VUEX_API_ORDERS_REQUEST_FETCH)
    if (state.ordersCancelRequest) state.ordersCancelRequest.cancel()
    await commit(VUEX_API_ORDERS_SET_CANCEL_TOKEN, 'ordersCancelRequest')
    return api.get(`orders/${orderType}/${orderSubtype}/year/${requesterCode}/${productType}`, {
      cancelToken: state.ordersCancelRequest.token
    }).then(response => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_SUCCESS)
      return response
    }).catch((err) => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_FAILED)

      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `VUEX_API_ORDER_APPROVAL_HISTORY | Cannot load '/api/orders/${orderType}/${orderSubtype}/year/${requesterCode}/${productType}' - ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },


  [VUEX_API_ORDER_EXCEL_EXPORT]: async ({ state, dispatch, commit }, payload = {}) => {
    const { data, filename } = payload

    commit(VUEX_API_ORDERS_REQUEST_FETCH, false)
    return api.post(`order-documents/xlsx`,
      data,
      { responseType: 'arraybuffer', }
    ).then(response => {
      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.click()

      commit(VUEX_API_ORDERS_REQUEST_FETCH_SUCCESS, true)
      return response
    }).catch((err, response) => {
      commit(VUEX_API_ORDERS_REQUEST_FETCH_FAILED, false)
      if (!axios.isCancel(err)) {
        dispatch(VUEX_TOAST_ADD_TO_QUEUE, {
          component: '_core/Toast/Toast_Message.vue',
          data: {
            type: 'error',
            message: `Error: ${err.response && err.response.data.message}`
          }
        })
      }
    })
  },


}

const mutations = {
  [VUEX_API_ORDERS_REQUEST_FETCH]: state => {
    state.ordersRequest = ITS__API__REQUEST_TYPE__PENDING
  },
  [VUEX_API_ORDERS_REQUEST_FETCH_SUCCESS]: state => {
    state.ordersRequest = ITS__API__REQUEST_TYPE__SUCCESS
  },
  [VUEX_API_ORDERS_REQUEST_FETCH_FAILED]: state => {
    state.ordersRequest = ITS__API__REQUEST_TYPE__FAILED
  },

  // Set Cancelation token
  [VUEX_API_ORDERS_SET_CANCEL_TOKEN]: (state, data) => {
    state[data] = axios.CancelToken.source()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
