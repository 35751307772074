import feConstants from '@/store/constants/config/fe_constants'

export default {
  modules: {
    toolbar: {
      title: 'Advertising',
      extension: {
        navigation: [
          {
            title: 'Pending',
            name: [
              {
                route: 'advertising--libraries--assets--manage--pending',
                type: 'AdvertisingAsset'
              },
              {
                route: 'advertising--libraries--logos--manage--pending',
                type: 'AdvertisingLogo'
              }
            ]
          },
          {
            title: 'Published',
            name: [
              {
                route: 'advertising--libraries--assets--manage--published',
                type: 'AdvertisingAsset'
              },
              {
                route: 'advertising--libraries--logos--manage--published',
                type: 'AdvertisingLogo'
              }
            ]
          },
          {
            title: 'Unpublished',
            name: [
              {
                route: 'advertising--libraries--assets--manage--unpublished',
                type: 'AdvertisingAsset'
              },
              {
                route: 'advertising--libraries--logos--manage--unpublished',
                type: 'AdvertisingLogo'
              }
            ]
          },
          {
            title: 'Upload Files',
            name: [
              {
                route: 'advertising--libraries--assets--manage--upload',
                type: 'AdvertisingAsset'
              },
              {
                route: 'advertising--libraries--logos--manage--upload',
                type: 'AdvertisingLogo'
              }
            ]
          }
        ]
      },
      subnav: [
        {
          title: 'Assets Library',
          name: 'advertising--libraries--assets',
          permissions: {
            accessRequires: [
              {
                permission: feConstants.ITS__PERMISSION__ADVERTISING__ASSETS
              }
            ]
          },
          subRoutes: [
            {
              title: 'View Assets',
              name: 'advertising--libraries--assets',
              permissions: {
                accessRequires: [
                  {
                    permission: feConstants.ITS__PERMISSION__ADVERTISING__ASSETS,
                    roles: [
                      feConstants.ITS__ROLE__EDITOR,
                      feConstants.ITS__ROLE__SENIOR_EDITOR
                    ]
                  }
                ]
              }
            },
            {
              title: 'Manage Assets',
              name: 'advertising--libraries--assets--manage--pending',
              permissions: {
                accessRequires: [
                  {
                    permission: feConstants.ITS__PERMISSION__ADVERTISING__ASSETS,
                    roles: [
                      feConstants.ITS__ROLE__EDITOR,
                      feConstants.ITS__ROLE__SENIOR_EDITOR
                    ]
                  }
                ]
              }
            }
          ],
          match: [
            'advertising--libraries--assets',
            'advertising--libraries--assets--manage--pending',
            'advertising--libraries--assets--manage--published',
            'advertising--libraries--assets--manage--unpublished',
            'advertising--libraries--assets--manage--upload'
          ]
        },
        {
          title: 'Logo Library',
          name: 'advertising--libraries--logos',
          permissions: {
            accessRequires: [
              {
                permission: feConstants.ITS__PERMISSION__ADVERTISING__LOGOS
              }
            ]
          },
          subRoutes: [
            {
              title: 'View Logos',
              name: 'advertising--libraries--logos',
              permissions: {
                accessRequires: [
                  {
                    permission: feConstants.ITS__PERMISSION__ADVERTISING__LOGOS,
                    roles: [
                      feConstants.ITS__ROLE__EDITOR,
                      feConstants.ITS__ROLE__SENIOR_EDITOR
                    ]
                  }
                ]
              }
            },
            {
              title: 'Manage Logos',
              name: 'advertising--libraries--logos--manage--pending',
              permissions: {
                accessRequires: [
                  {
                    permission: feConstants.ITS__PERMISSION__ADVERTISING__LOGOS,
                    roles: [
                      feConstants.ITS__ROLE__EDITOR,
                      feConstants.ITS__ROLE__SENIOR_EDITOR
                    ]
                  }
                ]
              }
            }
          ],
          match: [
            'advertising--libraries--logos',
            'advertising--libraries--logos--manage--pending',
            'advertising--libraries--logos--manage--published',
            'advertising--libraries--logos--manage--unpublished',
            'advertising--libraries--logos--manage--upload'
          ]
        }
      ]
    }
  }
}
