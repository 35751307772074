// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// PROPERTIES LOOKUP LISTS
import router from '@/router'
import _sortedUniq from 'lodash.sorteduniq'
import { VUEX_PROPERTIES_ARRAY_UPDATE } from '@/store/constants/config/properties'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import ColumnHelpers from '@/components/_core/GridsCore/helpers/ColumnHelpers'
import properties from '@/store/modules/config/properties'
import shared from 'skch_its_be_fe_shared'
const formatDateOnly = shared.dates.formatDateOnly
const pricesheet = shared.pricesheet

let PropertiesLookupLists = {
  getPropertiesLookupList (colId, getAsString = false, fromColumnFilter = false, node = null) {
    const assetSlug = router.currentRoute.value.meta.manageType
    try {
      switch (assetSlug) {
        case ITS__LIBRARIES__MANAGE_TYPE__STEP__ASSET:
          return PropertiesLookupLists.getPropertiesLookupList_Step(colId, getAsString)
        case ITS__LIBRARIES__MANAGE_TYPE__GLOBAL_RETAIL__ASSET:
          return PropertiesLookupLists.getPropertiesLookupList_GlobalRetail(colId, getAsString)
        case ITS__LIBRARIES__MANAGE_TYPE__PRODUCT__DOCUMENT:
          return PropertiesLookupLists.getPropertiesLookupList_ProductDocument(colId, getAsString)
        case ITS__LIBRARIES__MANAGE_TYPE__ADVERTISING__ASSET:
          return PropertiesLookupLists.getPropertiesLookupList_AdvertisingAsset(colId, getAsString)
        case ITS__LIBRARIES__MANAGE_TYPE__ADVERTISING__LOGO:
          return PropertiesLookupLists.getPropertiesLookupList_AdvertisingLogo(colId, getAsString)
        case ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL:
          return PropertiesLookupLists.getPropertiesLookupList_InternalAssortments(colId, getAsString)
        case ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__REGULAR:
          return PropertiesLookupLists.getPropertiesLookupList_Assortments(colId, getAsString)
        case ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__SEASONSMANAGER:
          return PropertiesLookupLists.getPropertiesLookupList_SeasonsManager(colId, getAsString)
        case ITS__LIBRARIES__MANAGE_TYPE__SAMPLE_INVENTORY__PRODUCTION:
          return PropertiesLookupLists.getPropertiesLookupList_SampleInventoryProduction(colId, getAsString, fromColumnFilter)
        case ITS__LIBRARIES__MANAGE_TYPE__SAMPLE_INVENTORY__SALES:
          return PropertiesLookupLists.getPropertiesLookupList_SampleInventorySales(colId, getAsString, fromColumnFilter)
        case ITS__ORDERS__SAMPLE__DETAIL:
          return PropertiesLookupLists.getPropertiesLookupList_OrdersDetail(colId, getAsString, fromColumnFilter, 'sample', node)
        case ITS__ORDERS__PROMO__DETAIL:
          return PropertiesLookupLists.getPropertiesLookupList_OrdersDetail(colId, getAsString, fromColumnFilter, 'promo', node)
        case ITS__ORDERS__WHOLESALE__DETAIL:
          return PropertiesLookupLists.getPropertiesLookupList_OrdersDetail(colId, getAsString, fromColumnFilter, 'wholesale', node)
        case ITS__ORDERS__PROMO__NEEDS_REVIEW:
        case ITS__ORDERS__PROMO__REVIEWED:
        case ITS__ORDERS__PROMO__ARCHIVED:
        case ITS__ORDERS__SAMPLE__SAMPLE_STYLES:
        case ITS__ORDERS__SAMPLE__ACTIVE_ORDERS:
        case ITS__ORDERS__SAMPLE__ARCHIVED_ORDERS:
        case ITS__ORDERS__SAMPLE__ARCHIVED_ORDERS:
        case ITS__ORDERS__WHOLESALE__DRAFT_ORDERS:
          return PropertiesLookupLists.getPropertiesLookupList_GenericFromGrid(colId, getAsString, fromColumnFilter, node)
      }
    } catch (error) {
      console.error('PropertiesLookupLists error', error)
    }
  },
  propertiesLookupListReturn (colId, getAsString, stringName, listroot) {
    if (getAsString) {
      return stringName
    } else {
      return (listroot[colId]) ? listroot[colId] : []
    }
  },
  getPropertiesLookupList_GenericFromGrid (colId, getAsString, fromColumnFilter = false, node = null) {
    let listroot = {}
    let stringName = null
    switch (colId) {
      case 'division':
      case 'products.division':
        stringName = null
        // get full name instead of ID
        listroot[colId] = PropertiesLookupLists.fetchColumnValuesFromGrid(colId)
        for (let i = 0; i < listroot[colId].length; i++) {
          listroot[colId][i] = pricesheet.getDivisionLongNameFromCode(listroot[colId][i], properties)
        }
        break
      default:
        stringName = null
        listroot[colId] = PropertiesLookupLists.fetchColumnValuesFromGrid(colId)
        if (fromColumnFilter) {
          // if (colId === 'products.shipper') {
          //   for (let i = 0; i < listroot[colId].length; i++) {
          //     //listroot[colId][i] = ColumnHelpers.getShipperNameFromCode(listroot[colId][i])
          //     let lbl = ColumnHelpers.getShipperNameFromCode(listroot[colId][i])
          //     listroot[colId][i] = {
          //       label: lbl,
          //       value: listroot[colId][i]
          //     }
          //   }
          // }
        }
        break
    }
    return PropertiesLookupLists.propertiesLookupListReturn(colId, getAsString, stringName, listroot)
  },

  getPropertiesLookupList_OrdersDetail (colId, getAsString, fromColumnFilter = false, type, node) {
    let listroot = {}
    let stringName = null
    // remap
    // fromColumnFilter = true = IN USE.
    // If not fromColumnFilter, then we are in CELL and that needs to default to everything
    // however, the reality is only side, size and status have options - the others have optionsInUse
    if (fromColumnFilter) {
      stringName = null
      switch (colId) {
        case 'division':
          // get full name instead of ID
          for (let i = 0; i < listroot[colId].length; i++) {
            listroot[colId][i] = pricesheet.getDivisionLongNameFromCode(listroot[colId][i], properties)
          }
          break
        default:
          listroot[colId] = PropertiesLookupLists.fetchColumnValuesFromGrid(colId)
          break
      }
    } else {
      switch (colId) {
        // SOURCE & SIZE NOTES!
        // On the sample order side, people should be able to select any size regardless of availability status.
        // The only limitation is that "Inventory" should not be selectable for a size when there is no availability data for that size.
        // All other sources should be selectable. The only limitation to that is if "Inventory" has been previously set (e.g. when the order is loaded) - once Inventory is "locked" then it is no longer selectable and it is permanently set to "Inventory".
        // Only for the promo side, we need to restrict the size choices to only those returned in the availability data and the source will always be "Inventory"
        case 'source':
          //
          // inventory is not allowed if the size is not present in availability
          // also, if node === null, that means we are in group edit mode.  in group edit, allow all fields except Inventory
          let res = null
          let items2 = properties.state.data.Orders.default.product[colId].slice()

          let items = []
          items2.forEach(item => {
            items.push({
              label: item,
              value: item
            })
          })

          if (node) {
            // value setter uses node data
            const availability = (node?.params?.data?.availability) ? node?.params?.data?.availability : node?.data?.availability
            const nodeDataSize = (node?.params?.data?.size) ? node?.params?.data?.size : node?.data?.size
            if (availability && nodeDataSize) {
              res = availability.find(item => item.size === nodeDataSize) || null
            }
          }

          // if no res, just strip out inventory
          if (!res) {
            // this removes inventory
            // items = items.filter(item => item !== 'Inventory')
            items.forEach(item => {
              item.disabled = item.label === 'Inventory'
            })
          }

          listroot[colId] = items
          stringName = null
          break
        case 'size':
          listroot[colId] = []
          if (type === 'promo') {
            // PROMO
            let availability = (node?.params?.data?.availability) ? node?.params?.data?.availability : node?.data?.availability
            let availDateWindow = properties.state.data.Orders.PROMO.properties.availDateWindow

            // filter by availDateWindow
            let today = new Date()
            let targetDate = new Date()
            targetDate.setDate(today.getDate() + availDateWindow)
            let filteredAvailability = availability.filter(item => {
              let itemDate = new Date(parseInt(item.date.toString().substring(0, 4)), // Year
                parseInt(item.date.toString().substring(4, 6)), // Month (0-based)
                parseInt(item.date.toString().substring(6, 8))) // Day
              return itemDate <= targetDate
            })

            // format sizes
            let sizes = filteredAvailability.map(item => item.size + ' - ' + formatDateOnly(item.date, 'MM/DD/YY'))
            if (sizes) {
              // sort the array of sizes alphabetically, while considering the numeric part first and then any trailing letters,
              sizes.sort((a, b) => {
                const numA = parseInt(a, 10)
                const numB = parseInt(b, 10)

                // First compare the numeric parts
                if (numA !== numB) {
                  return numA - numB
                }
                // If numeric parts are equal, compare the letter parts
                return a.localeCompare(b)
              })
              const transformedSizes = sizes.map(size => {
                const [value] = size.split(' - ')
                return { label: size, value: value }
              })
              listroot[colId] = transformedSizes
            }
          } else {
            let productType = (node?.params?.data?.productType) ? node?.params?.data?.productType : node?.data?.productType
            // SAMPLE
            if (productType) {
              listroot[colId] = properties.state.data.Orders.default.product.size[productType].slice()
            } else {
              listroot[colId] = []
            }
          }
          stringName = null
          break
        case 'status':
          // IF PROMO, AND APPROVER/SR APPROVER, THERE's ONLY THREE STATUS TYPES
          listroot[colId] = properties.state.data.Orders.default.status.slice()
          if (type === 'promo') {
            const t = GridHelpers.mgThisArray[0]
            if (t.hasAdvancedRights) {
              if (node.params.data.status === 'PENDING' || node.params.data.status === 'APPROVED' || node.params.data.status === 'DENIED') {
                listroot[colId] = ['APPROVED', 'DENIED']
              }
            }
          }
          stringName = 'Orders.default.status'
          break
        case 'returnType':
        case 'side':
          listroot[colId] = properties.state.data.Orders.default.product[colId].slice()
          stringName = 'Orders.default.product.' + colId
          break
        case 'shipper':
          listroot[colId] = properties.state.data.Orders.default.shipperFilteredForDropdown.slice()
          stringName = 'Orders.default.shipperCodesFilteredForDropdown'
          break
        case 'quantity':
          listroot[colId] = properties.state.data.Orders.default.quantity.slice()
          stringName = 'Orders.default.quantity'
          break
        case 'term':
          let finalTerms = []
          const t = GridHelpers.mgThisArray[0]


          // extra terms append (only add if the value is not already in finalTerms)
          let userTerms = t.order?.customer?.termsInfo || []
          if (userTerms.length > 0) {
            userTerms = userTerms.map(term => {
              const lbl = `${term.code} (${term.description})`
              return { label: lbl, value: term.code }
            })

            finalTerms.push(...userTerms)
          }


          let country = t.user?.wof?.country

          if (country) {
            country = country.toUpperCase()
            const terms = properties.state.data.Orders.WHOLESALE.properties.termsByCountry
            let defaultTerms = terms[country]?.slice() || []
            defaultTerms = defaultTerms.map(term => {
              const lbl = `${term.code} (${term.description})`
              return { label: lbl, value: term.code }
            })

            // Create a set of the existing term values
            const existingValues = new Set(finalTerms.map(term => term.value))
            // Filter out defaultTerms for unique values
            defaultTerms = defaultTerms.filter(term => !existingValues.has(term.value))

            finalTerms.push(...defaultTerms)
          }



          // Custom sorting function: numbers first, sorted numerically, then pure letters
          // finalTerms.sort((a, b) => {
          //   const extractParts = (str) => {
          //     const match = str.match(/^(\d+)?([A-Za-z]*)$/)
          //     return match ? [match[1] ? parseInt(match[1], 10) : null, match[2] || ""] : [null, ""]
          //   };
          //
          //   const [numA, textA] = extractParts(a.value)
          //   const [numB, textB] = extractParts(b.value)
          //
          //   if (numA !== null && numB !== null) {
          //     return numA - numB || textA.localeCompare(textB)
          //   }
          //   if (numA !== null) return -1
          //   if (numB !== null) return 1
          //
          //   return a.value.localeCompare(b.value)
          // })

          listroot[colId] = finalTerms
          stringName = 'Orders.WHOLESALE.properties.termsByCountry'
          break

        default:
          if (type === 'promo') {
            listroot[colId] = properties.state.data.Orders.PROMO.properties[colId].slice()
            stringName = 'Orders.PROMO.properties.' + colId
          } else if (type === 'wholesale') {
            listroot[colId] = properties.state.data.Orders.WHOLESALE.properties[colId].slice()
            stringName = 'Orders.WHOLESALE.properties.' + colId
          } else {
            listroot[colId] = properties.state.data.Orders.SAMPLE.properties[colId].slice()
            stringName = 'Orders.SAMPLE.properties.' + colId
          }
          break
      }
    }
    return PropertiesLookupLists.propertiesLookupListReturn(colId, getAsString, stringName, listroot)
  },

  getPropertiesLookupList_SampleInventoryProduction (colId, getAsString, fromColumnFilter = false) {
    let listroot = {}
    let stringName = null

    // remap
    // fromColumnFilter = true = IN USE.
    // If not fromColumnFilter, then we are in CELL and that needs to default to everything
    // however, the reality is only side, size and status have options - the others have optionsInUse
    switch (colId) {
      /// /////////////////////////////////////////////////////////////////////
      default:
        if (fromColumnFilter) {
          listroot[colId] = properties.state.data.SampleInventory.Production.properties[colId].optionsInUse.slice()
          stringName = 'SampleInventory.Production.properties.' + colId + '.optionsInUse'
        } else {
          if (colId === 'side' || colId === 'size' || colId === 'status') {
            listroot[colId] = properties.state.data.SampleInventory.Production.properties[colId].options.slice()
            stringName = 'SampleInventory.Production.properties.' + colId + '.options'
          } else {
            listroot[colId] = properties.state.data.SampleInventory.Production.properties[colId].optionsInUse.slice()
            stringName = 'SampleInventory.Production.properties.' + colId + '.optionsInUse'
          }
        }
        break
    }
    return PropertiesLookupLists.propertiesLookupListReturn(colId, getAsString, stringName, listroot)
  },
  getPropertiesLookupList_SampleInventorySales (colId, getAsString, fromColumnFilter) {
    let listroot = {}
    let stringName = null

    // remap
    // fromColumnFilter = true = IN USE.
    // If not fromColumnFilter, then we are in CELL and that needs to default to everything
    // however, the reality is only side, size and status have options - the others have optionsInUse
    switch (colId) {
      /// /////////////////////////////////////////////////////////////////////
      default:
        if (fromColumnFilter) {
          listroot[colId] = properties.state.data.SampleInventory.Sales.properties[colId].optionsInUse.slice()
          stringName = 'SampleInventory.Sales.properties.' + colId + '.optionsInUse'
        } else {
          if (colId === 'side' || colId === 'size' || colId === 'status') {
            listroot[colId] = properties.state.data.SampleInventory.Sales.properties[colId].options.slice()
            stringName = 'SampleInventory.Sales.properties.' + colId + '.options'
          } else {
            listroot[colId] = properties.state.data.SampleInventory.Sales.properties[colId].optionsInUse.slice()
            stringName = 'SampleInventory.Sales.properties.' + colId + '.optionsInUse'
          }
        }
        break
    }
    return PropertiesLookupLists.propertiesLookupListReturn(colId, getAsString, stringName, listroot)
  },
  getPropertiesLookupList_SeasonsManager (colId, getAsString) {
    let listroot = {}
    let stringName = null

    // remap
    switch (colId) {
      /// /////////////////////////////////////////////////////////////////////
      case 'state':
        listroot[colId] = properties.state.data.InternalAssortments.properties.seasonState.options.slice()
        // stringName = 'InternalAssortments.properties.' + colId + '.options'
        stringName = null // none are modifyable
        break

      default:
        listroot[colId] = properties.state.data.InternalAssortments.properties[colId].options.slice()
        // stringName = 'InternalAssortments.properties.' + colId + '.options'
        stringName = null // none are modifyable
        break
    }
    return PropertiesLookupLists.propertiesLookupListReturn(colId, getAsString, stringName, listroot)
  },

  getPropertiesLookupList_Step (colId, getAsString) {
    let listroot = {}
    let stringName = null
    switch (colId) {
      /// /////////////////////////////////////////////////////////////////////
      // inUseWithDefault
      case 'year':
      case 'lang':
        listroot[colId] = properties.state.data.StepAsset.properties[colId].optionsInUseWithDefault.slice()
        listroot[colId].sort()
        if (colId === 'year') {
          listroot[colId].reverse()
        }
        stringName = 'StepAsset.properties.' + colId + '.optionsInUseWithDefault'
        break

      /// /////////////////////////////////////////////////////////////////////
      // default
      default:

        listroot[colId] = properties.state.data.StepAsset.properties[colId].options.slice()
        stringName = 'StepAsset.properties.' + colId + '.options'
        break
    }
    return PropertiesLookupLists.propertiesLookupListReturn(colId, getAsString, stringName, listroot)
  },

  getPropertiesLookupList_GlobalRetail (colId, getAsString) {
    let listroot = {}
    let tmp = []
    let stringName = null
    switch (colId) {
      /// /////////////////////////////////////////////////////////////////////
      // VisualAsset.default
      case 'assetType':
        let tmpVisualAsset = properties.state.data.GlobalRetailAsset.properties.assetType.optionsVisualAsset.slice()
        let tmpResource = properties.state.data.GlobalRetailAsset.properties.assetType.optionsResource.slice()
        tmp = [...tmpVisualAsset, ...tmpResource].sort()
        tmp = _sortedUniq(tmp)
        listroot['assetType'] = tmp
        // stringName - don't set - let it stay null
        break

      /// /////////////////////////////////////////////////////////////////////
      // inUse
      case 'initiative':
      case 'assetSubType':
      case 'element':
      case 'dimensions':
      case 'lastEditedByUser':
        listroot[colId] = properties.state.data.GlobalRetailAsset.properties[colId].optionsInUse.slice()
        stringName = 'GlobalRetailAsset.properties.' + colId + '.optionsInUse'
        break

      /// /////////////////////////////////////////////////////////////////////
      // inUseWithDefault
      case 'year':
      case 'lang':
        listroot[colId] = properties.state.data.GlobalRetailAsset.properties[colId].optionsInUseWithDefault.slice()
        listroot[colId].sort()
        if (colId === 'year') {
          listroot[colId].reverse()
        }
        stringName = 'GlobalRetailAsset.properties.' + colId + '.optionsInUseWithDefault'
        break

      // files usage type
      case 'usageType':
        listroot[colId] = properties.state.data.AssetFile.properties.usageType.options.slice()
        stringName = 'AssetFile.properties.usageType.options'
        break

      /// /////////////////////////////////////////////////////////////////////
      // default
      default:
        listroot[colId] = properties.state.data.GlobalRetailAsset.properties[colId].options.slice()
        stringName = 'GlobalRetailAsset.properties.' + colId + '.options'
        break
    }
    return PropertiesLookupLists.propertiesLookupListReturn(colId, getAsString, stringName, listroot)
  },
  getPropertiesLookupList_ProductDocument (colId, getAsString) {
    let listroot = {}
    let stringName = null

    // remap
    switch (colId) {
      /// /////////////////////////////////////////////////////////////////////
      // Special - column header doesn't line up
      case 'primaryFile.groups':
        listroot[colId] = properties.state.data.ProductDocument.properties.groups.options.slice()
        stringName = 'ProductDocument.properties.groups.options'
        break

      /// /////////////////////////////////////////////////////////////////////
      // USE ProductDocument
      case 'docType':
      case 'divisionId':
      case 'gender':
      case 'lang':
        listroot[colId] = properties.state.data.ProductDocument.properties[colId].options.slice()
        stringName = 'ProductDocument.properties.' + colId + '.options'
        break

      /// /////////////////////////////////////////////////////////////////////
      // default (use Product)
      default:
        listroot[colId] = properties.state.data.Product.properties[colId].options.slice()
        stringName = 'Product.properties.' + colId + '.options'
        break
    }
    return PropertiesLookupLists.propertiesLookupListReturn(colId, getAsString, stringName, listroot)
  },
  getPropertiesLookupList_AdvertisingAsset (colId, getAsString) {
    let listroot = {}
    let stringName = null

    // remap
    switch (colId) {
      /// /////////////////////////////////////////////////////////////////////
      /// /////////////////////////////////////////////////////////////////////

      // default
      default:
        listroot[colId] = properties.state.data.AdvertisingAsset.properties[colId].options.slice()
        if (colId === 'year') {
          listroot[colId].sort()
          listroot[colId].reverse()
        }
        stringName = 'AdvertisingAsset.properties.' + colId + '.options'
        break
    }
    return PropertiesLookupLists.propertiesLookupListReturn(colId, getAsString, stringName, listroot)
  },
  getPropertiesLookupList_AdvertisingLogo (colId, getAsString) {
    let listroot = {}
    let stringName = null

    // remap
    switch (colId) {
      /// /////////////////////////////////////////////////////////////////////
      /// /////////////////////////////////////////////////////////////////////
      // default
      default:
        listroot[colId] = properties.state.data.AdvertisingLogo.properties[colId].options.slice()
        stringName = 'AdvertisingLogo.properties.' + colId + '.options'
        break
    }
    return PropertiesLookupLists.propertiesLookupListReturn(colId, getAsString, stringName, listroot)
  },
  getPropertiesLookupList_InternalAssortments (colId, getAsString) {
    let listroot = {}
    let stringName = null

    // re-construct what was "assortment type" to match how the options are getting sent to properties from back end
    // InternalStaticRetailDomestic, InternalStaticRetailInternational, InternalStaticWholesaleDomestic, InternalStaticWholesaleInternational'
    const channel = GridHelpers.mgThisArray[0].assortment?.channel // wholesale, retail
    const region = GridHelpers.mgThisArray[0].assortment?.region // domestic, international
    let internalAssortmentsOptionsId = `${ITS__ASSORTMENTS__ORG_TYPE__INTERNAL}${ITS__ASSORTMENTS__METHOD_TYPE__STATIC}${channel}${region}`

    // remap
    switch (colId) {
      /// /////////////////////////////////////////////////////////////////////
      /// /////////////////////////////////////////////////////////////////////
      case 'gender': // this is coming from internal assortments list view (not the detailed assortment)
        //
        let internalChannelType = ''
        if (GridHelpers.mgThisArray[0].assortments && GridHelpers.mgThisArray[0].assortments.length > 0) {
          internalChannelType = GridHelpers.mgThisArray[0].assortments[0].channel
        }
        if (internalChannelType === ITS__ASSORTMENTS__CHANNEL_TYPE__RETAIL) {
          listroot[colId] = properties.state.data.InternalAssortments.properties.combinedDivisionGroupRetail.options.slice()
        } else {
          listroot[colId] = properties.state.data.InternalAssortments.properties.combinedDivisionGroupWholesale.options.slice()
        }

        // listroot[colId] = items.map(a => a.name)
        stringName = null // READ ONLY - never need this string
        break

      case 'state': // this is coming from internal assortments list view (not the detailed assortment)
        listroot[colId] = properties.state.data.InternalAssortments.properties.internalAssortmentState.options.slice()
        stringName = null // READ ONLY - never need this string
        break

      case 'pillar':
        // super special list - dont use properties, use a special lookup that has already occured
        // use stash here because this function gets called in excel import as well
        stringName = null
        listroot[colId] = GridHelpers.mgStashThisArray[0].getInternalAssortmentsCategories()

        // just grab from grid
        // stringName = null
        // listroot[colId] = PropertiesLookupLists.fetchColumnValuesFromGrid(colId)
        stringName = null
        break

      case 'storeType':
        let items = properties.state.data[internalAssortmentsOptionsId].properties[colId].options.slice()
        listroot[colId] = items.map(a => a.name)
        stringName = null // READ ONLY - never need this string
        break
      // default
      default:
        listroot[colId] = properties.state.data[internalAssortmentsOptionsId].properties[colId].options.slice()
        stringName = internalAssortmentsOptionsId + '.properties.' + colId + '.options'
        break
    }
    return PropertiesLookupLists.propertiesLookupListReturn(colId, getAsString, stringName, listroot)
  },
  getPropertiesLookupList_Assortments (colId, getAsString) {
    let listroot = {}
    let stringName = null

    // remap
    switch (colId) {
      /// /////////////////////////////////////////////////////////////////////
      /// /////////////////////////////////////////////////////////////////////
      case 'pillar':
        // listroot[colId] = GridHelpers.mgStashThisArray[0].getassortmentPillars()
        // stringName = null

        stringName = null
        listroot[colId] = PropertiesLookupLists.fetchColumnValuesFromGrid(colId)

        break
      default:
        break
    }
    return PropertiesLookupLists.propertiesLookupListReturn(colId, getAsString, stringName, listroot)
  },

  fetchColumnValuesFromGrid (colId, propertyFilterCodeKey=null, propertyFilterLabelKey=null, propertyFilterDirectory=[]) {
    let ret = []
    const rows = GridHelpers.getRowNodes(false, false, null, true) // get unfiltered here
    //regarding unfiltered note above - Example: load page, click requester filter, select blanks, make no rows left.  Then click a filter like Delivery Type.  Delivery Type will detect there are no rows and have no values.  Now the bad thing - THIS IS CACHED.  So if you kill the filters and show the data again, Delivery Type will be empty since it was empty the first time you clicked it.  There is no good way of updating cache - I was exploring it, but I think it’s cutting edge and might be in a new aggrid.
    const hasDotNotation = (colId.includes('.'))
    for (let i = 0; i < rows.length; i++) {
      let node = rows[i]

      // get value from row node,
      let val = GridHelpers.mgThisArray[0].gridApi.getCellValue({
        rowNode: node,
        colKey: colId,
        useFormatter: true
      })
      if (!val) {
        val = node.data[colId]
      }

      if (node.data && val) {
        ret.push(val)
      } else if (hasDotNotation) {
        // dot notation is extra special, and probably needs adjusted to the getValue style
        const colIdParts = colId.split('.')
        if (colIdParts.length > 1) {
          const p1 = colIdParts[0]
          const p2 = colIdParts[1]
          if (node.data && node.data[p1][p2]) {
            ret.push(node.data[p1][p2])
          }
        }
      }
    }

    // reduce duplicates
    ret = [...new Set(ret)]

    // if we are matching to another value system
    if (propertyFilterCodeKey && propertyFilterLabelKey && propertyFilterDirectory) {
      ret = ret.map(code => {
        const match = propertyFilterDirectory.find(item => item[propertyFilterCodeKey] === code);
        return match ? match[propertyFilterLabelKey] : null; // Return label if found, otherwise null
      });
    }

    // final sort
    ret = ret.sort()

    return ret
  },

  editPropertiesLookupList (colId, newValue) {
    const gridApi = GridHelpers.mgStashThisArray[0].gridApi
    const col = gridApi.getColumn(colId)
    if (col?.colDef?.cellEditorParams && col?.colDef?.cellEditorParams?.canAdd) {
      let getAsString = true
      let lookupList = PropertiesLookupLists.getPropertiesLookupList(colId, getAsString)
      GridHelpers.mgStashThisArray[0].$store.dispatch(VUEX_PROPERTIES_ARRAY_UPDATE,
        {
          key: lookupList,
          action: 'add',
          value: newValue
        })
    }
  }
}
export default PropertiesLookupLists
