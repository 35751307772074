<template>
  <div class="thumbnail-renderer">
    <div class="img-responsive">
      <img
        :src="loadImage"
        @load="imageLoaded=true"
        @error="imageLoad_OnError"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import shared from 'skch_its_be_fe_shared'
import { s3Href } from '@/utils/file'

const imgSrc = shared.productImage.imgSrc
export default {
  name: 'ThumbnailRenderer',
  data () {
    return {
      src: null,
      imageLoaded: false,
      imageLoadFailed: false
    }
  },
  props: {
    doImageLookup: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    loadImage () {
      return !this.imageLoadFailed
        ? this.src
        : require('@/assets/images/thumb-image-coming-soon.jpg')
    }
  },
  methods: {
    init (params) {

    },
    getGui (params) {
      // return params.value
    },
    imageLoad_OnError () {
      this.imageLoaded = true
      this.imageLoadFailed = true
    },
    setImage: async function () {
      let ret = ''
      if (this.params.node.group === false) {
        if (this.params.data) {
          let thumbnailKey = this.params.colDef.field
          let img = this.params.data[thumbnailKey]
          if (this.params.isSeasonsManager) {
            // add seasons manager image bucket
            img = s3Href(img)
          } else if (this.doImageLookup) {

            // check if a fall back was loaded - for some reasons new producs getting added are loaded badly
            let hasFallback = false
            if (img) {
              hasFallback = (img.toString().includes('image+fallback'))
            }

            // do image lookup via style, color, A, productType
            if (!img || hasFallback) {
              if (this.params.isProducts) {
                img = imgSrc('medium', this.params.data.products.style, this.params.data.products.color, 'A', this.params.data.products.productType, false)
              } else {
                img = imgSrc('medium', this.params.data.style, this.params.data.color, 'A', this.params.data.productType, false)
              }
            }
          }

          // try image 1, then 2, then show fail
          // try {
          //   await axios.get(img)
          // } catch (err) {
          //   img = require('@/assets/images/thumb-image-coming-soon.jpg')

          /* double lookup
          img = imgSrc('medium', this.params.data.style, this.params.data.color, 'A', this.params.data.productType, true)
          try {
            await axios.get(img)
          } catch (err) {
            img = require('@/assets/images/thumb-image-coming-soon.jpg')
          }
           */
          //}
          ret = img
        }
      }
      this.src = ret
    }

  },
  created () {

  },
  mounted () {
    this.setImage()
  }

}
</script>
