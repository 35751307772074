import { ref, watch, computed, onMounted, onUnmounted, onBeforeMount } from 'vue'
import DataMiddleware from '@/components/_core/GridsCore/helpers/DataMiddleware'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import app from '@/main'
import { useStore } from 'vuex'
/*
class CustomSetColumnFilter extends window.agGrid.SetColumnFilter {
  afterGuiDetached(params) {
    // Call the original `afterGuiDetached` method
    super.afterGuiDetached(params)
    alert('a')
  }
}

 */
export function useGridGlobalSetup () {
  // LIFE CYCLE
  onMounted(() => {
    // simple control hooks
    // EX: $root.$emit('aggrid-event',{type:'deselect-all'})
    app.config.globalProperties.emitter.on('aggrid-event', (params) => {
      DataMiddleware.emitSwitchboard(params)
    })

    // add undo detection at the page level
    //window.addEventListener('keyup', GridHelpers.detectKeyUp)
    //window.addEventListener('keydown', GridHelpers.detectKeyDown)

    window.addEventListener('resize', GridHelpers.resize)
    GridHelpers.resize()
    setTimeout(GridHelpers.resize, 100)
    setTimeout(GridHelpers.resize, 250)
    setTimeout(GridHelpers.resize, 500)
    setTimeout(GridHelpers.resize, 1000)
    setTimeout(GridHelpers.resize, 1500)
    setTimeout(GridHelpers.resize, 3000)
  })

  onUnmounted(() => {
    // destroy simple control hooks
    app.config.globalProperties.emitter.off('aggrid-event')

    // destroy undo detection
    //window.removeEventListener('keyup', GridHelpers.detectKeyUp)
    //window.removeEventListener('keydown', GridHelpers.detectKeyDown)
    window.removeEventListener('resize', GridHelpers.resize)
  })

  onBeforeMount(() => {

  })

  // WATCHERS
  const store = useStore()
  const currentLang = computed(() => store.state.localization.currentLang)
  const confirmationTogglerRowDelete = computed(() => store.state.grid.confirmationTogglerRowDelete)
  watch(confirmationTogglerRowDelete, (newValue, oldValue) => {
    GridHelpers.removeSelectedRowsComfirmed(store.state.grid.confirmationTogglerRowDelete)
  }, { deep: true })

  const showUpdatingBar = ref(false) // Define a reactive property
  const updatingInformation = computed(() => store.state.grid.updatingInformation)
  watch(updatingInformation, (newValue, oldValue) => {
    showUpdatingBar.value = true
    setTimeout(() => {
      showUpdatingBar.value = false
    }, 3000)
  }, { deep: true })

  return {
    showUpdatingBar,
    currentLang
  }
}
