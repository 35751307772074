import { VUEX_SAMPLE_INVENTORY_TRACKING_CREATE } from '@/store/constants/models/samples'

export const VUEX_ORDERS_UPDATE_QUERY = 'VUEX_ORDERS_UPDATE_QUERY'

export const VUEX_ORDERS_DETAIL_REFETCH = 'VUEX_ORDERS_DETAIL_REFETCH'
export const VUEX_ORDERS_DETAIL_FETCH = 'VUEX_ORDERS_DETAIL_FETCH'
export const VUEX_ORDERS_DETAIL_FETCH_SUCCESS = 'VUEX_ORDERS_DETAIL_FETCH_SUCCESS'
export const VUEX_ORDERS_DETAIL_FETCH_FAILURE = 'VUEX_ORDERS_DETAIL_FETCH_FAILURE'
export const VUEX_ORDERS_DETAIL_CREATE = 'VUEX_ORDERS_DETAIL_CREATE'
export const VUEX_ORDERS_DETAIL_DUPLICATE = 'VUEX_ORDERS_DETAIL_DUPLICATE'
export const VUEX_ORDERS_LIST_FETCH = 'VUEX_ORDERS_LIST_FETCH'
export const VUEX_ORDERS_LIST_FETCH_SUCCESS = 'VUEX_ORDERS_LIST_FETCH_SUCCESS'
export const VUEX_ORDERS_LIST_FETCH_FAILURE = 'VUEX_ORDERS_LIST_FETCH_FAILURE'

export const VUEX_ORDERS_PRODUCTS_LOOKUP_UPDATE = 'VUEX_ORDERS_PRODUCTS_LOOKUP_UPDATE'
export const VUEX_ORDERS_GRID_SET_SNAPSHOT = 'VUEX_ORDERS_GRID_SET_SNAPSHOT'

export const VUEX_ORDERS_DETAIL_GRID_ROWS_ADD = 'VUEX_ORDERS_DETAIL_GRID_ROWS_ADD'
export const VUEX_ORDERS_DETAIL_GRID_ROWS_ADD_COMPLETE = 'VUEX_ORDERS_DETAIL_GRID_ROWS_ADD_COMPLETE'
export const VUEX_ORDERS_DETAIL_GRID_ROWS_UPDATE = 'VUEX_ORDERS_DETAIL_GRID_ROWS_UPDATE'
export const VUEX_ORDERS_DETAIL_GRID_ROWS_DELETE = 'VUEX_ORDERS_DETAIL_GRID_ROWS_DELETE'
export const VUEX_ORDERS_DETAIL_BATCH_UPDATE = 'VUEX_ORDERS_DETAIL_BATCH_UPDATE'
export const VUEX_ORDERS_DETAIL_BATCH_UPDATE2 = 'VUEX_ORDERS_DETAIL_BATCH_UPDATE2'
export const VUEX_ORDERS_DETAIL_BATCH_UPDATE_SUCCESS = 'VUEX_ORDERS_DETAIL_BATCH_UPDATE_SUCCESS'
export const VUEX_ORDERS_DETAIL_ADD_COMMENT = 'VUEX_ORDERS_DETAIL_ADD_COMMENT'

export const VUEX_ORDERS_DETAIL_GRID_REQUEST_CLEAR = 'VUEX_ORDERS_DETAIL_GRID_REQUEST_CLEAR'

export const VUEX_ORDERS_SET_SELECTED_COORDINATOR = 'VUEX_ORDERS_SET_SELECTED_COORDINATOR'
export const VUEX_ORDERS_CLEAR_SELECTED_COORDINATOR = 'VUEX_ORDERS_CLEAR_SELECTED_COORDINATOR'
export const VUEX_ORDERS_SET_GRID_SETTINGS = 'VUEX_ORDERS_SET_GRID_SETTINGS'
export const VUEX_ORDERS_UPDATE_GRID_SETTINGS = 'VUEX_ORDERS_UPDATE_GRID_SETTINGS'

export const VUEX_ORDERS_DETAIL_MARK_COMPLETE = 'VUEX_ORDERS_DETAIL_MARK_COMPLETE'
export const VUEX_ORDERS_DETAIL_MARK_COMPLETE_CONFIRMED = 'VUEX_ORDERS_DETAIL_MARK_COMPLETE_CONFIRMED'

export const VUEX_ORDERS_DETAIL_SUBMIT_NEW_SAMPLE = 'VUEX_ORDERS_DETAIL_SUBMIT_NEW_SAMPLE'

export const VUEX_ORDERS_CONFIRMATION_LEAVE_PAGE = 'VUEX_ORDERS_CONFIRMATION_LEAVE_PAGE'
export const VUEX_ORDERS_LIST_GRID_ROWS_DELETE = 'VUEX_ORDERS_LIST_GRID_ROWS_DELETE'

export const VUEX_ORDERS_DROP_PRODUCTS = 'VUEX_ORDERS_DROP_PRODUCTS'

export const VUEX_ORDERS_TRIGGER_WHOLESALE_QUANTITIES_CELL_REFRESH = 'VUEX_ORDERS_TRIGGER_WHOLESALE_QUANTITIES_CELL_REFRESH'

export const VUEX_ORDERS_DETAIL_EXCEL_EXPORT = 'VUEX_ORDERS_DETAIL_EXCEL_EXPORT'
