<template>
  <div class="cell-dropdown-picker" :class="(params?.inlineCell) ? 'inline' : 'popup'">
    <div v-if="doRender" justify="center">
      <div class="field-holder fh1" v-if="dropdownType === 1">
        <v-autocomplete
          filled
          density="compact"
          autofocus
          v-model:search="autocompleteSearch"
          :menu-props="menuProps"
          :multiple="multipleSelect"
          :items="lookup"
          item-title="label"
          item-value="value"
          v-model="picker"
          @input="setIsTypingWithClear"
          @keydown.enter="closePopup"
          @keydown.tab.prevent="handleTabKey"
          ref="vuetifyComponent"
          id="cell-dropdown-picker-1"
          :style="styleMinWidth"
          :no-data-text="customNoDataText"
          :loading="isLoading"
          :editable="canAdd"
          autocomplete="off"
        ></v-autocomplete>
      </div>
      <div class="field-holder fh2" v-else-if="dropdownType === 2">
        <v-autocomplete
          filled
          density="compact"
          autofocus
          v-model:search="autocompleteSearch"
          :menu-props="menuProps"
          :multiple="multipleSelect"
          :items="lookup"
          item-title="label"
          item-value="value"
          v-model="picker"
          ref="vuetifyComponent"
          id="cell-dropdown-picker-2"
          :style="styleMinWidth"
          @input="itemClicked"
          @keydown.enter="closePopup"
          @keydown.tab.prevent="handleTabKey"
          autocomplete="off"
          :editable="canAdd"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :disabled="item.raw.disabled"
              @keydown.enter="closePopup"
              @click="itemClicked"
            ></v-list-item>
          </template>
        </v-autocomplete>
      </div>
      <div class="field-holder fh3" v-else-if="dropdownType === 3">
        <v-combobox
          filled
          density="compact"
          autofocus
          full-width
          :editable="canAdd"
          :menu-props="menuProps"
          :multiple="multipleSelect"
          :chips="multipleSelect"
          :placeholder="'Enter ' + String(params.colDef.headerName).toLowerCase()"
          :items="lookup"
          item-title="label"
          item-value="value"
          v-model="picker"
          @input="setIsTypingWithClear"
          @keydown.enter="closePopup"
          @keydown.tab.prevent="handleTabKey"
          ref="vuetifyComponent"
          id="cell-dropdown-picker-3"
          :style="styleMinWidth"
          autocomplete="off"
        ></v-combobox>
      </div>
    </div>
  </div>
</template>

<script>
import PropertiesLookupLists from '@/components/_core/GridsCore/helpers/PropertiesLookupLists'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import { VUEX_API_SAMPLE_INVENTORY_AUTOCOMPLETE } from '@/store/constants/api'
import _debounce from 'lodash/debounce'
import { onUnmounted } from 'vue'

export default {
  name: 'DropdownEditor',
  data() {
    return {
      myComponent: null,
      picker: [],
      lookup: [],
      autocompleteSearch: '',
      openedOnce: false,
      isTyping: false,
      // vuetify menu properties (see https://vuetifyjs.com/en/components/menus/)
      menuProps: {
        transition: 'none'
      },
      doRender: false, // Used to delay rendering for proper grid integration
      canAdd: false,
      multipleSelect: false,
      doAutocomplete: false,
      doAutocompleteSpecial: '',
      customNoDataTextMode: null,
      isLoading: false,
      focusedIndex: -1 // used for arrow navigation if needed
    }
  },
  watch: {
    picker: {
      deep: true,
      handler() {
        // Slight delay to allow isTyping to update before acting on picker changes.
        setTimeout(() => {
          if (!this.isTyping && this.openedOnce) {
            this.itemClicked()
          }
          this.openedOnce = true
        }, 5)
      }
    }
  },
  computed: {
    dropdownType() {
      if (this.doAutocomplete) {
        return 1
      } else if (!this.canAdd) {
        return 2
      } else {
        return 3
      }
    },
    styleMinWidth() {
      if (this.params?.colDef?.minWidth) {
        return 'min-width:' + this.params.colDef.minWidth + 'px'
      } else {
        return ''
      }
    },
    customNoDataText() {
      return this.customNoDataTextMode || 'No Results Found'
    }
  },
  mounted() {
    this.openedOnce = false

    // Set initial positions for the menu
    this.menuProps['position-x'] = 0
    this.menuProps['position-y'] = 0

    // Set initial value from parameters
    let eventKey = this.params.eventKey
    if (eventKey === 'Enter') {
      eventKey = null
    }
    this.picker = eventKey ? eventKey : this.params.value

    this.canAdd = this.params.canAdd
    this.doAutocomplete = this.params.doAutocomplete

    if (this.params.doAutocompleteSpecial) {
      this.doAutocompleteSpecial = this.params.doAutocompleteSpecial
      this.doAutocomplete = true
      this.customNoDataTextMode = 'Enter Search Terms'
    }

    this.multipleSelect = this.params.multipleSelect
    if (this.multipleSelect) {
      const val = this.params.value?.toString()
      if (val) {
        this.picker = this.params.value.toString().split(',')
      } else {
        this.picker = []
      }
    }

    this.$nextTick(() => {
      // Delay 1: Wait for DOM
      const inputEl = this.$refs.vuetifyComponent?.$el?.querySelector('input')

      if (inputEl) {
        // Focus and select input manually
        inputEl.focus()
        inputEl.select()
      }

      // Delay 2: Let ag-Grid settle before showing UI
      setTimeout(() => {
        this.postMounted()

        // Delay 3: Ensure Vuetify dropdown menu is open
        setTimeout(() => {
          this.$refs.vuetifyComponent?.focus()
          this.$refs.vuetifyComponent?.$el?.querySelector('input')?.select()
          if (this.$refs.vuetifyComponent?.menu !== undefined) {
            this.$refs.vuetifyComponent.menu = true
          }
        }, 10)
      }, 50)
    })


  },
  methods: {
    getValue () {
      let val = this.picker?.value ? this.picker.value : this.picker
      if (Array.isArray(val)) {
        val = [...new Set(val)]
      }
      return val
    },
    // When inlineCell is true, we don't use a popup
    isPopup () {
      return this.params.inlineCell ? false : true
    },
    setIsTypingWithClear () {
      this.isTyping = true
      this.inputCallbackWithTypingClear2()
    },
    inputCallbackWithTypingClear2: _debounce(function () {
      this.isTyping = false
      if (this.doAutocompleteSpecial) {
        this.doAutocompleteLookup()
      }
    }, 500),
    itemClicked (e) {
      if (this.multipleSelect) {
        if (this.doAutocomplete) {
          // Clear input for multi-select after autocomplete
          this.autocompleteSearch = ''
        }
      } else {
        // Close the popup once an item is clicked
        this.closePopup()
      }
    },
    closePopup () {
      if (this.dropdownType === 1 || this.dropdownType === 2) {
        if (!this.multipleSelect) {
          this.picker = this.getValueByLabel(this.autocompleteSearch)
        }
      }
      this.params.stopEditing()
    },
    handleTabKey (event) {
      // Close the current editor first.
      this.closePopup()

      // Delay to allow the grid to process the close.
      setTimeout(() => {
        // Get the column state, which is an ordered list of column states.
        const colState = this.params.api.getColumnState()
        const currentColId = this.params.column.getColId()
        const currentIndex = colState.findIndex(col => col.colId === currentColId)
        let nextIndex = event.shiftKey ? currentIndex - 1 : currentIndex + 1
        let nextRowIndex = this.params.rowIndex

        // Handle wrapping to next/previous row if needed.
        if (nextIndex >= colState.length) {
          nextIndex = 0
          nextRowIndex++
        } else if (nextIndex < 0) {
          nextIndex = colState.length - 1
          nextRowIndex = Math.max(0, nextRowIndex - 1)
        }

        // Get the next column's ID from the column state.
        const nextColId = colState[nextIndex].colId

        // Start editing the next cell.
        this.params.api.startEditingCell({
          rowIndex: nextRowIndex,
          colKey: nextColId
        })
      }, 0)
    },
    postMounted () {
      this.doRender = true
      setTimeout(this.postMounted2, 10)
    },
    postMounted2 () {
      // Get lookup values based on the column field name.
      const field = this.params.colDef.field
      this.lookup = PropertiesLookupLists.getPropertiesLookupList(field, false, false, this)
      setTimeout(this.postMounted3, 10)
    },
    postMounted3 () {
      // Focus the Vuetify component and open its menu.
      this.myComponent = this.$refs?.vuetifyComponent
      if (this.myComponent) {
        this.myComponent.focus()
        this.myComponent.menu = true
      }
    },
    doAutocompleteLookup () {
      const val = String(this.autocompleteSearch)
      if (val.length >= 2) {
        let endpoint = null
        switch (this.doAutocompleteSpecial) {
          case 'sampleInventoryAutoComplete':
            endpoint = VUEX_API_SAMPLE_INVENTORY_AUTOCOMPLETE
            break
        }
        if (endpoint) {
          this.lookup = []
          this.isLoading = true
          const payload = {
            property: this.params.colDef.field,
            value: val
          }
          this.customNoDataTextMode = 'Searching...'
          this.lookup = []
          GridHelpers.mgThisArray[0].$store.dispatch(endpoint, payload).then(response => {
            this.lookup = response
            this.isLoading = false
            this.customNoDataTextMode = ''
          })
        }
      } else {
        this.lookup = []
        this.customNoDataTextMode = 'Enter Search Terms'
      }
    },
    getValueByLabel (label) {
      const item = this.lookup.find(item => item.label === label)
      return item ? (item.value || label) : label
    }
  }
}
</script>
<style lang="scss">
  .cell-dropdown-picker {
    &.inline {
      .v-field--variant-filled .v-field__overlay {
        background-color: transparent !important;
      }
      .v-field__input {
        row-gap: 0;
        column-gap: 0;
        min-height: auto;
        padding:0;
        background-color: transparent!important;
        margin: 0;
        input {
          margin: 0 5px;
          padding: 0 2px;
          font-size: 14px;
        }

      }
      .v-input__details {
        display: none!important;
      }
    }
  }
</style>
