// -------------------------------
/* Constants API */
// -------------------------------
export const VUEX_API_CONSTANTS_FETCH = 'VUEX_API_CONSTANTS_FETCH'
export const VUEX_API_CONSTANTS_SUCCESS = 'VUEX_API_CONSTANTS_SUCCESS'
export const VUEX_API_CONSTANTS_FAILURE = 'VUEX_API_CONSTANTS_FAILURE'
export const VUEX_API_CONSTANTS_SET_CANCEL_TOKEN = 'VUEX_API_CONSTANTS_SET_CANCEL_TOKEN'

// -------------------------------
/* Photos API */
// -------------------------------
export const VUEX_API_PHOTOS_FETCH = 'VUEX_API_PHOTOS_FETCH'
export const VUEX_API_PHOTOS_FETCH_STREAMING = 'VUEX_API_PHOTOS_FETCH_STREAMING'
export const VUEX_API_PHOTOS_FETCH_SUCCESS = 'VUEX_API_PHOTOS_FETCH_STREAMING_SUCCESS'
export const VUEX_API_PHOTOS_FETCH_FAILED = 'VUEX_API_PHOTOS_FETCH_STREAMING_FAILED'
export const VUEX_API_PHOTOS_ABORT = 'VUEX_API_PHOTOS_ABORT'

// -------------------------------
/* Assets API */
// -------------------------------
export const VUEX_API_ASSETS_FETCH = 'VUEX_API_ASSETS_FETCH'
export const VUEX_API_ASSETS_FETCH_STREAMING = 'VUEX_API_ASSETS_FETCH_STREAMING'
export const VUEX_API_ASSETS_FETCH_SUCCESS = 'VUEX_API_ASSETS_FETCH_SUCCESS'
export const VUEX_API_ASSETS_FETCH_FAILED = 'VUEX_API_ASSETS_FETCH_FAILED'
export const VUEX_API_ASSETS_SET_CANCEL_TOKEN = 'VUEX_API_ASSETS_SET_CANCEL_TOKEN'

// -------------------------------
/* Announcement API */
// -------------------------------
export const VUEX_API_ANNOUNCEMENT_FETCH = 'VUEX_API_ANNOUNCEMENT_FETCH'
export const VUEX_API_ANNOUNCEMENT_FETCH_SUCCESS = 'VUEX_API_ANNOUNCEMENT_FETCH_SUCCESS'
export const VUEX_API_ANNOUNCEMENT_FETCH_FAILURE = 'VUEX_API_ANNOUNCEMENT_FETCH_FAILURE'

export const VUEX_API_ANNOUNCEMENT_DELETE = 'VUEX_API_ANNOUNCEMENT_DELETE'
export const VUEX_API_ANNOUNCEMENT_DELETE_SUCCESS = 'VUEX_API_ANNOUNCEMENT_DELETE_SUCCESS'
export const VUEX_API_ANNOUNCEMENT_DELETE_FAILURE = 'VUEX_API_ANNOUNCEMENT_DELETE_FAILURE'

export const VUEX_API_ANNOUNCEMENT_UPDATE = 'VUEX_API_ANNOUNCEMENT_UPDATE'
export const VUEX_API_ANNOUNCEMENT_UPDATE_SUCCESS = 'VUEX_API_ANNOUNCEMENT_UPDATE_SUCCESS'
export const VUEX_API_ANNOUNCEMENT_UPDATE_FAILURE = 'VUEX_API_ANNOUNCEMENT_UPDATE_FAILURE'

export const VUEX_API_ANNOUNCEMENT_SEND_MAIL_SAVE_CHART = 'VUEX_API_ANNOUNCEMENT_SEND_MAIL_SAVE_CHART'
export const VUEX_API_ANNOUNCEMENT_SEND_MAIL_SAVE_CHART_SUCCESS = 'VUEX_API_ANNOUNCEMENT_SEND_MAIL_SAVE_CHART_SUCCESS'
export const VUEX_API_ANNOUNCEMENT_SEND_MAIL_SAVE_CHART_FAILURE = 'VUEX_API_ANNOUNCEMENT_SEND_MAIL_SAVE_CHART_FAILURE'

export const VUEX_API_ANNOUNCEMENT_PDF_DOWNLOAD = 'VUEX_API_ANNOUNCEMENT_PDF_DOWNLOAD'
export const VUEX_API_ANNOUNCEMENT_PDF_DOWNLOAD_SUCCESS = 'VUEX_API_ANNOUNCEMENT_PDF_DOWNLOAD_SUCCESS'
export const VUEX_API_ANNOUNCEMENT_PDF_DOWNLOAD_FAILURE = 'VUEX_API_ANNOUNCEMENT_PDF_DOWNLOAD_FAILURE'

// -------------------------------
/* Assortments API */
// -------------------------------
export const VUEX_API_ASSORTMENTS_REQUEST_FETCH = 'VUEX_API_ASSORTMENTS_REQUEST_FETCH'
export const VUEX_API_ASSORTMENTS_REQUEST_SUCCESS = 'VUEX_API_ASSORTMENTS_REQUEST_SUCCESS'
export const VUEX_API_ASSORTMENTS_REQUEST_FAILED = 'VUEX_API_ASSORTMENTS_REQUEST_FAILED'

export const VUEX_API_ASSORTMENT_REQUEST_FETCH = 'VUEX_API_ASSORTMENT_REQUEST_FETCH'
export const VUEX_API_ASSORTMENT_REQUEST_SUCCESS = 'VUEX_API_ASSORTMENT_REQUEST_SUCCESS'
export const VUEX_API_ASSORTMENT_REQUEST_FAILED = 'VUEX_API_ASSORTMENT_REQUEST_FAILED'

export const VUEX_API_ASSORTMENT_UPDATE = 'VUEX_API_ASSORTMENT_UPDATE'
export const VUEX_API_ASSORTMENT_UPDATE_SUCCESS = 'VUEX_API_ASSORTMENT_UPDATE_SUCCESS'
export const VUEX_API_ASSORTMENT_UPDATE_FAILED = 'VUEX_API_ASSORTMENT_UPDATE_FAILED'

export const VUEX_API_ASSORTMENT_UPDATE_BATCH = 'VUEX_API_ASSORTMENT_UPDATE_BATCH'
export const VUEX_API_ASSORTMENT_UPDATE_MERGE = 'VUEX_API_ASSORTMENT_UPDATE_MERGE'

export const VUEX_API_ASSORTMENT_DUPLICATE = 'VUEX_API_ASSORTMENT_DUPLICATE'

export const VUEX_API_ASSORTMENT_CREATE = 'VUEX_API_ASSORTMENT_CREATE'
export const VUEX_API_ASSORTMENT_DELETE = 'VUEX_API_ASSORTMENT_DELETE'

export const VUEX_API_ASSORTMENT_CREATE_FROM_KI_EXPORT = 'VUEX_API_ASSORTMENT_CREATE_FROM_KI_EXPORT'

export const VUEX_API_ASSORTMENT_PRODUCTS_FETCH = 'VUEX_API_ASSORTMENT_PRODUCTS_FETCH'
export const VUEX_API_ASSORTMENT_PRODUCTS_UPDATE = 'VUEX_API_ASSORTMENT_PRODUCTS_UPDATE'
export const VUEX_API_ASSORTMENT_PRODUCTS_UPDATE_SUCCESS = 'VUEX_API_ASSORTMENT_PRODUCTS_UPDATE_SUCCESS'

export const VUEX_API_ASSORTMENT_PRODUCTS_RENAME = 'VUEX_API_ASSORTMENT_PRODUCTS_RENAME'

export const VUEX_API_ASSORTMENT_PACKAGE_DOWNLOAD = 'VUEX_API_ASSORTMENT_PACKAGE_DOWNLOAD'
export const VUEX_API_ASSORTMENT_PACKAGE_DOWNLOAD_CANCEL = 'VUEX_API_ASSORTMENT_PACKAGE_DOWNLOAD_CANCEL'

export const VUEX_API_ASSORTMENTS_INTERNAL_TABLE_OF_CONTENTS_FETCH = 'VUEX_API_ASSORTMENTS_INTERNAL_TABLE_OF_CONTENTS_FETCH'

export const VUEX_API_ASSORTMENTS_INTERNAL_REQUEST_FETCH = 'VUEX_API_ASSORTMENTS_INTERNAL_REQUEST_FETCH'
export const VUEX_API_ASSORTMENT_INTERNAL_REQUEST_FETCH = 'VUEX_API_ASSORTMENT_INTERNAL_REQUEST_FETCH'
export const VUEX_API_ASSORTMENT_INTERNAL_PRODUCTS_REQUEST_FETCH = 'VUEX_API_ASSORTMENT_INTERNAL_PRODUCTS_REQUEST_FETCH'
export const VUEX_API_ASSORTMENT_INTERNAL_LOCKER = 'VUEX_API_ASSORTMENT_INTERNAL_LOCKER'

export const VUEX_API_ASSORTMENTS_SET_CANCEL_TOKEN = 'VUEX_API_ASSORTMENTS_SET_CANCEL_TOKEN'

// GroupSort
export const VUEX_API_ASSORTMENT_GROUPSORT_UPDATE = 'VUEX_API_ASSORTMENT_GROUPSORT_UPDATE'
export const VUEX_API_ASSORTMENT_GROUPSORT_UPDATE_SUCCESS = 'VUEX_API_ASSORTMENT_GROUPSORT_UPDATE_SUCCESS'
export const VUEX_API_ASSORTMENT_GROUPSORT_UPDATE_FAILURE = 'VUEX_API_ASSORTMENT_GROUPSORT_UPDATE_FAILURE'
export const VUEX_API_ASSORTMENT_GROUPSORT_SET_CANCEL_TOKEN = 'VUEX_API_ASSORTMENT_GROUPSORT_SET_CANCEL_TOKEN'

// -------------------------------
/* Seasons API */
// -------------------------------
// Seasons
export const VUEX_API_SEASONS_REQUEST_FETCH = 'VUEX_API_SEASONS_REQUEST_FETCH'
export const VUEX_API_SEASONS_REQUEST_SUCCESS = 'VUEX_API_SEASONS_REQUEST_SUCCESS'
export const VUEX_API_SEASONS_REQUEST_FAILED = 'VUEX_API_SEASONS_REQUEST_FAILED'

// Season
export const VUEX_API_SEASON_REQUEST_CREATE = 'VUEX_API_SEASON_REQUEST_CREATE'
export const VUEX_API_SEASON_REQUEST_FETCH = 'VUEX_API_SEASON_REQUEST_FETCH'
export const VUEX_API_SEASON_REQUEST_UPDATE = 'VUEX_API_SEASON_REQUEST_UPDATE'
export const VUEX_API_SEASON_REQUEST_DELETE = 'VUEX_API_SEASON_REQUEST_DELETE'

// Season Set
export const VUEX_API_SEASON_SET_UPDATE_STATE = 'VUEX_API_SEASON_SET_UPDATE_STATE'

export const VUEX_API_SEASONS_SET_CANCEL_TOKEN = 'VUEX_API_SEASONS_SET_CANCEL_TOKEN'

// -------------------------------
/* Collections API */
// -------------------------------
export const VUEX_API_COLLECTIONS_REQUEST = 'VUEX_API_COLLECTIONS_REQUEST'
export const VUEX_API_COLLECTION_REQUEST = 'VUEX_API_COLLECTION_REQUEST'
export const VUEX_API_COLLECTIONS_REQUEST_SUCCESS = 'VUEX_API_COLLECTIONS_REQUEST_SUCCESS'
export const VUEX_API_COLLECTIONS_REQUEST_FAILURE = 'VUEX_API_COLLECTIONS_REQUEST_FAILURE'

export const VUEX_API_COLLECTIONS_REQUEST_FETCH = 'VUEX_API_COLLECTIONS_REQUEST_FETCH'
export const VUEX_API_COLLECTION_REQUEST_FETCH = 'VUEX_API_COLLECTION_REQUEST_FETCH'
export const VUEX_API_COLLECTIONS_FETCH = 'VUEX_API_COLLECTIONS_FETCH'
export const VUEX_API_COLLECTION_LOOKUP = 'VUEX_API_COLLECTION_LOOKUP'
export const VUEX_API_COLLECTION_CREATE = 'VUEX_API_COLLECTION_CREATE'
export const VUEX_API_COLLECTION_UPDATE = 'VUEX_API_COLLECTION_UPDATE'

export const VUEX_API_COLLECTIONS_SET_CANCEL_TOKEN = 'VUEX_API_COLLECTIONS_SET_CANCEL_TOKEN'

// -------------------------------
/* Cutomers API */
// -------------------------------
export const VUEX_API_CUSTOMERS_SEARCH_REQUEST = 'VUEX_API_CUSTOMERS_SEARCH_REQUEST'
export const VUEX_API_CUSTOMERS_SEARCH_REQUEST_SUCCESS = 'VUEX_API_CUSTOMERS_SEARCH_REQUEST_SUCCESS'
export const VUEX_API_CUSTOMERS_SEARCH_REQUEST_FAILURE = 'VUEX_API_CUSTOMERS_SEARCH_REQUEST_FAILURE'
export const VUEX_API_CUSTOMERS_SEARCH_REQUEST_CLEAR_LIST = 'VUEX_API_CUSTOMERS_SEARCH_REQUEST_CLEAR_LIST'
export const VUEX_API_CUSTOMERS_SET_CANCEL_TOKEN = 'VUEX_API_CUSTOMERS_SET_CANCEL_TOKEN'

// -------------------------------
/* Documents API */
// -------------------------------
export const VUEX_API_DOCUMENTS_FETCH = 'VUEX_API_DOCUMENTS_FETCH'
export const VUEX_API_DOCUMENTS_SUCCESS = 'VUEX_API_DOCUMENTS_SUCCESS'
export const VUEX_API_DOCUMENTS_FAILED = 'VUEX_API_DOCUMENTS_FAILED'
export const VUEX_API_DOCUMENT_FETCH = 'VUEX_API_DOCUMENT_FETCH'
export const VUEX_API_DOCUMENT_SUCCESS = 'VUEX_API_DOCUMENT_SUCCESS'
export const VUEX_API_DOCUMENT_FAILURE = 'VUEX_API_DOCUMENT_FAILURE'
export const VUEX_API_DOCUMENT_DELETE = 'VUEX_API_DOCUMENT_DELETE'

export const VUEX_API_DOCUMENT_CREATE = 'VUEX_API_DOCUMENT_CREATE'
export const VUEX_API_DOCUMENT_CREATE_CANCEL = 'VUEX_API_DOCUMENT_CREATE_CANCEL'

export const VUEX_API_DOCUMENT_SCHEDULED_CREATE = 'VUEX_API_DOCUMENT_SCHEDULED_CREATE'
export const VUEX_API_DOCUMENT_SCHEDULED_DELETE = 'VUEX_API_DOCUMENT_SCHEDULED_DELETE'

export const VUE_API_DOCUMENT_EMAIL_FILE_CREATE = 'VUE_API_DOCUMENT_EMAIL_FILE_CREATE'

export const VUEX_API_SHARED_FILE_DOWNLOAD = 'VUEX_API_SHARED_FILE_DOWNLOAD'
export const VUEX_API_SHARED_FILE_DOWNLOAD_CANCEL = 'VUEX_API_SHARED_FILE_DOWNLOAD_CANCEL'

export const VUEX_API_SCHEDULED_FILE_DOWNLOAD = 'VUEX_API_SCHEDULED_FILE_DOWNLOAD'
export const VUEX_API_SCHEDULED_FILE_DOWNLOAD_CANCEL = 'VUEX_API_SCHEDULED_FILE_DOWNLOAD_CANCEL'

export const VUEX_API_DOCUMENTS_SET_CANCEL_TOKEN = 'VUEX_API_DOCUMENTS_SET_CANCEL_TOKEN'

// -------------------------------
/* Assets API */
// -------------------------------
export const VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH = 'VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH'
export const VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_STREAMING = 'VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_STREAMING'
export const VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_SUCCESS = 'VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_SUCCESS'
export const VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_FAILED = 'VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_FETCH_FAILED'
export const VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_SET_CANCEL_TOKEN = 'VUEX_API_ASSORTMENT_SHOWROOM_PRODUCTS_SET_CANCEL_TOKEN'

// -------------------------------
/* Products API */
// -------------------------------
export const VUEX_API_PRODUCTS_REQUEST_FETCH = 'VUEX_API_PRODUCTS_REQUEST_FETCH'
export const VUEX_API_PRODUCTS_REQUEST_SUCCESS = 'VUEX_API_PRODUCTS_REQUEST_SUCCESS'
export const VUEX_API_PRODUCTS_REQUEST_FAILED = 'VUEX_API_PRODUCTS_REQUEST_FAILED'

export const VUEX_API_PRODUCTS_STYLES_FETCH = 'VUEX_API_PRODUCTS_STYLES_FETCH'
export const VUEX_API_PRODUCTS_STYLE_COLORS_FETCH = 'VUEX_API_PRODUCTS_STYLE_COLORS_FETCH'

export const VUEX_API_PRODUCT_TEARSHEET = 'VUEX_API_PRODUCT_TEARSHEET'

export const VUEX_API_PRODUCTS_VALIDATE = 'VUEX_API_PRODUCTS_VALIDATE'

export const VUEX_API_PRODUCTS_SET_CANCEL_TOKEN = 'VUEX_API_PRODUCTS_SET_CANCEL_TOKEN'

// -------------------------------
/* AWS API */
// -------------------------------
export const VUEX_API_S3_GET_SIGNED_URLS = 'VUEX_API_S3_GET_SIGNED_URLS'
export const VUEX_API_S3_COMPLETE_UPLOAD = 'VUEX_API_S3_COMPLETE_UPLOAD'
export const VUEX_API_S3_UPLOAD_REQUEST = 'VUEX_API_S3_UPLOAD_REQUEST'
export const VUEX_API_S3_UPLOAD_REQUEST_SUCCESS = 'VUEX_API_S3_UPLOAD_REQUEST_SUCCESS'
export const VUEX_API_S3_UPLOAD_REQUEST_FAILURE = 'VUEX_API_S3_UPLOAD_REQUEST_FAILURE'

export const VUEX_API_S3_ASSETS_SIGNED_REQUEST = 'VUEX_API_S3_ASSETS_SIGNED_REQUEST'
export const VUEX_API_S3_DOWNLOAD_SIGNED_REQUEST = 'VUEX_API_S3_DOWNLOAD_SIGNED_REQUEST'
export const VUEX_API_S3_DOWNLOAD_PUBLIC = 'VUEX_API_S3_DOWNLOAD_PUBLIC'

export const VUEX_API_AWS_S3_UPLOAD = 'VUEX_API_AWS_S3_UPLOAD' // TODO: Depricate

// -------------------------------
// Statistics API
// -------------------------------
export const VUEX_API_STATISTICS_SEND = 'VUEX_API_STATISTICS_SEND'

// -------------------------------
// Libraries API
// -------------------------------
export const VUEX_API_LIBRARIES_REQUEST_FETCH = 'VUEX_API_LIBRARIES_REQUEST_FETCH'
export const VUEX_API_LIBRARIES_REQUEST_FETCH_SUCCESS = 'VUEX_API_LIBRARIES_REQUEST_FETCH_SUCCESS'
export const VUEX_API_LIBRARIES_REQUEST_FETCH_FAILED = 'VUEX_API_LIBRARIES_REQUEST_FETCH_FAILED'
export const VUEX_API_LIBRARIES_SET_CANCEL_TOKEN = 'VUEX_API_LIBRARIES_SET_CANCEL_TOKEN'
export const VUEX_API_LIBRARIES_PRODUCTS_UPDATE = 'VUEX_API_LIBRARIES_PRODUCTS_UPDATE'
export const VUEX_API_LIBRARIES_PRODUCTS_UPDATE_SUCCESS = 'VUEX_API_LIBRARIES_PRODUCTS_UPDATE_SUCCESS'
export const VUEX_API_LIBRARIES_PRODUCTS_DELETE = 'VUEX_API_LIBRARIES_PRODUCTS_DELETE'
export const VUEX_API_LIBRARIES_PRODUCTS_DELETE_SUCCESS = 'VUEX_API_LIBRARIES_PRODUCTS_DELETE_SUCCESS'

export const VUEX_API_LIBRARIES_FILE_CHECK_FILENAME = 'VUEX_API_LIBRARIES_FILE_CHECK_FILENAME'
export const VUEX_API_LIBRARIES_DOWNLOAD_ASSETS_XLSX_REPORT = 'VUEX_API_LIBRARIES_DOWNLOAD_ASSETS_XLSX_REPORT'

// -------------------------------
// Sample Library API
// -------------------------------
export const VUEX_API_SAMPLE_INVENTORY_FETCH = 'VUEX_API_SAMPLE_INVENTORY_FETCH'
export const VUEX_API_SAMPLE_INVENTORY_CREATE = 'VUEX_API_SAMPLE_INVENTORY_CREATE'
export const VUEX_API_SAMPLE_INVENTORY_PUT = 'VUEX_API_SAMPLE_INVENTORY_PUT'
export const VUEX_API_SAMPLE_INVENTORY_PATCH = 'VUEX_API_SAMPLE_INVENTORY_PATCH'
export const VUEX_API_SAMPLE_INVENTORY_BATCH = 'VUEX_API_SAMPLE_INVENTORY_BATCH'
export const VUEX_API_SAMPLE_INVENTORY_DELETE = 'VUEX_API_SAMPLE_INVENTORY_DELETE'
export const VUEX_API_SAMPLE_INVENTORY_PRODUCTS_DELETE_SUCCESS = 'VUEX_API_SAMPLE_INVENTORY_PRODUCTS_DELETE_SUCCESS'
export const VUEX_API_SAMPLE_INVENTORY_PRODUCTS_UPDATE_SUCCESS = 'VUEX_API_SAMPLE_INVENTORY_PRODUCTS_UPDATE_SUCCESS'
export const VUEX_API_SAMPLE_INVENTORY_VALIDATE = 'VUEX_API_SAMPLE_INVENTORY_VALIDATE'
export const VUEX_API_SAMPLE_INVENTORY_AUTOCOMPLETE = 'VUEX_API_SAMPLE_INVENTORY_AUTOCOMPLETE'
export const VUEX_API_SAMPLE_INVENTORY_EXPORT = 'VUEX_API_SAMPLE_INVENTORY_EXPORT'

export const VUEX_API_SAMPLE_INVENTORY_TRACKING_FETCH = 'VUEX_API_SAMPLE_INVENTORY_TRACKING_FETCH'
export const VUEX_API_SAMPLE_INVENTORY_TRACKING_CREATE = 'VUEX_API_SAMPLE_INVENTORY_TRACKING_CREATE'

export const VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE = 'VUEX_API_SAMPLE_INVENTORY_REQUEST_SET_STATE'
export const VUEX_API_SAMPLE_INVENTORY_SET_CANCEL_TOKEN = 'VUEX_API_SAMPLE_INVENTORY_SET_CANCEL_TOKEN'

export const VUEX_API_SAMPLE_INVENTORY_MOVE_TO_PRODUCTION = 'VUEX_API_SAMPLE_INVENTORY_MOVE_TO_PRODUCTION'

// -------------------------------
// Packages API
// -------------------------------
export const VUEX_API_PACKAGE_CREATE = 'VUEX_API_PACKAGE_CREATE'
export const VUE_API_PACKAGE_EMAIL_CREATE = 'VUE_API_PACKAGE_EMAIL_CREATE'
export const VUE_API_PACKAGE_DOWNLOAD_SPREADSHEET = 'VUE_API_PACKAGE_DOWNLOAD_SPREADSHEET'
export const VUE_API_PACKAGE_DOWNLOAD_DIVISION = 'VUE_API_PACKAGE_DOWNLOAD_DIVISION'
export const VUE_API_PACKAGE_EMAIL_DIVISION = 'VUE_API_PACKAGE_EMAIL_DIVISION'
export const VUE_API_PACKAGE_EMAIL_SPREADSHEET = 'VUE_API_PACKAGE_EMAIL_SPREADSHEET'
export const VUE_API_PACKAGE_DOWNLOAD_ASSORTMENT = 'VUE_API_PACKAGE_DOWNLOAD_ASSORTMENT'

// -------------------------------
// Mail API
// -------------------------------
export const VUEX_API_MAIL_SEND = 'VUEX_API_MAIL_SEND'
export const VUEX_API_MAIL_SEND_SUCCESS = 'VUEX_API_MAIL_SEND_SUCCESS'
export const VUEX_API_MAIL_SEND_FAILURE = 'VUEX_API_MAIL_SEND_FAILURE'

// -------------------------------
// Options API
// -------------------------------
export const VUEX_API_OPTIONS_FETCH = 'VUEX_API_OPTIONS_FETCH'
export const VUEX_API_OPTIONS_INTERNAL_ASSORTMENT_FETCH = 'VUEX_API_OPTIONS_INTERNAL_ASSORTMENT_FETCH'
export const VUEX_API_OPTIONS_FETCH_SUCCESS = 'VUEX_API_OPTIONS_FETCH_SUCCESS'
export const VUEX_API_OPTIONS_FETCH_FAILURE = 'VUEX_API_OPTIONS_FETCH_FAILURE'
export const VUEX_API_OPTIONS_SET_CANCEL_TOKEN = 'VUEX_API_OPTIONS_SET_CANCEL_TOKEN'

// -------------------------------
// Reporting API
// -------------------------------
export const VUEX_API_REPORTING_FETCH = 'VUEX_API_REPORTING_FETCH'
export const VUEX_API_REPORTING_SUCCESS = 'VUEX_API_REPORTING_SUCCESS'
export const VUEX_API_REPORTING_FAILED = 'VUEX_API_REPORTING_FAILED'
export const VUEX_API_REPORTING_SET_CANCEL_TOKEN = 'VUEX_API_REPORTING_SET_CANCEL_TOKEN'

// Users API
// -------------------------------
export const VUEX_API_USERS_FETCH = 'VUEX_API_USERS_FETCH'
export const VUEX_API_USERS_FETCH_SUCCESS = 'VUEX_API_USERS_FETCH_SUCCESS'
export const VUEX_API_USERS_FETCH_FAILURE = 'VUEX_API_USERS_FETCH_FAILURE'

export const VUEX_API_USERS_AUTOCOMPLETE = 'VUEX_API_USERS_AUTOCOMPLETE'

export const VUEX_API_USERS_SET_CANCEL_TOKEN = 'VUEX_API_USERS_SET_CANCEL_TOKEN'

// User Flags API
// -------------------------------
export const VUEX_API_USER_FLAGS_UPDATE_SEEN_STATUS = 'VUEX_API_USER_FLAGS_UPDATE_SEEN_STATUS'

// Orders
// --------------------------------
export const VUEX_API_ORDER_FETCH = 'VUEX_API_ORDER_FETCH'
export const VUEX_API_ORDER_CREATE = 'VUEX_API_ORDER_CREATE'
export const VUEX_API_ORDER_DUPLICATE = 'VUEX_API_ORDER_DUPLICATE'
export const VUEX_API_ORDER_DELETE = 'VUEX_API_ORDER_DELETE'
export const VUEX_API_ORDERS_PRODUCTS_DELETE = 'VUEX_API_ORDERS_PRODUCTS_DELETE'
export const VUEX_API_ORDERS_REQUEST_FETCH = 'VUEX_API_ORDERS_REQUEST_FETCH'
export const VUEX_API_ORDER_BATCH_UPDATE = 'VUEX_API_ORDER_BATCH_UPDATE'
export const VUEX_API_ORDER_ADDRESS_LOOKUP = 'VUEX_API_ORDER_ADDRESS_LOOKUP'
export const VUEX_API_ORDER_ZIPCODE_LOOKUP = 'VUEX_API_ORDER_ZIPCODE_LOOKUP'
export const VUEX_API_ORDER_ADDRESS_VALIDATION = 'VUEX_API_ORDER_ADDRESS_VALIDATION'
export const VUEX_API_ORDER_ADD_COMMENT = 'VUEX_API_ORDER_ADD_COMMENT'
export const VUEX_API_ORDER_APPROVAL_HISTORY = 'VUEX_API_ORDER_APPROVAL_HISTORY'

export const VUEX_API_ORDERS_WOF_STYLES_FETCH = 'VUEX_API_ORDERS_WOF_STYLES_FETCH'
export const VUEX_API_ORDERS_WOF_STYLE_COLORS_FETCH = 'VUEX_API_ORDERS_WOF_STYLE_COLORS_FETCH'

export const VUEX_API_ORDERS_REQUEST_FETCH_SUCCESS = 'VUEX_API_ORDERS_REQUEST_FETCH_SUCCESS'
export const VUEX_API_ORDERS_REQUEST_FETCH_FAILED = 'VUEX_API_ORDERS_REQUEST_FETCH_FAILED'

export const VUEX_API_ORDERS_SET_CANCEL_TOKEN = 'VUEX_API_ORDERS_SET_CANCEL_TOKEN'
export const VUEX_API_ORDERS_DROP_PRODUCTS = 'VUEX_API_ORDERS_DROP_PRODUCTS'
export const VUEX_API_ORDER_EXCEL_EXPORT = 'VUEX_API_ORDER_EXCEL_EXPORT'


// Notifications
// -------------------------------
export const VUEX_API_NOTIFICATION_FETCH = 'VUEX_API_NOTIFICATION_FETCH'
export const VUEX_API_NOTIFICATIONS_FETCH = 'VUEX_API_NOTIFICATIONS_FETCH'
export const VUEX_API_NOTIFICATIONS_MARK_ALL_READ = 'VUEX_API_NOTIFICATIONS_MARK_ALL_READ'
export const VUEX_API_NOTIFICATIONS_BATCH_UPDATE = 'VUEX_API_NOTIFICATIONS_BATCH_UPDATE'

export const VUEX_API_NOTIFICATIONS_FETCH_SUCCESS = 'VUEX_API_NOTIFICATIONS_FETCH_SUCCESS'
export const VUEX_API_NOTIFICATIONS_FETCH_FAILED = 'VUEX_API_NOTIFICATIONS_FETCH_FAILED'

export const VUEX_API_NOTIFICATIONS_SET_CANCEL_TOKEN = 'VUEX_API_NOTIFICATIONS_SET_CANCEL_TOKEN'

// ASSIGNMENTS
// -------------------------------
export const VUEX_API_ASSIGNMENTS_REQUEST_FETCH_COORDINATORS = 'VUEX_API_ASSIGNMENTS_REQUEST_FETCH_COORDINATORS'
export const VUEX_API_ASSIGNMENTS_REQUEST_CREATE_COORDINATOR = 'VUEX_API_ASSIGNMENTS_REQUEST_CREATE_COORDINATOR'
export const VUEX_API_ASSIGNMENTS_REQUEST_UPDATE_COORDINATOR = 'VUEX_API_ASSIGNMENTS_REQUEST_UPDATE_COORDINATOR'
export const VUEX_API_ASSIGNMENTS_REQUEST_DELETE_ASSIGNMENT = 'VUEX_API_ASSIGNMENTS_REQUEST_DELETE_ASSIGNMENT'

export const VUEX_API_ASSIGNMENTS_REQUEST_FETCH_SUCCESS = 'VUEX_API_ASSIGNMENTS_REQUEST_FETCH_SUCCESS'
export const VUEX_API_ASSIGNMENTS_REQUEST_FETCH_FAILED = 'VUEX_API_ASSIGNMENTS_REQUEST_FETCH_FAILED'

export const VUEX_API_ASSIGNMENT_PATCH_REQUEST_STATUS = 'VUEX_API_ASSIGNMENT_PATCH_REQUEST_STATUS'

export const VUEX_API_ASSIGNMENTS_SET_CANCEL_TOKEN = 'VUEX_API_ASSIGNMENTS_SET_CANCEL_TOKEN'

// WOF (Passthrough)
// -------------------------------
export const VUEX_API_WOF_REQUEST_SUCCESS = 'VUEX_API_WOF_REQUEST_SUCCESS'
export const VUEX_API_WOF_REQUEST_FAILED = 'VUEX_API_WOF_CUSTOMER_DETAILS_REQUEST_FAILED'

export const VUEX_API_WOF_VALIDATE_REQUEST = 'VUEX_API_WOF_VALIDATE_REQUEST'
export const VUEX_API_WOF_VALIDATE_REQUEST_SUCCESS = 'VUEX_API_WOF_VALIDATE_REQUEST_SUCCESS'
export const VUEX_API_WOF_VALIDATE_REQUEST_FAILED = 'VUEX_API_WOF_VALIDATE_REQUEST_FAILED'

export const VUEX_API_WOF_CUSTOMER_DETAILS_REQUEST = 'VUEX_API_WOF_CUSTOMER_DETAILS_REQUEST'
export const VUEX_API_WOF_CUSTOMER_AUTOCOMPLETE_REQUEST = 'VUEX_API_WOF_CUSTOMER_AUTOCOMPLETE_REQUEST'

export const VUE_API_WOF_SET_CUSTOMER_LIST = 'VUE_API_WOF_SET_CUSTOMER_LIST'
export const VUEX_API_CUSTOMERS_SEARCH_REQUEST_CLEAR_LIST_WOF = 'VUEX_API_CUSTOMERS_SEARCH_REQUEST_CLEAR_LIST_WOF'

export const VUEX_API_WOF_SET_CANCEL_TOKEN = 'VUEX_API_WOF_SET_CANCEL_TOKEN'

export const VUEX_API_WOF_VALIDATE_REP = 'VUEX_API_WOF_VALIDATE_REP'


