<template>
  <div :cellComponentKey="cellComponentKey"  @focusout="closePopup" class="libraries-grid-working-file-renderer">

    <div v-if="hasWorkingFile" class="icon-holder">
      <v-tooltip
        top dark
        open-delay="100"
        nudge-top="-45"
        nudge-left="20"
        transition="scroll-y-transition"
        content-class="working-file-tooltip"
      >
        <template v-slot:activator="{ props }">
          <span v-bind="props" class="material-icons help">info</span>
        </template>
        <div><span class='working-file-tooltip' v-html="fileInfo"></span></div>
      </v-tooltip>
    </div>

    <div v-if="canDownload" class="icon-holder">
      <v-tooltip
        top
        dark
        open-delay="100"
        nudge-top="0"
        nudge-left="20"
        transition="scroll-y-transition"
        content-class="working-file-tooltip"
      >
        <template v-slot:activator="{ props }">
          <span v-bind="props" @click="downloadFile" class="material-icons">cloud_download</span>
        </template>
        <div><span class="working-file-tooltip">Download {{ mainLabel }}</span></div>
      </v-tooltip>
    </div>

    <div v-if="canMerge" class="icon-holder">
      <v-tooltip
        top
        dark
        open-delay="100"
        nudge-top="0"
        nudge-left="20"
        transition="scroll-y-transition"
        content-class="working-file-tooltip"
      >
        <template v-slot:activator="{ props }">
          <span v-bind="props" @click="mergeFile" class="material-icons">merge_type</span>
        </template>
        <div><span class="working-file-tooltip">Merge {{ mainLabel }} Into Primary Asset</span></div>
      </v-tooltip>
    </div>

    <div v-if="hasWorkingFile" class="icon-holder">
      <v-tooltip
        top
        dark
        open-delay="100"
        nudge-top="0"
        nudge-left="20"
        transition="scroll-y-transition"
        content-class="working-file-tooltip"
      >
        <template v-slot:activator="{ props }">
          <span v-bind="props" @click="deleteFile" class="material-icons">delete_forever</span>
        </template>
        <div><span class="working-file-tooltip">Delete {{ mainLabel }}</span></div>
      </v-tooltip>
    </div>
    <div v-else-if="paramsData._id"  class="icon-holder">
      <v-tooltip
        top
        dark
        open-delay="100"
        nudge-top="0"
        nudge-left="20"
        transition="scroll-y-transition"
        content-class="working-file-tooltip"
      >
        <template v-slot:activator="{ props }">
          <span v-bind="props" @click="uploadFile" class="material-icons">cloud_upload</span>
        </template>
        <div><span class="working-file-tooltip">Upload {{ mainLabel }}</span></div>
      </v-tooltip>
    </div>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import _cloneDeep from 'lodash.clonedeep'
import PrimaryWorkingFileHelper from '@/components/_core/GridsCore/helpers/PrimaryWorkingFileHelper'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import ColumnHelpers from '@/components/_core/GridsCore/helpers/ColumnHelpers'

import {
  VUEX_DIALOG_SHOW
} from '@/store/constants/ui/dialog'
import {
  VUEX_LIBRARIES_FILE_DELETE
} from '@/store/constants/models/libraries'

export default {
  name: 'WorkingFileRenderer',
  data () {
    return {
      cellComponentKey: '',
      fileChangedObjects: {},
      paramsData: {},
      mainLabel: 'Working File'
    }
  },
  watch: {
    assetFileChangedObjectsToggler: {
      deep: true,
      handler () {
        if (this.assetFileChangedObjects[this.cellComponentKey] && this.assetFileChangedObjects[this.cellComponentKey].deleted) {
          this.paramsData.workingFile = {}
        }
      }
    }
  },
  computed: {
    ...mapState({
      assetFileChangedObjects: state => state.libraries.assetFileChangedObjects,
      assetFileChangedObjectsToggler: state => state.libraries.assetFileChangedObjectsToggler
    }),
    hasWorkingFile () {
      return (this.assetFileChangedObjectsToggler && this.paramsData?.workingFile?._id)
    },
    canDownload () {
      return (this.assetFileChangedObjectsToggler && this.paramsData?.workingFile?.sourceFile)
    },
    canMerge () {
      return (
        this.assetFileChangedObjectsToggler &&
        this.paramsData &&
        (this.paramsData.workingFile && this.paramsData.workingFile.sourceFile) &&
        (!this.paramsData.primaryFile || (this.paramsData.primaryFile && !this.paramsData.primaryFile.sourceFile))
      )
    },
    fileInfo () {
      let ret = ''

      if (this.assetFileChangedObjectsToggler) {
        if (this.paramsData.workingFile.filename) {
          ret += '<b>' + this.paramsData.workingFile.filename + '</b>'
        } else if (this.paramsData.workingFile.sourceFile) {
          ret += '<b>' + this.paramsData.workingFile.sourceFile + '</b>'
        }
        if (this.paramsData.workingFile.fileSize) {
          ret += '<br><b>File Size: </b>' + ColumnHelpers.humanFileSize(this.paramsData.workingFile.fileSize)
        }
        if (this.paramsData.workingFile.createdDate) {
          ret += '<br><b>Date Added: </b>' + ColumnHelpers.formatterDateTime(this.paramsData.workingFile.createdDate)
        }
        if (this.paramsData.workingFile.addMethod) {
          ret += '<br><b>Method Added: </b>' + this.paramsData.workingFile.addMethod
        }
        if (this.paramsData.workingFile.recentDownloads) {
          ret += '<br><b>Recent Download Count: </b>' + this.paramsData.workingFile.recentDownloads
        }
        if (this.paramsData.workingFile.allDownloads) {
          ret += '<br><b>Total Download Count: </b>' + this.paramsData.workingFile.allDownloads
        }
        if (this.paramsData.workingFile.mimeType) {
          ret += '<br><b>File Type: </b>' + this.paramsData.workingFile.mimeType
        }
      }

      return ret
    }
  },
  methods: {
    ...mapActions({
      openDialog: VUEX_DIALOG_SHOW
    }),

    init (params) {

    },
    getGui (params) {

    },
    getValue (params) {
      if (this.paramsData.workingFile) {

      }
      // return this.picker;
    },

    closePopup () {
      // cell editor
      if (typeof this.params.stopEditing === 'function') {
        this.params.stopEditing()
      }
    },

    uploadFile () {
      this.openDialog({
        content: '_core/Dialogs/Libraries/Grids/Dialog_FileUpload.vue',
        title: 'Add ' + this.mainLabel,
        subtitle: 'Upload your new ' + this.mainLabel.toLowerCase() + ' here',
        data: {
          cellComponentKey: this.cellComponentKey,
          assetID: this.paramsData._id,
          assetData: this.paramsData,
          usageType: 'Working File'
        }
      })
    },

    downloadFile () {
      PrimaryWorkingFileHelper.downloadWorkingFile(this.paramsData)
    },

    deleteFile () {
      if (this.paramsData.workingFile.filename) {
        let payload = _cloneDeep(this.paramsData)
        // GLOBAL ACTION PROMPT: Pass messages & dispatch action
        let actionObj = {
          checkChangedFields: false,
          title: 'Are You Sure?',
          description: 'You are about to delete the working file for the "' + this.paramsData.name + '" asset. This cannot be undone.',
          dispatchAction: VUEX_LIBRARIES_FILE_DELETE,
          dispatchActionObject: {
            cellComponentKey: this.cellComponentKey,
            assetID: payload._id,
            fileIDs: [{ _id: payload.workingFile._id }]
          }
        }
        GridHelpers.mgThisArray[0].$store.dispatch('VUEX_GLOBAL_ACTION_PROMPT_SHOW', { actionObj: actionObj })
      }
    },

    mergeFile () {
      if (this.paramsData.workingFile.filename) {
        let payload = _cloneDeep(this.paramsData)
        payload.cellComponentKey = this.cellComponentKey
        GridHelpers.popupMergeWorkingFile(payload)
      }
    }

  },
  created () {
    if (this.params.data) {
      if (!this.params.data.cellComponentKeyWorking) {
        this.params.data.cellComponentKeyWorking = 'key-' + Date.now()
      }
      if (this.params.mainLabel) {
        this.mainLabel = this.params.mainLabel
      }
      this.cellComponentKey = this.params.data.cellComponentKeyWorking
      this.paramsData = _cloneDeep(this.params.data)
    }
  },
  mounted () {

  }
}
</script>
