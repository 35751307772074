import feConstants from '@/store/constants/config/fe_constants'

export default {
  modules: {
    toolbar: {
      title: 'Orders',
      extension: {
        navigation: []
      },
      subnav: [
        {
          title: 'Sample Orders',
          name: 'orders--sample-orders--active-orders',
          accessRequires: [
            {
              permission: feConstants.ITS__PERMISSION__ORDERS__SAMPLE_ORDERS
            }
          ],
          subRoutes: [
            {
              title: 'Sample Orders',
              name: 'orders--sample-orders--active-orders',
              accessRequires: [
                {
                  permission: feConstants.ITS__PERMISSION__ORDERS__SAMPLE_ORDERS
                }
              ]
            },
            {
              title: 'Manage Assignments',
              name: 'orders--manage-assignments',
              accessRequires: [
                {
                  permission: feConstants.ITS__PERMISSION__ORDERS__SAMPLE_ORDERS,
                  roles: [feConstants.ITS__ROLE__SENIOR_COORDINATOR]
                }
              ]
            }
          ],
          match: [
            'orders--sample-orders--sample-styles',
            'orders--sample-orders--active-orders',
            'orders--sample-orders--archived-orders',
            'orders--sample-orders--details',
            'orders--manage-assignments'
          ]
        },
        {
          title: 'Promo Orders',
          name: 'orders--promo-orders--needs-review',
          accessRequires: [
            {
              permission: feConstants.ITS__PERMISSION__ORDERS__PROMO_ORDERS
            }
          ],
          match: [
            'orders--promo-orders--needs-review',
            'orders--promo-orders--reviewed',
            'orders--promo-orders--archived',
            'orders--promo-orders--details'
          ]
        },
        {
          title: 'Wholesale Orders',
          name: 'orders--wholesale-orders--draft-orders',
          accessRequires: [
            {
              permission: feConstants.ITS__PERMISSION__ORDERS__WHOLESALE_ORDERS
            }
          ],
          match: [
            'orders--wholesale-orders--draft-orders',
            'orders--wholesale-orders--details'
          ]
        }
      ]
    }
  }
}
