import i18next from "i18next"

import {
  VUEX_LOCALSTORAGE_DATA_SET
} from '@/store/constants/localStorage'

import {
  VUEX_LOCALIZATION_CHANGE_LANG
} from '@/store/constants/localization'

const state = {
  currentLang: 'en-US',
  fallbackLang: 'en-US'
}
const getters = {}
const actions = {
  [VUEX_LOCALIZATION_CHANGE_LANG]: ({ commit, dispatch }, langCode) => {
    i18next.changeLanguage(langCode)
    commit(VUEX_LOCALIZATION_CHANGE_LANG, langCode)
    dispatch(
      VUEX_LOCALSTORAGE_DATA_SET,
      { path: 'localization.language', value: langCode }
    )
  }
}
const mutations = {
  [VUEX_LOCALIZATION_CHANGE_LANG]: (state, langCode) => {
    state.currentLang = langCode
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
