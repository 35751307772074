<template>
  <v-select
    :clearable="clearable"
    :color="color"
    :disabled="disabled"
    :items="items"
    :item-title="itemTitle"
    :item-value="itemValue"
    :label="label"
    :model-value="modelValue"
    :multiple="multiple"
    :rules="rules"
    :return-object="returnObject"
    @update:modelValue="update"
  ></v-select>
</template>

<script>
export default {
  props: {
    appendIcon: {
      type: String
    },
    clearable: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean
    },
    hideDetails: {
      type: Boolean
    },
    items: {
      type: Object
    },
    itemTitle: {
      type: String,
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    label: {
      type: String
    },
    modelValue: {
      type: [String, Object]
    },
    multiple: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    prependIcon: {
      type: String
    },
    prependInnerIcon: {
      type: String
    },
    readonly: {
      type: Boolean
    },
    rules: {
      type: Object
    },
    type: {
      type: String,
      default: 'text'
    },
    variant: {
      type: String,
      default: 'filled'
    },
    returnObject: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    value () {
      return this.modelValue
    }
  },

  emits: ['update:modelValue'],

  methods: {
    update (val) {
      this.$emit('update:modelValue', val)
    }
  }
}
</script>
