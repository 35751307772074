<template>
  <div class="custom-tooltip-vue">
    <p v-if="this.params.tooltip">{{ this.params.tooltip }}</p>
    <p v-else><span>Source File: </span>{{ this.params.value }}</p>
  </div>
</template>
<script>
export default {
  name: 'CustomTooltip',
  data () {
    return {
      color: null,
      type: null,
      sourceFile: ''
    }
  },
  beforeMount () {
    var data = this.params.api.getDisplayedRowAtIndex(this.params.rowIndex).data
    data.color = this.params.color || 'white'
    this.setState(data)
  },
  mounted () {
    this.$el.style['background-color'] = this.color
  },
  methods: {
    setState (obj) {
      Object.assign(this, obj)
    }
  }
}
</script>
