<template>
  <div v-show="enableTooltip" class="custom-tooltip-vue">
    <p>{{ this.params.optionMessage }}</p>
  </div>
</template>
<script>
export default {
  name: 'GenericTooltip',
  data () {
    return {
      optionColor: null,
      type: null,
      sourceFile: ''
    }
  },
  beforeMount () {
    var data = this.params.api.getDisplayedRowAtIndex(this.params.rowIndex).data
    this.optionColor = (this.params.optionColor) ? this.params.optionColor : '#ececec'
    this.setState(data)
  },
  mounted () {
    if (this.$el) {
      this.$el.style['background-color'] = this.optionColor
    }
  },
  computed: {
    enableTooltip () {
      // a special check if product is deleted
      if (this.params.optionSpecialProductDeletedCheck) {
        return !this.createdDate
      } else {
        return true
      }
    }
  },
  methods: {
    setState (obj) {
      Object.assign(this, obj)
    }
  }
}
</script>
