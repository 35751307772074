import _merge from 'lodash.merge'
import store from '@/store/store'

const today = new Date()
today.setFullYear(today.getFullYear() - 1)
const oneYearAgo = today.toISOString().slice(0, 10)

export default {
  getRequestObj ({ manageType, role, userCode }) {
    // -------------------------------------
    // Variables
    // -------------------------------------
    // base options
    const baseOptions = {
      sort: { orderNumber: -1 },
      finalProject: {
        approverName: 1,
        buyer: 1,
        completedDate: 1,
        createdDate: 1,
        deliveryMethod: 1,
        orderApprovalStatus: 1,
        orderIdentifier: 1,
        orderNumber: 1,
        productType: 1,
        reason: 1,
        requestedDeliveryDate: 1,
        requesterName: 1,
        seniorApproverName: 1,
        status: 1,
        submittedDate: 1,
      }
    }

    const wholesaleBaseOptions = {
      sort: { orderNumber: -1 },
      finalProject: {
        type: 1,
        customer: 1,
        purpose: 1,
        account: 1,
        po: 1,
        buyer: 1,
        origin: 1,
        region: 1,
        shippingMethod: 1,
        status: 1,
        createdDate: 1,
        modifiedDate: 1,
        orderNumber: 1,
        repNumber: 1,
        repNumber2: 1,
        unavailableSkus: 1,
        // these below are not used in the grid display, but needed when duplicating order
        emailCC: 1,
        instructions: 1,
        phone: 1,
        stores: 1
      }
    }

    const sampleBaseOptions = {
      sort: { 'products.status': 1 },
      finalProject: {
        orderIdentifier: 1,
        requestedDeliveryDate: 1,
        productType: 1,
        orderApprovalStatus: 1
      }
    }
    // -------------------------------------
    // -------------------------------------




    // -------------------------------------
    // Permission
    // -------------------------------------
    const approverCodeKey = role === ITS__ROLE__SENIOR_APPROVER
      ? 'seniorApproverCode'
      : role === ITS__ROLE__APPROVER
        ? 'approverCode'
        : null

    const isSampleCoordinator = store.getters.isSampleCoordinator().hasPerm
    const isSeniorSampleCoordinator = store.getters.isSeniorSampleCoordinator().hasPerm
    // -------------------------------------
    // -------------------------------------




    // -------------------------------------
    // manageType specfic requests
    // -------------------------------------
    let reqObj = {}
    switch (manageType) {
      //////////////////////////////////////////////
      /////// PROMO REQUESTS ///////
      //////////////////////////////////////////////
      // PROMO | Needs Review
      case ITS__ORDERS__PROMO__NEEDS_REVIEW :
        if (role === ITS__ROLE__APPROVER ||
            role === ITS__ROLE__SENIOR_APPROVER) {
          reqObj = {
            [approverCodeKey]: userCode,
            '$or': [
              {
                requesterCode: userCode,
                status: { '$in': ['DRAFT', 'SUBMITTED', 'IN PROGRESS'] },
                orderApprovalStatus: { '$in': role === ITS__ROLE__APPROVER
                                      ? ['PENDING', 'REQUIRES SENIOR']
                                      : ['PENDING'] }
              },
              {
                requesterCode: { '$ne': userCode },
                status: { '$in': ['SUBMITTED', 'IN PROGRESS'] },
                orderApprovalStatus: { '$in': role === ITS__ROLE__APPROVER
                                      ? ['REQUIRES APPROVER']
                                      : ['REQUIRES SENIOR'] }
              }
            ],
            _options: baseOptions
          }

          if (approverCodeKey) reqObj[approverCodeKey] = userCode
          return reqObj
        } else {
          return {
            status: { '$in': ['DRAFT', 'SUBMITTED', 'IN PROGRESS'] },
            orderApprovalStatus: { '$in': ['PENDING', 'REQUIRES APPROVER', 'REQUIRES SENIOR'] },
            _options: baseOptions
          }
        }

      // PROMO | Reviewed
      case ITS__ORDERS__PROMO__REVIEWED :
        reqObj = {
          status: { '$in': ['SUBMITTED', 'IN PROGRESS', 'UNAPPROVED ORDER', 'CANCELED'] },
          orderApprovalStatus: { '$in': role === ITS__ROLE__APPROVER
            ? ['REQUIRES SENIOR', 'REVIEW COMPLETE', 'PROCESSED', 'FINALIZED']
            : ['REVIEW COMPLETE', 'PROCESSED', 'FINALIZED'] },
         _options: baseOptions
        }

        if (approverCodeKey) reqObj[approverCodeKey] = userCode
        return reqObj

      // PROMO | Archived
      case ITS__ORDERS__PROMO__ARCHIVED :
        reqObj = {
          status: { '$in': ['COMPLETED', 'ARCHIVED', 'INACTIVE'] },
          createdDate: { '$gte': oneYearAgo },
         _options: baseOptions
        }

        if (approverCodeKey) reqObj[approverCodeKey] = userCode
        return reqObj




      //////////////////////////////////////////////
      /////// SAMPLE REQUESTS ///////
      //////////////////////////////////////////////
      // SAMPLE | Archived
      case ITS__ORDERS__SAMPLE__SAMPLE_STYLES :
        reqObj = {
          status: ['SUBMITTED', 'IN PROGRESS'],
          _options: _merge(baseOptions, {
            ...sampleBaseOptions,
            productLevel: true
          }, {
            finalProject: {
              products: 1
            }
          })
        }

        return reqObj
        // SAMPLE | Archived
      case ITS__ORDERS__SAMPLE__ACTIVE_ORDERS :
        reqObj = {
          status: !isSampleCoordinator && !isSeniorSampleCoordinator ? ['DRAFT', 'SUBMITTED', 'IN PROGRESS'] : ['SUBMITTED', 'IN PROGRESS'],
          _options: _merge(baseOptions, sampleBaseOptions)
        }

        return reqObj
        // SAMPLE | Archived
      case ITS__ORDERS__SAMPLE__ARCHIVED_ORDERS :
        reqObj = {
          status: ['COMPLETED', 'ARCHIVED', 'INACTIVE'],
          createdDate: { '$gte': oneYearAgo },
          _options: _merge(baseOptions, sampleBaseOptions)
        }

        return reqObj




      //////////////////////////////////////////////
      /////// WHOLESALE REQUESTS ///////
      //////////////////////////////////////////////
      // WHOLESALE | Drafts
      case ITS__ORDERS__WHOLESALE__DRAFT_ORDERS :
        reqObj = {
          status: ['DRAFT', 'SUBMITTED', 'INACTIVE'],
          _options: _merge(wholesaleBaseOptions, { /* viewAsCoordinatorCode: userCode */ })
        }

        // extras
        reqObj._options.finalProject.account = 1
        reqObj._options.finalProject.shippingMethod = 1
        reqObj._options.finalProject.po = 1
        reqObj._options.finalProject.region = 1
        reqObj._options.finalProject.origin = 1

        return reqObj

      // WHOLESALE | Details
      case ITS__ORDERS__WHOLESALE__DETAIL :
        reqObj = {
          _options: _merge(wholesaleBaseOptions, {
            finalProject: {
              orderIdentifier: 1,
              skus: 1,
              stores: 1,
              emailCC: 1,
              instructions: 1,
              phone: 1,
              hardCopy: 1
            }
          })
        }

        return reqObj




      //////////////////////////////////////////////
      /////// DEFAULT ///////
      //////////////////////////////////////////////
      default :
        console.error(`requestHelper | ${manageType} | manageType not configured`)
        return null
    }
  }
}
