function LargeTextEditor () {}
LargeTextEditor.prototype.init = function (params) {
  let t = this
  this.value = params.value
  if (params.eventKey) {
    this.value = params.eventKey
  }
  if (!this.value) {
    this.value = ''
  }

  let maxWidth = (params.maxWidth) ? params.maxWidth : 130

  // make container
  this.container = document.createElement('div')
  this.container.className = 'large-text-editor'

  // add textarea field
  this.eTextarea = document.createElement('textarea')
  this.eTextarea.innerHTML = this.value


  this.eTextarea.setAttribute('maxlength', maxWidth)
  this.eTextarea.id = 'largeTextEditorID'
  this.container.appendChild(this.eTextarea)

  let footer = document.createElement('div')
  footer.className = 'indicator-footer'
  this.container.appendChild(footer)

  // add desc
  let desc = document.createElement('div')
  desc.className = 'indicator-description'
  // desc.innerHTML = "New Line: Shift+Enter"
  footer.appendChild(desc)

  // add indicator
  let initialLength = this.value.toString().length
  let indicator = document.createElement('div')
  indicator.className = 'indicator'
  indicator.innerHTML = `<span id='indicator-txt-current' class='indicator-txt indicator-txt-current'>${initialLength}</span><span class='indicator-txt indicator-slash'> / </span><span class='indicator-txt indicator-txt-total'>${maxWidth}</span>`
  this.eTextarea.addEventListener('keyup', function (event) {
    let val = t.eTextarea.value.toString()
    let obj = document.getElementById('indicator-txt-current')
    obj.innerHTML = val.length
  })
  /*
  this.eTextarea.addEventListener('keydown', function (event) {
    let key = event.which || event.keyCode;
    if (event.shiftKey && event.code==="Enter") { // shift+enter allows for newlines
      event.stopPropagation()
    }
  }.bind(t))
   */
  footer.appendChild(indicator)

  // mouse leave cancel
  this.container.addEventListener('mouseleave', params.stopEditing)
}
LargeTextEditor.prototype.getGui = function () {
  return this.container
}
LargeTextEditor.prototype.afterGuiAttached = function () {
  // focus on text field
  let obj = document.getElementById('largeTextEditorID')
  obj.selectionStart = obj.selectionEnd = 10000
  obj.focus()
}
LargeTextEditor.prototype.getValue = function () {
  return this.eTextarea.value
}
LargeTextEditor.prototype.destroy = function () {

}
LargeTextEditor.prototype.isPopup = function () {
  return true
}

export default LargeTextEditor
