export const VUEX_PROPERTIES_UPDATE = 'VUEX_PROPERTIES_UPDATE'
export const VUEX_PROPERTIES_PARTIAL_UPDATE = 'VUEX_PROPERTIES_PARTIAL_UPDATE'
export const VUEX_PROPERTIES_ARRAY_UPDATE = 'VUEX_PROPERTIES_ARRAY_UPDATE'
export const VUEX_PROPERTIES_INITIALIZED = 'VUEX_PROPERTIES_INITIALIZED'

/**
 * Use this to look up property names and options etc.
 * options contains options data for use in selects, etc.
 *
 * Note! "savesToDb" indicates whether a field saves to the DB for an assortment
 * Do not change this value or add it with value true to other fields!
 *
 * "editable" is used by the grid only
 *
 * Note! All properties referenced by components should exist here so that Vue reactivity works automatically
 * when we update with data from the backend.
 *
 * @const
 */
export const properties = {
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  Product: {
    shortLabel: 'Product',
    label: 'Product',
    properties: {
      // BaseProduct properties
      createdDate: {
        shortLabel: 'Cr. Date',
        label: 'Created Date',
        editable: false
      },
      style: {
        shortLabel: 'Style',
        label: 'Style ID',
        editable: false,
        savesToDb: true
      },

      image: {
        shortLabel: 'Img',
        label: 'Image',
        editable: false
      },

      thumbnail: {
        shortLabel: 'Thumb',
        label: 'Thumbnail',
        editable: true,
        savesToDb: true
      },
      printImage: {
        shortLabel: 'Print Img',
        label: 'Print Image',
        editable: true,
        savesToDb: true
      },
      name: {
        shortLabel: 'Name',
        label: 'Name',
        editable: false
      },
      sizeScale: {
        shortLabel: 'Sz. Scale',
        label: 'Size Scale',
        editable: false
      },
      outsole: {
        shortLabel: 'Outsole',
        label: 'Outsole',
        editable: false
      },
      upper: {
        shortLabel: 'Upper',
        label: 'Upper',
        editable: false
      },
      division: {
        shortLabel: 'Division',
        label: 'Division',
        editable: false,
        options: []
      },
      gender: {
        shortLabel: 'Gender',
        label: 'Gender',
        editable: false,
        options: []
      },
      description: {
        shortLabel: 'Description',
        label: 'Style Description',
        editable: false
      },
      features: {
        shortLabel: 'Features',
        label: 'Features',
        editable: false
      },
      color: {
        shortLabel: 'Color',
        label: 'Color ID',
        editable: false,
        savesToDb: true
      },
      nrf: {
        shortLabel: 'NRF',
        label: 'NRF Code',
        editable: false
      },
      vegan: {
        shortLabel: 'Vegan',
        label: 'Vegan',
        editable: false
      },
      colorDescription: {
        shortLabel: 'Color Description',
        label: 'Color Description',
        editable: false
      },
      inOrders: {
        shortLabel: 'In Sample Orders',
        label: 'In Sample Orders',
        editable: false
      },
      selected: {
        shortLabel: 'Selected',
        label: 'Selected',
        editable: false,
        options: []
      },

      // updated in database
      updatedDate: {
        shortLabel: 'Updated Date',
        label: 'Product Data Updated Date',
        editable: false
      },
      // modified in an assortment
      modifiedDate: {
        shortLabel: 'Mod. Date',
        label: 'Assortment Product Modified Date',
        editable: false
      },
      // Product properties
      seasonCodes: {
        shortLabel: 'Season',
        label: 'Season Codes',
        editable: false,
        options: []
      },
      firstCost: {
        shortLabel: '1st Cost',
        label: 'First Cost',
        editable: false
      },
      boxCategory: {
        shortLabel: 'Box Category',
        label: 'Box Category',
        editable: false
      },

      dutyCode: {
        shortLabel: 'Duty Code',
        label: 'Duty Code',
        editable: false
      },

      // colorPercent - front end calculated column only - no back end
      colorPercent: {
        shortLabel: 'Color %',
        label: 'Color %',
        editable: false,
        definition: 'Color Quantity / Style Quantity'
      },

      // POP Product properties
      units: {
        shortLabel: 'Qty Per Pack',
        label: 'Quantity Per Pack',
        editable: false
      },
      capacity: {
        shortLabel: 'Capacity',
        label: 'Capacity',
        editable: false
      },
      capacityUnit: {
        shortLabel: 'Capacity',
        label: 'Capacity',
        editable: false
      },
      dimensions: {
        shortLabel: 'Dimensions',
        label: 'Dimensions',
        editable: false
      },
      metricDimensions: {
        shortLabel: 'Dimensions',
        label: 'Dimensions',
        editable: false
      },
      expirationDate: {
        shortLabel: 'Exp. Date',
        label: 'Expiration Date',
        editable: false
      },
      popCategory: {
        shortLabel: 'Category',
        label: 'Category',
        editable: false,
        options: []
      },
      popType: {
        shortLabel: 'Type',
        label: 'Type',
        editable: false,
        options: []
      },
      popGender: {
        shortLabel: 'Gender',
        label: 'Gender',
        editable: false,
        options: []
      },
      relationships: {
        shortLabel: 'Order With',
        label: 'Order With',
        editable: false
      },
      usage: {
        shortLabel: 'Usage',
        label: 'Usage',
        editable: false,
        options: []
      },
      materials: {
        shortLabel: 'Materials',
        label: 'Materials',
        editable: false,
        options: []
      },

      // Assortment product properties
      styleNote: {
        shortLabel: 'Style Note',
        label: 'Style Note',
        editable: true,
        savesToDb: true
      },
      colorNote: {
        shortLabel: 'Color Note',
        label: 'Color Note',
        editable: true,
        savesToDb: true
      },
      collectionStyleNote: {
        shortLabel: 'Viewer Style Note',
        label: 'Viewer Style Note',
        editable: true,
        savesToDb: true
      },
      collectionColorNote: {
        shortLabel: 'Viewer Color Note',
        label: 'Viewer Color Note',
        editable: true,
        savesToDb: true
      },
      label: {
        shortLabel: 'Label',
        label: 'Label',
        editable: true,
        savesToDb: true
      },
      cost: {
        shortLabel: 'Cost',
        label: 'Cost',
        editable: true,
        savesToDb: true
      },
      netCost: {
        shortLabel: 'Net Cost',
        label: 'Net Cost',
        editable: true,
        savesToDb: true,
        definition: 'Cost - Discount Amount'
      },
      suggestedRetail: {
        shortLabel: 'Sugg. Retail',
        label: 'Suggested Retail',
        editable: true,
        savesToDb: true
      },
      discountPercent: {
        shortLabel: 'Disc. %',
        label: 'Discount %',
        editable: true
      },
      discountAmount: {
        shortLabel: 'Disc. Amt.',
        label: 'Discount Amount',
        editable: true
      },
      quantity: {
        shortLabel: 'Quantity',
        label: 'Quantity',
        editable: true,
        savesToDb: true
      },
      quantityOnHand: {
        shortLabel: 'On Hand',
        label: 'On Hand',
        editable: true,
        savesToDb: true
      },

      // styleQuantity - front end calculated column only - no back end
      styleQuantity: {
        shortLabel: 'Style Quantity',
        label: 'Style Quantity',
        editable: false
      },
      // styleQuantityOnHand - front end calculated column only - no back end
      styleQuantityOnHand: {
        shortLabel: 'Style On Hand',
        label: 'Style On Hand',
        editable: false
      },

      initialMarkup: {
        shortLabel: 'Init. Markup',
        label: 'Initial Markup',
        editable: false
      },
      // styleSellThru - front end calculated column only - no back end
      styleSellThru: {
        shortLabel: 'Style Sell Thru',
        label: 'Style Sell Thru',
        editable: false,
        definition: 'Style Quantity / (Style Quantity + Style On Hand)'
      },
      sellThru: {
        shortLabel: 'Sell Thru',
        label: 'Sell Thru',
        editable: false,
        definition: 'Quantity / (Quantity + On Hand)'
      },
      percentage: {
        shortLabel: 'Percentage',
        label: 'Percentage',
        editable: false
      },

      /// ///////////////////////////////////////////////////////////////
      /// NESTED
      categories: {
        properties: {
          type: {
            shortLabel: 'Cat. Type',
            label: 'Category Type',
            editable: false,
            options: []
          },
          shape: {
            shortLabel: 'Cat. Shape',
            label: 'Category Shape',
            editable: false,
            options: []
          },
          height: {
            shortLabel: 'Cat. Height',
            label: 'Category Height',
            editable: false,
            options: []
          },
          closure: {
            shortLabel: 'Cat. Closure',
            label: 'Category Closure',
            editable: false,
            options: []
          },
          sport: {
            shortLabel: 'Cat. Sport',
            label: 'Category Sport',
            editable: false,
            options: []
          },
          lighted: {
            shortLabel: 'Cat. Lighted',
            label: 'Category Lighted',
            editable: false,
            options: []
          },
          vegan: {
            shortLabel: 'Vegan',
            label: 'Vegan',
            editable: false,
            options: []
          },
          apparelType: {
            shortLabel: 'Apparel Type',
            label: 'Apparel Type',
            editable: false,
            options: []
          },
          apparelSubType: {
            shortLabel: 'Apparel Subtype',
            label: 'Apparel Subtype',
            editable: false,
            options: []
          }
        }
      },
      eCommerce: {
        properties: {
          description: {
            shortLabel: 'Ecom. Desc.',
            label: 'E-commerce Description',
            editable: false
          },
          details: {
            shortLabel: 'Ecom. Details',
            label: 'E-commerce Details',
            editable: false

          },
          construction: {
            shortLabel: 'Ecom. Constr.',
            label: 'E-commerce Construction',
            editable: false
          }
        }
      },
      components: {
        properties: {
          color1: {
            shortLabel: 'Pr. Color',
            label: 'Primary Color',
            editable: false,
            options: []
          },
          color2: {
            shortLabel: 'Sec. Color',
            label: 'Secondary Color',
            editable: false,
            options: []
          },
          color3: {
            shortLabel: 'Ter. Color',
            label: 'Tertiary Color',
            editable: false,
            options: []
          },
          material1: {
            shortLabel: 'Pr. Material',
            label: 'Primary Material',
            editable: false,
            options: []
          },
          material2: {
            shortLabel: 'Sec. Material',
            label: 'Secondary Material',
            editable: false,
            options: []
          },
          material3: {
            shortLabel: 'Ter. Material',
            label: 'Tertiary Material',
            editable: false,
            options: []
          }
        }
      },
      locations: {
        properties: {
          code: {
            shortLabel: 'Location',
            label: 'Pricing Location',
            editable: false,
            options: []
          },
          lineStatus: {
            shortLabel: 'Status',
            label: 'Line Status',
            editable: false,
            options: []
          },
          sizeRange: {
            shortLabel: 'Sz. Range',
            label: 'Size Range',
            editable: false
          },
          wholesalePrice: {
            shortLabel: 'Cost',
            label: 'Cost',
            editable: false
          },
          originalCost: {
            shortLabel: 'Orig. Cost',
            label: 'Original Cost',
            editable: false
          },
          suggestedRetail: {
            shortLabel: 'Sugg. Retail',
            label: 'Suggested Retail',
            editable: false
          },
          markupPercent: {
            shortLabel: 'Init. Markup',
            label: 'Initial Markup',
            editable: true,
            definition: '(SuggestedRetail - NetCost) / SuggestedRetail'
          },
          statusDate: {
            shortLabel: 'Status Date',
            label: 'Status Date',
            editable: false
          },
          lastProdDate: {
            shortLabel: 'Last Prod.',
            label: 'Last Produced Date',
            editable: false
          },
          styleLastProdDate: {
            shortLabel: 'Last Prod.',
            label: 'Last Produced Date',
            editable: false
          },
          addDate: {
            shortLabel: 'Add Date',
            label: 'Add Date',
            editable: false
          },
          firstAvailDate: {
            shortLabel: 'First Avail.',
            label: 'First Availability Date',
            editable: false
          },
          totalInventory: {
            shortLabel: 'Inventory',
            label: 'Inventory',
            editable: false
          },
          availability: {
            shortLabel: 'Availability',
            label: 'Availability',
            editable: false,
            properties: {
              inventory: {
                shortLabel: 'Inventory',
                label: 'Inventory',
                editable: false
              },
              size: {
                shortLabel: 'Size',
                label: 'Size',
                editable: false
              },
              date: {
                shortLabel: 'Date',
                label: 'Date',
                editable: false
              },
              upc: {
                shortLabel: 'UPC',
                label: 'UPC',
                editable: false
              }
            }
          }
        }
      },
      // Added with Key Initiatives
      pillar: {
        shortLabel: 'Pillar',
        label: 'Pillar',
        editable: true
      },
      lifeCycle: {
        shortLabel: 'Lifecycle',
        label: 'Lifecycle',
        editable: true
      },
      dueDate: {
        shortLabel: 'Due Date',
        label: 'Due Date',
        editable: true
      },
      usaDc: {
        shortLabel: 'USA DC',
        label: 'USA DC',
        editable: false
      },
      boughtStatus: {
        shortLabel: 'Bought Status',
        label: 'Bought Status',
        editable: false
      },
      samples: {
        shortLabel: 'Samples',
        label: 'Samples',
        editable: true
      },
      rushSamples: {
        shortLabel: 'Rush Samples',
        label: 'Rush Samples',
        editable: true
      },
      newColor: {
        shortLabel: 'New Color',
        label: 'New Color',
        editable: false
      },
      carryover: {
        shortLabel: 'Carryover',
        label: 'Carryover',
        editable: false
      },
      focusStyle: {
        shortLabel: 'Focus Style',
        label: 'Focus Style',
        editable: true
      },

      storeType: {
        shortLabel: 'Store Type',
        label: 'Store Type',
        editable: true
      }

    }// properties
  }, // Product

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  Assortment: {
    properties: {
      title: {
        shortLabel: 'Title',
        label: 'Title',
        editable: false,
        savesToDb: true
      },
      subTitle: {
        shortLabel: 'Subtitle',
        label: 'Customer/Subtitle',
        options: [],
        savesToDb: true
      },
      productType: {
        shortLabel: 'Prod. Type',
        label: 'Product Type',
        editable: false,
        options: [],
        savesToDb: true
      },
      logo: {
        shortLabel: 'Logo',
        label: 'Logo',
        editable: false,
        savesToDb: true
      },
      // only a single user can star their own assortments so can be stored here
      starred: {
        shortLabel: 'Starred',
        label: 'Starred',
        editable: false,
        savesToDb: true
      },
      archived: {
        shortLabel: 'Archived',
        label: 'Archived',
        editable: false,
        savesToDb: true
      },
      locationId: {
        shortLabel: 'Location',
        label: 'Pricing Location',
        editable: false,
        savesToDb: true
        // lookup in product.properties.locations.code.options
      },
      status: {
        shortLabel: 'Status',
        label: 'Assortment Status',
        editable: false,
        savesToDb: true
        // lookup in product.properties.locations.lineStatus.options
      },
      createdDate: {
        shortLabel: 'Cr. Date',
        label: 'Created Date',
        editable: false,
        savesToDb: true
      },
      modifiedDate: {
        shortLabel: 'Mod. Date',
        label: 'Modified Date',
        editable: false,
        savesToDb: true
      },
      // TODO: remove folder and references to it and replace with hierarchy
      folder: {
        shortLabel: 'Folder',
        label: 'Assortment Folder',
        editable: false,
        savesToDb: true
      },
      // folder nesting for user with ag-grid
      hierarchy: {
        shortLabel: 'Folder',
        label: 'Assortment Folder',
        editable: false,
        savesToDb: true
      },
      labels: {
        shortLabel: 'Labels',
        label: 'Labels',
        editable: false,
        savesToDb: true
      },
      // For dynamic assortments
      query: {
        shortLabel: 'Search',
        label: 'Search Conditions',
        editable: false,
        savesToDb: true
      },
      // For static assortments
      products: {
        savesToDb: true
      },
      alerts: {
        savesToDb: true
      },
      // not exposed to user
      uiSettings: {
        gridSettings: {
          properties: {
            sortOrder: {
              savesToDb: true
            },
            groupIsEnabled: {
              savesToDb: true
            }
          }
        },
        properties: {
          filterBar: {
            savesToDb: true
          }
        }
      }
    }
  },

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  AssortmentDocument: {
    properties: {
      documentType: {
        properties: {
          type: {
            label: 'Document Type',
            shortLabel: 'Doc. Type',
            editable: false,
            savesToDb: true
          },
          subType: {
            label: 'Document Subtype',
            shortLabel: 'Doc. Subtype',
            editable: false,
            savesToDb: true
          }
        },
        options: [
          { type: 'Linesheet', subType: 'Condensed' },
          { type: 'Linesheet', subType: 'Expanded' },
          { type: 'Pricesheet', subType: 'Condensed' },
          { type: 'Pricesheet', subType: 'Expanded' },
          { type: 'Tearsheet', subType: 'With Hero Image' },
          { type: 'Tearsheet', subType: 'Thumbnails Only' }
        ]
      },
      settings: {
        label: 'Document Settings',
        shortLabel: 'Doc. Settings',
        editable: false,
        savesToDb: true,
        // Look up property names for these settings from product object above
        properties: {
          // Primary Settings
          cost: {
            shortLabel: 'Cost',
            label: 'Cost',
            editable: true,
            savesToDb: true
          },
          originalCost: {
            shortLabel: 'Orig. Cost',
            label: 'Original Cost',
            editable: false,
            savesToDb: true
          },
          suggestedRetail: {
            shortLabel: 'Sugg. Retail',
            label: 'Suggested Retail',
            editable: true,
            savesToDb: true
          },
          netCost: {
            shortLabel: 'Net Cost',
            label: 'Net Cost',
            editable: true,
            savesToDb: true
          },
          initialMarkup: {
            shortLabel: 'Init. Markup',
            label: 'Initial Markup',
            editable: false,
            savesToDb: true
          },
          colorSellThru: {
            shortLabel: 'Sell Thru',
            label: 'Sell Thru',
            editable: false,
            savesToDb: true
          },
          percentage: {
            shortLabel: 'Percentage',
            label: 'Percentage',
            editable: false,
            savesToDb: true
          },
          quantity: {
            shortLabel: 'Quantity',
            label: 'Quantity',
            editable: true,
            savesToDb: true
          },
          availability: {
            shortLabel: 'Availability',
            label: 'Availability',
            editable: false,
            savesToDb: true
          },
          inventory: {
            shortLabel: 'Inventory',
            label: 'Inventory',
            editable: false,
            savesToDb: true
          },
          futureWholesalePrice: {
            shortLabel: 'Future Cost',
            label: 'Future Cost',
            editable: false,
            saveToDb: true
          },
          futureSuggestedRetail: {
            shortLabel: 'Future Sugg. RT',
            label: 'Future Sugg. RT',
            editable: false,
            saveToDb: true
          },
          futureEffectiveDate: {
            shortLabel: 'Future Eff. Date',
            label: 'Future Eff. Date.',
            editable: false,
            saveToDb: true
          },
          colorLastProdDate: {
            shortLabel: 'Last Prod.',
            label: 'Last Produced Date',
            editable: false,
            savesToDb: true
          },
          // Secondary Settings
          division: {
            shortLabel: 'Division',
            label: 'Division',
            editable: false,
            savesToDb: true
          },
          pillar: {
            shortLabel: 'Pillar',
            label: 'Pillar',
            editable: false,
            savesToDb: true
          },
          marketingDescription: {
            shortLabel: 'Marketing Description',
            label: 'Marketing Description',
            editable: false,
            savesToDb: true
          },
          width: {
            shortLabel: 'Width',
            label: 'Width',
            editable: false,
            savesToDb: true
          },
          otherSpecialWidths: {
            shortLabel: 'Width',
            label: 'Other Special Widths',
            editable: false,
            savesToDb: true
          },
          dutyCode: {
            shortLabel: 'Duty Code',
            label: 'Duty Code',
            editable: false,
            savesToDb: true
          },
          boxCategory: {
            shortLabel: 'Box Category',
            label: 'Box Category',
            editable: false,
            savesToDb: true
          },
          sizeRange: {
            shortLabel: 'Sz. Range',
            label: 'Size Range',
            editable: false,
            savesToDb: true
          },
          sizeScale: {
            shortLabel: 'Sz. Scale',
            label: 'Size Scale',
            editable: false,
            savesToDb: true
          },
          features: {
            shortLabel: 'Features',
            label: 'Features',
            editable: false,
            savesToDb: true
          },
          styleLastProdDate: {
            shortLabel: 'Last Prod.',
            label: 'Last Produced Date',
            editable: false,
            savesToDb: true
          },
          // styleSellThru - front end calculated column only - no back end
          styleSellThru: {
            shortLabel: 'Style Sell Thru',
            label: 'Style Sell Thru',
            editable: false,
            savesToDb: true
          },
          nrf: {
            shortLabel: 'NRF',
            label: 'NRF Code',
            editable: false,
            savesToDb: true
          },
          seasonCodes: {
            shortLabel: 'Season',
            label: 'Season Codes',
            editable: false,
            options: [],
            savesToDb: true
          },

          // POP Primary Settings
          dimensions: {
            shortLabel: 'Dimensions',
            label: 'Dimensions',
            editable: false,
            savesToDb: true
          },
          popCategory: {
            shortLabel: 'Category',
            label: 'Category',
            editable: false,
            options: [],
            savesToDb: true
          },
          materials: {
            shortLabel: 'Materials',
            label: 'Materials',
            editable: false,
            options: [],
            savesToDb: true
          },
          usage: {
            shortLabel: 'Usage',
            label: 'Usage',
            editable: false,
            options: [],
            savesToDb: true
          },
          wholesalePrice: {
            shortLabel: 'Cost',
            label: 'Cost',
            editable: false,
            savesToDb: true
          },
          capacityUnit: {
            shortLabel: 'Capacity',
            label: 'Capacity',
            editable: false,
            savesToDb: true
          },
          units: {
            shortLabel: 'Qty Per Pack',
            label: 'Quantity Per Pack',
            editable: false,
            savesToDb: true
          },
          relationships: {
            shortLabel: 'Order With',
            label: 'Order With',
            editable: false,
            savesToDb: true
          },
          expirationDate: {
            shortLabel: 'Exp. Date',
            label: 'Expiration Date',
            editable: false
          },

          // Document Export Settings
          // Header Footer Settings
          pageNumbers: {
            shortLabel: 'Page Number',
            label: 'Page Number',
            editable: false,
            savesToDb: true
          },
          author: {
            shortLabel: 'Author',
            label: 'Author',
            editable: false,
            savesToDb: true
          },
          assortmentDate: {
            shortLabel: 'Date',
            label: 'Date',
            editable: false,
            savesToDb: true
          },
          sizeLegend: {
            shortLabel: 'Size Legend',
            label: 'Size Legend',
            editable: false,
            savesToDb: true
          },
          priceDisclaimer: {
            shortLabel: 'Price Disclaimer',
            label: 'Price Disclaimer',
            editable: false,
            savesToDb: true
          },
          kiCoverPage: {
            shortLabel: 'Season Cover Page',
            label: 'Season Cover Page',
            editable: false,
            savesToDb: true
          },
          coverPage: {
            shortLabel: 'Title Page',
            label: 'Title Page',
            editable: false,
            savesToDb: true
          },
          sortNumber: {
            shortLabel: 'Show Sort Number',
            label: 'Show Sort Number',
            editable: false,
            savesToDb: true
          },
          hideSpillover: {
            shortLabel: 'Hide Color Spillover',
            label: 'Hide Color Spillover',
            editable: false,
            savesToDb: true
          },
          sectionBreaks: {
            shortLabel: 'Include Sections Breaks',
            label: 'Include Section Breaks',
            editable: false,
            savesToDb: true
          },
          manualHeroSelection: {
            shortLabel: 'Manual Image Selection',
            label: 'Manual Image Selection',
            editable: false,
            savesToDb: true
          },
          displayGroupPage: {
            shortLabel: 'Group Dividers',
            label: 'Group Dividers',
            editable: false,
            savesToDb: true
          },
          displayGroupPageBreaks: {
            shortLabel: 'Group Page Breaks',
            label: 'Group Page Breaks',
            editable: false,
            savesToDb: true
          },
          printResolution: {
            shortLabel: 'High Resolution Images',
            label: 'High Resolution Images',
            editable: false,
            savesToDb: true
          },
          cadOnly: {
            shortLabel: 'Display CAD Images Only',
            label: 'Display CAD Images Only',
            editable: false,
            savesToDb: true
          },
          // Spreadsheet Export Settings
          includeImages: {
            shortLabel: 'Include Images',
            label: 'Include Images',
            editable: false,
            savesToDb: true
          }
        }
      }
    }
  },
  User: {
    options: [],
    properties: {
      provided: {
        properties: {
          entity: {
            options: []
          },
          country: {
            options: []
          }
        }
      }
    }
  },
  AssetFile: {
    properties: {
      usageType: {
        options: []
      }
    }
  },
  AdvertisingAsset: {
    properties: {
      tag: {
        options: [],
        optionsInUse: []
      },
      region: {
        options: []
      },
      status: {
        options: []
      },
      season: {
        options: [],
        optionsEditor: [],
        default: ''
      },
      orientation: {
        options: []
      },
      assetType: {
        options: [],
        optionsEditor: []
      },
      storeType: {
        options: []
      },
      usage: {
        options: []
      },
      gender: {
        options: []
      },
      category: {
        options: []
      },
      vmCategory: {
        options: []
      },
      mediaDisplay: {
        options: []
      }
    }
  },
  GlobalRetailAsset: {
    properties: {
      season: {
        options: []
      },
      entity: {
        options: []
      },
      storeType: {
        options: []
      },
      category: {
        options: []
      },
      lang: {
        optionsInUse: [],
        optionsInUseWithDefault: []
      },
      year: {
        optionsInUse: [],
        optionsInUseWithDefault: []
      },
      assetType: {
        optionsVisualAsset: [],
        optionsResource: []
      },
      assetSubType: {
        optionsInUse: []
      },
      gender: {
        optionsInUse: []
      },
      element: {
        optionsInUse: []
      },
      initiative: {
        optionsInUse: []
      },
      dimensions: {
        optionsInUse: []
      }
    }
  },
  GroupSort: {
    displayGroup: {
      index: null,
      displayGroup: null,
      title: null,
      openState: false,
      alwaysOpen: false,
      contentTab: 0,
      locked: false,
      styles: [],
      customContent: {
        displayGroup: null,
        html: '',
        previewJpg: null,
        sourceFile: null
      }
    },
    Internal: {
      groupBy: {
        name: 'Pillar',
        value: 'pillar'
      },
      autoSort: {}
    },
    Regular: {
      groupBy: {
        name: 'None',
        value: 'none'
      },
      autoSort: {}
    }
  },
  Localization: {
    languages: [
      { title: 'English', value: 'en-US' },
      { title: 'Deutsch', value: 'de-DE' },
      { title: 'Español', value: 'es-ES' },
      { title: 'Français', value: 'fr-FR' },
      { title: 'Italiano', value: 'it-IT' },
      { title: '日本語', value: 'ja-JP' },
      { title: 'Polski', value: 'pl-PL' },
      { title: 'Português', value: 'pt-BR' },
      { title: '中文', value: 'zh-CN' },
    ],
  },
}
