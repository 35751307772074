import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
function LabelEditor () {}
LabelEditor.defaultParams = {}
LabelEditor.doCancelAfterEnd = false
LabelEditor.prototype.init = function (params) {
  let labels = GridHelpers.mgThisArray[0].assortment.labels

  LabelEditor.defaultParams = params
  LabelEditor.doCancelAfterEnd = false
  this.value = ''

  this.container = document.createElement('div')

  // check for labels
  let colors = []
  if (labels.length > 0) {
    for (let i = 0; i < labels.length; i++) {
      if (labels[i].color !== '') {
        colors.push(labels[i])
      }
    }
  }

  if (colors.length > 0) {
    this.container.className = 'label-selector popup'
    this.arr = []

    for (let i = 0; i < colors.length; i++) {
      let color = colors[i]
      if (color.color && color.name) {
        let colorID = color.color.toString()
        let colorName = color.name.toString()

        let tmp = document.createElement('div')
        tmp.className = 'color color--' + colorID
        tmp.setAttribute('title', colorName)
        let t = this
        tmp.addEventListener('click', function (event) {
          t.selectLabel(colorID)
          params.stopEditing()
        }.bind(t, params))
        this.arr.push(tmp)
        this.container.appendChild(tmp)
      }
    }

    let colorID = ''
    let colorName = 'None'
    let tmp = document.createElement('div')
    tmp.className = 'color color--none'
    tmp.setAttribute('title', colorName)
    let t = this
    tmp.addEventListener('click', function (event) {
      t.selectLabel(colorID)
      params.stopEditing()
    }.bind(t, params))
    this.arr.push(tmp)
    this.container.appendChild(tmp)
  } else {
    this.container.className = 'label-selector popup nolabels'
    this.container.innerHTML = '<p>To use this, first create your labels using the "Create Labels" feature.</p>'
  }

  // mouse leave cancel
  this.container.addEventListener('mouseleave', this.doMouseLeave)
}
LabelEditor.prototype.selectLabel = function (color) {
  this.container.removeEventListener('mouseleave', this.doMouseLeave)
  this.value = color
}
LabelEditor.prototype.getGui = function () {
  return this.container
}
LabelEditor.prototype.afterGuiAttached = function () {
  this.container.focus()
}
LabelEditor.prototype.getValue = function () {
  return this.value
}
LabelEditor.prototype.destroy = function () {
  this.arr = []
}
LabelEditor.prototype.isPopup = function () {
  return true
}

LabelEditor.prototype.isCancelAfterEnd = function () {
  return LabelEditor.doCancelAfterEnd
}
LabelEditor.prototype.doMouseLeave = function () {
  LabelEditor.doCancelAfterEnd = true
  LabelEditor.defaultParams.stopEditing()
}

export default LabelEditor
