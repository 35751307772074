import feConstants from '@/store/constants/config/fe_constants'
// REPLACE PERMISSION WITH STEP PERMISSIONS ONCE THEY EXIST
export default {
  modules: {
    toolbar: {
      title: 'Sales',
      extension: {
        navigation: [
          {
            title: 'Pending',
            name: 'sales--libraries--step--manage--pending'
          },
          {
            title: 'Published',
            name: 'sales--libraries--step--manage--published'
          },
          {
            title: 'Unpublished',
            name: 'sales--libraries--step--manage--unpublished'
          },
          {
            title: 'Upload Files',
            name: 'sales--libraries--step--manage--upload'
          }
        ]
      },
      subnav: [
        {
          title: 'STEP',
          name: 'sales--libraries--step',
          permissions: {
            accessRequires: [
              {
                permission: feConstants.ITS__PERMISSION__SALES__STEP_TRAINING
              }
            ]
          },
          subRoutes: [
            {
              title: 'View STEP Assets',
              name: 'sales--libraries--step',
              permissions: {
                accessRequires: [
                  {
                    permission: feConstants.ITS__PERMISSION__SALES__STEP_TRAINING,
                    roles: [
                      feConstants.ITS__ROLE__EDITOR,
                      feConstants.ITS__ROLE__SENIOR_EDITOR
                    ]
                  }
                ]
              }
            },
            {
              title: 'Manage STEP Assets',
              name: 'sales--libraries--step--manage--pending',
              permissions: {
                accessRequires: [
                  {
                    permission: feConstants.ITS__PERMISSION__SALES__STEP_TRAINING,
                    roles: [
                      feConstants.ITS__ROLE__EDITOR,
                      feConstants.ITS__ROLE__SENIOR_EDITOR
                    ]
                  }
                ]
              }
            }
          ],
          match: [
            'sales--libraries--step',
            'sales--libraries--step--manage--pending',
            'sales--libraries--step--manage--published',
            'sales--libraries--step--manage--unpublished',
            'sales--libraries--step--manage--upload'
          ]
        },
        {
          title: 'Travel Calendar',
          open: 'https://travel-calendar.skechers.com',
          permissions: {
            accessRequires: [
              { permission: feConstants.ITS__PERMISSION__SALES__TRAVEL_CALENDAR }
            ]
          }
        },
        {
          title: 'Key Item Price',
          open: 'https://key-item-pricing.skechers.com',
          permissions: {
            accessRequires: [
              { permission: feConstants.ITS__PERMISSION__SALES__KEY_ITEM_PRICING }
            ]
          }
        },
        {
          title: 'Spending Approval',
          open: 'https://spending-approval.skechers.com',
          permissions: {
            accessRequires: [
              { permission: feConstants.ITS__PERMISSION__SALES__SPENDING_APPROVAL }
            ]
          }
        },
        {
          title: 'UPC Lookup',
          open: 'https://upc-lookup.skechers.com',
          permissions: {
            accessRequires: [
              { permission: feConstants.ITS__PERMISSION__SALES__UPC_LOOKUP }
            ]
          }
        }
      ]
    }
  }
}
