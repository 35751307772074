<template>
  <div v-if="type==='radio'" class="radio-text">
    <v-radio-group
      inline
      size="x-small"
      v-bind:key="'radio-btn'+modelToggler"
      v-model="radios"
    >
      <v-radio
        :label="labelOn"
        color="green"
        :value="valueOn"
      ></v-radio>
      <v-radio
        :label="labelOff"
        color="red"
        :value="valueOff"
      ></v-radio>
    </v-radio-group>
  </div>
  <div v-else :class="`cell-checkbox-renderer ${type}`" @click="toggleCheckbox">
    <v-switch
      v-if="type==='switch'"
      size="x-small"
      :label="getSwitchLabel"
      class="switch-text"
      v-bind:key="'switch'+modelToggler"
      v-model="model"
    ></v-switch>
    <v-btn
      v-else
      size="x-small"
      :class="(params.value) ? 'selected' : ''"
    >
      <v-icon size="x-small" density="compact">check</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'CheckboxRenderer',
  data () {
    return {
      type: null,
      valueOn: true,
      valueOff: false,
      labelOn: '',
      labelOff: '',
      radios: '',
      model: null,
      modelToggler: Math.random(),
      skipUndefinedNormalizing: false
    }
  },
  watch: {
    radioOn (newValue) {
      this.toggleRadio(newValue)
    },
    radios (newValue) {
      this.toggleRadio(newValue)
    }
  },
  mounted () {
    this.type = (this.params.type) ? this.params.type : 'checkbox'
    this.valueOn = (this.params.valueOn) ? this.params.valueOn : true
    this.valueOff = (this.params.valueOff) ? this.params.valueOff : false
    this.labelOn = (this.params.labelOn) ? this.params.labelOn : this.valueOn
    this.labelOff = (this.params.labelOff) ? this.params.labelOff : this.valueOff
    this.skipUndefinedNormalizing = (this.params.skipUndefinedNormalizing)

    // data normalizing
    if (this.type !== 'radio') {
      if (this.params.value !== this.valueOn && this.params.value !== this.valueOff) {
        if (this.params.value && this.params.value.toLowerCase() === this.valueOn.toLowerCase()) {
          this.toggleCheckbox('on')
        } else {
          if (!this.skipUndefinedNormalizing) {
            this.toggleCheckbox('off')
          }
        }
      }
    }

    if (this.type === 'radio') {
      this.radios = this.params.value
    } else {
      this.model = (this.params.value === this.valueOn)
      this.modelToggler = Math.random()
    }
  },

  computed: {
    getSwitchLabel () {
      if (this.modelToggler && this.model === true) {
        return this.labelOn
      } else {
        return this.labelOff
      }
    }
  },

  methods: {
    init (params) {

    },

    getGui (params) {

    },
    getValue (params) {
      return params.value
    },
    toggleCheckbox (force = null) {
      // send new value to aggrid for backend
      const field = this.params.colDef.field
      let newValue = this.params.value

      // toggle it to the alternate
      newValue = (newValue === this.valueOn) ? this.valueOff : this.valueOn

      if (force === 'off') {
        newValue = this.valueOff
      } else if (force === 'on') {
        newValue = this.valueOn
      }

      this.params.node.setDataValue(field, newValue)
      this.model = (newValue === this.valueOn)
      this.modelToggler = Math.random()
    },
    toggleRadio (newValue) {
      const field = this.params.colDef.field
      this.params.node.setDataValue(field, newValue)
      this.modelToggler = Math.random()
    }
  }
}
</script>
<style lang="scss">
.cell-checkbox-renderer {
  cursor: pointer;
  &.radio {
    width: 100%;
  }
  .switch-text {
    .v-input__control {
      top:12px;
      position: relative;
    }
    .v-selection-control {
      transform: scale(0.7);
      transform-origin: left center;
    }
    .v-label {
      font-size: 18px;
      margin-left: -6px;
    }
  }

  button {
    display: inline-flex;
    background-color: #C1C1C1 !important;
    box-shadow: none!important;
    color: rgba(255, 255, 255, 0.5);
    min-width: 20px!important;
    min-height: 20px!important;
    width: 20px!important;
    height: 20px!important;
    max-width: 20px!important;
    max-height: 20px!important;
    transition: all 350ms ease;
    opacity: 0.5;
    border-radius: 50%;
    border: none;
    padding: 0!important;
    margin:0!important;
    align-items: center;
    justify-content: center;
    .v-icon {
      font-size: 12px;
      line-height: 12px;
      color: rgba(0, 0, 0, 0.87);
    }
    &.selected {
      opacity: 1;
      background-color: $app-color-primary !important;
      .v-icon {
        color: #fff;
      }
    }
  }

}

.radio-text {
  //width: 100%;
  position: relative;
  top:11px;
  .v-selection-control-group {
    .v-label {
      font-size: 13px;
    }
  }
}
</style>
