// import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
let MyAssortmentDragger = {
  potentialParent: {},

  moveToPath: function (newParentPath, node, allUpdatedNodes, api) {
    var oldPath = node.data.hierarchy;
    var fileName = oldPath[oldPath.length - 1];
    var newChildPath = newParentPath.slice();
    newChildPath.push(fileName);

    // Update node hierarchy
    node.data.oldHierarchy = oldPath.slice(0);
    node.data.hierarchy = newChildPath;

    // Remove node from old parent
    var oldParentPath = oldPath.slice(0, -1);
    var oldParentNode = this.findNodeByPath(api, oldParentPath);
    if (oldParentNode && oldParentNode.childrenAfterGroup) {
      oldParentNode.childrenAfterGroup = oldParentNode.childrenAfterGroup.filter(
        (child) => child.id !== node.id
      );
    }

    // Add node to list of updated nodes
    allUpdatedNodes.push(node.data);

    // Recurse for child nodes
    if (node.childrenAfterGroup) {
      node.childrenAfterGroup.forEach((childNode) => {
        this.moveToPath(newChildPath, childNode, allUpdatedNodes, api);
      });
    }

    //GridHelpers.mgThisArray[0].gridKey = Math.random()
  },

  isSelectionParentOfTarget: function (selectedNode, targetNode) {
    var children = selectedNode.childrenAfterGroup;
    for (var i = 0; i < children.length; i++) {
      if (targetNode && children[i].id === targetNode.id) {
        return true;
      }
      MyAssortmentDragger.isSelectionParentOfTarget(children[i], targetNode);
    }
    return false;
  },

  arePathsEqual: function (path1, path2) {
    if (path1.length !== path2.length) {
      return false;
    }
    var equal = true;
    path1.forEach(function (item, index) {
      if (path2[index] !== item) {
        equal = false;
      }
    });
    return equal;
  },

  setPotentialParentForNode: function (api, overNode, event) {
    if (api) {
      var newPotentialParent = null;
      if (event !== null) {
        if (overNode && overNode.data) {
          newPotentialParent = overNode.data.type === 'folder' ? overNode : overNode.parent;
        } else if (event.overIndex === -1) {
          newPotentialParent = '';
        }

        var alreadySelected = MyAssortmentDragger.potentialParent === newPotentialParent;
        if (alreadySelected) {
          return;
        }
      } else {
        newPotentialParent = '';
      }

      // Refresh rows
      var rowsToRefresh = [];
      if (MyAssortmentDragger.potentialParent) {
        rowsToRefresh.push(MyAssortmentDragger.potentialParent);
      }
      if (newPotentialParent) {
        rowsToRefresh.push(newPotentialParent);
      }

      MyAssortmentDragger.potentialParent = newPotentialParent;
      MyAssortmentDragger.refreshRows(api, rowsToRefresh);
    }
  },

  refreshRows: function (api, rowsToRefresh) {
    if (!rowsToRefresh || rowsToRefresh.length === 0) return;


    var params = {
      rowNodes: rowsToRefresh,
      force: false,
    };

    api.refreshCells(params);

    // Also refresh the full view for any structural changes
    api.onGroupExpandedOrCollapsed();
  },

  findNodeByPath: function (api, path) {
    let rootNode = api.getDisplayedRowAtIndex(0); // Assuming the root is at index 0
    let currentNode = rootNode;

    for (let i = 0; i < path.length; i++) {
      if (!currentNode || !currentNode.childrenAfterGroup) return null;

      currentNode = currentNode.childrenAfterGroup.find(
        (child) => child.data.name === path[i]
      );
    }

    return currentNode;
  },
};

export default MyAssortmentDragger;
