<template>
  <div class="libraries-grid-date-picker">
    <v-row justify="center">
      <v-date-picker
        @update:modelValue="modelUpdated"
        @keydown.escape="closePopup"
        @click:cancel="closePopup"
        :model-value="picker"
        hide-actions
      ></v-date-picker>
    </v-row>
  </div>
</template>

<script>
import shared from 'skch_its_be_fe_shared'
// import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
// import ColumnHelpers from '@/components/_core/GridsCore/helpers/ColumnHelpers'
import dayjs from 'dayjs'
const dateOnlyFromJsDate = shared.dates.dateOnlyFromJsDate
export default {
  name: 'DatePickerEditor',
  data () {
    return {
      picker: null,
      useVCalendarComponent: false // letting this be a toggle in simplest way to preserve my old code so when we switch back it'll be easier to switch back
    }
  },
  methods: {
    getValue () {
      let val = null
      if (this.picker) {
        if (this.params.saveAsJsDate) {
          val = new Date(this.picker)
          val = dateOnlyFromJsDate(val)
        } else {
          val = dayjs(this.picker).format('MM/DD/YYYY')
        }
      }
      return val
    },

    isPopup () {
      return true
    },
    modelUpdated (val) {
      this.picker = val
      this.params.stopEditing()
    },
    closePopup () {
      this.params.stopEditing()
    }
  },
  beforeMount () {
    /*
    THIS IS CODE TO SET A DEFAULT VALUE - but it caluses bugs.
    Ignoring this old code for now (from vue2)
    But v-date-picker is kind of bad and not ready for production
    We likely need to chande global VUE3 settings to do adapter: DayJsAdapter,  See here:
    https://vuetifyjs.com/en/components/date-pickers/

    let val = this.params.value
    if (val) {
      // if saveAsJsDate, value will be a number, so make it into a string - with january offset - to show default value
      if (this.params.saveAsJsDate) {
        val = GridHelpers.convertDateNumberToDateString(val)
        this.picker = ColumnHelpers.formatterDate(val, 'YYYY-MM-DD')
      } else {
        const yyyy = Number(val.substring(0, 4))
        const mm = Number(val.substring(5, 7)) - 1
        const dd = Number(val.substring(8, 10))
        let val2 = new Date(yyyy, mm, dd) // do this or face timezone wrath
        this.picker = val2
      }
    }
     */
  }
}
</script>
