<template>
  <div :class="['cell-chips-renderer', (isXSmall) ? 'xsmall' : 'small']">
    <v-row>
      <div v-for="(item, index) in chips" class="chip-outer" v-bind:key="'chip-'+ index + randomKey">
        <v-chip
          v-if="showChip(index)"
          class="ma-2"
          color="primary"
          closable :size="isXSmall ? 'xsmall' : 'small'"
          @click:close="chipClose(index)"
        >
          {{ item }}
        </v-chip>
      </div>
    </v-row>
  </div>
</template>

<script>
import ColumnHelpers from '@/components/_core/GridsCore/helpers/ColumnHelpers'

export default {
  name: 'ChipsRenderer',
  data () {
    return {
      chips: [],
      chipsDisplayHolder: [],
      rekey: Math.random(),
      usePropertiesLabel: false, // for rendered, is properties label and value? or just straight value
      isXSmall: false
    }
  },
  computed: {
    randomKey () {
      if (this.rekey) {
        return Math.random()
      } else {
        return Math.random()
      }
    }
  },

  methods: {
    init (params) {

    },
    getGui (params) {

    },
    getValue (params) {
      if (this.usePropertiesLabel) {
        return ColumnHelpers.rendererUsePropertiesLabel(params)
      } else {
        return params.value
      }
    },
    showChip (index) {
      return (this.chipsDisplayHolder[index])
    },

    chipClose (index) {
      if (this.chipsDisplayHolder[index]) {
        // hide value
        this.chipsDisplayHolder[index] = false
        this.rekey = Math.random()

        // send new value to aggrid for backend
        let newValue = this.getValueArrayForBackend()
        let field = this.params.colDef.field
        this.params.node.setDataValue(field, newValue)
      }
    },

    getValueArrayForBackend () {
      let filtered = []
      if (this.rekey) {
        for (let i = 0; i < this.chips.length; i++) {
          if (this.chipsDisplayHolder[i]) {
            filtered.push(this.chips[i])
          }
        }
      }
      return filtered
    }

  },
  created () {
    // add chips
    if (this.params.value) {
      let val = this.params.value.toString().trim()
      if (val) {
        this.chips = this.params.value.toString().split(',')
        for (let i = 0; i < this.chips.length; i++) {
          this.chipsDisplayHolder[i] = true
        }
      }
    }
  },
  mounted () {
    this.isXSmall = (this.params.isXSmall)
    this.usePropertiesLabel = (this.params.usePropertiesLabel)
  }
}
</script>
