import store from '@/store/store'

// import feConstants from '@/store/constants/config/fe_constants'
import { loadView, loadComponent } from '@/helpers/asyncLoaders'
import { beforeEnterMiddleware } from '@/router/navGuards'
import { ordersSampleMeta } from '@/router/routeMeta'
import feConstants from '@/store/constants/config/fe_constants'

import {
  VUEX_OPTIONS_FETCH
} from '@/store/constants/options'

export default {
  path: '/orders/samples',
  component: loadView('app/App.vue'),
  beforeEnter: (to, from, next) => {
    beforeEnterMiddleware(to, from, next, {
      authenticate: true,
      permissions: {
        accessRequires: [{ permission: ITS__PERMISSION__ORDERS__SAMPLE_ORDERS }]
      },
      callback: async (next) => {
        await store.dispatch(VUEX_OPTIONS_FETCH, 'orders')
        next()
      }
    })
  },
  children: [
    {
      path: '',
      name: 'orders--default',
      redirect: { name: 'orders--sample-orders--active-orders' }
    },
    {
      path: 'sample-styles',
      name: 'orders--sample-orders--sample-styles',
      component: loadComponent('Orders/Orders_SampleOrders.vue'),
      meta: {
        orderType: feConstants.ITS__ORDERS__ORDER_REQUEST_TYPE__SAMPLE,
        orderSubtype: feConstants.ITS__ORDERS__ORDER_TYPE__SAMPLE,
        manageType: feConstants.ITS__ORDERS__SAMPLE__SAMPLE_STYLES,
        allowCoordinatorChange: false
      }
    },
    {
      path: 'active-orders',
      name: 'orders--sample-orders--active-orders',
      component: loadComponent('Orders/Orders_SampleOrders.vue'),
      meta: {
        orderType: feConstants.ITS__ORDERS__ORDER_REQUEST_TYPE__SAMPLE,
        orderSubtype: feConstants.ITS__ORDERS__ORDER_TYPE__SAMPLE,
        manageType: feConstants.ITS__ORDERS__SAMPLE__ACTIVE_ORDERS,
        allowCoordinatorChange: true
      }
    },
    {
      path: 'archived-orders',
      name: 'orders--sample-orders--archived-orders',
      component: loadComponent('Orders/Orders_SampleOrders.vue'),
      meta: {
        orderType: feConstants.ITS__ORDERS__ORDER_REQUEST_TYPE__SAMPLE,
        orderSubtype: feConstants.ITS__ORDERS__ORDER_TYPE__SAMPLE,
        manageType: feConstants.ITS__ORDERS__SAMPLE__ARCHIVED_ORDERS,
        allowCoordinatorChange: true
      }
    },
    {
      path: 'order/:orderId',
      name: 'orders--sample-orders--details',
      component: loadComponent('Orders/Orders_Details.vue'),
      /* beforeEnter: (to, from, next) => {
        Promise.all([
          store.dispatch(VUEX_OPTIONS_FETCH, 'assortments')
          // store.dispatch(VUEX_ROUTING_ENTER_ORDERS_SAMPLE_DETAILS, { to: to, from: from })
        ]).then(() => next())
      }, */
      meta: {
        orderType: feConstants.ITS__ORDERS__ORDER_REQUEST_TYPE__SAMPLE,
        orderSubtype: feConstants.ITS__ORDERS__ORDER_TYPE__SAMPLE,
        manageType: feConstants.ITS__ORDERS__SAMPLE__DETAIL
      }
    }
  ],
  meta: ordersSampleMeta
}
