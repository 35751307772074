<template>
  <div class="aggrid-style-finder">
    <v-text-field
      variant="underlined"
      density="compact"
      hide-details="auto"
      color="primary"
      clearable
      placeholder="Style Finder"
      v-model="styleFinder"
      maxlength="10"
      :clear-icon-cb="clearAllStyleFinderMatch"
    ></v-text-field>
    <div v-if="styleFinder && styleFinder.length>1" class="nav-info">
      <div v-if="matchingNodes.length>1" @click="scrollPrevious" class="icon up">
        <span class="material-icons">keyboard_arrow_up</span>
      </div>
      <div v-if="matchingNodes.length>1" @click="scrollNext" class="icon down">
        <span class="material-icons">keyboard_arrow_down</span>
      </div>
      <div class="info-display" v-html="infoDisplay"></div>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash.debounce'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
export default {
  name: 'style-finder',
  components: {},
  data: function () {
    return {
      styleFinder: '',
      matchingNodes: [],
      matchingNodesCurrent: 0,
      t: null,
      infoDisplay: ''
    }
  },
  mounted () {
    this.t = GridHelpers.mgThisArray[0]
  },
  watch: {
    styleFinder: _debounce(function (val) {
      if (!val) {
        this.clearAllStyleFinderMatch()
      } else if (val.length > 1) {
        this.searchForStyle(val.toLowerCase())
      } else {
        this.clearAllStyleFinderMatch()
      }
    }, 300)
  },

  methods: {
    clearNodesStore () {
      this.matchingNodes = []
      this.matchingNodesCurrent = 0
    },
    clearAllStyleFinderMatch () {
      let rows = GridHelpers.getRowNodes(false, true)
      for (let i = 0; i < rows.length; i++) {
        let node = rows[i]
        node.data.styleFinderMatch = false
      }
      this.clearNodesStore()
      this.setDisplayText()
    },
    searchForStyle (val) {
      this.clearNodesStore()

      // make lower case
      val = val.toLowerCase()

      // let rows = GridHelpers.rows
      let rows = GridHelpers.getRowNodes(false, true)
      for (let i = 0; i < rows.length; i++) {
        let node = rows[i]

        // make lower case
        let styleString = node.data?.style
        styleString = styleString.toLowerCase()

        if (styleString.includes(val)) {
          node.data.styleFinderMatch = true
          this.matchingNodes.push(node)
        } else {
          node.data.styleFinderMatch = false
        }
      }
      this.setDisplayText()

      // refresh display
      this.t.gridApi.refreshCells()

      // scroll to
      if (this.matchingNodes.length > 0) {
        this.scrollToStyle()
      }
    },
    setDisplayText () {
      if (this.styleFinder && this.styleFinder.length > 1) {
        const length = this.matchingNodes.length
        const current = (length === 0) ? 0 : this.matchingNodesCurrent + 1
        this.infoDisplay = `<p>${(current)}/${length}</p>`
      } else {
        this.infoDisplay = ''
      }
      this.t.gridApi.refreshCells()
    },
    scrollNext () {
      this.matchingNodesCurrent++
      if (this.matchingNodesCurrent >= this.matchingNodes.length) {
        this.matchingNodesCurrent = 0
      }
      this.scrollToStyle()
    },
    scrollPrevious () {
      this.matchingNodesCurrent--
      if (this.matchingNodesCurrent < 0) {
        this.matchingNodesCurrent = this.matchingNodes.length - 1
      }
      this.scrollToStyle()
    },
    scrollToStyle () {
      for (let i = 0; i < this.matchingNodes.length; i++) {
        this.matchingNodes[i].data.styleFinderMatchCurrent = false
      }

      const node = this.matchingNodes[this.matchingNodesCurrent]
      node.data.styleFinderMatchCurrent = true

      // this.t.gridApi.ensureNodeVisible(node, 'top') // used to work, but it broke - flipping style to index
      this.t.gridApi.ensureIndexVisible(node?.rowIndex, 'top')
      this.setDisplayText()
    }
  }
}
</script>
<style lang="scss">
.aggrid-style-finder {
  position: relative;
  top:-8px;
  display: flex;
  align-items: center;
  .v-text-field__details {
    display: none;
  }
  .v-text-field {
    width: 125px;
    input {
      font-size: 13px;
    }
  }
  .v-application--is-ltr .v-text-field .v-label {
    top:8px;
  }

  .nav-info {
    display: flex;
    width:120px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: 5px;
    .icon {
      user-select: none;
      width:16px;
      text-align: center;
      height: 12px;
      margin-left: 5px;
      .material-icons {
        font-size: 16px;
        line-height: 12px;
      }
      transition: all 350ms ease-in-out;
      cursor: pointer;
      &:hover {
        opacity:0.6;
      }
    }
    .info-display {
      user-select: none;
      width:50px;
      margin-left: 10px;
      text-align: left;
      p {
        margin: 0;
      }
    }
  }

}
</style>
