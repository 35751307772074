import store from '@/store/store'

import { loadView, loadComponent } from '@/helpers/asyncLoaders'
import { beforeEnterMiddleware } from '@/router/navGuards'
import { ordersWholesaleMeta } from '@/router/routeMeta'
import feConstants from '@/store/constants/config/fe_constants'

import { VUEX_OPTIONS_FETCH } from '@/store/constants/options'

export default {
  path: '/orders/wholesale',
  component: loadView('app/App.vue'),
  beforeEnter: (to, from, next) => {
    beforeEnterMiddleware(to, from, next, {
      authenticate: true,
      permissions: {
        accessRequires: [{ permission: ITS__PERMISSION__ORDERS__WHOLESALE_ORDERS }],
        userAccess: {
          'wof': true,
          'departmentAccess': ['Wholesale Sales']
        }
      },
      callback: async (next) => {
        await store.dispatch(VUEX_OPTIONS_FETCH, 'orders')
        next()
      }
    })
  },
  children: [
    {
      path: 'drafts',
      name: 'orders--wholesale-orders--draft-orders',
      component: loadComponent('Orders/Orders_WholesaleOrders.vue'),
      meta: {
        orderType: feConstants.ITS__ORDERS__ORDER_REQUEST_TYPE__WHOLESALE,
        orderSubtype: feConstants.ITS__ORDERS__ORDER_TYPE__WHOLESALE,
        manageType: feConstants.ITS__ORDERS__WHOLESALE__DRAFT_ORDERS
        // allowCoordinatorChange: true
      }
    },
    {
      path: 'order/:orderId',
      name: 'orders--wholesale-orders--details',
      component: loadComponent('Orders/Orders_Details.vue'),
      meta: {
        orderType: feConstants.ITS__ORDERS__ORDER_REQUEST_TYPE__WHOLESALE,
        orderSubtype: feConstants.ITS__ORDERS__ORDER_TYPE__WHOLESALE,
        manageType: feConstants.ITS__ORDERS__WHOLESALE__DETAIL
      }
    }
  ],
  meta: ordersWholesaleMeta
}
