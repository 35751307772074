import feConstants from '@/store/constants/config/fe_constants'

export default {
  modules: {
    toolbar: {
      title: 'Global Retail',
      extension: {
        navigation: [
          {
            title: 'Pending',
            name: 'global-retail--libraries--manage--pending'
          },
          {
            title: 'Published',
            name: 'global-retail--libraries--manage--published'
          },
          {
            title: 'Unpublished',
            name: 'global-retail--libraries--manage--unpublished'
          },
          {
            title: 'Upload Files',
            name: 'global-retail--libraries--manage--upload'
          }
        ]
      },
      subnav: [
        {
          title: 'Graphic Asset Library',
          name: 'global-retail--libraries--assets-landing',
          permissions: {
            accessRequires: [
              {
                permission: feConstants.ITS__PERMISSION__GLOBALRETAIL__ASSETS
              }
            ]
          },
          match: [
            'global-retail--libraries--assets-landing',
            'global-retail--libraries--assets'
          ]
        },
        {
          title: 'Resource Library',
          name: 'global-retail--libraries--resources-landing',
          permissions: {
            accessRequires: [
              {
                permission: feConstants.ITS__PERMISSION__GLOBALRETAIL__RESOURCES
              }
            ]
          },
          match: [
            'global-retail--libraries--resources-landing',
            'global-retail--libraries--resources'
          ]
        },
        {
          title: 'Document Library',
          name: 'product--libraries--documents',
          permissions: {
            accessRequires: [
              {
                permission: feConstants.ITS__PERMISSION__PRODUCT__DOCUMENTS
              }
            ]
          },
          match: [
            'product--libraries--documents-landing',
            'product--libraries--documents'
          ]
        },
        {
          title: 'Manage',
          name: 'global-retail--libraries--manage--default',
          permissions: {
            accessRequires: [
              {
                permission: feConstants.ITS__PERMISSION__GLOBALRETAIL__ASSETS,
                roles: [
                  feConstants.ITS__ROLE__EDITOR,
                  feConstants.ITS__ROLE__SENIOR_EDITOR
                ]
              },
              {
                permission: feConstants.ITS__PERMISSION__GLOBALRETAIL__RESOURCES,
                roles: [
                  feConstants.ITS__ROLE__EDITOR,
                  feConstants.ITS__ROLE__SENIOR_EDITOR
                ]
              }
            ]
          },
          match: [
            'global-retail--libraries--manage--pending',
            'global-retail--libraries--manage--pending',
            'global-retail--libraries--manage--published',
            'global-retail--libraries--manage--unpublished',
            'global-retail--libraries--manage--upload'
          ]
        }
      ]
    }
  }
}
