// import Vue from 'vue'

import _merge from 'lodash.merge'
import _clone from 'lodash.clonedeep'
import _get from 'lodash.get'
import _sortBy from 'lodash.sortby'
import _union from 'lodash.union'
import _uniq from 'lodash.uniq'
import _uniqBy from 'lodash.uniqby'
// import _pick from 'lodash.pick'

import Debug from 'logdown'

import { insertSortedIndex } from '@/utils'

import {
  VUEX_PROPERTIES_UPDATE,
  VUEX_PROPERTIES_PARTIAL_UPDATE,
  VUEX_PROPERTIES_ARRAY_UPDATE,
  VUEX_PROPERTIES_INITIALIZED,
  properties
} from '@/store/constants/config/properties'

// note from matthew: i use this to debug frequently, so just have it commented out here for quick reference
//import options from '@/store/modules/options';console.info('options', options);

const d = new Debug('its:state:modules:config:properties')

/**
 * Properties module state
 * Load base data from constants
 * @const
 */
// let state = Object.assign({}, properties, { initialized: false })
const state = {
  data: {
    ...properties
  }
}

const getters = {}

/**
 * Properties module action definitions
 * @const
 */
const actions = {
  // Load option data from server provided responses
  // Should be called by options module when it is ready
  [VUEX_PROPERTIES_UPDATE]: async ({ rootState, commit }) => {
    const divList = _get(rootState, 'options.data.ProductDocument.default.division', []).map(v => ({
      label: `${v.code} - ${v.name}`,
      value: v.code,
      name: v.name
    }))
    const customerList = _get(rootState, 'options.data.customer', []).map(v => ({ label: v.name, value: v.image }))
    const productTypeList = _get(rootState, 'options.data.productType', []).map(v => ({ label: v, value: v }))
    const genderList = _get(rootState, 'options.data.gender', []).map(v => ({ label: v.name, value: v.code }))
    const genderListProductDoc = _get(rootState, 'options.data.ProductDocument.default.gender', []).map(v => ({
      label: v,
      value: v
    }))
    const statusList = _get(rootState, 'options.data.lineStatus', []).map(v => ({ label: v.name, value: v.code }))
    const featureList = _get(rootState, 'options.data.feature', []).map(v => ({ label: v.name, value: v.code }))
    const featureFootwear = _get(rootState, 'options.data.feature', []).filter(f => f.footwear).map(v => ({
      label: v.name,
      value: v.code
    }))
    const featureApparel = _get(rootState, 'options.data.feature', []).filter(f => f.apparel).map(v => ({
      label: v.name,
      value: v.code
    }))
    const usageListPOP = _get(rootState, 'options.data.usage', []).map(v => ({ label: v, value: v }))
    const locationList = _get(rootState, 'options.data.location', []).map(v => (Object.assign({}, v, {
      label: v.name,
      value: v.code
    })))
    const popTypeList = _get(rootState, 'options.data.popType', []).map(v => ({ label: v, value: v }))
    const popGenderList = _get(rootState, 'options.data.popGender', []).map(v => ({ label: v, value: v }))
    const popCategoryList = _get(rootState, 'options.data.popCategory', []).map(v => ({ label: v, value: v }))

    // User emails are no longer provided by BE

    const usersList = _get(rootState, 'options.data.users', []).map(v => ({
      label: `${v.name} ${v.lastName}`,
      value: v.code
    }))
    // const productUsersList = _get(rootState, 'options.data.users', []).filter(v => v.can[ITS__PERMISSION__PRODUCTS]).map(v => ({
    //   label: `${v.name} ${v.lastName}`,
    //   value: v.email
    // }))
    // const productAssortmentUsersList = _get(rootState, 'options.data.users', []).filter(v => v.can[ITS__PERMISSION__PRODUCT__ASSORTMENTS]).map(v => ({
    //   label: `${v.name} ${v.lastName}`,
    //   value: v.email
    // }))
    // const productDocumentUsersList = _get(rootState, 'options.data.users', []).filter(v => v.can[ITS__PERMISSION__PRODUCT__DOCUMENTS]).map(v => ({
    //   label: `${v.name} ${v.lastName}`,
    //   value: v.email
    // }))
    // const globalRetailAssetUsersList = _get(rootState, 'options.data.users', []).filter(v => v.can[ITS__PERMISSION__GLOBALRETAIL__ASSETS]).map(v => ({
    //   label: `${v.name} ${v.lastName}`,
    //   value: v.email
    // }))
    // const globalRetailResourceUsersList = _get(rootState, 'options.data.users', []).filter(v => v.can[ITS__PERMISSION__GLOBALRETAIL__RESOURCES]).map(v => ({
    //   label: `${v.name} ${v.lastName}`,
    //   value: v.email
    // }))
    // const advertisingAssetUsersList = _get(rootState, 'options.data.users', []).filter(v => v.can[ITS__PERMISSION__ADVERTISING__ASSETS]).map(v => ({
    //   label: `${v.name} ${v.lastName}`,
    //   value: v.email
    // }))
    // const advertisingLogoUsersList = _get(rootState, 'options.data.users', []).filter(v => v.can[ITS__PERMISSION__ADVERTISING__LOGOS]).map(v => ({
    //   label: `${v.name} ${v.lastName}`,
    //   value: v.email
    // }))

    /* const InternalStaticWholesaleInternationalKeyInitiatives = _get(rootState, 'options.data.InternalStaticWholesaleInternational.inUse.pillar', []).map(v => ({
      label: v,
      value: v
    })) */

    // RE-CREATING - options.data.RegularStatic.default.availableSeasons
    const optionsYear = _get(rootState, 'options.data.year', [])
    const optionsSeason = _get(rootState, 'options.data.season', [])
    let regularStaticAvailableSeasons = []
    for (let i = 0; i < optionsYear.length; i++) {
      for (let ii = 0; ii < optionsSeason.length; ii++) {
        let obj = {
          name: optionsSeason[ii] + ' ' + optionsYear[i],
          season: optionsSeason[ii],
          year: optionsYear[i]
        }
        regularStaticAvailableSeasons.push(obj)
      }
    }

    const popMaterialsList = _get(rootState, 'options.data.popMaterial', []).map(v => ({ label: v, value: v }))

    const directivesSubtypeOptions = _get(rootState, 'options.data.GlobalRetailAsset.Resource.inUse.assetTypes.Directives.assetSubType', [])
    const standardsSubtypeOptions = _get(rootState, 'options.data.GlobalRetailAsset.Resource.inUse.assetTypes.Standards.assetSubType', [])
    const trainingSubtypeOptions = _get(rootState, 'options.data.GlobalRetailAsset.Resource.inUse.assetTypes.Training.assetSubType', [])
    const assetSubTypeOptionsConcat = []
      .concat(directivesSubtypeOptions)
      .concat(standardsSubtypeOptions)
      .concat(trainingSubtypeOptions).sort()
    const assetSubTypeOptions = [...new Set(assetSubTypeOptionsConcat)]

    // for options.data.userGroup, for some reason, it uses description and name, except description is most often blank - so setting both to name
    const userGroup = _get(rootState, 'options.data.userGroup', []).map(v => ({ label: v.name, value: v.name }))

    // set up category and component options
    const categoryLists = {}
    const categories = _get(rootState, 'options.data.category', {})
    for (let category in categories) {
      categoryLists[category] = { options: categories[category] }
    }

    const componentLists = {}
    const components = _get(rootState, 'options.data.components', {})
    for (let component in components) {
      componentLists[component] = { options: components[component] }
    }

    // COMBINED WHOLESALE
    let internalStaticWholesaleDomestic = _get(rootState, 'options.data.InternalStaticWholesaleDomestic.default.availableSeasons', []).map(v => ({ ...v, label: v.name }))
    let domesticWholesaleDivisionGroup = _get(rootState, 'options.data.InternalStaticWholesaleDomestic.default.divisionGroup', [])
    let internalStaticWholesaleInternational = _get(rootState, 'options.data.InternalStaticWholesaleInternational.default.availableSeasons', []).map(v => ({ ...v, label: v.name }))
    let internationalWholesaleDivisionGroup = _get(rootState, 'options.data.InternalStaticWholesaleInternational.default.divisionGroup', [])
    let combinedWholesale = internalStaticWholesaleDomestic.concat(internalStaticWholesaleInternational)
    let combinedDivisionGroupWholesale = domesticWholesaleDivisionGroup.concat(internationalWholesaleDivisionGroup)
    internalStaticWholesaleDomestic = _uniq(_sortBy(internalStaticWholesaleDomestic, 'name'))
    internalStaticWholesaleInternational = _uniq(_sortBy(internalStaticWholesaleInternational, 'name'))
    combinedWholesale = _uniq(_sortBy(combinedWholesale, 'name'))
    combinedDivisionGroupWholesale = _uniq(combinedDivisionGroupWholesale.sort())

    // COMBINED RETAIL
    let internalStaticRetailDomestic = _get(rootState, 'options.data.InternalStaticRetailDomestic.default.availableSeasons', []).map(v => ({ ...v, label: v.name }))
    let domesticRetailDivisionGroup = _get(rootState, 'options.data.InternalStaticRetailDomestic.default.divisionGroup', [])
    let internalStaticRetailInternational = _get(rootState, 'options.data.InternalStaticRetailInternational.default.availableSeasons', []).map(v => ({ ...v, label: v.name }))
    let internationalRetailDivisionGroup = _get(rootState, 'options.data.InternalStaticRetailInternational.default.divisionGroup', [])
    let combinedRetail = internalStaticRetailDomestic.concat(internalStaticRetailInternational)
    let combinedDivisionGroupRetail = domesticRetailDivisionGroup.concat(internationalRetailDivisionGroup)
    internalStaticRetailDomestic = _uniq(_sortBy(internalStaticRetailDomestic, 'name'))
    internalStaticRetailInternational = _uniq(_sortBy(internalStaticRetailInternational, 'name'))
    combinedRetail = _uniq(_sortBy(combinedRetail, 'name'))
    combinedDivisionGroupRetail = _uniq(combinedDivisionGroupRetail.sort())

    let domesticRetailLifecycle = _get(rootState, 'options.data.InternalStaticRetailDomestic.default.products.default.lifeCycle')
    let internationalRetailLifecycle = _get(rootState, 'options.data.InternalStaticRetailDomestic.default.products.default.lifeCycle')
    let combinedRetailLifeCycle = _union(domesticRetailLifecycle, internationalRetailLifecycle)

    let domesticRetailStoreType = _get(rootState, 'options.data.InternalStaticRetailDomestic.default.products.default.storeType')
    let internationalRetailStoreType = _get(rootState, 'options.data.InternalStaticRetailDomestic.default.products.default.storeType')
    let combinedRetailStoreType = _union(domesticRetailStoreType, internationalRetailStoreType).map(v => ({
      label: v.name,
      value: v.name
    }))

    let domesticRetailSamples = _get(rootState, 'options.data.InternalStaticRetailDomestic.default.products.default.samples')
    let domesticWholsaleSamples = _get(rootState, 'options.data.InternalStaticWholesaleDomestic.default.products.default.samples')
    let internationalRetailSamples = _get(rootState, 'options.data.InternalStaticRetailInternational.default.products.default.samples')
    let internationalWholesaleSamples = _get(rootState, 'options.data.InternalStaticWholesaleInternational.default.products.default.samples')
    let combinedInternalSamples = _union(domesticRetailSamples, domesticWholsaleSamples, internationalRetailSamples, internationalWholesaleSamples).map(v => ({
      label: v,
      value: v
    }))

    // FOR ORDERS
    // get just the coordinatorNames
    let coordinatorNames = _get(rootState, 'options.data.SampleOrder.SAMPLE.default.coordinator', [])
    coordinatorNames = coordinatorNames.map(coordinator => coordinator.coordinatorName)
    coordinatorNames = _sortBy(_uniq(coordinatorNames))

    // shipper dropdown list codes
    // Filter the shippers to only those with coordinatorSelectable: true
    // Sort the filtered shippers by their name
    // Map the sorted shippers to get their codes & remove dupes
    let shipperFilteredForDropdown = _get(rootState, 'options.data.SampleOrder.default.product.shipper', {})
    shipperFilteredForDropdown = Object.values(shipperFilteredForDropdown).filter(shipper => shipper.coordinatorSelectable)
    shipperFilteredForDropdown = _sortBy(shipperFilteredForDropdown, shipper => shipper.name)
    shipperFilteredForDropdown = shipperFilteredForDropdown.map(shipper => {
      return {
        value: shipper.code,
        label: shipper.name
      }
    })

    let optionsData = {
      Product: {
        properties: {
          productType: {
            options: productTypeList,
            optionsModel: _get(rootState, 'options.data.productType', [])
          },
          gender: {
            options: genderList
          },
          country: {
            options: _get(rootState, 'options.data.country', [])
          },
          seasonCodes: {
            options: _get(rootState, 'options.data.seasonCode', [])
          },
          lineStatus: {
            options: statusList,
            optionsModel: _get(rootState, 'options.data.lineStatus', [])
          },
          features: {
            options: featureList,
            optionsFootwear: featureFootwear,
            optionsApparel: featureApparel
          },
          usage: {
            options: usageListPOP
          },
          locations: {
            properties: {
              code: {
                options: locationList,
                optionsModel: _get(rootState, 'options.data.location', [])
              }
            }
          },
          popType: {
            options: popTypeList
          },
          popGender: {
            options: popGenderList
          },
          popCategory: {
            options: popCategoryList
          },
          materials: {
            options: popMaterialsList
          },
          division: {
            options: _get(rootState, 'options.data.division', []).map(v => ({
              label: `${v.code} - ${v.name}`,
              name: v.name,
              value: v.code
            }))
          },
          components: {
            properties: componentLists
          },
          categories: {
            properties: categoryLists
          },
          boughtStatus: {
            options: _get(rootState, 'options.data.InternalStaticWholesaleInternational.default.boughtStatus', [])
          },
          category: {
            options: _get(rootState, 'options.data.InternalStaticWholesaleInternational.inUse.category', [])
          },
          pillar: {
            options: _union(
              _get(rootState, 'options.data.InternalStaticWholesaleInternational.inUse.pillarFootwear', []),
              _get(rootState, 'options.data.InternalStaticRetailInternational.inUse.pillarFootwear', []),
              _get(rootState, 'options.data.InternalStaticWholesaleDomestic.inUse.pillarFootwear', []),
              _get(rootState, 'options.data.InternalStaticRetailDomestic.inUse.pillarFootwear', []),
              _get(rootState, 'options.data.InternalStaticWholesaleInternational.inUse.pillarApparel', []),
              _get(rootState, 'options.data.InternalStaticRetailInternational.inUse.pillarApparel', []),
              _get(rootState, 'options.data.InternalStaticWholesaleDomestic.inUse.pillarApparel', []),
              _get(rootState, 'options.data.InternalStaticRetailDomestic.inUse.pillarApparel', []),
              _get(rootState, 'options.data.InternalStaticWholesaleInternational.inUse.pillarPOP', []),
              _get(rootState, 'options.data.InternalStaticRetailInternational.inUse.pillarPOP', []),
              _get(rootState, 'options.data.InternalStaticWholesaleDomestic.inUse.pillarPOP', []),
              _get(rootState, 'options.data.InternalStaticRetailDomestic.inUse.pillarPOP', [])
            )
          },
          lifeCycle: {
            options: combinedRetailLifeCycle
          },
          storeType: {
            options: combinedRetailStoreType
          },
          samples: {
            options: combinedInternalSamples
          },
          rushSamples: {
            options: _get(rootState, 'options.data.InternalStaticWholesaleInternational.default.products.default.rushSamples', [])
          },
          keyInitiative: {
            options: combinedWholesale
          },
          territories: {
            options: _get(rootState, 'options.data.territory', [])
          },
          availableSeasons: {
            retail: {
              options: _get(rootState, 'options.data.availableRetailSeasons', []).map(v => ({ ...v, label: `${v.year} ${v.season}` }))
            },
            wholesale: {
              options: _get(rootState, 'options.data.availableWholesaleSeasons', []).map(v => ({ ...v, label: `${v.year} ${v.season}` }))
            },
            all: {
              options: _get(rootState, 'options.data.availableWholesaleSeasons', []).concat(_get(rootState, 'options.data.availableWholesaleSeasons', [])).map(v => ({ ...v, label: `${v.year} ${v.season}` }))
            }
          },
          groupBy: {
            Regular: {
              default: {
                options: [
                  { name: 'Gender', value: 'gender' },
                  { name: 'Division', value: 'division' },
                  { name: 'Division Group', value: 'divisionGroup' },
                  { name: 'Pillar', value: 'pillar' },
                  { name: 'None', value: 'none' }
                ]
              },
              POP: {
                options: [
                  { name: 'Gender', value: 'popGender' },
                  { name: 'Category', value: 'popCategory' },
                  { name: 'Pillar', value: 'pillar' },
                  { name: 'None', value: 'none' }
                ]
              }
              /* apparel: {
                options: []
              } */
            },
            Internal: {
              default: {
                options: [
                  { name: 'Pillar', value: 'pillar' },
                  { name: 'None', value: 'none' }
                ]
              }
              /* pop: {
                options: []
              }, */
              /* apparel: {
                options: []
              } */
            }
          },
          autosort: {
            Regular: {
              default: {
                options: [
                  { name: 'Gender', value: 'gender' },
                  { name: 'DivisionGroup', value: 'divisionGroup' },
                  { name: 'Division', value: 'division' },
                  { name: 'Pillar', value: 'pillar' },
                  { name: 'Outsole', value: 'outsole' },
                  { name: 'Upper', value: 'upper' },
                  { name: 'Style', value: 'styleNumeric' }
                ]
              },
              POP: {
                options: [
                  { name: 'Gender', value: 'genderPop' },
                  { name: 'Pillar', value: 'pillar' },
                  { name: 'Style', value: 'styleNumeric' }
                ]
              }
            },
            Internal: {
              default: {
                options: [
                  { name: 'Gender', value: 'gender' },
                  { name: 'DivisionGroup', value: 'divisionGroup' },
                  { name: 'Division', value: 'division' },
                  { name: 'Pillar', value: 'pillar' },
                  { name: 'Outsole', value: 'outsole' },
                  { name: 'Upper', value: 'upper' },
                  { name: 'Style', value: 'styleNumeric' }
                ]
              }
            }
          }
        }
      },
      ProductDocument: {
        properties: {
          docType: {
            options: _get(rootState, 'options.data.ProductDocument.default.docType', [])
          },
          lang: {
            options: _get(rootState, 'options.data.ProductDocument.inUse.lang', []),
            optionsInUse: _get(rootState, 'options.data.ProductDocument.inUse.lang', []),
            optionsInUseWithDefault: _get(rootState, 'options.data.ProductDocument.inUseWithDefault.lang', [])
          },
          groups: {
            options: userGroup
          },
          divisionId: {
            options: divList
          },
          gender: {
            options: genderListProductDoc
          },
          region: {
            options: _get(rootState, 'options.data.ProductDocument.default.region', [])
          }
        }
      },
      Assortment: {
        properties: {
          channel: {
            options: _get(rootState, 'options.data.channel', [])
          },
          season: {
            options: _get(rootState, 'options.data.season', [])
          },
          seasonRetail: {
            options: _get(rootState, 'options.data.season3', [])
          },
          year: {
            options: _get(rootState, 'options.data.year', [])
          },
          internalAssortmentState: {
            options: _get(rootState, 'options.data.internalAssortmentState', [])
          },
          seasonState: {
            options: _get(rootState, 'options.data.seasonState', [])
          },
          productType: {
            options: productTypeList
          },
          subTitle: {
            options: customerList
          }
        }
      },
      StaticAssortment: {
        properties: {
          season: {
            options: _get(rootState, 'options.data.season', [])
          },
          year: {
            options: _get(rootState, 'options.data.year', [])
          },
          channel: {
            options: _get(rootState, 'options.data.channel', [])
          }
        }
      },
      User: {
        // These are no longer provided by BE
        options: usersList,
        // options: usersList,
        // Filtered lists of users based on user permisions
        // optionsProduct: productUsersList,
        // optionsProductAssortment: productAssortmentUsersList,
        // optionsProductDocument: productDocumentUsersList,
        // optionsGlobalRetailAsset: globalRetailAssetUsersList,
        // optionsGlobalRetailResource: globalRetailResourceUsersList,
        // optionsAdvertisingLogo: advertisingLogoUsersList,
        // optionsAdvertisingAsset: advertisingAssetUsersList,

        properties: {
          provided: {
            properties: {
              entity: {
                options: _get(rootState, 'options.data.entity', [])
              },
              country: {
                options: _get(rootState, 'options.data.country', [])
              }
            }
          }
        }
      },
      AssetFile: {
        properties: {
          usageType: {
            options: _get(rootState, 'options.data.AssetFile.default.usageType', [])
          }
        }
      },
      AdvertisingAsset: {
        properties: {
          tag: {
            options: _get(rootState, 'options.data.AdvertisingAsset.default.tag', []),
            optionsInUse: _get(rootState, 'options.data.AdvertisingAsset.inUse.tag', [])
          },
          region: {
            options: _get(rootState, 'options.data.AdvertisingAsset.default.region', [])
          },
          status: {
            options: _get(rootState, 'options.data.AdvertisingAsset.default.status', [])
          },
          orientation: {
            options: _get(rootState, 'options.data.orientation', [])
          },
          season: {
            options: _get(rootState, 'options.data.AdvertisingAsset.default.season', []),
            optionsEditor: _get(rootState, 'options.data.AdvertisingAsset.default.seasonEditor', []),
            default: _get(rootState, 'options.data.AdvertisingAsset.default.seasonDefault', '')
          },
          assetType: {
            options: _get(rootState, 'options.data.AdvertisingAsset.default.assetType', []),
            optionsEditor: _get(rootState, 'options.data.AdvertisingAsset.default.assetTypeEditor', [])
          },
          storeType: {
            options: _get(rootState, 'options.data.AdvertisingAsset.default.storeType', [])
          },
          productSubType: {
            options: _get(rootState, 'options.data.AdvertisingAsset.default.productSubType', [])
          },
          usage: {
            options: _get(rootState, 'options.data.AdvertisingAsset.default.usage', [])
          },
          gender: {
            options: _get(rootState, 'options.data.AdvertisingAsset.default.gender', [])
          },
          year: {
            options: _get(rootState, 'options.data.AdvertisingAsset.default.year', []),
            // optionsInUse: _get(rootState, 'options.data.AdvertisingAsset.inUse.year', []),
            optionsInUseWithDefault: _get(rootState, 'options.data.AdvertisingAsset.inUseWithDefault.year', [])
          },
          category: {
            options: _get(rootState, 'options.data.AdvertisingAsset.default.category', [])
          },
          vmCategory: {
            options: _get(rootState, 'options.data.AdvertisingAsset.default.vmCategory', [])
          },
          mediaDisplay: {
            options: _get(rootState, 'options.data.AdvertisingAsset.default.mediaDisplay', [])
          },
          seasonDefault: _get(rootState, 'options.data.AdvertisingAsset.default.seasonDefault'),
          yearDefault: _get(rootState, 'options.data.AdvertisingAsset.default.yearDefault')
        }
      },
      AdvertisingLogo: {
        properties: {
          category: {
            options: _get(rootState, 'options.data.AdvertisingLogo.default.category', [])
          },
          gender: {
            options: _get(rootState, 'options.data.AdvertisingLogo.default.gender', [])
          }
        }
      },
      GlobalRetailAsset: {
        properties: {
          season: {
            options: _get(rootState, 'options.data.GlobalRetailAsset.default.season', [])
          },
          entity: {
            options: _get(rootState, 'options.data.GlobalRetailAsset.default.entity', [])
          },
          storeType: {
            options: _get(rootState, 'options.data.GlobalRetailAsset.default.storeType', [])
          },
          category: {
            options: _get(rootState, 'options.data.GlobalRetailAsset.default.category', [])
          },
          lang: {
            options: _get(rootState, 'options.data.GlobalRetailAsset.inUse.lang', []),
            optionsInUse: _get(rootState, 'options.data.GlobalRetailAsset.inUse.lang', []),
            optionsInUseWithDefault: _get(rootState, 'options.data.GlobalRetailAsset.inUseWithDefault.lang', [])
          },
          year: {
            options: _get(rootState, 'options.data.GlobalRetailAsset.inUse.year', []),
            optionsInUse: _get(rootState, 'options.data.GlobalRetailAsset.inUse.year', []),
            optionsInUseWithDefault: _get(rootState, 'options.data.GlobalRetailAsset.inUseWithDefault.year', [])
          },
          assetType: {
            options: _get(rootState, 'options.data.GlobalRetailAsset.VisualAsset.default.assetType', []),
            optionsVisualAsset: _get(rootState, 'options.data.GlobalRetailAsset.VisualAsset.default.assetType', []),
            optionsResource: _get(rootState, 'options.data.GlobalRetailAsset.Resource.default.assetType', [])
          },
          assetSubType: {
            options: assetSubTypeOptions,
            optionsInUse: assetSubTypeOptions
          },
          gender: {
            options: _get(rootState, 'options.data.GlobalRetailAsset.default.gender', [])
          },
          element: {
            options: _get(rootState, 'options.data.GlobalRetailAsset.inUse.element', []),
            optionsInUse: _get(rootState, 'options.data.GlobalRetailAsset.inUse.element', [])
          },
          initiative: {
            options: _get(rootState, 'options.data.GlobalRetailAsset.inUse.initiative', []),
            optionsInUse: _get(rootState, 'options.data.GlobalRetailAsset.inUse.initiative', [])
          },
          dimensions: {
            options: _get(rootState, 'options.data.GlobalRetailAsset.inUse.dimensions', []),
            optionsInUse: _get(rootState, 'options.data.GlobalRetailAsset.inUse.dimensions', [])
          },
          lastEditedByUser: {
            optionsInUse: _get(rootState, 'options.data.GlobalRetailAsset.inUse.lastEditedByUser', [])
          },
          yearDefault: _get(rootState, 'options.data.GlobalRetailAsset.default.yearDefault')
        }
      },
      StepAsset: {
        properties: {
          divisionId: {
            options: divList
          },
          lastEditedByUser: {
            optionsInUse: _get(rootState, 'options.data.StepAsset.inUse.lastEditedByUser', [])
          },
          gender: {
            options: _get(rootState, 'options.data.StepAsset.default.gender', [])
          },
          season: {
            options: _get(rootState, 'options.data.StepAsset.default.season', [])
          },
          year: {
            options: _get(rootState, 'options.data.StepAsset.inUse.year', []),
            optionsInUse: _get(rootState, 'options.data.StepAsset.inUse.year', []),
            optionsInUseWithDefault: _get(rootState, 'options.data.StepAsset.inUseWithDefault.year', [])
          },
          assetType: {
            options: _get(rootState, 'options.data.StepAsset.default.assetType', [])
          },
          lang: {
            options: _get(rootState, 'options.data.StepAsset.inUse.lang', []),
            optionsInUse: _get(rootState, 'options.data.StepAsset.inUse.lang', []),
            optionsInUseWithDefault: _get(rootState, 'options.data.StepAsset.inUseWithDefault.lang', [])
          }
        }
      },
      InternalAssortments: {
        properties: {
          combinedWholesale: {
            options: combinedWholesale
          },
          combinedDivisionGroupWholesale: {
            options: combinedDivisionGroupWholesale
          },
          combinedRetail: {
            options: combinedRetail
          },
          combinedDivisionGroupRetail: {
            options: combinedDivisionGroupRetail
          },
          internalAssortmentState: {
            options: _get(rootState, 'options.data.internalAssortmentState', [])
          },
          seasonState: {
            options: _get(rootState, 'options.data.seasonState', [])
          },
          season: {
            options: _get(rootState, 'options.data.season', [])
          },
          year: {
            options: _get(rootState, 'options.data.year', [])
          },
          channel: {
            options: _get(rootState, 'options.data.channel', [])
          }

        }
      },
      locations: locationList,

      Orders: {
        default: {
          product: _get(rootState, 'options.data.SampleOrder.default.product', []),
          status: _get(rootState, 'options.data.SampleOrder.default.status', []),
          shipperFilteredForDropdown: shipperFilteredForDropdown,
          shippersObject: _get(rootState, 'options.data.SampleOrder.default.product.shipper', []),
          quantity: [1, 2, 3, 4, 5, 6, 7, 8, 9]
        },

        PROMO: {
          properties: {
            reason: _get(rootState, 'options.data.SampleOrder.PROMO.default.reason', []),
            availDateWindow: _get(rootState, 'options.data.SampleOrder.PROMO.default.availDateWindow', null),
          }
        },

        SAMPLE: {
          properties: {
            coordinatorName: coordinatorNames,
            coordinator: _get(rootState, 'options.data.SampleOrder.SAMPLE.default.coordinator', []).map(item => ({ ...item, coordinatorName: `${item.name} ${item.lastName}` })),
            coordinatorAssignment: _get(rootState, 'options.data.SampleOrder.SAMPLE.default.coordinatorAssignment', []),
            coordinatorAssignmentUnique: _uniqBy(_get(rootState, 'options.data.SampleOrder.SAMPLE.default.coordinatorAssignment', []), name => name.coordinatorCode).sort((a, b) => a.coordinatorName.localeCompare(b.coordinatorName)),
            deliveryMethod: _get(rootState, 'options.data.SampleOrder.SAMPLE.default.deliveryMethod', []),
            reason: _get(rootState, 'options.data.SampleOrder.SAMPLE.default.reason', []),
            sizePreference: ['Preferred', 'Required']
          }
        },

        WHOLESALE: {
          properties: {
            additionalTerm: _get(rootState, 'options.data.WholesaleOrder.default.additionalTerm', []),
            origin: _get(rootState, 'options.data.WholesaleOrder.default.origin', []),
            region: _get(rootState, 'options.data.WholesaleOrder.default.region', []),
            shippingMethod: _get(rootState, 'options.data.WholesaleOrder.default.shippingMethod', []),
            termsByRegion: _get(rootState, 'options.data.WholesaleOrder.default.termsByRegion', []),
            termsByCountry: _get(rootState, 'options.data.WholesaleOrder.default.termsByCountry', []),
            packCodes: _get(rootState, 'options.data.WholesaleOrder.default.packCodes', []),
            currency: _get(rootState, 'options.data.WholesaleOrder.default.currency', []),
          }
        }
      },

      SampleInventory: {
        default: {
          properties: {
            gender: {
              options: _get(rootState, 'options.data.SampleInventory.default.gender', []).map(v => ({ label: v, value: v }))
            },
            library: {
              options: _get(rootState, 'options.data.SampleInventory.default.library', []).map(v => ({ label: v, value: v }))
            },
            side: {
              options: _get(rootState, 'options.data.SampleInventory.default.side', []).map(v => ({ label: v, value: v }))
            },
            size: {
              options: _get(rootState, 'options.data.SampleInventory.default.size', []).map(v => ({ label: v, value: v }))
            },
            actions: {
              options: _get(rootState, 'options.data.SampleInventoryTracking.default.action', []).map(v => ({ label: v, value: v }))
            }
          }
        },
        Production: {
          properties: {
            bin: {
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Production.inUse.bin', []).map(v => ({ label: v, value: v }))
            },
            createdByUser: {
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Production.inUse.createdByUser', []).map(v => ({ label: v, value: v }))
            },
            division: {
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Production.inUse.division', []).map(v => ({ label: v, value: v }))
            },
            lastEditedByUser: {
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Production.inUse.lastEditedByUser', []).map(v => ({ label: v, value: v }))
            },
            quantity: {
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Production.inUse.quantity', []).map(v => ({ label: v, value: v }))
            },
            side: {
              options: _get(rootState, 'options.data.SampleInventory.default.side', []).map(v => ({ label: v, value: v })), // DEFAULT
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Production.inUse.side', []).map(v => ({ label: v, value: v }))
            },
            size: {
              options: _get(rootState, 'options.data.SampleInventory.default.size', []).map(v => ({ label: v, value: v })), // DEFAULT
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Production.inUse.size', []).map(v => ({ label: v, value: v }))
            },
            status: {
              options: _get(rootState, 'options.data.SampleInventory.Production.default.status', []).sort().map(v => ({ label: v, value: v })), // DEFAULT RELATIVE TO PRODUCTION
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Production.inUse.status', []).sort().map(v => ({ label: v, value: v }))
            }
          }
        },

        Sales: {
          properties: {
            createdByUser: {
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Sales.inUse.createdByUser', []).map(v => ({ label: v, value: v }))
            },
            division: {
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Sales.inUse.division', []).map(v => ({ label: v, value: v }))
            },
            lastEditedByUser: {
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Sales.inUse.lastEditedByUser', []).map(v => ({ label: v, value: v }))
            },
            quantity: {
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Sales.inUse.quantity', []).map(v => ({ label: v, value: v }))
            },
            shelf: {
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Sales.inUse.shelf', []).map(v => ({ label: v, value: v }))
            },
            region: {
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Sales.inUse.region', []).map(v => ({ label: v, value: v }))
            },
            aisle: {
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Sales.inUse.aisle', []).map(v => ({ label: v, value: v }))
            },
            direction: {
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Sales.inUse.direction', []).map(v => ({ label: v, value: v }))
            },
            level: {
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Sales.inUse.level', []).map(v => ({ label: v, value: v }))
            },
            side: {
              options: _get(rootState, 'options.data.SampleInventory.default.side', []).map(v => ({ label: v, value: v })), // DEFAULT
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Sales.inUse.side', []).map(v => ({ label: v, value: v }))
            },
            size: {
              options: _get(rootState, 'options.data.SampleInventory.default.size', []).map(v => ({ label: v, value: v })), // DEFAULT
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Sales.inUse.size', []).map(v => ({ label: v, value: v }))
            },
            status: {
              options: _get(rootState, 'options.data.SampleInventory.Sales.default.status', []).sort().map(v => ({ label: v, value: v })), // DEFAULT RELATIVE TO SALES
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Sales.inUse.status', []).sort().map(v => ({ label: v, value: v }))
            },
            newBin: {
              optionsInUse: _get(rootState, 'options.data.SampleInventory.Sales.inUse.newBin', []).map(v => ({ label: v, value: v }))
            },
          }
        }
      }
    }

    // hard coded values array to match how the files are coming in from options
    const internalsArray = ['InternalStaticRetailDomestic', 'InternalStaticRetailInternational', 'InternalStaticRetailEurope', 'InternalStaticWholesaleDomestic', 'InternalStaticWholesaleInternational', 'InternalStaticWholesaleEurope']
    for (const internalType of internalsArray) {
      let optionsStarterName = 'options.data.' + internalType
      if (internalType === 'InternalStaticRetailEurope') {
        optionsStarterName = 'options.data.InternalStaticRetailInternational'
      } else if (internalType === 'InternalStaticWholesaleEurope') {
        optionsStarterName = 'options.data.InternalStaticWholesaleInternational'
      }
      optionsData[internalType] = {
        properties: {
          boughtStatus: {
            options: _get(rootState, optionsStarterName + '.default.boughtStatus', [])
          },
          category: {
            options: _get(rootState, optionsStarterName + '.inUse.category', []),
            optionsInUse: _get(rootState, optionsStarterName + '.inUse.category', [])
          },
          pillar: {
            options: _get(rootState, optionsStarterName + '.inUse.pillar', [])
          },
          samples: {
            options: _get(rootState, optionsStarterName + '.default.products.default.samples', [])
          },
          lifeCycle: {
            options: _get(rootState, optionsStarterName + '.default.products.default.lifeCycle', [])
          },
          rushSamples: {
            options: _get(rootState, optionsStarterName + '.default.products.default.rushSamples', [])
          },
          storeType: {
            options: _get(rootState, optionsStarterName + '.default.products.default.storeType', [])
          },
          availableSeasons: {
            options: _get(rootState, optionsStarterName + '.default.availableSeasons', []).map(v => ({ ...v, label: v.name }))
          },
          divisionGroup: {
            options: _get(rootState, optionsStarterName + '.default.divisionGroup', [])
          },
          currentSeason: {
            value: _get(rootState, optionsStarterName + '.default.currentSeason', '')
          },
          currentYear: {
            value: _get(rootState, optionsStarterName + '.default.currentYear', '')
          },
          defaultRegion: {
            value: _get(rootState, optionsStarterName + '.default.defaultRegion', '')
          },
          state: {
            value: _get(rootState, optionsStarterName + '.default.state', ITS__ASSORTMENTS__STATE__PUBLISHED)
          }
        }
      }
    }

    commit(VUEX_PROPERTIES_UPDATE, optionsData)
    commit(VUEX_PROPERTIES_INITIALIZED)
  },
  // when you just want to update a few things directly
  [VUEX_PROPERTIES_PARTIAL_UPDATE]: async ({ commit }, payload) => {
    commit(VUEX_PROPERTIES_UPDATE, payload)
  },
  [VUEX_PROPERTIES_ARRAY_UPDATE]: async ({ commit }, payload) => {
    commit(VUEX_PROPERTIES_ARRAY_UPDATE, payload)
  }
}

/**
 * Properties module mutation definitions
 * @const
 */
const mutations = {
  // update using deep merge
  [VUEX_PROPERTIES_UPDATE]: (state, data) => {
    const stateCopy = _clone(state.data)
    state.data = _merge(stateCopy, data)
    // state = Object.freeze(_merge(stateCopy, data))

    /* const stateData = _clone(state)
    const newData = _merge(stateData, data)
    for (const key of Object.keys(newData)) {
      state[key] = newData[key]
      // Vue.set(state, key, newData[key])
    } */

    d.log('Properties after initialization. *All components should reference property options through here!*', state)
  },

  [VUEX_PROPERTIES_ARRAY_UPDATE]: async (state, data) => {
    // matthew fix 9/21/2023
    // this is a super deep change.  but array was coming back undefined.  realized it needs to hook to state.data
    // not sure when this broke
    const array = _get(state.data, data.key)

    let index
    switch (data.action) {
      case 'add':
        let values
        if (Array.isArray(data.value)) {
          values = data.value
        } else {
          values = [data.value]
        }
        values.forEach(function (val, index2) {
          if (val) {
            if (!array.includes(val)) { // make sure it's not already in the array
              index = insertSortedIndex(array, val)
              if (array[index] === val) return
              array.splice(index, 0, val)
            }
          }
        })
        return
      case 'remove':
        index = array.findIndex(i => i === data.oldValue)
        if (index < 0) return
        array.splice(index, 1)
        return
      case 'rename':
        index = array.findIndex(i => i === data.oldValue)
        if (index < 0) return
        array.splice(index, 1, data.newValue)
        return
      default:
        d.log('Bad action', data.action)
    }
  },

  [VUEX_PROPERTIES_INITIALIZED]: state => {
    state.initialized = true
  }
}

/**
 * Properties module of our Vuex store.
 * @module store/modules/properties
 */

export default {
  state,
  getters,
  actions,
  mutations
}
