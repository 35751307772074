import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import ColumnHelpers from '@/components/_core/GridsCore/helpers/ColumnHelpers'
import ExcelErrors from '@/components/_core/GridsCore/helpers/ExcelErrors'
import XLSX from 'xlsx'
import cloneDeep from 'lodash/cloneDeep'
import _trim from 'lodash.trim'
import router from '@/router'
import longWaitBlocker from '@/helpers/longWaitBlocker'
import BigNumber from 'bignumber.js'
import {
  VUEX_GRID_REKEY_EXCEL_UPLOADER
} from '@/store/constants/models/assortments'
import shared from 'skch_its_be_fe_shared'
import properties from '@/store/modules/config/properties'
import DataMiddleware from '@/components/_core/GridsCore/helpers/DataMiddleware'
import canvas from 'canvas'

const pricesheet = shared.pricesheet
const imgSrc = shared.productImage.imgSrc

let ExcelMiddleware = {
  // variables
  step: 1,
  agGridColumns: [],
  excelColumns: [],
  modelObj: {},
  worksheetArray: [],
  worksheetArrayObjects: [],
  valueMapping: [],
  rowData: [],
  clickedImportOnce: false,
  validationSequenceLoading: false,
  entriesAreBeingAddedFromExcelImport: false,
  importGridThis: null,
  bulkProductType: null,
  subtype: '',

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // INIT
  reinit: function () {
    const t = GridHelpers.mgStashThisArray[0]
    let oldSubtype = ExcelMiddleware.subtype
    ExcelMiddleware.modelObj = {}
    ExcelMiddleware.worksheetArray = []
    ExcelMiddleware.worksheetArrayObjects = []
    ExcelMiddleware.valueMapping = []
    ExcelMiddleware.step = 1
    ExcelMiddleware.agGridColumns = []
    ExcelMiddleware.clickedImportOnce = false
    ExcelMiddleware.setValidationSequenceLoading(false)
    ExcelMiddleware.errorObject = {}
    ExcelMiddleware.subtype = t?.subtype
    if (ExcelMiddleware.importGridThis) {
      if (t?.type === 'sample-inventory-grid') {
        ExcelMiddleware.importGridThis.productIntegrationStep = 'clear'
      } else {
        ExcelMiddleware.importGridThis.productIntegrationStep = 'clear'
        ExcelMiddleware.importGridThis.$store.dispatch('VUEX_PRODUCTS_CLEAR')
      }
      ExcelMiddleware.importGridThis.validationErrorsToggler = Math.random()
    }

    // clear out old row data? generally yes.
    let clearOldOldData = true
    if (t?.type === 'sample-inventory-grid') { // leave existing logic for sample inventory
      if (oldSubtype === ExcelMiddleware.subtype) {
        clearOldOldData = false // don't clear out old data if you are in the same Production or Sales subtype
      }
    }
    if (clearOldOldData) {
      ExcelMiddleware.rowData = []
    }

    ExcelErrors.reinit()
  },
  setImportGridThis (t) {
    ExcelMiddleware.importGridThis = t
  },
  restoreImportGridThis () {
    let killOld = true
    GridHelpers.setMasterGridThis(ExcelMiddleware.importGridThis, killOld)

    // clear out old import grid data
    if (GridHelpers.mgThisArray[0].gridApi) {
      let rows = GridHelpers.getRowNodes()
      GridHelpers.mgThisArray[0].gridApi.applyTransaction({ remove: rows })
    }
  },

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // IMPORT
  uploadExcel: function (e) {
    ExcelMiddleware.reinit()

    let reader = new FileReader()
    reader.onload = (event) => {
      let data = event.target.result
      let workbook = XLSX.read(data, { type: 'binary' })
      ExcelMiddleware.mapXMLColumnsToImportGridColumns(workbook)
    }
    if (e.target.files) {
      let file = e.target.files[0]
      reader.readAsBinaryString(file)
    }
  },
  sheet2Arr: function (sheet) {
    let result = []
    let row = null
    let rowNum = null
    let colNum = null
    if (sheet['!ref']) {
      let range = XLSX.utils.decode_range(sheet['!ref'])
      for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        row = []
        for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
          let nextCell = sheet[XLSX.utils.encode_cell({ r: rowNum, c: colNum })]
          if (typeof nextCell === 'undefined') {
            row.push(void 0)
          } else {
            row.push(nextCell.w)
          }
        }
        result.push(row)
      }
    }
    return result
  },

  mapXMLColumnsToImportGridColumns: function (workbook) {
    // check the first sheet
    let firstSheetName = workbook.SheetNames[0]
    let worksheet = workbook.Sheets[firstSheetName]
    ExcelMiddleware.worksheetArray = ExcelMiddleware.sheet2Arr(worksheet)

    // get a list of all Excel Columns
    ExcelMiddleware.excelColumns = []
    if (ExcelMiddleware.worksheetArray[0]) {
      for (let i = 0; i < ExcelMiddleware.worksheetArray[0].length; i++) {
        if (ExcelMiddleware.worksheetArray[0][i]) {
          let obj = {
            value: 'col-' + i,
            text: ExcelMiddleware.worksheetArray[0][i]
          }
          ExcelMiddleware.excelColumns.push(obj)
        }
      }
    }

    // get a list of all the usable grid columns
    // SUBTYPE SPECIFIC: read from the stash array if nested (eg: assortment manager), or the current grid if not (eg: bulk download)
    let t = GridHelpers.mgThisArray[0]
    let tStash = GridHelpers.mgStashThisArray[0]
    let gridColumns
    let type
    if (ExcelMiddleware.importGridThis.subtype === 'bulk-downloader') {
      gridColumns = t.gridApi.getColumns() // current
      type = t.type
    } else if (tStash?.type === 'orders-detail') {
      gridColumns = t.gridApi.getColumns() // current
      type = tStash.type
    } else {
      gridColumns = tStash.gridApi.getColumns() // parent
      type = tStash.type
    }
    ExcelMiddleware.agGridColumns = GridHelpers.getEditableColumns(gridColumns, type)

    // Try to figure out the defaults!
    for (let i = 0; i < ExcelMiddleware.agGridColumns.length; i++) {
      const colObj = ExcelMiddleware.agGridColumns[i]
      const colTxt = colObj.text.toLowerCase()
      const colVal = colObj.value.toLowerCase()
      let index = -1

      // 1: DIRECT NAME to NAME MATCH
      let match = ExcelMiddleware.excelColumns.filter(x => x['text'].toLowerCase() === colTxt)
      if (match.length > 0) {
        index = match[0].value
      }

      // 2: EXCEL NAME = COLUMN FIELD ID MATCH
      if (index === -1) {
        match = ExcelMiddleware.excelColumns.filter(x => x['text'].toLowerCase() === colVal)
        if (match.length > 0) {
          index = match[0].value
        }
      }

      // 3: EXCEL NAME = PRODUCT PROPERTIES MATCH
      if (index === -1) {
        let propObj = properties.state.data.Product.properties[colObj.value] // don't lower case this, case-sensitive key
        if (propObj) {
          match = ExcelMiddleware.excelColumns.filter(x => x['text'].toLowerCase() === propObj.label.toLowerCase())
          if (match.length > 0) {
            index = match[0].value
          }
        }
      }

      // 4: EXCEL NAME CONTAINS COLUMN NAME
      if (index === -1) {
        match = ExcelMiddleware.excelColumns.filter(x => x['text'].toLowerCase().includes(colTxt))
        if (match.length > 0) {
          index = match[0].value
        }
      }

      // 5: EXCEL NAME CONTAINS COLUMN FIELD
      if (index === -1) {
        match = ExcelMiddleware.excelColumns.filter(x => x['text'].toLowerCase().includes(colVal))
        if (match.length > 0) {
          index = match[0].value
        }
      }

      // DONE: IF MATCHED, SET THE RELATIONSHIP
      if (index !== -1) {
        ExcelMiddleware.modelObj[colObj.value] = index
      }
    } // for

    t.openDialog({
      content: '_core/Dialogs/Grids/Dialog_ExcelColumnMap.vue',
      title: 'Column Associations',
      subtitle: 'Finish Your Excel Mapping ',
      data: {}
    })
  },

  // after we map/associate the columns for aggrid and excel,
  // let's do a product lookup, grab default values, then populate aggrid
  importExcelDataToImportGrid (valueMapping) {
    const t = GridHelpers.mgStashThisArray[0]
    ExcelMiddleware.valueMapping = valueMapping
    let productsToLookup = []
    for (let i = 1; i < ExcelMiddleware.worksheetArray.length; i++) {
      let row = ExcelMiddleware.worksheetArray[i]
      let style = ''
      let color = ''
      let obj = {
        origdata: {}
      }
      for (let prop in valueMapping) {
        if (valueMapping[prop]) {
          let field = prop.toString()
          let value = valueMapping[prop].toString()
          value = value.replace('col-', '')

          let cleanRowObj = ExcelMiddleware.cleanExcelImportValueCell(row[value], field)

          // if a value exists, set it
          obj.origdata[field] = cleanRowObj.valueOriginal
          obj[field] = cleanRowObj.valueFinal

          // special style and color variables to set id after
          if (field === 'style') {
            style = cleanRowObj.valueFinal
          } else if (field === 'color') {
            color = cleanRowObj.valueFinal
          }
        }
      }

      // if style, color can be blank
      if (style) {
        obj.id = style + '-' + color
        productsToLookup.push(obj)
      }
    }

    if (t?.type === 'sample-inventory-grid' || t?.type === 'orders-detail') {
      ExcelMiddleware.importExcelDataToImportGrid_Step2_NoProductsLookup(productsToLookup)
    } else {
      ExcelMiddleware.importExcelDataToImportGrid_Step2_ProductsLookup(productsToLookup)
    }
  },
  importExcelDataToImportGrid_Step2_NoProductsLookup (productsToLookup) {
    // just go right into refactor and add

    ExcelMiddleware.rowData = []
    for (let i = 0; i < productsToLookup.length; i++) {
      productsToLookup[i].id = Math.random() // imported uses random id method
      ExcelMiddleware.rowData.push(productsToLookup[i])
    }

    // rekey and hide blocker
    ExcelMiddleware.importGridThis.$store.dispatch(VUEX_GRID_REKEY_EXCEL_UPLOADER)
    longWaitBlocker.hide()
  },
  importExcelDataToImportGrid_Step2_ProductsLookup (productsToLookup) {
    if (ExcelMiddleware.importGridThis.productIntegrationStep === 'clear') {
      ExcelMiddleware.importGridThis.productIntegrationStep = 'init'
    }
    ExcelMiddleware.worksheetArrayObjects = cloneDeep(productsToLookup)
    // do a product lookup
    let styleColors = []
    for (let i = 0; i < productsToLookup.length; i++) {
      // if color is blank, the back end wants color to be undefined, not blank
      if (productsToLookup[i].color === '') {
        delete productsToLookup[i].color
      }
      let obj = {
        'style': productsToLookup[i].style,
        'color': productsToLookup[i].color
      }
      styleColors.push(obj)
    }
    if (styleColors.length > 0) {
      DataMiddleware.productsFetch(styleColors)
    } else {
      longWaitBlocker.hide()
      GridHelpers.mgThisArray[0].hideOverlay()
    }
  },
  importExcelDataToImportGrid_Step2_ProductsLookupSuccess () {
    // check if has color
    const spreadsheetHasColor = !!(ExcelMiddleware.valueMapping.color)
    let uniqueStylesAdded = [] // maintain a list of unique styles added

    // got products, now merge with imported excel data and import
    let products = ExcelMiddleware.importGridThis.products
    ExcelMiddleware.rowData = []
    for (let i = 0; i < ExcelMiddleware.worksheetArrayObjects.length; i++) {
      let rowOG = ExcelMiddleware.worksheetArrayObjects[i]
      let finalRow = cloneDeep(rowOG)

      // get product fetch row
      let rowProductFetch = {}
      let rowProductFetchArray = products.filter(x => x['styleColorId'] === rowOG.styleColorId)
      if (rowProductFetchArray.length > 0) {
        // parse and add
        rowProductFetch = rowProductFetchArray[0]
        finalRow = { ...rowProductFetch, ...rowOG }
        ExcelMiddleware.importExcelDataToImportGrid_Step3_RefactorDataAndAdd(finalRow)
      } else {
        // if no color, grab all products and output them
        if (!rowOG.color) {
          // for style only,do a lookup of all colors
          // unless the spreadsheet has no colors mapped, then be sure to do it only ONCE for style
          let doLookup = true
          if (!spreadsheetHasColor) {
            let existingObj = uniqueStylesAdded.includes(rowOG.style.toLowerCase())
            if (existingObj) {
              doLookup = false
            } else {
              uniqueStylesAdded.push(rowOG.style.toLowerCase())
            }
          }

          if (doLookup) {
            let rowProductFetchArray = products.filter(x => x['style'] === rowOG.style)
            if (rowProductFetchArray.length > 0) {
              for (let ii = 0; ii < rowProductFetchArray.length; ii++) {
                // parse and add
                rowProductFetch = rowProductFetchArray[ii]
                let finalRow = {
                  style: rowOG.style
                }
                finalRow = { ...finalRow, ...rowProductFetch }
                ExcelMiddleware.importExcelDataToImportGrid_Step3_RefactorDataAndAdd(finalRow)
              }
            } else {
              ExcelMiddleware.importExcelDataToImportGrid_Step3_RefactorDataAndAdd(finalRow)
            }
          }
        } else {
          ExcelMiddleware.importExcelDataToImportGrid_Step3_RefactorDataAndAdd(finalRow)
        }
      }
    }

    // rekey and hide blocker
    ExcelMiddleware.importGridThis.$store.dispatch(VUEX_GRID_REKEY_EXCEL_UPLOADER)
    longWaitBlocker.hide()

    // kick off validate for bulk edit
    if (ExcelMiddleware.importGridThis.subtype === 'bulk-downloader') {
      ExcelMiddleware.importGridThis.$nextTick(() => {
        setTimeout(() => {
          ExcelMiddleware.importGridThis.validateAndContinue()
        }, 100)
      })
    }
  },

  importExcelDataToImportGrid_Step3_RefactorDataAndAdd (finalRow) {
    const tStash = GridHelpers.mgStashThisArray[0]
    finalRow = cloneDeep(finalRow)

    // add origdata holder
    if (!finalRow.origdata) finalRow.origdata = {}

    // check for discount cost, amount and percent
    if (finalRow.origdata.netCost) {
      // do nothing
    } else if (finalRow.cost) {
      let cost = BigNumber(ColumnHelpers.numberRemoveExtraneous(finalRow.cost))
      if (finalRow.origdata.discountPercent) {
        let discountPercent = BigNumber(ColumnHelpers.numberRemoveExtraneous(finalRow.origdata.discountPercent))
        finalRow.netCost = pricesheet.netCostFromDiscountPercentage(cost, discountPercent, -1)
      } else if (finalRow.origdata.discountAmount) {
        let discountAmount = BigNumber(ColumnHelpers.numberRemoveExtraneous(finalRow.origdata.discountAmount))
        finalRow.netCost = pricesheet.netCostFromDiscountAmount(cost, discountAmount, -1)
      }
    }

    // run through filter
    if (tStash?.type !== 'orders-detail') {
      const options = {
        isInternalAssortment: (router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL),
        assortment: GridHelpers.mgThisArray[0].assortment,
        properties: properties
      }
      finalRow = pricesheet.prepAssortmentDetailDataFromBackend(finalRow, -1, options)
    }

    // add random id
    finalRow.id = Math.random() // imported uses random id method

    // add
    ExcelMiddleware.rowData.push(finalRow)
  },

  specialColumnCalculationCleanup (rowData) {
    let styleNotes = []
    let labels = GridHelpers.mgThisArray[0].assortment.labels

    for (let i = 0; i < rowData.length; i++) {
      let node = rowData[i]

      // make sure color label is in dropdown set of colors
      // if not, clear it
      // if it matches, make sure we use the "color" value of the label, which functions like an ID
      if (node.label) {
        let nodeLabelLower = node.label.toLowerCase()
        let colorMatch = labels.filter(function (x) {
          if (x.color && x.name) {
            // match either name or value
            return (x.color.toLowerCase() === nodeLabelLower || x.name.toLowerCase() === nodeLabelLower)
          } else {
            return false
          }
        })
        if (colorMatch.length === 0) {
          node.label = ''
        } else {
          node.label = colorMatch[0].color
        }
      }

      // synch style notes
      if (node.styleNote) {
        if (styleNotes[node.style]) {
          node.styleNote = styleNotes[node.style]
        } else {
          styleNotes[node.style] = node.styleNote
        }
      }
    }

    return rowData
  },

  // EXPORT!
  exportToExcel (params) {
    let assortmentName = GridHelpers.mgThisArray[0].assortment.title
    let filename = assortmentName + '.xlsx'
    let excludeColumns = ['ag-Grid-AutoColumn']
    let t = GridHelpers.mgThisArray[0]

    if (t.type === 'assortments-list') {
      switch (router.currentRoute.value.name) {
        case 'assortment-manager--archived':
          filename = 'archived-assortments.xlsx'
          break
        case 'assortment-manager--recent':
          filename = 'recent-assortments.xlsx'
          break
        case 'assortment-manager--starred':
          filename = 'starred-assortments.xlsx'
          break
        case 'assortment-manager--quick-access':
          // grab info from params since there are two per page
          if (params) {
            if (params.api.gridCore.eGridDiv.id === 'myGrid-starred') {
              filename = 'starred-quick-access-assortments.xlsx'
              t = GridHelpers.mgThisArray[0]
            } else if (params.api.gridCore.eGridDiv.id === 'myGrid-recent') {
              filename = 'recent-quick-access-assortments.xlsx'
              t = GridHelpers.mgThisArray[1]
            }
          }
          break
        default:
          filename = 'all-assortments.xlsx'
          break
      }
      excludeColumns = ['link']
    }

    // add header row
    let rowHeaders = []
    let gridColumns = t.gridApi.getColumns()
    for (let i = 0; i < gridColumns.length; i++) {
      if (excludeColumns.includes(gridColumns[i].colId) === false) {
        // let headerTitle = utils.capitalizeEachWord(gridColumns[i].colDef.headerName)
        rowHeaders.push(gridColumns[i].colId)
      }
    }

    // params
    let config = {
      // customHeader: rowHeader
      allColumns: false,
      fileName: filename,
      sheetName: assortmentName,
      skipGroups: true,
      columnKeys: rowHeaders,
      processCellCallback: function (params) {
        let val = params.value
        if (params.column.colDef.field === 'thumbnail') {
          if (!val) {
            val = imgSrc('medium', params.node.data.style, params.node.data.color, 'A', params.node.data.productType)
          }
        }
        return val
      }
    }
    if (t.type === 'assortments-list') {
      config.skipGroups = false
    }
    t.gridApi.exportDataAsExcel(config)

    GridHelpers.mgThisArray[0].$store.dispatch('VUEX_STATISTICS_SEND', {
      action: ITS__STATISTICS__ACTION_TYPE__ASSORTMENT__DOWNLOAD_ZIP_IMAGES
    })
  },

  cleanExcelImportValueCell (cellValue, field) {
    let valueFinal = cellValue
    // remove \n\r line breaks
    if (cellValue) {
      cellValue = cellValue.replace(/[\n\r]+/g, '')
      cellValue = _trim(cellValue)
    }

    // run parsers & setters on init import
    const gridColumns = GridHelpers.mgThisArray[0].gridApi.getColumns()
    for (let i = 0; i < gridColumns.length; i++) {
      if (gridColumns[i].colId === field) {
        // set value to blank
        if (typeof cellValue === 'undefined') {
          cellValue = ''
        }

        // simulate params
        let params = {
          api: null,
          newValue: cellValue,
          column: gridColumns[i],
          colDef: gridColumns[i].colDef,
          gridApi: GridHelpers.mgThisArray[0].gridApi,
          data: {}
        }

        // run parser to clean up data
        if (gridColumns[i].colDef.valueParser) {
          cellValue = gridColumns[i].colDef.valueParser(params)
        }

        // reinit valueAfterSetter
        valueFinal = cellValue

        // run setter too, store seperately to better use in origdata calculations
        if (gridColumns[i].colDef.valueSetter) {
          gridColumns[i].colDef.valueSetter(params)
          if (params.data[field]) {
            valueFinal = cloneDeep(params.data[field])
          } else {
            if (gridColumns[i].colDef.cellEditorParams && gridColumns[i].colDef.cellEditorParams.canAdd) {
              // can add, do nothing
            } else {
              // can't add, make blank
              valueFinal = ''
            }
          }
        }
      }
    }

    // technically valueOriginal isn't the exact original, but a slightly cleaned up version
    // valueFinal runs through aggrid setters to ensure no bad data gets introduced
    return {
      valueOriginal: cellValue,
      valueFinal: valueFinal
    }
  },

  /// ///////////////////////////////////////////////////////////////////////////
  // ADD TO GRID FINAL
  // this occurs at the end of excel import
  addToParentGrid () {
    const t = GridHelpers.mgStashThisArray[0]
    switch (t?.type) {
      case 'sample-inventory-grid':
        ExcelMiddleware.addToParentGridSampleInventory()
        break
      case 'orders-detail':
      default:
        // just calling out that orders-detail also uses assortments for lookup - but in addToParentGridAssortments2 we'll add a check to ping a different endpoint to do the actual add
        ExcelMiddleware.addToParentGridAssortments()
        break
    }
  },

  addToParentGridSampleInventory () {
    // clear all filters
    ExcelErrors.showItemsAll()
    const t = GridHelpers.mgStashThisArray[0]

    // get all items
    let payload = []
    let rows = GridHelpers.getRowNodes(false, false)
    for (let i = 0; i < rows.length; i++) {
      let node = rows[i]
      let addIt = false
      if (t?.subtype === 'Production') {
        if (node.data.division && node.data.style && node.data.color && node.data.status && node.data.side && node.data.totalQty && node.data.bin) { // only Sample Status, Side, Quantity, Bin required
          addIt = true
        }
      } else if (t?.subtype === 'Sales') {
        if (node.data.division && node.data.style && node.data.color && node.data.status && node.data.side && node.data.totalQty && node.data.region && node.data.aisle && node.data.direction && node.data.level) { // only Sample Status, Side, Quantity, region, aisle, direction, level required
          addIt = true
        }
      } // subtypes

      if (addIt) {
        let newobj = {}
        for (let i = 0; i < ExcelMiddleware.agGridColumns.length; i++) {
          const colObj = ExcelMiddleware.agGridColumns[i]
          let field = colObj.value
          if (field !== 'onHandQty') {
            newobj[field] = node.data[field]
          }
          if (field === 'totalQty') {
            let onHandQty = cloneDeep(node.data.totalQty)
            newobj['onHandQty'] = onHandQty
          }
        }
        payload.push(newobj)
      }
    }
    GridHelpers.mgThisArray[0].errorTxt = ''
    ExcelMiddleware.entriesAreBeingAddedFromExcelImport = true
    GridHelpers.mgThisArray[0].rowsAddSampleInventory(payload).then(res => {
    }) // FROM HERE, look for allImportedClosePopup triggered by a toggler
  },

  addToParentGridAssortments () {
    // lookup products again, since it could have changed
    let rows = GridHelpers.getRowNodes()
    let styleColors = []
    for (let i = 0; i < rows.length; i++) {
      let obj = {
        'style': rows[i].data.style,
        'color': rows[i].data.color
      }
      styleColors.push(obj)
    }

    // should always be > 0, but this is a failsafe
    if (styleColors.length > 0) {
      GridHelpers.mgThisArray[0].productIntegrationStep = 'final'
      DataMiddleware.productsFetch(styleColors)
    } else {
      longWaitBlocker.hide()
      GridHelpers.mgThisArray[0].hideOverlay()
    }
  },

  addToParentGridAssortments2 () {
    const t = GridHelpers.mgThisArray[0]
    const tStash = GridHelpers.mgStashThisArray[0]
    // clear all filters
    ExcelErrors.showItemsAll()

    // do it
    let payload = []
    let rows = GridHelpers.getRowNodes()
    let products = t.products
    let options = {
      properties: properties
    }
    if (tStash?.type === 'orders-detail') {
      // don't merge in product lookup data to assortment - we just use products for validation, not for data
      for (let i = 0; i < rows.length; i++) {
        let node = rows[i]
        if (node.data.style && node.data.color) {
          let newobj = JSON.parse(JSON.stringify(node.data))
          delete newobj.error
          delete newobj.origdata
          newobj = DataMiddleware.convertOrderToRowData_PolishObjectData(newobj, t.order, t.user)
          payload.push(newobj)
        } // if style and color
      } // row loop

    } else {
      options.isInternalAssortment = (router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL)
      options.assortment = t.assortment

      // merge in product lookup data to assortment
      for (let i = 0; i < rows.length; i++) {
        let node = rows[i]
        if (node.data.style && node.data.color) {
          let productObj = products.filter(x => x['style'] === node.data.style && x['color'] === node.data.color)
          if (productObj.length > 0) {
            let newobj = { ...productObj[0], ...node.data } // second overwrites the first
            newobj = cloneDeep(newobj)

            // force this
            if (productObj[0].locations) {
              newobj.locations = productObj[0].locations
            }

            // clear holding info
            delete newobj.error
            delete newobj.origdata
            newobj = JSON.parse(JSON.stringify(newobj))
            newobj = pricesheet.prepAssortmentDetailDataFromBackend(newobj, -1, options)

            payload.push(newobj)
          }// if product
        } // if style and color
      } // row loop
    }

    // add
    t.errorTxt = ''
    ExcelMiddleware.entriesAreBeingAddedFromExcelImport = true
    if (tStash?.type === 'orders-detail') {
      t.rowsAddOrdersDetail(payload).then(res => {
      }) // FROM HERE, look for allImportedClosePopup triggered by a toggler
    } else {
      t.rowsAdd(payload).then(res => {
      }) // FROM HERE, look for allImportedClosePopup triggered by a toggler
    }
  },

  oneFieldMatchesStatusColor (field) {
    const t = GridHelpers.mgStashThisArray[0]
    let ret = false
    if (t?.type === 'sample-inventory-grid') {
      if (field === 'style' || field === 'color' || field === 'side' || field === 'size' || field === 'status' || field === 'moldNum' || field === 'moldDate' || field === 'bin') {
        ret = true
      }
    } else {
      if (field === 'style' || field === 'color') {
        ret = true
      }
    }
    return ret
  },

  setValidationSequenceLoading (isLoading) {
    ExcelMiddleware.validationSequenceLoading = isLoading
    if (ExcelMiddleware.importGridThis) {
      ExcelMiddleware.importGridThis.validationErrorsToggler = Math.random()
    }
  },

  // called from footer - and also if enterGridWithoutPrompt is set
  addRows (count = 1) {
    let arr = []
    for (let i = 0; i < count; i++) {
      let obj = {
        id: Math.random(),
        origdata: {
          newRow: true
        }
      }
      arr.push(obj)
    }
    return arr
  },

  defaultExcelStyles: function () {
    return [
      {
        id: 'numberType',
        numberFormat: {
          format: '0'
        }
      },
      {
        id: 'currencyFormat',
        numberFormat: {
          format: '#,##0.00 €'
        }
      },
      {
        id: 'negativeInBrackets',
        numberFormat: {
          format: '$[blue] #,##0;$ [red](#,##0)'
        }
      },
      {
        id: 'booleanType',
        dataType: 'Boolean'
      },
      {
        id: 'stringType',
        dataType: 'String'
      },
      {
        id: 'dateType',
        dataType: 'DateTime'
      }
    ]
  },

  exportCurrentGridSimple: async function (filename = 'export') {
    let rowNodes = GridHelpers.getRowNodes()
    await ExcelMiddleware.preloadAllImages(rowNodes)
    GridHelpers.mgThisArray[0].gridApi.exportDataAsExcel({
      skipColumnGroupHeaders: true,
      fileName: filename + '.xlsx',
      sheetName: filename,
      rowHeight: 25,
      addImageToCell: (rowIndex, col, value) => {
        if (col.getColId() !== 'thumbnail') {
          return
        }

        let base64Image = null
        for (let i = 0; i < ExcelMiddleware.generator.preloadedImagesSources.length; i++) {
          const tempsrc = ExcelMiddleware.generator.preloadedImagesSources[i]
          if (tempsrc === value) {
            base64Image = ExcelMiddleware.generator.base64Images[i]
          }
        }
        if (!base64Image) {
          return
        }
        return {
          image: {
            id: Math.random(),
            base64: base64Image,
            imageType: 'jpg',
            width: 40,
            height: 40,
            position: {
              offsetX: 5,
              offsetY: 5
            }
          }
        }
      },
      processCellCallback: function (params) {
        let val = params.value
        const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.000Z$/
        if (dateRegex.test(val)) {
          // Create a date object and format it
          const date = new Date(val)
          val = date.toISOString().substring(0, 10)
        } else if (params.column.colDef.field === 'tracking' || params.column.colDef.field === 'products.tracking') {
          val = String(val)
        } else if (params.column.colDef.field === 'shipper' || params.column.colDef.field === 'products.shipper') {
          val = ColumnHelpers.getShipperNameFromCode(val)
        } else if (params.column.colDef.field === 'thumbnail') {
          if (!val) {
            if (params.node.data.style && params.node.data.color) {
              val = imgSrc('medium', params.node.data.style, params.node.data.color, 'A')
            } else if (params.node.data.products?.style && params.node.data.products?.color) {
              val = imgSrc('medium', params.node.data.products?.style, params.node.data.products?.color, 'A')
            }
          }

          // check to see if the preloader detected a base64. if it did not have one, then make the val blank.  if we dont make it blank, it'll show a url instead of a photo
          let hasBase64Image = false
          for (let i = 0; i < ExcelMiddleware.generator.preloadedImagesSources.length; i++) {
            const tempsrc = ExcelMiddleware.generator.preloadedImagesSources[i]
            if (tempsrc === val) {
              hasBase64Image = true
            }
          }
          if (!hasBase64Image) {
            val = ''
          }
        }
        return val
      } // processCellCallback
    })
  },

  preloadAllImages: async function (nodes) {
    ExcelMiddleware.generator = {
      preloadedImages: [],
      base64Images: [],
      imagePromise: [],
      preloadedImagesSources: []
    }
    const imageSize = 'medium' // 'xlarge'
    if (nodes) {
      for (let i = 0; i < nodes.length; i++) {
        // PROMISE
        ExcelMiddleware.generator.imagePromise.push(new Promise((resolve, reject) => {
          const obj = nodes[i]
          // get image
          let img = obj.data?.thumbnail
          if (!img && obj.data) {
            if (obj.data.style && obj.data.color) {
              img = imgSrc(imageSize, obj.data.style, obj.data.color, 'A')
            } else if (obj.data.products?.style && obj.data.products?.color) {
              img = imgSrc(imageSize, obj.data.products?.style, obj.data.products?.color, 'A')
            }
          }

          ExcelMiddleware.generator.preloadedImages[i] = new Image()
          ExcelMiddleware.generator.preloadedImages[i].crossOrigin = 'anonymous'
          ExcelMiddleware.generator.preloadedImages[i].onload = function () {
            // set base64Images
            let res = ExcelMiddleware.getBase64Image(ExcelMiddleware.generator.preloadedImages[i])
            ExcelMiddleware.generator.base64Images[i] = res
            resolve({
              i,
              status: 'ok'
            })
          } // img.onload

          ExcelMiddleware.generator.preloadedImages[i].onerror = function () {
            ExcelMiddleware.generator.base64Images[i] = null
            ExcelMiddleware.generator.preloadedImagesSources[i] = null
            resolve({
              i, status: 'error'
            })
          }.bind(this, i) // img.onload

          // if image, load it - else return promise
          if (img) {
            ExcelMiddleware.generator.preloadedImagesSources[i] = img
            ExcelMiddleware.generator.preloadedImages[i].src = img
          } else {
            ExcelMiddleware.generator.base64Images[i] = null
            ExcelMiddleware.generator.preloadedImagesSources[i] = null
            resolve({
              i,
              status: 'error'
            })
          }
        }))
      } // end for
      return Promise.all(ExcelMiddleware.generator.imagePromise)
    } else {
      return true
    }
  },

  getBase64Image (img) {
    const createCanvas = canvas.createCanvas
    let ret = null
    try {
      const canvas = createCanvas(img.width, img.height)
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      ret = canvas.toDataURL('image/jpg')
    } catch (err) {
    }
    // return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    return ret
  },

  // only save state for sample-inventory-grid
  saveState () {
    const t = GridHelpers.mgStashThisArray[0]
    if (t?.type === 'sample-inventory-grid') {
      let rows = GridHelpers.getRowNodes()
      let rowData = []
      for (let i = 0; i < rows.length; i++) {
        const obj = rows[i].data
        rowData.push(obj)
      }
      ExcelMiddleware.rowData = rowData
    }
  }
}
export default ExcelMiddleware
