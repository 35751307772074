export const VUEX_ROUTING_ENTER_ORDERS_SAMPLE = 'VUEX_ROUTING_ENTER_ORDERS_SAMPLE'

export const VUEX_ROUTING_ENTER_ORDERS_PROMO = 'VUEX_ROUTING_ENTER_ORDERS_PROMO'

export const VUEX_ROUTING_ENTER_ORDERS_WHOLESALE = 'VUEX_ROUTING_ENTER_ORDERS_WHOLESALE'

export const VUEX_ROUTING_ENTER_ORDERS_SAMPLE_DETAILS = 'VUEX_ROUTING_ENTER_ORDERS_SAMPLE_DETAILS'

export const VUEX_ROUTING_ENTER_ORDERS_PROMO_DETAILS = 'VUEX_ROUTING_ENTER_ORDERS_PROMO_DETAILS'

export const VUEX_ROUTING_ENTER_ORDERS_WHOLESALE_DETAILS = 'VUEX_ROUTING_ENTER_ORDERS_WHOLESALE_DETAILS'

export const VUEX_ROUTING_ENTER_ORDERS_COORDINATOR_ASSIGNMENTS = 'VUEX_ROUTING_ENTER_ORDERS_COORDINATOR_ASSIGNMENTS'
