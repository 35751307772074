
export default {
  computed: {
    computeProductString () {
      return (string, data, options) => {
        if (!data.displayFlags || options.length === 0) return string

        let str = string
        options.forEach(option => {
          if (data.displayFlags[option] && option === 'usFallback') str += ' (US)'
        })

        return str
      }
    }
  }
}
