// import Debug from 'logdown'
// const d = new Debug('its:aggrid:GridHelpers')
import i18next from 'i18next'
import _orderBy from 'lodash.orderby'
import cloneDeep from 'lodash/cloneDeep'
import truncate from 'lodash/truncate'
import shared from 'skch_its_be_fe_shared'

import router from '@/router'
import store from '@/store/store'

import { generateInternalAssortmentName } from '@/helpers/assortmentHelper'
import DataMiddleware from '@/components/_core/GridsCore/helpers/DataMiddleware'
import ColumnHelpers from '@/components/_core/GridsCore/helpers/ColumnHelpers'
import ServerSideDatasourceAssortmentManager from '@/components/_core/GridsCore/ServerSideDatasource/AssortmentManager'
import ServerSideDatasourceLibraries from '@/components/_core/GridsCore/ServerSideDatasource/Libraries'
import ServerSideDatasourceSampleInventory from '@/components/_core/GridsCore/ServerSideDatasource/SampleInventory'
import properties from '@/store/modules/config/properties'
import checkUserPermissions from '@/mixins/checkUserPermissions'
import PropertiesLookupLists from '@/components/_core/GridsCore/helpers/PropertiesLookupLists'
import { VUEX_GRID_CONFIRMATION_ARCHIVE_SELECTED } from '@/store/constants/models/assortments'
import { VUEX_GRID_CONFIRMATION_ROW_DELETE } from '@/store/constants/ui/grid'

import { VUEX_DOCUMENTS_FETCH_REQUEST } from '@/store/constants/models/documents'

import { VUEX_TOAST_ADD_TO_QUEUE } from '@/store/constants/ui/toast'

const pricesheet = shared.pricesheet

let GridHelpers = {
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // settings
  // array of this references to loaded grids
  mgThisArray: [],
  // this reference to specially stashed grid
  mgStashThisArray: [],
  // which keys are down
  keysDepressed: [],
  // used to provide buffer for re-calc calculations
  temporarilyDisableHistory: false,
  temporarilyDisableHistoryTimer: null,
  // is grid focused
  focused: false,
  // stored values related to displaying the grid
  gridHelperSettings: {},

  // selectDetailsStore
  selectionDetailsStore: {},

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // GridHelper setup functions
  rekeyMasterGrid: function () {
    this.mgThisArray = []
    this.keysDepressed = []
    DataMiddleware.clearHistories()
    ColumnHelpers.resetConfig()
  },
  // stash keeps a copy of this - (used in import spreadsheet)
  stashMasterGridThis: function () {
    GridHelpers.mgStashThisArray = GridHelpers.mgThisArray.slice(0)
  },
  // stash restores a copy of this - (used in import spreadsheet)
  restoreMasterGridThis: function () {
    GridHelpers.mgThisArray = GridHelpers.mgStashThisArray.slice(0)
  },
  setMasterGridThis: function (t, killOld) {
    if (killOld) {
      GridHelpers.mgThisArray = []
    }
    GridHelpers.mgThisArray.push(t)
    GridHelpers.resetGridHelperSettings()
  },
  resetGridHelperSettings: function () {
    GridHelpers.selectionDetailsStore = {}

    let decimalPlaces = 2
    let currencySymbol = '$'
    if (GridHelpers.isGridReady()) {
      if (GridHelpers.mgThisArray[0].assortment?.locationId) {
        let locationId = GridHelpers.mgThisArray[0].assortment.locationId
        let locations = properties.state.data.Product.properties.locations.properties.code.options
        let assortmentOptions = locations.filter((params) => params['code'] === locationId)
        if (assortmentOptions.length > 0) {
          if (assortmentOptions[0].decimalPlaces) {
            decimalPlaces = assortmentOptions[0].decimalPlaces
          }
          if (assortmentOptions[0].currencySymbol) {
            currencySymbol = assortmentOptions[0].currencySymbol
          }
        }
      }
    } else {
      setTimeout(GridHelpers.resetGridHelperSettings, 10)
    }
    GridHelpers.gridHelperSettings.decimalPlaces = decimalPlaces
    GridHelpers.gridHelperSettings.currencySymbol = currencySymbol
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  resize: function () {
    // main header
    let elem = document.querySelector('.app-view header')
    if (elem) {
      let elemStyle = window.getComputedStyle(elem)
      let elemHeight =
        elem.offsetHeight + parseInt(elemStyle.marginTop) + parseInt(elemStyle.marginBottom)
      document.documentElement.style.setProperty('--headerHeight', `${elemHeight}px`)
    }
    elem = document.querySelector('.aggrid-component .grid-header-bar')
    if (elem) {
      let elemStyle = window.getComputedStyle(elem)
      let elemHeight =
        elem.offsetHeight + parseInt(elemStyle.marginTop) + parseInt(elemStyle.marginBottom)
      document.documentElement.style.setProperty('--gridHeaderHeight', `${elemHeight}px`)
    } else {
      document.documentElement.style.setProperty('--ordersSingleSearchHeight', `0px`)
    }

    elem = document.querySelector('.orders--single-search')
    if (elem) {
      let elemStyle = window.getComputedStyle(elem)
      let elemHeight =
        elem.offsetHeight + parseInt(elemStyle.marginTop) + parseInt(elemStyle.marginBottom)
      document.documentElement.style.setProperty('--ordersSingleSearchHeight', `${elemHeight}px`)
    } else {
      document.documentElement.style.setProperty('--ordersSingleSearchHeight', `0px`)
    }
  },
  // Utility functions
  isGridReady: function () {
    let ret = false
    if (
      GridHelpers &&
      GridHelpers.mgThisArray.length > 0 &&
      GridHelpers.mgThisArray[0] &&
      GridHelpers.mgThisArray[0].gridApi
    ) {
      ret = true
    }
    return ret
  },

  // checks both group data and tree data
  isNodeGroup: function (node) {
    if (node.group || (node.data && node.data.type && node.data.type === 'folder')) {
      return true
    } else {
      return false
    }
  },

  // are we editing a cell?
  isEditingCell: function () {
    let ret = false
    let gridApi = GridHelpers.mgThisArray[0].gridApi
    let cells = gridApi.getEditingCells()
    if (cells && cells.length > 0) {
      ret = true
    }
    return ret
  },

  // is this the repository pending view?
  isLibraryPendingView () {
    return router?.currentRoute?.value.name.indexOf('pending') > -1
  },

  // checks if has master detail
  hasMasterDetail: function (params) {
    let ret = false
    const t = GridHelpers.mgThisArray[0]
    if (t.masterDetail) {
      if (t.type === 'orders-detail') {
        // if it has partial shipments
        ret = params.data?.partialShipments?.length > 0
      } else if (t.assortment?.method === ITS__ASSORTMENTS__METHOD_TYPE__STATIC) {
        // dynamic always falsee
        if (params.data?.locations?.availability) {
          if (params.data.locations.availability.length > 0) {
            // show if there is  availability
            ret = true
            // but hide if no totalInventory for R
            if (params.data.locations.lineStatus === 'R' && !params.data.locations.totalInventory) {
              ret = false
            }
          }
        }
      }
    }
    return ret
  },

  moveArray: function (arr, oldIndex, newIndex) {
    while (oldIndex < 0) {
      oldIndex += arr.length
    }
    while (newIndex < 0) {
      newIndex += arr.length
    }
    if (newIndex >= arr.length) {
      var k = newIndex - arr.length
      while (k-- + 1) {
        arr.push(undefined)
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
    return arr
  },

  // sort array by multi-column keys
  // array.sort(GridHelpers.fieldSorter(['state', '-price']))
  fieldSorter: (fields) => (a, b) =>
    fields
      .map((o) => {
        let dir = 1
        if (o[0] === '-') {
          dir = -1
          o = o.substring(1)
        }
        return a[o] > b[o] ? dir : a[o] < b[o] ? -dir : 0
      })
      .reduce((p, n) => p || n, 0),

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // DATA PARSING FUNCTIONS
  // re-parse quick column - pass in sort key and direction
  parseQuickMyAssortment (arr, field, sortDirection) {
    // let newData = JSON.parse(JSON.stringify(arr))
    let newData = cloneDeep(arr)

    // now loop through and replace
    for (let i = 0; i < newData.length; i++) {
      let obj = newData[i]
      if (obj.field === field) {
        obj.sort = sortDirection
      } else {
        obj.sort = ''
      }
    }
    return newData.slice(0)
  },

  // re-parse repository - just tweaking colwidth
  parseRepository (columnDefArray, doTallRows) {
    // let theWidth = (doTallRows) ? 190 : 60
    let theWidth = 60
    // now loop through and replace
    for (let i = 0; i < columnDefArray.length; i++) {
      let obj = columnDefArray[i]
      if (obj.children) {
        for (let ii = 0; ii < obj.children.length; ii++) {
          let obj2 = obj.children[ii]
          if (obj2.field === 'primaryFile.thumbnail') {
            obj2.minWidth = theWidth
            obj2.maxWidth = theWidth
          }
        }
      } else {
        if (obj.field === 'primaryFile.thumbnail') {
          obj.minWidth = theWidth
          obj.maxWidth = theWidth
        }
      }
    }
    return columnDefArray
  },

  // redraw the grid
  // this is called when a folder title is renamwed
  // TODO - hope that Ag grid fixes their bug, or find a more elegant hack
  parseBuggyTreeData: function () {
    let addArr = []
    let rows = GridHelpers.getRowNodes()
    for (let i = 0; i < rows.length; i++) {
      let node = rows[i]
      if (node?.data) {
        addArr.push(node.data)
        // gridApi.batchUpdateRowData({ update: [node.data] })
      }
    }
    let gridApi = GridHelpers.mgThisArray[0].gridApi
    let updatedRows = []
    for (let i = 0; i < addArr.length; i++) {
      let obj = cloneDeep(addArr[i])
      updatedRows.push(obj)
    }
    gridApi.applyTransaction({ remove: updatedRows })
    setTimeout(function () {
      let updatedRows = []
      for (let i = 0; i < addArr.length; i++) {
        let obj = cloneDeep(addArr[i])
        updatedRows.push(obj)
      }
      gridApi.applyTransaction({ add: updatedRows })
    }, 100)
  },

  // converts dateas number to string - used in datefield components
  //
  convertDateNumberToDateString (val) {
    const valTemp = String(val)
    const yyyy = valTemp.substring(0, 4)
    let mm = valTemp.substring(4, 6)
    const dd = valTemp.substring(6, 8)
    mm = Number(mm) + 1
    if (mm < 10) {
      mm = '0' + String(mm)
    } else {
      mm = String(mm)
    }
    return yyyy + '-' + mm + '-' + dd
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // KEY UP, KEY DOWN - UNDO and REDO related
  detectKeyDown: function (e) {
    if (GridHelpers.isGridReady()) {
      if (!GridHelpers.isEditingCell()) {
        let gridApi = GridHelpers.mgThisArray[0].gridApi

        // DO DELETE FIRST AND BAIL
        if ((e.code === 'Backspace' || e.code === 'Delete') && GridHelpers.focused) {
          // e.preventDefault()
          gridApi.stopEditing()
          GridHelpers.clearValuesWithinSelectedRange()
          // GridHelpers.removeSelectedRowsSelected()
          GridHelpers.purgeKeysFromDepressed('Backspace')
          GridHelpers.purgeKeysFromDepressed('Delete')
          return
        }

        // only push if not already held down
        let keyAlready = GridHelpers.keysDepressed.filter((x) => x['code'] === e.code)
        if (keyAlready.length === 0) {
          GridHelpers.keysDepressed.push(e)
        }

        // SOME CLEANUP so you can toggle back and forth
        if (e.code === 'KeyA') {
          GridHelpers.purgeKeysFromDepressed('KeyD')
        } else if (e.code === 'KeyD') {
          GridHelpers.purgeKeysFromDepressed('KeyA')
        } else {
          GridHelpers.purgeKeysFromDepressed('KeyA')
          GridHelpers.purgeKeysFromDepressed('KeyD')
        }

        // see what keys are clicked
        let keyZ = false
        let keyA = false
        let keyD = false
        let keyAlt = false
        let keyControl = false
        if (GridHelpers.keysDepressed.length >= 2) {
          for (let i = 0; i < GridHelpers.keysDepressed.length; i++) {
            if (GridHelpers.keysDepressed[i].code === 'KeyZ') {
              keyZ = true
            }
            if (GridHelpers.keysDepressed[i].code === 'KeyA') {
              keyA = true
            }
            if (GridHelpers.keysDepressed[i].code === 'KeyD') {
              keyD = true
            }
            if (GridHelpers.keysDepressed[i].altKey) {
              keyAlt = true
            }
            if (GridHelpers.keysDepressed[i].metaKey || GridHelpers.keysDepressed[i].ctrlKey) {
              keyControl = true
            }
          }

          // control-Z detected? keyAlt means redo
          if (keyZ && keyAlt) {
            e.preventDefault()
            GridHelpers.actionRedo()
          } else if (keyZ && keyControl) {
            e.preventDefault()
            GridHelpers.actionUndo()
          } else if (keyD && keyControl) {
            e.preventDefault()
            GridHelpers.deselectAll()
          } else if (keyA && keyControl) {
            e.preventDefault()
            // you must do both or control A followed by control d actually deletes values
            GridHelpers.deselectAll()
            GridHelpers.selectAll()
          }
        } // if keys length>2
      } // is editing cell
    } // is grid ready
  }, // detectKeyDown
  detectKeyUp: function (e) {
    if (e.code === 'KeyZ' || e.code === 'KeyA' || e.code === 'KeyD') {
      GridHelpers.purgeKeysFromDepressed(e.code)
    } else {
      GridHelpers.keysDepressed = []
    }
  },
  purgeKeysFromDepressed (code) {
    let idx = GridHelpers.keysDepressed.findIndex((x) => x.code === code)
    if (idx > -1) {
      GridHelpers.keysDepressed.splice(idx, 1) // remove
    }
  },

  actionUndo: function () {
    const t = GridHelpers.mgThisArray[0]
    t?.gridApi.undoCellEditing()
    GridHelpers.deselectAll()
    //DataMiddleware.undoLastChange()
  },
  actionRedo: function () {
    const t = GridHelpers.mgThisArray[0]
    t?.gridApi.redoCellEditing()
    GridHelpers.deselectAll()
    //DataMiddleware.redoLastChange()
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // RESIZE
  eventGridSizeChanged (e) {
    if (e.clientWidth) {
      for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
        const t = GridHelpers.mgThisArray[z]
        if (t?.gridApi) {
          if (!e?.api?.context?.destroyed) {
            t.gridApi.sizeColumnsToFit()
          }
        }
      }
    }
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // DATA SELECTION, KEYBOARD SELECTION, CELL VALUE MANIPULATION
  // custom keyboard surpression
  suppressKeyboardEvent: function (params) {
    // TRUE means disable all AG Grid default call back behavior
    let ret = true
    if (params.editing) {
      // if edit, we want the callback behavior
      ret = false
    } else {
      // permit certain keys, like enter
      if (/[a-zA-Z0-9-_ ]/.test(params.event.code)) {
        if (params.event.code === 'Backspace' || params.event.code === 'Delete') {
          ret = true
        } else {
          ret = false
        }
      } else if (params.event.code === 'Enter') {
        ret = false
      }
    }
    return ret
  },

  // select all cells and rows
  selectAll: function () {
    for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
      let gridApi = GridHelpers.mgThisArray[z].gridApi
      if (gridApi) {
        let rows = GridHelpers.getRowNodes()
        for (let i = 0; i < rows.length; i++) {
          rows[i].setSelected(true)
        }
      }
    }
  },
  deselectAll: function () {
    for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
      let gridApi = GridHelpers.mgThisArray[z].gridApi
      if (gridApi) {
        gridApi.deselectAll() // rows
        if (GridHelpers.mgThisArray[z].rowModelType === 'clientSide') {
          gridApi.deselectAllFiltered() // clear filters, only works on clientSide
        }
        GridHelpers.deselectCellRange() // cell range
      }
    }
  },
  // deselect cell range
  deselectCellRange: function () {
    for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
      let gridApi = GridHelpers.mgThisArray[z].gridApi
      gridApi.clearRangeSelection() // range selection
      gridApi.stopEditing() // stop editing
    }
  },
  selectNestedChildNodes: function (node) {
    for (let ii = 0; ii < node.allLeafChildren.length; ii++) {
      let node2 = node.allLeafChildren[ii]
      node2.setSelected(true)
    }
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // DATA UPDATING - POST UPDATE
  // some cell values have changed, recalculate specific columns
  postUpdateRecalculations (params) {
    let field = params.colDef.field
    if (
      field === 'discountPercent' ||
      field === 'discountAmount' ||
      field === 'netCost' ||
      field === 'cost'
    ) {
      /*
        these columns are somewhat complex
        they both 1) editable and 2) are a calculated result
        so one columns updates the other 2
        and then only netCost is actually stored on the back end
     */
      let cost = ColumnHelpers.numberRemoveExtraneous(params.data.cost)
      let netCost = ''
      let discountAmount = ''
      let discountPercent = ''

      // set to 0 if blank
      let newValue = params.newValue
      if (newValue === '') {
        newValue = 0
      }
      if (!params.data.updatingField) {
        params.data.updatingField = true
        if (field === 'discountPercent') {
          netCost = pricesheet.netCostFromDiscountPercentage(cost, newValue, -1)
          discountAmount = pricesheet.discountAmountFromCosts(cost, netCost, -1)
          discountPercent = newValue
        } else if (field === 'discountAmount') {
          netCost = pricesheet.netCostFromDiscountAmount(cost, newValue, -1)
          discountAmount = newValue
          discountPercent = pricesheet.discountPercentageFromCosts(cost, netCost, -1)
        } else if (field === 'netCost' || field === 'cost') {
          netCost = ColumnHelpers.numberRemoveExtraneous(params.data.netCost)
          discountAmount = pricesheet.discountAmountFromCosts(cost, netCost, -1)
          discountPercent = pricesheet.discountPercentageFromCosts(cost, netCost, -1)
        }

        // set values
        if (field !== 'discountAmount') {
          params.node.setDataValue('discountAmount', discountAmount)
        }
        if (field !== 'discountPercent') {
          params.node.setDataValue('discountPercent', discountPercent)
        }
        if (field !== 'netCost') {
          params.node.setDataValue('netCost', netCost)
        }

        setTimeout(function () {
          params.data.updatingField = false
        }, 300)
      }
    }

    // check if style note changed, if so, apply style note to all other colors for this style
    if (field === 'styleNote') {
      GridHelpers.applyFieldValueToAllColorsInStyle(params, 'styleNote')
    }
    if (field === 'collectionStyleNote') {
      GridHelpers.applyFieldValueToAllColorsInStyle(params, 'collectionStyleNote')
    }

    // check if pillar changed for key initiatives, if so, apply pillar to all other colors for this style
    if (
      field === 'pillar' &&
      (router.currentRoute.value.meta.manageType ===
        ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL ||
        router.currentRoute.value.meta.manageType ===
          ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__REGULAR)
    ) {
      GridHelpers.applyFieldValueToAllColorsInStyle(params, 'pillar')
    }
  },
  applyFieldValueToAllColorsInStyle (params, field) {
    let style = params.data.style
    let styleData = GridHelpers.returnMatchingStylesFromAssortment(style)
    if (styleData.length > 1) {
      for (let i = 0; i < styleData.length; i++) {
        const tobj = styleData[i]
        if (tobj.id !== params.data.id) {
          const tnode = GridHelpers.mgThisArray[0].gridApi.getRowNode(tobj.id)
          if (params.newValue !== tnode.data[field]) {
            tnode.setDataValue(field, params.newValue)
          }
        }
      }
    }
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // CELL MENUS - Appears when right clicking a cell
  getContextMenuItems: function (params) {
    let results = []

    if (
      GridHelpers.mgThisArray[0].$options.name === 'MasterGrid' &&
      GridHelpers.mgThisArray[0].type === 'assortments-list'
    ) {
      results.push({
        name: 'New Folder',
        action: function () {
          GridHelpers.mgThisArray[0].addNewSingleFolder(params.node.id)
        }
      })
    }

    if (params?.node?.group !== true) {
      let title = ''
      if (params.node.data.type === 'folder') {
        title =
          'Delete the "' +
          truncate(params.node.data.title, { length: 50 }) +
          '" folder & everything in it'
      } else if (params.node.data.type === 'item') {
        if (
          router?.currentRoute?.value.name === 'assortment-manager--all-assortments' ||
          router?.currentRoute?.value.name === 'assortment-manager--archived'
        ) {
          title = 'Delete "' + truncate(params.node.data.title, { length: 50 }) + '"'
        }
      } else if (
        GridHelpers.mgThisArray[0].assortment?.orgType === ITS__ASSORTMENTS__ORG_TYPE__REGULAR &&
        GridHelpers.mgThisArray[0].assortment?.method === ITS__ASSORTMENTS__METHOD_TYPE__STATIC
      ) {
        title = 'Delete "' + params.node.data.style + '-' + params.node.data.color + '"'
      }

      if (title !== '') {
        results.push({
          name: title,
          action: function () {
            let nodesToDelete = [params.node.data]

            // grab all children if type is folder
            if (params?.node?.data?.type === 'folder') {
              for (let ii = 0; ii < params.node.allLeafChildren.length; ii++) {
                let node2 = params.node.allLeafChildren[ii]
                nodesToDelete.push(node2.data)
              }
            }

            GridHelpers.removeSelectedRowsBegin(nodesToDelete)
            /*
              let params2 = {
                payload: nodesToDelete
              }
              DataMiddleware.emitRowsDelete(params2)
              */
          }
        })
      } // title !==''
    } // not group

    // if mastergrid type, add expanders/contractors
    if (GridHelpers.mgThisArray[0].$options.name === 'MasterGrid') {
      // add separator?
      if (results.length > 0) {
        // results.push('separator')
      }

      // defaults
      results.push('expandAll', 'contractAll')
    }

    // Export to excel for everyone
    /*
    results.push(
      {
        name: 'Export to Excel',
        action: function () {
          ExcelMiddleware.exportToExcel(params)
        }
      }
    )

     */

    return results
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // RANGE SELECTIONS
  // returns all of the nodes for the range selection
  pasteStart (params) {
    return true
  },
  pasteEnd (params) {
    return true
  },
  getTreedataSelectionRowData () {
    let gridApi = GridHelpers.mgThisArray[0].gridApi
    let selected = gridApi.getSelectedRows()
    let ret = {
      rows: cloneDeep(selected)
    }
    return ret
  },
  getRangeSelectionNodes (skipStopClearCheck = false) {
    let ret = {
      nodes: [],
      editableNodes: [],
      clearableNodes: [], // almost the same as editable
      editableNodesMinusStyleColor: []
    }
    if (GridHelpers.isGridReady()) {
      let gridApi = GridHelpers.mgThisArray[0].gridApi
      let selected = gridApi.getCellRanges()
      if (selected) {
        for (let i = 0; i < selected.length; i++) {
          let obj = selected[i]
          let startRow = Math.min(obj.startRow.rowIndex, obj.endRow.rowIndex)
          let endRow = Math.max(obj.startRow.rowIndex, obj.endRow.rowIndex)

          let api = gridApi
          for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
            obj.columns.forEach(function (column) {
              //var rowModel = api.getModel()
              //var node = rowModel.getRow(rowIndex)
              let node = api.getDisplayedRowAtIndex(rowIndex)
              let nodeCol = {
                column: column,
                node: node
              }

              // special edit blocks
              let allSpecialBlocksPassed = true
              switch (column.colDef.field) {
                case 'thumbnail':
                case 'primaryFile.thumbnail':
                  allSpecialBlocksPassed = false
                  break
              }

              // special clearable blocks
              // for libraries, some editable things are not clearable
              // these are required fields to publish - so are clearable in pending but not afterwards
              let canClear = true
              if (GridHelpers.mgThisArray[0].isLibrariesGrid) {
                if (!GridHelpers.isLibraryPendingView()) {
                  // skipStopClearCheck - is only true because under certain circumstances I need to call this from with stopColumnFromClear
                  if (!skipStopClearCheck) {
                    canClear = !GridHelpers.stopColumnFromClear(column.colDef.field)
                  }
                }
              }

              if (column.colDef.editable && allSpecialBlocksPassed) {
                ret.editableNodes.push(nodeCol)
                if (column.colDef.field !== 'style' && column.colDef.field !== 'color') {
                  ret.editableNodesMinusStyleColor.push(nodeCol)
                }
                if (canClear) {
                  ret.clearableNodes.push(nodeCol)
                }
              }

              // add all nodes
              ret.nodes.push(nodeCol)
            })
          }
        }
      }
    }
    return ret
  },

  // calculate how many rows and cells are selected
  getSelectionDetails () {
    let cellRangeLength = 0
    let selectedRowsLength = 0
    let treedataItemsLength = 0
    let treedataFoldersLength = 0

    let rangeSelectionNodes = []
    let columnSelectedCounts = []
    let columnSelectedCountsMinusStyleColor = []
    let ordersListDeletableNodes = []
    let uniqueRows = []

    if (GridHelpers.isGridReady()) {
      const t = GridHelpers.mgThisArray[0]
      let gridApi = t.gridApi

      if (t.subtype === 'quick') {
        // QUICK
        rangeSelectionNodes.rows = []
        for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
          let gridApi = GridHelpers.mgThisArray[z].gridApi
          if (gridApi) {
            let len = 0
            let sr = gridApi.getSelectedRows()
            if (sr) {
              len = sr.length
              for (let i = 0; i < sr.length; i++) {
                rangeSelectionNodes.rows.push(sr[i])
              }
            }
            selectedRowsLength += len
          }
        }
      } else if (t.type === 'orders-list') {
        rangeSelectionNodes.rows = []
        ordersListDeletableNodes.rows = []
        for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
          let gridApi = GridHelpers.mgThisArray[z].gridApi
          if (gridApi) {
            let len = 0
            let sr = gridApi.getSelectedRows()
            if (sr) {
              len = sr.length
              for (let i = 0; i < sr.length; i++) {
                if (sr[i].status === 'DRAFT') {
                  ordersListDeletableNodes.rows.push(sr[i])
                }
                rangeSelectionNodes.rows.push(sr[i])
              }
            }
            selectedRowsLength += len
          }
        }
      } else if (t.type === 'assortments-list' && t.subtype !== 'internal') {
        // MY ASSORTMENTS
        rangeSelectionNodes = GridHelpers.getTreedataSelectionRowData()
        // count up items and folders for my assortments
        for (let i = 0; i < rangeSelectionNodes.rows.length; i++) {
          let tobj = rangeSelectionNodes.rows[i]
          if (tobj.type === 'item') {
            treedataItemsLength++
          } else if (tobj.type === 'folder') {
            treedataFoldersLength++
          }
          cellRangeLength++
        }
        let selectedRows = gridApi.getSelectedRows()
        if (selectedRows) {
          selectedRowsLength = selectedRows.length
        }
      } else {
        // ALL OTHERS
        rangeSelectionNodes = GridHelpers.getRangeSelectionNodes()
        for (let i = 0; i < rangeSelectionNodes.editableNodes.length; i++) {
          // all editableNodes - includes groups, excludes read only
          let tobj = rangeSelectionNodes.editableNodes[i]
          if (tobj.node) {
            if (tobj.node.group === false) {
              // add to total
              cellRangeLength++

              // add unique row count - used in serverSide (non-clientSide) data, since getSelectedRows doesn't work
              uniqueRows.push(tobj.node.rowIndex)

              // count column totals
              let field = tobj.column.colDef.field
              let headerName = tobj.column.colDef.headerName

              let colobjArray = columnSelectedCounts.filter((params) => params['field'] === field)
              let colobj = {}
              if (colobjArray.length > 0) {
                colobj = colobjArray[0]
              } else {
                colobj = {
                  field: field,
                  title: headerName,
                  num: 0
                }
                columnSelectedCounts.push(colobj)
                if (field !== 'style' && field !== 'color') {
                  columnSelectedCountsMinusStyleColor.push(colobj)
                }
              }

              colobj.num++
            } // tobj.node.group
          }
        }
        let selectedRows = gridApi.getSelectedRows()
        if (selectedRows) {
          selectedRowsLength = selectedRows.length
        }
      } // else (all others)
    } // ready

    let txtCell = 'cell'
    let txtRow = 'item'
    if (GridHelpers.mgThisArray && GridHelpers.mgThisArray[0]?.isLibrariesGrid) {
      txtRow = 'row'
    }

    let ret = {
      storeExists: true,
      selectedCells: cellRangeLength,
      selectedRows: selectedRowsLength,
      selectedCellsLabel: cellRangeLength === 1 ? txtCell : txtCell + 's',
      selectedRowsLabel: selectedRowsLength === 1 ? txtRow : txtRow + 's',
      somethingSelected: cellRangeLength > 0 || selectedRowsLength > 0,

      ordersListDeletableNodes: ordersListDeletableNodes,
      rangeSelectionNodes: rangeSelectionNodes,
      columnSelectedCounts: columnSelectedCounts,
      columnSelectedCountsMinusStyleColor: columnSelectedCountsMinusStyleColor,

      treedataItemsLength: treedataItemsLength,
      treedataFoldersLength: treedataFoldersLength
    }
    GridHelpers.selectionDetailsStore = ret
    return ret
  },

  barInfoRows () {
    let ret = ''
    if (!GridHelpers.selectionDetailsStore?.storeExists) {
      GridHelpers.getSelectionDetails()
    }
    let selectionDetails = GridHelpers.selectionDetailsStore
    const t = GridHelpers.mgThisArray[0]
    if (t.subtype !== 'internal' && t.subtype !== 'quick' && t.type === 'assortments-list') {
      let foldersTxt = selectionDetails.treedataFoldersLength === 1 ? ' folder ' : ' folders '
      let itemsTxt = selectionDetails.treedataItemsLength === 1 ? ' assortment ' : ' assortments '
      let foldersCount = selectionDetails.treedataFoldersLength
      let itemsCount = selectionDetails.treedataItemsLength
      if (foldersCount > 0 && itemsCount > 0) {
        ret = foldersCount + foldersTxt + 'and ' + itemsCount + itemsTxt + ' selected'
      } else if (foldersCount > 0) {
        ret = foldersCount + foldersTxt + ' selected'
      } else if (itemsCount > 0) {
        ret = itemsCount + itemsTxt + ' selected'
      }
    } else {
      let cellsRowsCount = selectionDetails.selectedRows
      let cellsRowsLabel = selectionDetails.selectedRowsLabel
      ret = cellsRowsCount + ' ' + cellsRowsLabel + ' selected'
    }
    return ret
  },
  barInfoCells (selectionDetails) {
    let ret = ''
    let editableLabel = ' editable '
    let cellsRowsCount = selectionDetails?.selectedCells
    let cellsRowsLabel = selectionDetails?.selectedCellsLabel
    ret = cellsRowsCount + editableLabel + cellsRowsLabel + ' selected'
    return ret
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // DELETE/ARCHIVE item/folder
  returnSelectedRowsAndSubfolderItems (mgIndex = 0) {
    // get all selected rows
    let gridApi = GridHelpers.mgThisArray[mgIndex].gridApi
    let rows = gridApi.getSelectedRows()

    // grab all children under tree data folder
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].type === 'folder') {
        let id = rows[i].id
        let node = gridApi.getRowNode(id)
        if (node) {
          GridHelpers.selectNestedChildNodes(node)
        }
      }
    }
    // grab final set and delete
    let selectedData = gridApi.getSelectedRows()
    return selectedData
  },

  // passsed true or false to archiveIt - if false, unarchives
  archiveSelectedRows (archiveIt) {
    let description = ''
    if (archiveIt) {
      description = 'Archived Assortments will be available under the archive tab.'
    } else {
      description = 'Unarchived Assortments will appear back in the other tabs.'
    }

    // GLOBAL ACTION PROMPT: Pass messages & dispatch action
    let actionObj = {
      checkChangedFields: false,
      title: 'Are You Sure?',
      description: description,
      dispatchAction: VUEX_GRID_CONFIRMATION_ARCHIVE_SELECTED,
      dispatchActionObject: {
        archiveIt: archiveIt
      }
    }
    GridHelpers.mgThisArray[0].$store.dispatch('VUEX_GLOBAL_ACTION_PROMPT_SHOW', {
      actionObj: actionObj
    })
  },
  archiveSelectedRowsConfirmed (archiveIt) {
    // use state value instead of boolean
    const stateArchiveIt = archiveIt
      ? ITS__ASSORTMENTS__STATE__ARCHIVED
      : ITS__ASSORTMENTS__STATE__PUBLISHED

    // get all selected nodes from mutliple grids
    let allSelectedNodes = []
    for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
      let selectedData = GridHelpers.returnSelectedRowsAndSubfolderItems(z)
      for (let i = 0; i < selectedData.length; i++) {
        let obj = selectedData[i]
        allSelectedNodes.push(obj)
      }
    }
    // mark all as archived or unarchived
    for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
      let removeArr = []
      let gridApi = GridHelpers.mgThisArray[z].gridApi
      for (let i = 0; i < allSelectedNodes.length; i++) {
        let obj = allSelectedNodes[i]
        removeArr.push(obj)
        if (obj.type === 'item') {
          let node = gridApi.getRowNode(obj.id)
          if (node) {
            // node.setDataValue('archived', archiveIt) //old way
            node.setDataValue('state', stateArchiveIt)
          }
        }
      }
      // now remove aesthetically from front end
      gridApi.applyTransaction({ remove: removeArr })
    }
  },
  removeOrdersListDeleteableRowsSelected: function () {
    let selectedData = GridHelpers.getSelectionDetails()
    selectedData = selectedData?.ordersListDeletableNodes.rows
    if (selectedData.length) {
      GridHelpers.removeSelectedRowsBegin(selectedData)
    }
  },
  removeSelectedRowsSelected: function () {
    let selectedData = []
    if (GridHelpers.mgThisArray[0].subtype === 'quick') {
      for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
        let gridApi = GridHelpers.mgThisArray[z].gridApi
        let sr = gridApi.getSelectedRows()
        if (sr) {
          for (let i = 0; i < sr.length; i++) {
            selectedData.push(sr[i])
          }
        }
      }
    } else if (GridHelpers.mgThisArray[0].type === 'assortments-list') {
      selectedData = GridHelpers.returnSelectedRowsAndSubfolderItems()
    } else {
      let gridApi = GridHelpers.mgThisArray[0].gridApi
      selectedData = gridApi.getSelectedRows()
    }
    GridHelpers.removeSelectedRowsBegin(selectedData)
  },
  removeSelectedRowsBegin: async function (rowData) {
    if (rowData.length > 0) {
      const t = GridHelpers.mgThisArray[0]
      // get description
      let description = ''
      if (t.subtype === 'seasons-manager') {
        let folderCount = rowData.filter((x) => x['type'] !== 'folder')
        let txtAssortment = 'one season.'
        if (folderCount.length !== 1) {
          txtAssortment = folderCount.length + ' seasons.'
        }
        description = 'You are about to delete ' + txtAssortment
      } else if (t.type === 'assortments-list') {
        let folderCount = rowData.filter((x) => x['type'] !== 'folder')
        let txtAssortment = 'one assortment.'
        if (folderCount.length !== 1) {
          txtAssortment = folderCount.length + ' assortments.'
        }
        description = 'You are about to delete ' + txtAssortment
      } else if (t.isLibrariesGrid) {
        let txtProduct = 'one row.'
        if (rowData.length > 1) {
          txtProduct = rowData.length + ' rows.'
        }
        description = 'You are about to delete ' + txtProduct
      } else if (t.type === 'orders-list') {
        description = i18next.t('orders:DELETE_DIALOG.DRAFT_COUNT', { count: rowData.length })
      } else {
        description = i18next.t('orders:DELETE_DIALOG.PRODUCT_COUNT', { count: rowData.length })
      }

      // check for scheduled docs on assortment lists - but dont check on key initiatives or seasons
      if (
        t.type === 'assortments-list' &&
        t.subtype !== 'internal' &&
        t.subtype !== 'seasons-manager'
      ) {
        await t.$store.dispatch(VUEX_DOCUMENTS_FETCH_REQUEST).then((res) => {})

        let hasScheduleDoc = false
        for (let i = 0; i < rowData.length; i++) {
          let id = rowData[i]._id
          if (t.documents.length > 0) {
            let filteredData = t.documents.filter(
              (x) => x['assortment'] && x['assortment']['_id'] === id
            )
            if (filteredData.length > 0) {
              hasScheduleDoc = true
            }
          }
        }

        if (hasScheduleDoc) {
          let txtAssortment = 'assortment.'
          if (rowData.length > 1) {
            txtAssortment = ' assortments.'
          }
          description += ' This will also delete ALL SCHEDULED REPORTS for the ' + txtAssortment
        }
      }

      // add final copy
      description += i18next.t('orders:DELETE_DIALOG.WARNING')

      // GLOBAL ACTION PROMPT: Pass messages & dispatch action
      let actionObj = {
        checkChangedFields: false,
        title: i18next.t('orders:DELETE_DIALOG.TITLE'),
        description: description,
        dispatchAction: VUEX_GRID_CONFIRMATION_ROW_DELETE,
        dispatchActionObject: {
          rowData: rowData,
          subtype: t.subtype
        },
        labelYes: i18next.t('common:YES'),
        labelNo: i18next.t('common:NO'),
      }

      t.$store.dispatch('VUEX_GLOBAL_ACTION_PROMPT_SHOW', { actionObj: actionObj })
    } // if selecteddata
  },
  removeSelectedRowsComfirmed: async function (confirmationTogglerRowDelete) {
    let rowData = cloneDeep(confirmationTogglerRowDelete)
    // grab final set and delete
    if (rowData) {
      await GridHelpers.mgThisArray[0].$store.dispatch('VUEX_GRID_CONFIRMATION_ROW_DELETE_CLEAR')
      let params = {
        payload: rowData
      }
      DataMiddleware.emitRowsDelete(params)
    }
  },

  // select a range of cells, clears all the values within it
  clearValuesWithinSelectedRange () {
    if (GridHelpers.isGridReady()) {
      let gridApi = GridHelpers.mgThisArray[0].gridApi

      let nodes = GridHelpers.getRangeSelectionNodes().clearableNodes
      for (let i = 0; i < nodes.length; i++) {
        let colId = nodes[i].column.colDef.field
        // if forcing a zero, use zero
        let valueParser = nodes[i].column.colDef.valueParser
          ? nodes[i].column.colDef.valueParser.name
          : ''
        let newValue = ''
        if (valueParser === 'valueParserBigNumberPassthroughForceZero') {
          newValue = 0
        }
        nodes[i].node.setDataValue(colId, newValue)
      }
      // refresh
      gridApi.redrawRows() // refreshes the visual front end
      GridHelpers.deselectAll()
    }
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // POPUPS
  // group edit
  popupGroupEdit: function () {
    let extraParams = {
      netCostDiscountEtcFiltered: false
    }
    let sd = GridHelpers.getSelectionDetails()
    let finalFieldList = sd.columnSelectedCountsMinusStyleColor

    /// /////////////////////////////////////////////////////////////////////////////
    // check for netCost, discountAmount, & discountPercent - and pick one
    // TODO: also check for suggested retail and init markup - maybe just skip init markup if any of the above are set, or if sugg retail is set
    let netCostSelected = 0
    let discountAmountSelected = 0
    let discountPercentSelected = 0
    for (let i = 0; i < finalFieldList.length; i++) {
      let col = finalFieldList[i]
      if (col.field === 'netCost') {
        netCostSelected = 1
      } else if (col.field === 'discountAmount') {
        discountAmountSelected = 1
      } else if (col.field === 'discountPercent') {
        discountPercentSelected = 1
      }
    }
    if (netCostSelected + discountAmountSelected + discountPercentSelected > 1) {
      let tempFields = []
      for (let i = 0; i < finalFieldList.length; i++) {
        let col = finalFieldList[i]
        let doPush = true
        if (
          col.field === 'discountAmount' &&
          (netCostSelected === 1 || discountPercentSelected === 1)
        ) {
          doPush = false
          extraParams.netCostDiscountEtcFiltered = true
        } else if (col.field === 'discountPercent' && netCostSelected === 1) {
          doPush = false
          extraParams.netCostDiscountEtcFiltered = true
        }
        if (doPush) {
          tempFields.push(col)
        }
      }
      finalFieldList = tempFields
    }
    GridHelpers.multiFieldEditDialogue(finalFieldList, extraParams)
  },

  popupMergeWorkingFile: function (payload) {
    const extraParams = {
      isWorkingFileMerge: true,
      payload: payload
    }
    const cols = GridHelpers.getEditableColumns()
    const finalFieldList = cols.map((x) => {
      return {
        field: x.value,
        title: x.text,
        num: 1,
        defaultValue: payload[x.value] ? payload[x.value] : ''
      }
    })
    GridHelpers.multiFieldEditDialogue(finalFieldList, extraParams)
  },

  multiFieldEditDialogue: function (finalFieldList, extraParams) {
    // finalFieldList = array of {field, num, title}
    if (finalFieldList) {
      // set field types base on column definitions
      let fieldTypes = []
      let gridColumns = GridHelpers.mgStashThisArray[0].gridApi.getColumns()
      for (let i = 0; i < gridColumns.length; i++) {
        const col = gridColumns[i]

        // if allowed
        if (col.colDef.editable) {
          // default
          let params = {
            type: 'text',
            params: {}
          }

          // set special types
          if (col.colDef.cellEditor === 'DropdownEditor') {
            if (col.colDef.cellEditorParams) {
              params.params = col.colDef.cellEditorParams
              params.lookup = PropertiesLookupLists.getPropertiesLookupList(col.colId)
            }
            params.type = 'DropdownEditor'
          } else if (col.colDef.cellEditor === 'DatePickerEditor') {
            params.type = 'DatePickerEditor'
          } else if (col.colId === 'label') {
            params.type = 'label'
          } else {
            params.type = 'text'
          }

          // store fields types
          fieldTypes[col.colId] = params
        }
      } // end for

      let title = 'Edit Cells'
      let subtitle = 'Enter the new values below'
      if (extraParams.isWorkingFileMerge) {
        const assetSlug = router.currentRoute.value.meta.manageType
        const titleLabel = assetSlug === 'advertisingLogo' ? 'Source' : 'Working'
        title = titleLabel + ' File Merge'
        subtitle = 'Choose a primary file to merge this into.'
      }

      GridHelpers.mgThisArray[0].openDialog({
        content: '_core/Dialogs/Grids/Dialog_MultiFieldEdit.vue',
        title: title,
        subtitle: subtitle,
        data: {
          fields: finalFieldList,
          fieldTypes: fieldTypes,
          extraParams: extraParams
        }
      })
    }
  },

  // new assortment popup
  popupNewAssortment: function () {
    if (
      router.currentRoute.value.meta.manageType ===
      ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL
    ) {
      let internalChannelType = router.currentRoute.value.meta.internalChannelType
      GridHelpers.mgThisArray[0].openDialog({
        content: 'AssortmentManager/Dialogs/Grids/Dialog_NewEditAssortmentInternal.vue',
        title: 'Select Assortment Attributes',
        subtitle: '',
        data: {
          internalChannelType: internalChannelType
        }
      })
    } else {
      GridHelpers.mgThisArray[0].openDialog({
        content: 'AssortmentManager/Dialogs/Grids/Dialog_NewEditAssortment.vue',
        title: 'Select Assortment Type',
        subtitle: '',
        data: {
          editMode: false
        }
      })
    }
  },

  // copy assortment popup
  popupCopyAssortment: function () {
    const sd = GridHelpers.getSelectionDetails()
    const rowCount = sd.rangeSelectionNodes.rows.length
    const pluralize = rowCount !== 1 ? 's' : ''
    const msg = `You have selected ${rowCount} assortment${pluralize} to copy`
    GridHelpers.mgThisArray[0].openDialog({
      content: 'AssortmentManager/Dialogs/Grids/Dialog_CopyAssortment.vue',
      title: 'Copy Assortment',
      subtitle: msg,
      data: {
        rows: sd.rangeSelectionNodes.rows || []
      },
      tabs: ['Duplicate for yourself', 'Copy for another user'],
      tabBar: null
    })
  },

  // new order popup
  popupNewOrder: function (orderType) {
    let contentPath, dialogTitle

    switch (orderType) {
      case ITS__ORDERS__ORDER_TYPE__SAMPLE:
        contentPath = 'Orders/Dialogs/Dialog_NewEditSampleOrder.vue'
        dialogTitle = 'New Sample Order'
        break
      case ITS__ORDERS__ORDER_TYPE__PROMO:
        contentPath = 'Orders/Dialogs/Dialog_NewEditPromoOrder.vue'
        dialogTitle = 'New Promo Order'
        break
      case ITS__ORDERS__ORDER_TYPE__WHOLESALE:
        contentPath = 'Orders/Dialogs/Dialog_NewEditWholesaleOrder.vue'
        dialogTitle = i18next.t('orders:WHOLESALE.CREATE_DIALOG.FORM.TITLE')
        break
    }

    GridHelpers.mgThisArray[0].openDialog({
      content: contentPath,
      title: dialogTitle,
      data: {
        editMode: false
      }
    })
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // DATA COMPARE
  newValueDiffersFromOld (newValue, oldValue) {
    let ret = false
    if (oldValue !== newValue) {
      // seems good... except we now have the messy business of comparing strings to numbers
      // so lets make sure the numbers aren't different
      if (!isNaN(oldValue) || !isNaN(oldValue)) {
        // so at least one if this is a number - let's do a number comparise now
        ret = Number(oldValue) !== Number(newValue)
      } else {
        ret = true
      }
    }
    return ret
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // CORE ROW NODES
  // gets the rows - or sometimes we just want the groups
  // note that it gets them in order as they appear on the screen, aka ...AfterFilterAndSort
  // groupsDataOnly = true - just groups
  // groupsDataOnly = false - rows and groups
  // specificallyExcludeGroups = true - ignore all groups
  getRowNodes (groupsDataOnly = false, specificallyExcludeGroups = false, thisOverride = null, unfiltered = false) {
    let rows = []
    if (GridHelpers.isGridReady()) {
      let t = (thisOverride) || GridHelpers.mgThisArray[0]
      if (t.rowModelType !== 'serverSide') { // set !== serverSide instead of === clientSide to handle possibility for undefined
        let gridApi = t.gridApi
        if (gridApi) {
          try {
            if (unfiltered) {
              gridApi.forEachNode(function (rowNode, index) {
                if (rowNode && typeof rowNode !== 'undefined') {
                  if (groupsDataOnly) {
                    if (rowNode.group) {
                      rows.push(rowNode)
                    }
                  } else {
                    if (specificallyExcludeGroups) {
                      if (!rowNode.group) {
                        rows.push(rowNode)
                      }
                    } else {
                      rows.push(rowNode)
                    }
                  }
                }
              })
            } else {
              gridApi.forEachNodeAfterFilterAndSort(function (rowNode, index) {
                if (rowNode && typeof rowNode !== 'undefined') {
                  if (groupsDataOnly) {
                    if (rowNode.group) {
                      rows.push(rowNode)
                    }
                  } else {
                    if (specificallyExcludeGroups) {
                      if (!rowNode.group) {
                        rows.push(rowNode)
                      }
                    } else {
                      rows.push(rowNode)
                    }
                  }
                }
              })
            }

          } catch (err) {}
        } // ig grid api
      }
    }
    return rows.slice(0)
  },

  // given a list of nodes, export an array of row data
  extractRowDataFromRowNodes (rowNodesArray, extractChildrenFromGroup) {
    let ret = []
    for (let i = 0; i < rowNodesArray.length; i++) {
      let obj = rowNodesArray[i]
      if (extractChildrenFromGroup && obj.group) {
        let children = obj.childrenAfterSort
        for (let ii = 0; ii < children.length; ii++) {
          let objChild = children[ii]
          if (objChild.data) {
            ret.push(objChild.data)
          }
        }
      } else if (obj.data) {
        ret.push(obj.data)
      }
    }
    return ret.slice(0)
  },

  // this returns a snapshot of SORTED row DATA
  // if you need SORTED row NODES, then set returnNodes to true (currently not used)
  // generateNewRowData is called after manual drags, or after toggling the manual drag button
  generateNewRowData (rows, returnNodes, groupIsEnabledModel) {
    let ret = []
    let tobj = null
    let newSort = 0
    if (groupIsEnabledModel) {
      // move group by group, then sort children within
      for (let i = 0; i < rows.length; i++) {
        let objGroup = rows[i]
        if (objGroup?.group) {
          let children = objGroup.childrenAfterSort

          // CHECK NESTED
          for (let ii = 0; ii < children.length; ii++) {
            let objGroup2 = children[ii]

            // DONT GO DEEP NESTED
            // The data is there after grouping above, so can safely skip over groups again
            if (!objGroup2?.group) {
              if (returnNodes) {
                tobj = children[ii]
              } else {
                tobj = cloneDeep(children[ii].data)
              }
              tobj.sort = newSort
              ret.push(tobj)
              newSort++
            }
          }
        }
      }
    } else {
      // sort by child index, groups are ignored
      rows.sort(GridHelpers.fieldSorter(['sort']))
      for (let i = 0; i < rows.length; i++) {
        let obj = rows[i]
        if (obj && !obj.group) {
          if (returnNodes) {
            tobj = obj
          } else {
            tobj = cloneDeep(obj.data)
          }
          tobj.sort = newSort
          ret.push(tobj)
          newSort++
        }
      }
    }
    return ret
  },

  // pass in a style and an assortment, get all matching styles back
  // if you don't pass in assortment, it will check for default this.assortment
  returnMatchingStylesFromAssortment (style, assortment = null) {
    let ret = []
    let retData = []
    if (assortment) {
      retData = this.assortment?.products
    } else {
      let rowNodes = GridHelpers.getRowNodes()
      retData = GridHelpers.extractRowDataFromRowNodes(rowNodes)
    }

    if (retData) {
      ret = retData.filter((x) => x['style'] === style)
    }
    return ret
  },

  determineGroupUngroup () {
    const t = GridHelpers.mgThisArray[0]
    if (t.gridApi) {
      if (t.groupIsEnabledModel) {
        GridHelpers.groupColumns()
      } else {
        GridHelpers.ungroupColumns()
      }

      // also send sort order to back end
      DataMiddleware.prepAndSendSortPacketForBackend(t.groupIsEnabledModel)
    }
  },

  // ungroup
  ungroupColumns (firstPageLoad) {
    const t = GridHelpers.mgThisArray[0]

    t.groupIsEnabledModel = false
    /// t.enabledManagedDragging  = !t.groupIsEnabledModel

    // expand nothing
    // t.gridApi.gridCore.gridOptions.groupDefaultExpanded = 0

    // set grouped column to null
    if (t.gridOptions?.gridApi) {
      t.gridOptions.gridApi.setRowGroupColumns([])
    }

    // unhide hidden group
    // set grouped column
    if (
      router.currentRoute.value.meta.manageType ===
      ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL
    ) {
      if (t.type === 'assortments-list' && t.subtype === 'internal') {
        // LIST OF KEY INITIATIVES
        ColumnHelpers.setColumnVisible('gender', true)
      } else {
        // KEY INITIATIVES ASSORTMENTS
        ColumnHelpers.setColumnVisible('pillar', true)
      }
    } else {
      // REGULAR ASSORTMENTS
      // No longer hiding a column due to how multi-group works in ag-grid
      // ColumnHelpers.setColumnVisible('pillar', true)
      ColumnHelpers.setColumnVisible('style', true)
      // ColumnHelpers.setColumnVisible('color', true)
    }

    // send to back end - as array
    // but not for any of key initiatives
    if (
      router.currentRoute.value.meta.manageType !==
      ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL
    ) {
      if (!firstPageLoad) {
        let obj = {
          groupIsEnabled: t.groupIsEnabledModel
        }
        DataMiddleware.addNewChange([obj], 'settingsUpdate')
      }
    }
  },

  // group
  groupColumns () {
    const t = GridHelpers.mgThisArray[0]
    t.groupIsEnabledModel = true
    // t.enabledManagedDragging  = !t.groupIsEnabledModel

    // set grouped column
    if (
      router.currentRoute.value.meta.manageType ===
      ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL
    ) {
      if (t.type === 'assortments-list' && t.subtype === 'internal') {
        // LIST OF KEY INITIATIVES
        // t.gridApi.gridCore.gridOptions.groupDefaultExpanded = 1
        t.gridOptions.gridApi.setRowGroupColumns(['gender'])
        ColumnHelpers.setColumnVisible('gender', false)
      } else {
        // KEY INITIATIVES ASSORTMENTS
        // t.gridApi.gridCore.gridOptions.groupDefaultExpanded = 1
        t.gridOptions.gridApi.setRowGroupColumns(['pillar', 'style'])
        ColumnHelpers.setColumnVisible('pillar', false)
      }
    } else {
      // REGULAR ASSORTMENTS
      // ColumnHelpers.setColumnVisible(columnId, false)
      // t.gridApi.gridCore.gridOptions.groupDefaultExpanded = 1
      t.gridOptions.gridApi.setRowGroupColumns(['style'])
      // t.gridOptions.gridApi.setRowGroupColumns(['pillar', 'style'])
      // ColumnHelpers.setColumnVisible('pillar', false)
      ColumnHelpers.setColumnVisible('style', false)
      // ColumnHelpers.setColumnVisible('color', false)
    }

    // send to back end - as array
    // but not for any of key initiatives
    if (
      router.currentRoute.value.meta.manageType !==
      ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL
    ) {
      let obj = {
        groupIsEnabled: t.groupIsEnabledModel
      }
      DataMiddleware.addNewChange([obj], 'settingsUpdate')
    }

    // kill group drag
    if (t.enabledManagedDragging) {
      t.disableDrag()
    }
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // DEFAULT MESSAGING
  // default message settings
  getNoRowsToShowMessage () {
    /*
    1. My assortments: "No assortments created. To get started create an assortment above."
    2. Manage Assortments static: "No products added. To get started add product above."
    3. Manage Assortment Dynamic no chips: "No products added. To get started select filters above."
    4. Manage Assortment Dynamic no results: "No results found for the filters applied in your location."
     */
    let value = ''
    if (GridHelpers.isGridReady()) {
      // only show a value once the grid is ready to display them
      // this mostly means, dont flash an error message until the grid starts
      let t = GridHelpers.mgThisArray[0]
      if (t.assortment?.method === ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC) {
        let chips = t.appliedFilterBarChips()
        // adds a delay so the message doesn't flash
        if (ServerSideDatasourceAssortmentManager.readyCount >= 0) {
          // was 3
          if (chips && chips.length > 0) {
            value = 'No results found for the filters applied in your location.'
          } else {
            // no chips
            value = 'No products added. To get started select filters above.'
          }
        }
      } else if (t.assortment?.method === ITS__ASSORTMENTS__METHOD_TYPE__STATIC) {
        // check to make sure there is no row data first
        if (t.assortment?.products.length === 0) {
          value = 'No products added. To get started add your products above.'
        }
      } else if (t.type === 'assortments-list') {
        // check to make sure there is no assortments first
        if (t.assortments.length === 0) {
          value = {
            text: 'No assortments created. To get started create an assortment.',
            type: 'assortments'
          }
        }
      }
    }
    return value
  },
  getLibrariesNoRowsToShowMessage () {
    let value = ''
    if (ServerSideDatasourceLibraries.totalCount === 0) {
      value = 'No assets available.'
    }
    return value
  },
  getSampleInventoryNoRowsToShowMessage () {
    let value = ''
    if (ServerSideDatasourceSampleInventory.totalCount === 0) {
      value = 'No assets available.'
    }
    return value
  },
  getOrdersNoRowsToShowMessage () {
    let value = ''
    if (GridHelpers.isGridReady()) {
      let t = GridHelpers.mgThisArray[0]
      if (t && t.orders?.length === 0) {
        value = 'No orders available.'
      } else {
        // dont even show a message because its annoying
        // value = 'No items available.'
      }
    }
    return value
  },
  getOrdersDetailsNoRowsToShowMessage () {
    let value = ''
    if (GridHelpers.isGridReady()) {
      let t = GridHelpers.mgThisArray[0]
      if (t && t.order?.products.length === 0) {
        value = 'No orders available.'
      }
    }
    return value
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // FILTERING

  // should work, but doesn't - currently not used
  // leaving as a placeholder, maybe aggrid will fix later when they provide a
  collapseAllColumnGroups () {
    let t = GridHelpers.mgThisArray[0]
    const cols = t.gridApi.getColumns()
    for (let i = 0; i < cols.length; i++) {
      let col = cols[i]
      col.expanded = false
    }
    // refresh
    t.gridApi.refreshHeader()
  },

  // get column name from ID
  getColumnNameFromID (colId) {
    let ret = colId
    if (GridHelpers.isGridReady()) {
      let gridApi = GridHelpers.mgThisArray[0].gridApi
      if (gridApi) {
        const cols = gridApi.getColumns()
        for (let i = 0; i < cols.length; i++) {
          let tobj = cols[i]
          if (tobj && tobj?.colId === colId) {
            ret = tobj.colDef?.headerName
            break
          }
        }
      }
    }
    return ret
  },
  getSortedByDescription (sortState) {
    let ret = ''
    if (sortState) {
      sortState.sort((a, b) => a.sortIndex - b.sortIndex)
      for (let i = 0; i < sortState.length; i++) {
        let tobj = sortState[i]
        if (tobj.sort) {
          if (ret !== '') {
            ret += ', '
          }
          ret += GridHelpers.getColumnNameFromID(tobj.colId)
        }
      }
    }
    return ret
  },
  getSortedByItems (sortState) {
    let ret = []
    sortState.sort((a, b) => a.sortIndex - b.sortIndex)
    for (let i = 0; i < sortState.length; i++) {
      let tobj = sortState[i]
      if (tobj.sort) {
        ret.push({
          key: tobj.colId,
          name: GridHelpers.getColumnNameFromID(tobj.colId),
          sort: 'asc'
        })
      }
    }
    return ret
  },
  getSortedByItemsState (sortState) {
    let ret = []
    sortState.sort((a, b) => a.sortIndex - b.sortIndex)
    let cnt = 0
    for (let i = 0; i < sortState.length; i++) {
      let tobj = sortState[i]
      if (tobj.sort) {
        ret.push({
          colId: tobj.colId,
          sort: tobj.sort,
          sortIndex: cnt
        })
        cnt++
      }
    }
    return ret
  },
  clearSortKey (key) {
    const t = GridHelpers.mgThisArray[0]
    const sortState = GridHelpers.mgThisArray[0].gridApi.getColumnState()
    const columnsSorted = sortState.filter((x) => x['sort'] !== null)
    let sortObj = []
    if (columnsSorted.length > 0) {
      sortObj = DataMiddleware.parseAssortmentSort(columnsSorted, false, true)
    }

    // final parse - only push new sort where key is not present
    let finalSortArray = []
    let sortIndex = 0
    for (let tobj in sortObj) {
      if (tobj !== key) {
        finalSortArray.push({
          colId: tobj,
          sort: sortObj[tobj] === -1 ? 'desc' : 'asc',
          sortIndex: sortIndex
        })
        sortIndex++
      }
    }

    t.gridApi.applyColumnState({
      state: finalSortArray,
      defaultState: {
        // important to say 'null' as undefined means 'do nothing'
        sort: null
      }
    })
    t.gridApi.onSortChanged()
  },

  refreshSortModel (doSave = false) {
    for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
      const t = GridHelpers.mgThisArray[z]
      if (t?.gridApi) {
        t.gridApi.refreshClientSideRowModel('sort')
      }
    }

    if (doSave) {
      setTimeout(() => {
        DataMiddleware.generateAndSendSortPacketToBackend()
      }, 750)
    }
  },

  // get a list of all editable columns from active sheet
  getEditableColumns (gridColumns = null, extraSetting = null) {
    if (!gridColumns) {
      const t = GridHelpers.mgThisArray[0]
      gridColumns = t.gridApi.getColumns()
    }
    let excludeColumns = [
      'thumbnail',
      'primaryFile.thumbnail',
      'previewJpg',
      'createdDate',
      'createdByUser',
      'modifiedDate',
      'lastEditedByUser'
    ]

    // we will hard code some extra values for sample inventory
    // this is because it has an edit mode and a read only mode - so it's harded to tell what columns
    // are editable, because someone will try to import in "read only mode" - aka, NO colums are editable
    if (extraSetting === 'sample-inventory-grid') {
      // none for now, i applied the 4 relevant ones above
      // 'createdDate', 'createdByUser', 'modifiedDate', 'lastEditedByUser'
    }

    let ret = []
    for (let i = 0; i < gridColumns.length; i++) {
      let passCheck = false
      // if editable and not in exclude column
      if (
        gridColumns[i].colDef.editable &&
        excludeColumns.includes(gridColumns[i].colId) === false
      ) {
        passCheck = true
      }
      // always show style and color
      if (gridColumns[i].colId === 'style' || gridColumns[i].colId === 'color') {
        passCheck = true
      }

      // Note - editable is skipped here
      if (extraSetting === 'sample-inventory-grid') {
        if (excludeColumns.includes(gridColumns[i].colId) === false) {
          passCheck = true
        }
      }

      // finally :)
      if (passCheck) {
        // despite all the checks above, hide if column is not visible
        if (gridColumns[i].visible) {
          let obj = {
            value: gridColumns[i].colId,
            text: gridColumns[i].colDef.headerName,
            required: gridColumns[i].colId === 'style'
          }
          ret.push(obj)
        }
      }
    }
    return ret
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // expand/collapse everything, except master detail stuff
  // although, if not grouped, then yes, expand the master detail stuff on expand all
  expandAll () {
    const t = GridHelpers.mgThisArray[0]
    if (t?.gridApi) {
      t.gridApi.forEachNode((node) => {
        if (t.type === 'assortments-list') {
          // my assortments - batch these
          node.expanded = true
        } else if (t.groupIsEnabledModel) {
          // just expand groups - not master Details
          if (GridHelpers.isNodeGroup(node)) {
            // DynamicAssortments you can't batch
            if (t.assortment?.method === ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC) {
              node.setExpanded(true)
            } else {
              node.expanded = true
            }
          }
        } else {
          // is not grouped, so just do master details
          if (GridHelpers.hasMasterDetail(node)) {
            // DynamicAssortments you can't batch
            if (t.assortment?.method === ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC) {
              node.setExpanded(true)
            } else {
              node.expanded = true
            }
          }
        }
      })
      if (
        t.assortment?.method === ITS__ASSORTMENTS__METHOD_TYPE__STATIC &&
        t.assortment?.orgType === ITS__ASSORTMENTS__ORG_TYPE__REGULAR
      ) {
        t.gridApi.onGroupExpandedOrCollapsed()
      }
    }
  },

  // collapse all
  collapseAll () {
    const t = GridHelpers.mgThisArray[0]
    if (t?.gridApi) {
      let isServerSide =
        t.assortment?.method === ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC ||
        t.gridOptions.rowModelType === 'serverSide'
      t.gridApi.forEachNode((node) => {
        // DynamicAssortments you can't batch
        if (isServerSide) {
          node.setExpanded(false)
        } else {
          node.expanded = false
        }
      })
      if (!isServerSide) {
        t.gridApi.onGroupExpandedOrCollapsed()
      }
    }
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // FOCUS RELATED
  // does ag grid have focus
  setFocusIn () {
    GridHelpers.focused = true
  },
  setFocusOut () {
    GridHelpers.focused = false
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // SPECIAL COLOR LABEL CHECK
  colorLabelExists (colorToCheck) {
    let ret = false
    if (GridHelpers.mgThisArray[0]) {
      let labels = GridHelpers.mgThisArray[0].assortment.labels
      for (let i = 0; i < labels.length; i++) {
        if (colorToCheck === labels[i].color) {
          ret = true
        }
      }
    }
    return ret
  },
  /// /////////////////////////////////////////////////////////////////////////
  // SERVER SIDE PAGING
  disableServerPaging () {
    const t = GridHelpers.mgThisArray[0]
    t.doServerPagination = false
    t.gridApi.paginationSetPageSize(100000)
  },
  enableServerPaging () {
    const t = GridHelpers.mgThisArray[0]
    t.doServerPagination = true
    t.gridApi.paginationSetPageSize(t.cacheBlockSize)
  },

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // SPECIAL LIBRARY EQUATIONS - is this a valid row to publish? TO CLEAR?
  // NOTE: CROSS CHECK WITH stopColumnFromClear  (these relate but are not identical)
  isLibraryValidToPublish (obj) {
    const assetSlug = router.currentRoute.value.meta.manageType
    switch (assetSlug) {
      case ITS__LIBRARIES__MANAGE_TYPE__STEP__ASSET:
        return obj.name && obj.assetType && obj.lang && obj.gender
      case ITS__LIBRARIES__MANAGE_TYPE__GLOBAL_RETAIL__ASSET:
        return obj.name && obj.assetType && obj.primaryFile && obj.primaryFile._id
      case ITS__LIBRARIES__MANAGE_TYPE__PRODUCT__DOCUMENT:
        return obj.name && obj.docType && obj.primaryFile.groups.length > 0
      case ITS__LIBRARIES__MANAGE_TYPE__ADVERTISING__LOGO:
        return obj.name && obj.category
      case ITS__LIBRARIES__MANAGE_TYPE__ADVERTISING__ASSET:
        let isValid = obj.name && obj.assetType && obj.category && obj.gender
        if (isValid && obj.assetType === 'Marketing Image') {
          // also check for
          if (obj.beginDate && obj.expireDate) {
            let beginDateValid = ColumnHelpers.formatterDate(obj.beginDate)
            let expireDateValid = ColumnHelpers.formatterDate(obj.expireDate)
            isValid = beginDateValid !== 'Invalid Date' && expireDateValid !== 'Invalid Date'
          } else {
            isValid = false
          }
        }
        return isValid
    }
  },
  // for libraries, is column clearable?
  // NOTE: CROSS CHECK WITH isLibraryValidToPublish (these relate but are not identical)
  stopColumnFromClear (colId) {
    let ret = false
    const assetSlug = router.currentRoute.value.meta.manageType
    switch (assetSlug) {
      case ITS__LIBRARIES__MANAGE_TYPE__STEP__ASSET:
        ret = colId === 'name' || colId === 'assetType' || colId === 'lang' || colId === 'gender'
        break
      case ITS__LIBRARIES__MANAGE_TYPE__GLOBAL_RETAIL__ASSET:
        ret = colId === 'name' || colId === 'assetType' // skip primary file because this is handled differently
        break
      case ITS__LIBRARIES__MANAGE_TYPE__PRODUCT__DOCUMENT:
        ret = colId === 'name' || colId === 'docType' || colId === 'primaryFile.groups'
        break
      case ITS__LIBRARIES__MANAGE_TYPE__ADVERTISING__LOGO:
        ret = colId === 'name' || colId === 'category'
        break
      case ITS__LIBRARIES__MANAGE_TYPE__ADVERTISING__ASSET:
        ret =
          colId === 'name' || colId === 'assetType' || colId === 'category' || colId === 'gender'
        if (!ret) {
          // if not stopped already, not check if a Marketing Image has been selected
          let nodes = GridHelpers.getRangeSelectionNodes(true).nodes
          for (let i = 0; i < nodes.length; i++) {
            let obj = nodes[i]
            if (obj.node.data.assetType === 'Marketing Image') {
              ret = colId === 'beginDate' || colId === 'expireDate'
            }
          }
        }
        break
    }
    return ret
  },

  /// /////////////////////////////////////////////////////////////////////////
  // SPECIAL KEY INITIATIVES  VALUES

  // dispatch fetch key assortments initiatives based on dropdown object
  // list is multiple values, but we aren't using that anymore
  dispatchKeyIniativesListFromDropdownObject (t, dropdownObject) {
    // pass default into fetch
    t.$store.dispatch('VUEX_ROUTING_LOADED_ASSORTMENT_MANAGER_INTERNAL', dropdownObject)
  },

  // you can pass in an arrow of names to not include - was added for the copy assortment feature to skip current season/year/region
  getInternalAssortmentNames (t, channelType, skipNamesArray = []) {
    // type is wholesale or retail
    let ret = []
    let propertiesInternalAssortments = []
    try {
      if (channelType === ITS__ASSORTMENTS__CHANNEL_TYPE__WHOLESALE) {
        propertiesInternalAssortments = cloneDeep(
          properties.state.data.InternalAssortments.properties.combinedWholesale.options
        )
      } else if (channelType === ITS__ASSORTMENTS__CHANNEL_TYPE__RETAIL) {
        propertiesInternalAssortments = cloneDeep(
          properties.state.data.InternalAssortments.properties.combinedRetail.options
        )
      }
    } catch (error) {}

    if (propertiesInternalAssortments) {
      for (let i = 0; i < propertiesInternalAssortments.length; i++) {
        let tobj = propertiesInternalAssortments[i]
        let accessRequires = [
          {
            permission: ITS__PERMISSION__PRODUCT__INTERNAL_ASSORTMENTS,
            roles: [ITS__ROLE__USER, ITS__ROLE__ADMIN_EDITOR],
            infoKinds: [
              {
                InternalAssortmentsData: {
                  subRoles: {
                    // orgType: tobj.orgType,
                    channel: tobj.channel,
                    // method: tobj.method,
                    region: [ITS__REGION__ALL, tobj.region],
                    productType: [ITS__PRODUCT_TYPE__ALL, tobj.productType],
                    role: [ITS__ROLE__EDITOR, ITS__ROLE__SENIOR_EDITOR]
                  }
                }
              }
            ]
          }
        ]
        let permittedToAddToList = checkUserPermissions(
          {
            accessRequires: accessRequires
          },
          store
        ).hasPerm

        // only add if not in skip array && has permission
        if (permittedToAddToList && !skipNamesArray.includes(tobj.name)) {
          let obj = {
            // main two lookup: label and value
            text: tobj.name,
            value: tobj.name,

            // extra values for additional reference points
            channel: tobj.channel,
            orgType: tobj.orgType,
            method: tobj.method,
            productType: tobj.productType,
            region: tobj.region,
            season: tobj.season,
            year: String(tobj.year)
          }
          ret.push(obj)
        }
      }
    }

    // special sort
    return _orderBy(ret, ['year', 'season', 'region', 'productType'], ['desc', 'asc', 'asc', 'asc'])
  },

  // pass in a season/year/region, group, and title
  // returns true if unique or fase
  checkIfInternalAssortmentsProposedNameExists (
    proposedMainName,
    proposedGroup,
    proposedTitle,
    proposedId,
    assortments
  ) {
    const proposedFinal = proposedMainName + '--a--' + proposedGroup + '--b--' + proposedTitle
    let proposedNameExists = false
    // let rows = GridHelpers.getRowNodes(false)
    for (let i = 0; i < assortments.length; i++) {
      const rowObj = assortments[i]
      const rowDataObj = rowObj.data ? rowObj.data : rowObj // sometimes I pull this from assortment JSON, sometimes from row nodes
      const existingId = rowDataObj._id
      const existingGroup = rowDataObj.gender
      const existingTitle = rowDataObj.title
      const existingMainName = generateInternalAssortmentName(rowDataObj)
      const existingFinal = existingMainName + '--a--' + existingGroup + '--b--' + existingTitle
      if (existingFinal === proposedFinal) {
        // check to see if IDs match - if they match, then we are editing the existing assortment.  And that is ok.
        // for exmample, editing the logo.
        // but sometimes you don't want to allow this, so just pass a blank through
        if (existingId !== proposedId) {
          proposedNameExists = true
        }

        break
      }
    }

    // message
    if (proposedNameExists) {
      // display an error message
      let dispatchObject = {
        component: '_core/Toast/Toast_Message.vue',
        data: {
          message: 'This assortment already exists.  Please create a unique one.',
          type: 'error'
        }
      }
      GridHelpers.mgThisArray[0].$store.dispatch(VUEX_TOAST_ADD_TO_QUEUE, dispatchObject)
    }

    return proposedNameExists
  }
}
export default GridHelpers
