export const VUEX_CONTROLBAR_SET_DOCUMENT_DATA = 'VUEX_CONTROLBAR_SET_DOCUMENT_DATA'

export const VUEX_CONTROLBAR_GET_STYLES = 'VUEX_CONTROLBAR_GET_STYLES'
export const VUEX_CONTROLBAR_GET_STYLES_SUCCESS = 'VUEX_CONTROLBAR_GET_STYLES_SUCCESS'
export const VUEX_CONTROLBAR_GET_STYLES_FAILURE = 'VUEX_CONTROLBAR_GET_STYLES_FAILURE'
export const VUEX_CONTROLBAR_SET_STYLES_LOADING = 'VUEX_CONTROLBAR_SET_STYLES_LOADING'
export const VUEX_CONTROLBAR_CLEAR_ALL_STYLE_DATA = 'VUEX_CONTROLBAR_CLEAR_ALL_STYLE_DATA'
export const VUEX_CONTROLBAR_CLEAR_STYLE_COLORS_DATA = 'VUEX_CONTROLBAR_CLEAR_STYLE_COLORS_DATA'
export const VUEX_CONTROLBAR_SET_COLORS_LOADING = 'VUEX_CONTROLBAR_SET_COLORS_LOADING'
export const VUEX_CONTROLBAR_SET_COLORS_SUCCESS = 'VUEX_CONTROLBAR_SET_COLORS_SUCCESS'

export const VUEX_CONTROLBAR_GET_WOF_STYLES = 'VUEX_CONTROLBAR_GET_WOF_STYLES'
export const VUEX_CONTROLBAR_GET_WOF_STYLE_COLORS = 'VUEX_CONTROLBAR_GET_WOF_STYLE_COLORS'

export const VUEX_CONTROLBAR_UPDATE_QUERY = 'VUEX_CONTROLBAR_UPDATE_QUERY'
export const VUEX_CONTROLBAR_FETCH_PRODUCTS = 'VUEX_CONTROLBAR_FETCH_PRODUCTS'

export const VUEX_CONTROLBAR_GET_STYLE_COLORS = 'VUEX_CONTROLBAR_GET_STYLE_COLORS'
export const VUEX_CONTROLBAR_GET_STYLE_COLORS_SUCCESS = 'VUEX_CONTROLBAR_GET_STYLE_COLORS_SUCCESS'

export const VUEX_CONTROLBAR_SET_TAB = 'VUEX_CONTROLBAR_SET_TAB'
